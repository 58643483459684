import React, { useState } from "react";
import styled from "styled-components";
import {
  Box,
  Table,
  TableBody,
  InputBase,
  FormControl,
  NativeSelect,
} from "@mui/material";
import API from "utils/brandAPI";
import { useQuery } from "react-query";

const Leaderboard = ({ artistId = "", timePeriod = "" }) => {
  const { isLoading, data, refetch, error } = useQuery(
    ["stats", artistId, timePeriod],
    () => API.get(`/spotify/stats?artistId=${artistId}&after=${timePeriod}`),
    {
      keepPreviousData: true,
      onError: () => {},
      onSuccess(data) {},
      enabled: !!artistId,
    }
  );

  return (
    <Wrapper role="tabpanel">
      {isLoading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error?.message}</div>
      ) : (
        <Table>
          <thead>
            <TableListWrapper>
              <Tableheader style={{ width: "50px" }}>#</Tableheader>
              <Tableheader style={{ width: "175px" }}>Username</Tableheader>
              <Tableheader style={{ width: "120px" }}>
                Email Address
              </Tableheader>
              <Tableheader style={{ width: "120px" }}>
                Total Streams
              </Tableheader>
              <Tableheader style={{ width: "120px" }}>
                Streams per Song
              </Tableheader>
              <Tableheader style={{ width: "120px" }}>
                Total Minutes Listened
              </Tableheader>
              <Tableheader style={{ width: "200px" }}>% Ind.Album</Tableheader>
              <Tableheader style={{ width: "120px" }}>
                % Total Album
              </Tableheader>
              <Tableheader style={{ width: "120px" }}>
                Top Artist / Album
              </Tableheader>
            </TableListWrapper>
          </thead>
          <TableBody>
            {(data?.data?.data || []).map((row, index) => {
              return (
                <TableListWrapper key={`${index}key`}>
                  <TableList width={50}>{index + 1}</TableList>
                  <TableList width={175}>{row.name}</TableList>
                  <TableList width={120}>{row.email}</TableList>
                  <TableList width={120}>
                    {row.trackListens.reduce((a, b) => a + b.count, 0)}
                  </TableList>
                  <TableList width={200}>
                    <ul>
                      {row.trackListens.map((track) => (
                        <li key={track._id}>{track.name}</li>
                      ))}
                    </ul>
                  </TableList>
                  <TableList width={120}>
                    {(row.minutesListenedTotal / 60).toFixed(1)}
                  </TableList>
                  <TableList width={200}>
                    <ul>
                      {row.albumListens.map((album) => (
                        <li key={album.name}>
                          {album.name}: {(album.percent * 100).toFixed(2)}%
                        </li>
                      ))}
                    </ul>
                  </TableList>
                  <TableList width={120}>
                    {(row.totalAlbumListenPercent * 100).toFixed(2)}%
                  </TableList>
                  <TableList width={120}>
                    <ul>
                      {row.topAlbums.map((album) => (
                        <li key={album.name}>{album.name}</li>
                      ))}
                    </ul>
                  </TableList>
                </TableListWrapper>
              );
            })}
          </TableBody>
        </Table>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 32px 48px;
  height: 100%;
  background-color: #1a1a22;
  border-radius: 15px;
  height: fit-content;
`;
const Tableheader = styled.th`
  color: #6096ff;
  padding-block: 15px;
  word-wrap: break-word;
  font-size: 15px;
`;
const TableList = styled.td`
  color: #7f86ad;
  text-align: center;
  padding-block: 15px;
  padding-inline: 7px;
  word-wrap: break-word;
`;
const TotalScore = styled.td`
  color: #fff;
  text-align: center;
  padding-block: 15px;
  padding-inline: 7px;
  word-wrap: break-word;
`;
const TableListWrapper = styled.tr`
  border-bottom: 0.5px solid #352b42;
  border-collapse: collapse;
`;
const Flex = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
`;
export default Leaderboard;
