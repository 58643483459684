import React, { useMemo, useState } from "react";
import styled from "styled-components";
import RewardsTabsBar from "containers/ManageReward/components/RewardsTabsBar";
import { isEmpty } from "utils/object";

const MembershipSection = ({
  membershipData = [],
  membershipDataSummary,
  membershipHoldings,
}) => {
  if (isEmpty(membershipHoldings))
    return <>You haven`t claimed any memberships</>;

  if (membershipData.length === 0) return <></>;

  const [activeTab, setActiveTab] = useState({
    id: membershipData[0]._id,
    name: membershipData[0].name,
    image: membershipData[0].image,
  });

  const progress = useMemo(() => {
    let idx = membershipData.findIndex(
      (item) => item._id === membershipHoldings?.membershipEntry?._id
    );
    idx = idx === -1 ? 0 : idx;
    return 0;
  }, [membershipData]);

  return (
    <Wrapper>
      <img
        src={activeTab.image}
        style={{
          width: "388px",
          height: "388px",
          borderRadius: "12px",
        }}
      ></img>
      <Content>
        <RewardsTabsBar
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          membershipDataSummary={membershipDataSummary}
          isAllRemoved={true}
        />
        <MembershipTitle>{activeTab.name} Membership</MembershipTitle>
        <BarWrapper>
          <Bar>
            <BarProgress progress={progress}></BarProgress>
          </Bar>
          <TextBodyM style={{ textAlign: "end" }}>{progress} pts</TextBodyM>
          <TextBodyM>
            You are {100 - progress} points away from unlocking this membership.
          </TextBodyM>
        </BarWrapper>
        <NextSteps>
          <NextStep>
            <Circle />
            Refer 5 new members sign up
          </NextStep>
          <NextStep>
            <Circle />
            Share link on Twitter
          </NextStep>
          <NextStep>
            <Circle />
            Refer 5 new members sign up
          </NextStep>
        </NextSteps>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const MembershipTitle = styled.div`
  ${(props) => props.theme.typography.header1};
`;

const BarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Bar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 10px;
  width: 100%;
  border-radius: 100px;
  background-color: ${(props) => props.theme.palette.darkGrey};
`;

const BarProgress = styled.div`
  display: flex;
  height: 10px;
  border-radius: 50px;
  width: ${(props) => props.progress + "%"};
  background-color: ${(props) => props.theme.palette.campaignBlue};
`;

const TextBodyM = styled.div`
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.bodyM};
`;

const Circle = styled.div`
  display: flex;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  border: 1px solid;
  border-color: ${(props) => props.theme.palette.lightGrey};
  background-color: ${(props) => props.theme.palette.black};
`;

const NextSteps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const NextStep = styled.div`
  display: flex;
  gap: 16px;
  color: ${(props) => props.theme.palette.lightBlue};
  ${(props) => props.theme.typography.bodyM};
  align-items: center;
`;

export default MembershipSection;
