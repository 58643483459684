/**
 * AddRewardModal
 *
 * @format
 */

import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, ButtonBase } from "@mui/material";
import { Close } from "@mui/icons-material";
import InputBase from "@mui/material/InputBase";
import brandAPI from "utils/brandAPI";
import { Link } from "react-router-dom";

export default function ConnectShopifyModal({
  showModal,
  setShowModal,
  shopifyStoresForBrandID,
  onConncted = () => {},
}) {
  // console.log(shopifyStoresForBrandID);
  const [userName, setUserName] = useState("");

  const handleClose = () => {
    setShowModal(false);
  };

  const [shopifyStoreValue, setshopifyStoreValue] = useState("");

  const handleshopifyStoreValueChange = (event) => {
    const value = event.target.value;
    setshopifyStoreValue(value);
  };

  const [shopifyAccessTokenValue, setshopifyAccessTokenValue] = useState("");

  const handleshopifyAccessTokenValueChange = (event) => {
    const value = event.target.value;
    setshopifyAccessTokenValue(value);
  };

  const createShopifyStoreForBrandId = async () => {
    // console.log(shopifyStoreValue, shopifyAccessTokenValue);
    if (
      shopifyStoresForBrandID &&
      shopifyStoresForBrandID[0]?.shopifyStoreName
    ) {
      if (
        shopifyStoresForBrandID[0]?.shopifyStoreAccessToken !==
        shopifyAccessTokenValue
      ) {
        const response = await brandAPI.post(
          "/shopify/updateShopifyStoreAccessToken",
          {
            shopifyStoreName: shopifyStoreValue,
            shopifyStoreAccessToken: shopifyAccessTokenValue,
          }
        );

        console.log(response?.data);
        await getShopifyStoreByBrand();
      } else {
        window.alert("errror same access token");
      }
    } else {
      if (shopifyStoreValue && shopifyAccessTokenValue) {
        try {
          const response = await brandAPI.post("/shopify/createShopifyStore", {
            shopifyStoreName: shopifyStoreValue,
            shopifyStoreAccessToken: shopifyAccessTokenValue,
          });

          console.log(response?.data);
          await getShopifyStoreByBrand();
        } catch (e) {
          console.log(e);
        }
      } else {
        window.alert("error while posting shopify store");
      }
    }
  };

  useEffect(() => {
    const getShopifyOrdersForShopifyStore = async () => {
      try {
        if (shopifyStoresForBrandID.length > 0) {
          if (shopifyStoresForBrandID[0]?.shopifyStoreName) {
            setshopifyStoreValue(shopifyStoresForBrandID[0]?.shopifyStoreName);
          }
        }
      } catch (error) {
        console.log("get cheapest membership error:", error);
      }
    };

    getShopifyOrdersForShopifyStore();
  }, [shopifyStoresForBrandID]);

  return (
    <ModalLayout
      open={showModal}
      onClose={handleClose}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapperBox>
        <CustomModal
          style={{
            padding: "60px 60px 26px 60px",
          }}
        >
          <MainText>Shopify</MainText>
          <Content>
            <FieldWrapper>
              <Label>Shopify Store URL</Label>
              <FormField
                sx={formFieldOverrides}
                placeholder="Shopify Store URL"
                inputProps={{
                  "aria-label": "username",
                }}
                fullWidth
                value={shopifyStoreValue}
                onChange={handleshopifyStoreValueChange}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Label>Shopify Store Access Token</Label>
              <FormField
                sx={formFieldOverrides}
                placeholder="Shopify Store Access Token"
                inputProps={{
                  "aria-label": "username",
                }}
                fullWidth
                value={shopifyAccessTokenValue}
                onChange={handleshopifyAccessTokenValueChange}
              />
            </FieldWrapper>
            {shopifyStoreValue && (
              <Link to={"/shopifyStores"}>shopify store analytics</Link>
            )}
            <ConnectButton
              onClick={createShopifyStoreForBrandId}
              variant="contained"
            >
              Connect Shopify Store
            </ConnectButton>
          </Content>
        </CustomModal>
      </ModalWrapperBox>
    </ModalLayout>
  );
}

const ModalLayout = styledMui(Modal)(() => ({
  outline: "none",
}));
const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "16px 20px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "24.2px",
  color: "#F4F3EE",
  height: "60px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};
const ModalWrapperBox = styledMui(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "627px",
  background: theme.palette.darkestGrey,
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
}));

const CustomModal = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 60px;
  width: 100%;
  height: 100%;
  outline: "none";
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  overflow: auto;
  margin-top: 32px;
`;
const ConnectButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "18px 20px",
  height: "60px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const Label = styled.div`
  color: #6096ff;
  font-size: 20px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const FormField = styledMui(InputBase)({});

const MainText = styled.div`
  display: flex;
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  width: 100%;
  justify-content: center;
`;
