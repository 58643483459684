/**
 *
 * EditLandingPage
 *
 */

import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { SvgIcon, Button, CircularProgress } from "@mui/material";
import * as svgComponents from "images/icons/svg-components";
import StyledAccordian from "components/StyledAccordian";
import Accordion from "components/Accordion";
import EditLandingPageDisplay from "containers/LandingPage/components/EditLandingPageDisplay/Loadable";
import { useNavigate } from "react-router-dom";
import LandingPageDesignForms from "./components/LandingPageDesignForms/Loadable";
import LandingPageMembershipForms from "./components/LandingPageMembershipForms/Loadable";
import LandingPageStoryForms from "./components/LandingPageStoryForms/Loadable";
import LandingPageFaqForms from "./components/LandingPageFaqForms/Loadable";
import LandingPageHowDoesItWork from "./components/LandingPageHowDoesItWork";
import { isEmpty } from "utils/object";
import { uploadFile } from "utils/s3";
import VisibilityIcon from "@mui/icons-material/Visibility";
import queryString from "query-string";
import { LANDING_PAGE_DEFAULTS } from "utils/constants";
import LogoImg from "images/campaignlogo.png";
import { Link } from "react-router-dom";
import API from "utils/brandAPI";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SaveLandingPageModal from "components/SaveLandingPageModal";
function LandingPageEdit() {
  const [formValues, setFormValues] = useState(LANDING_PAGE_DEFAULTS);
  const [fetchedLandingPage, setFetchedLandingPage] = useState({});
  const queryParams = queryString.parse(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [membershipData, setMembershipData] = useState([]);
  const navigate = useNavigate();
  const [showExitModal, setShowExitModal] = useState(false);
  const [isDesignSaved, setIsDesignSaved] = useState(false);

  const brandId = localStorage.getItem("brandId") || queryParams?.brandId;

  const init = async () => {
    setIsLoading(true);
    const res = await API.get(`/landingPage/${brandId}`);
    setFetchedLandingPage(res.data.data.landingPage);
    if (!isEmpty(res.data.data.landingPage)) {
      setFormValues({
        ...res.data.data.landingPage,
        theme: res.data.data.landingPage.theme || LANDING_PAGE_DEFAULTS.theme,
        howWorks:
          res.data.data.landingPage.howWorks.length > 0
            ? res.data.data.landingPage.howWorks
            : [
                { title: "", body: "" },
                { title: "", body: "" },
                { title: "", body: "" },
              ],
        faq: res.data.data.landingPage.faq || LANDING_PAGE_DEFAULTS.faq,
        brandColor:
          res.data.data.landingPage.brandColor ||
          LANDING_PAGE_DEFAULTS.brandColor,
        memberships:
          res.data.data.landingPage.memberships.length > 0
            ? res.data.data.landingPage.memberships
            : res.data.data.memberships.map((m) => m._id),
          slug:res?.data?.data?.slug
      });
    }

    const mres = await API.get("membership/nonDefault");
    setMembershipData(
      mres.data.data.map((m) => ({
        ...m,
        rewards: res.data.data.rewards.filter((r) => r.membershipId === m._id),
      }))
    );
    setIsLoading(false);
  };
  useEffect(() => {
    init();
    return () => {};
  }, []);

  const handleSave = async () => {
    let updatedFormValues = { ...formValues };

    // upload header img to s3
    let headerImageUrl = formValues.headerImage;
    if (headerImageUrl && headerImageUrl instanceof File) {
      headerImageUrl = await uploadFile(formValues.headerImage);
    }

    updatedFormValues = {
      ...updatedFormValues,
      headerImage: headerImageUrl,
    };
    setIsLoading(true);
    if (isEmpty(fetchedLandingPage)) {
      await API.post(`/landingPage`, updatedFormValues);
      setIsDesignSaved(false)
    } else {
      await API.patch(
        `/landingPage/${brandId}`,
        updatedFormValues
      );
      setIsDesignSaved(false)
    }
    setIsLoading(false);
  };

  const handleGoBack = () => {
    navigate(`/landingpage-preview`);
  };

  const handlePreviewClick = () => {
    navigate(`/landingpage?brandId=${brandId}`);
  };

  const handleChange = (event, name, value) => {
    setIsDesignSaved(true)
    if (name === "headerImage") {
      let updatedBackgroundImage;
      if (event.target.files) {
        const backgroundImage = event.target.files[0];
        const newName = backgroundImage.name.replace(/\s/g, ""); //removing spaces in file's name
        updatedBackgroundImage = new File([backgroundImage], newName, {
          type: backgroundImage.type,
        });
        setFormValues({
          ...formValues,
          [name]: updatedBackgroundImage,
        });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleClickBack = () => {
    isDesignSaved ? setShowExitModal(true):handleGoBack()
  };

  const handleSaveExit = async () => {
    await handleSave();
    navigate(`/landingpage-preview`);
  };

  return (
    <Wrapper>
      <HeaderButtons>
        <BackButton onClick={handleClickBack}>
          <ChevronLeftIcon />
          <span>Back to Dashboard</span>
        </BackButton>
        <PreviewButton onClick={handlePreviewClick}>
          Preview
          <ButtonIcon>
            <VisibilityIcon />
          </ButtonIcon>
        </PreviewButton>
        <SaveButton onClick={handleSave}>
          {isLoading ? <CircularProgress size={28} /> : "Save Design"}
        </SaveButton>
      </HeaderButtons>

      <Content>
        <LeftContent>
          <LeftInnerWrapper>
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <EditLandingPageDisplay
                formValues={formValues}
                membershipData={membershipData}
              />
            )}
          </LeftInnerWrapper>
        </LeftContent>
        <RightContent>
          {/* Design */}
          <Accordion title="Design" id="design-top-scroll-id">
            <LandingPageDesignForms
              formValues={formValues}
              handleChange={handleChange}
            />
          </Accordion>
          {/* Story */}
          <Accordion title="Story" id="story-theme-scroll-id">
            <LandingPageStoryForms
              formValues={formValues}
              handleChange={handleChange}
            />
          </Accordion>

          <Accordion title="How Does It Work?" id="how-does-it-work-scroll-id">
            <LandingPageHowDoesItWork
              formValues={formValues}
              handleChange={handleChange}
            />
          </Accordion>

          {/* Memberships */}
          <Accordion title="Memberships" id="membership-scroll-id">
            <LandingPageMembershipForms
              formValues={formValues}
              handleChange={handleChange}
              membershipData={membershipData}
            />
          </Accordion>

          {/* FAQs */}
          <Accordion title="FAQs" id="faqs-scroll-id">
            <LandingPageFaqForms
              formValues={formValues}
              handleChange={handleChange}
            />
          </Accordion>
        </RightContent>
      </Content>
      <SaveLandingPageModal
        showModal={showExitModal}
        setShowModal={setShowExitModal}
        onClose={handleGoBack}
        onSave={handleSaveExit}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  color: #6096ff;
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  &:hover {
    opacity: 0.7;
  }
`;

const BaseButton = styledMui(Button)(() => ({
  width: 221,
  height: 47,
  borderRadius: 10,
  textTransform: "none",
  fontSize: 20,
  fontFamily: "Poppins",
  "&:hover": {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
}));

const Content = styled.section`
  display: flex;
  width: 100%;
`;

const LeftContent = styled.section`
  display: flex;
  flex-direction: column;
  flex: 5;
  overflow: auto;
  max-height: calc(100vh - 93px);
  height: calc(100vh - 93px);
  background: #1a1a22;
  ::-webkit-scrollbar {
    width: 12px;
    background: transparent;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    /* border: 1px solid ${(props) => props.theme.palette.itemsHover}; */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    cursor: pointer;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
const LeftInnerWrapper = styled.div`
  height: 100%;
  padding: 40px 0;
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
`;
const RightContent = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow: 0px 4px 50px #0d0d14;
  overflow-y: auto;
  max-height: calc(100vh - 93px);
  height: calc(100vh - 93px);
  overflow-x: hidden;
  width: 510px;
  min-width: 510px;
  background: #1a1a22;
  padding: 20px 40px;
  ::-webkit-scrollbar {
    width: 12px;
    background: transparent;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    /* border: 1px solid ${(props) => props.theme.palette.itemsHover}; */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    cursor: pointer;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #352b42;
  height: 92px;
  padding: 0 40px;
  align-items: center;
  img {
    height: 40px;
    margin: auto 0;
  }
`;

const SaveButton = styledMui(BaseButton)(() => ({
  background: "#284AFE",
  borderRadius: "6px",
  color: "#ffffff",
  paddingLeft: "20px",
  paddingRight: "20px",
  width: "fit-content",
  borderRadius: "6px",
  height: "52px",
  fontSize: "18px",
}));

const PreviewButton = styledMui(BaseButton)(() => ({
  border: "1px solid #352B42",
  color: "#6096FF",
  paddingLeft: "12px",
  paddingRight: "12px",
  paddingLeft: "20px",
  paddingRight: "20px",
  width: "fit-content",
  background: "transparent",
  borderRadius: "6px",
  height: "52px",
  marginLeft: "auto",
  marginRight: "20px",
  fontSize: "18px",
}));
const ButtonIcon = styled.span`
  margin-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
  height: 24px;
`;
LandingPageEdit.propTypes = {
  setPage: PropTypes.func,
  formValues: PropTypes.object,
  handleChange: PropTypes.func,
  addQuestion: PropTypes.func,
  membershipData: PropTypes.array,
};

export default memo(LandingPageEdit);
