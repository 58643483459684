import S3 from "react-aws-s3";

const S3_BUCKET = "campaignlayer";
const REGION = "us-west-2";

export const uploadFile = async (file) => {
  const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  };
  const ReactS3Client = new S3(config);
  let res = await ReactS3Client.uploadFile(
    file,
    Date.now().toString() + file.name
  );
  return res.location;
};
