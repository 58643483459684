import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import {
  FormControl,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { connect } from "react-redux";
import { IconUpload } from "images/icons/svg-components";
import makeSelectMembers from "containers/Members/selectors";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { fetchMembers } from "containers/Members/actions";
import MemberTable from "./MemberTable";
import { fetchMemberships } from "containers/ManageMembership/actions";
import makeSelectManageMembership from "containers/ManageMembership/selectors";
import makeSelectManageReward from "containers/ManageReward/selectors";

import { fetchDataAction } from "containers/ManageReward/actions";

const AirdropMembers = ({
  formValues,
  setFormValues,
  fetchMembers,
  fetchMemberships,
  membersState,
  allMemberships,
  isEdit,
  fetchDataAction,
  allrewards,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filterOptions = [];

  const [membershipType, setMembershipType] = useState("");
  const [rewardType, setRewardType] = useState("");

  const { members } = membersState;
  const { allMembershipData } = allMemberships;
  const { rewardData } = allrewards;
  useEffect(() => {
    fetchMembers();
    fetchMemberships();
    fetchDataAction();
  }, []);
  const isBenifit=(Ids)=>
  {
    let flag=false
    Ids.map((id)=>{
      if(id==rewardType)
      {
        flag= true
      }
    })
    return flag
  }
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };
  const handleChange = (newItems) => {
    setFormValues((prev) => ({ ...prev, users: newItems }));
  };

  return (
    <Wrapper>
      <Header>
        <div>
          <RewardTypesGroup>
            <div>Filter by</div>
            <FormControl style={{ width: 220 }}>
              <NativeSelect
                onChange={(event) => {
                  // debugger

                  // handleFilter(event);
                  setMembershipType(event.target.value);
                }}
                value={membershipType}
                label="Filter"
                placeholder="Membership Type"
                input={<FormField />}
                style={{ paddingRight: 8 }}
                IconComponent={() => (
                  <KeyboardArrowDownOutlinedIcon fontSize="small" />
                )}
              >
                <option value="">Membership Type</option>
                {allMembershipData &&
                  allMembershipData.map((option) => (
                    <option key={option.name}>{option.name}</option>
                  ))}
              </NativeSelect>
            </FormControl>
            <FormControl style={{ width: 180 }}>
              <NativeSelect
                value={rewardType}
                style={{ paddingRight: 8 }}
                onChange={(event) => {
                  setRewardType(event.target.value);
                  // handleFilter(event);
                }}
                label="Filter"
                input={<FormField placeholder="Reward Type" />}
                placeholder="Reward Type"
                IconComponent={() => (
                  <KeyboardArrowDownOutlinedIcon fontSize="small" />
                )}
              >
                <option value="" >
                  Reward Type
                </option>
                {rewardData.map((option) => (
                  <option key={option.name} value={option._id}>
                    {option.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </RewardTypesGroup>
          <CustomFormControl variant="standard" style={{ marginTop: 20 }}>
            <InputLabel htmlFor="input-with-icon-adornment" />
            <InputField
              id="input-with-icon-adornment"
              disableUnderline
              placeholder="Search By Email Address"
              value={searchTerm}
              onChange={handleSearchChange}
              startAdornment={
                <InputAdornment position="start"></InputAdornment>
              }
            />
          </CustomFormControl>
        </div>
        <CsvUploadButton>
          Upload CSV <IconUpload />
        </CsvUploadButton>
      </Header>
      <ContentWrapper>
        <MemberTable
          formValues={formValues}
          members={members.filter((member) =>
            member.email.includes(searchTerm) && membershipType
              ? member.membershipName == membershipType
              : true && rewardType ? member.userbenefitsIds.length > 0
              ? isBenifit(member.userbenefitsIds)
              : false:true
          )}
          handleChange={handleChange}
          isEdit={isEdit}
        />
      </ContentWrapper>
    </Wrapper>
  );
};

const CsvUploadButton = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-radius: 6px;
  border: 1px solid #352b42;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
  gap: 12px;
  height: 44px;
`;

const Header = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
`;

const RewardTypesGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
  height: 44px;
  color: #7f86ad;
  font-size: 18px;
`;

const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.bodyM,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "16px 20px",
  color: theme.palette.lightGrey,
  maxHeight: "44px",

  "& .MuiNativeSelect-select": {
    padding: 0,

    "&.MuiInputBase-input": {
      paddingRight: "12px",
    },
  },
}));

const CustomFormControl = styledMui(FormControl)({
  "& .MuiInputBase-root": {
    marginTop: "0",
    width: 500,
  },
});

const InputField = styledMui(Input)(({ theme }) => ({
  ...theme.typography.bodyM,
  width: "402px",
  display: "flex",
  justifyContent: "center",
  paddingLeft: "10px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "16px 20px",
  color: theme.palette.lightGrey,
  maxHeight: "44px",

  "& .MuiInput-input": {
    padding: 0,
    color: theme.palette.lightGrey,
    "&::placeholder": {
      color: theme.palette.lightGrey,
      opacity: 1,
    },
  },
}));

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 16px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const mapStateToProps = createStructuredSelector({
  membersState: makeSelectMembers(),
  allMemberships: makeSelectManageMembership(),
  allrewards: makeSelectManageReward(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchMembers: () => dispatch(fetchMembers()),
    fetchMemberships: () => dispatch(fetchMemberships()),
    fetchDataAction: () => dispatch(fetchDataAction),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(AirdropMembers);
