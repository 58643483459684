import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import makeSelectShopifyStoreOrders from "./selectors";
import PropTypes from "prop-types";
import { fetchShopifyStoreOrders } from "./actions";
import API from "utils/brandAPI";
import styled from "styled-components";
import Layout from "components/Layout";
import { styled as styledMui } from "@mui/material/styles";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { IconFullScreen, IconUpload } from "images/icons/svg-components";
import { Table, TableHead, TableBody, Checkbox } from "@mui/material";
import { formatSreams } from "containers/Analytics/ArtistDashboard/utils";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import AccountDetail from "components/AccountDetails";
import { mainTheme } from "theme/mainTheme";
import { useQuery } from "react-query";

const TwitterIntegration = () => {
  const [showModal, setshowModal] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState({});

  const sortOptions = ["Total Redeemed"];
  const filterOptions = ["Most Popular", "Upload Date", "Price"];

  const [sortType, setsortType] = useState(sortOptions[0]);
  const [filterType, setFilterType] = useState(filterOptions[0]);

  const [searchTerm, setSearchTerm] = useState("");

  const [analyticsData, setAnalyticsData] = useState([]);

  const { isLoading, error } = useQuery(
    ["twitter_analytics"],
    () => API.get("/twitter/analytics"),
    {
      keepPreviousData: true,
      onSuccess: (res) => {
        console.log("twitter data", res.data.data);
        setAnalyticsData(res.data.data || []);
      },
    }
  );

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleRowSelect = (event, emailAddress) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [
        ...prevSelectedRows,
        emailAddress,
      ]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row !== emailAddress)
      );
      if (selectedRows.length == 1) {
        setSelectAll(false);
      }
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allEmailAddresses = data.map((row) => row.email);
      setSelectedRows(allEmailAddresses);
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      setSelectAll(false);
    }
  };

  function CustomCheckbox(props) {
    return (
      <StyledCheckbox
        disableRipple
        checkedIcon={
          <CheckedBox>
            <CheckIcon fontSize="small" sx={{ color: "white" }} />
          </CheckedBox>
        }
        indeterminateIcon={
          <IndeterminateBox>
            <RemoveIcon fontSize="small" />
          </IndeterminateBox>
        }
        icon={<CheckBoxIcon />}
        {...props}
      />
    );
  }

  return (
    <Layout activeTab={"twitter"}>
      <PageWrapper>
        <Header>
          <HeaderWrapper>
            Twitter
            <ExportButton>
              <IconUpload />
              Export Data
            </ExportButton>
          </HeaderWrapper>

          {/* <InputField
            id="input-with-icon-adornment"
            disableUnderline
            placeholder="shopify store"
            value={shopifyStoreValue}
            onChange={handleshopifyStoreValueChange}
            startAdornment={<InputAdornment position="start"></InputAdornment>}
          />
          <InputField
            id="input-with-icon-adornment"
            disableUnderline
            placeholder="shopify store access token"
            value={shopifyAccessTokenValue}
            onChange={handleshopifyAccessTokenValueChange}
            startAdornment={<InputAdornment position="start"></InputAdornment>}
          />
          <ExportButton onClick={createShopifyStoreForBrandId}>
            update
          </ExportButton> */}
        </Header>
        <MainContentWrapper>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableHeadCell
                  style={{ width: "20px", paddingRight: "28px" }}
                >
                  <CustomCheckbox
                    checked={selectAll}
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length <= data.length
                    }
                    onChange={handleSelectAll}
                  />
                </StyledTableHeadCell>
                <StyledTableHeadCell style={{ paddingLeft: 0 }}>
                  Username
                </StyledTableHeadCell>
                <StyledTableHeadCell>Likes</StyledTableHeadCell>
                <StyledTableHeadCell>
                  Tweets With Artist Tag
                </StyledTableHeadCell>
              </StyledTableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <tr>
                  <td
                    colSpan={4}
                    style={{
                      width: "100%",
                      padding: "50px",
                      textAlign: "center",
                    }}
                  >
                    Loading ...
                  </td>
                </tr>
              ) : error ? (
                <tr>
                  <td
                    colSpan={4}
                    style={{
                      width: "100%",
                      padding: "50px",
                      textAlign: "center",
                    }}
                  >
                    {error}
                  </td>
                </tr>
              ) : (
                analyticsData.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableBodyCell style={{ paddingRight: 0 }}>
                      <CustomCheckbox
                        checked={selectedRows.includes(index)}
                        onChange={(event) => handleRowSelect(event, index)}
                      />
                    </StyledTableBodyCell>
                    <StyledTableBodyCell style={{ paddingLeft: 0 }}>
                      {row.username}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>{row?.likes}</StyledTableBodyCell>
                    <StyledTableBodyCell style={{ paddingRight: 0 }}>
                      {row.comments}
                    </StyledTableBodyCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
          <Drawer
            anchor={"right"}
            open={showModal}
            sx={{
              "& .MuiDrawer-paper": {
                width: "29%",
              },
            }}
            onClose={() => {
              setshowModal((prev) => !prev);
            }}
          >
            <ContentWrapper>
              <AccountDetail
                data={selectedModalData}
                onClose={() => {
                  setshowModal((prev) => !prev);
                }}
              />
            </ContentWrapper>
          </Drawer>
        </MainContentWrapper>
      </PageWrapper>
    </Layout>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 60px 40px 0 40px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
  overflow-y: scroll;
`;

const StyledTableRow = styled.tr`
  border-bottom: 1px solid #352b42;
  border-collapse: collapse;
`;

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0;
  }
  &.MuiCheckbox-indeterminate {
    color: ${(props) => props.theme.palette.white} !important;
  }
`;

const CheckBoxIcon = styledMui("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  width: 24,
  height: 24,
  background: theme.palette.lightGrey,
}));

const CheckedBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));

const IndeterminateBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));

const StyledTableHeadCell = styled.th`
  padding: 16px;
  text-align: start;

  && {
    ${(props) => props.theme.typography.bodyMBold};
    color: ${(props) => props.theme.palette.lightBlue};
  }
`;

const StyledTableBodyCell = styled.th`
  padding: 16px;
  text-align: start;

  .icon {
    color: ${(props) => props.theme.palette.lightGrey};
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }

  && {
    ${(props) => props.theme.typography.bodyMBold};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;

const MainContentWrapper = styled.div`
  display: flex;
  border-radius: 12px;
  padding: 40px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
`;

const ExportButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyS,
  display: "flex",
  width: "fit-content",
  borderRadius: 6,
  padding: "8px 20px",
  textTransform: "none",
  gap: "10px",
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
}));

const RewardTypesGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  height: 44px;
`;

const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.bodyM,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "16px 20px",
  color: theme.palette.lightGrey,
  maxHeight: "44px",

  "& .MuiNativeSelect-select": {
    padding: 0,

    "&.MuiInputBase-input": {
      paddingRight: "12px",
    },
  },
}));

const CustomFormControl = styledMui(FormControl)({
  "& .MuiInputBase-root": {
    marginTop: "0",
  },
});

const InputField = styledMui(Input)(({ theme }) => ({
  ...theme.typography.bodyM,
  width: "402px",
  display: "flex",
  justifyContent: "center",
  paddingLeft: "10px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "16px 20px",
  color: theme.palette.lightGrey,
  maxHeight: "44px",

  "& .MuiInput-input": {
    padding: 0,
    color: theme.palette.lightGrey,
    "&::placeholder": {
      color: theme.palette.lightGrey,
      opacity: 1,
    },
  },
}));

const PageWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 28px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.header1};
`;

TwitterIntegration.propTypes = {
  fetchShopifyStoreOrders: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  shopifyStoreOrdersState: makeSelectShopifyStoreOrders(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchShopifyStoreOrders: () => dispatch(fetchShopifyStoreOrders()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(TwitterIntegration);
