/**
 *
 * QrCodeModalDesignForms
 *
 */

import React, { memo, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import InputBase from "@mui/material/InputBase";
import { Button, FormControl, NativeSelect } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import { MuiColorInput } from "mui-color-input";
import { RemoveButton, handleRemoveFile } from "components/RemoveButton";
import SelectBenfitModal from "components/SelectBenefitModal";
import RewardCard from "../RewardCard"
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
//import SelectBenfitModal from 'src/components/SelectBenfitModal'
import { LANDING_PAGE_HEADER_IMAGE_SIZE_LIMIT } from "utils/constants";

function QrCodeModalDesignForms({ formValues, handleChange, rewards }) {
  const {
    headerImage,
    fontFamily,
    text
  } = formValues;
  const [headerImageError, setHeaderImageError] = useState("");
  const headerRef = useRef();
  const [headerRemovable, setHeaderRemovable] = useState(
    headerImage === "" ? false : true
  );
  let qrCodeModel = JSON.parse(localStorage.getItem("qrCodeModel"));
  const [showSelectBenfitModal, setShowSelectBenfitModal] = useState(false);
  


  return (
    <Fields>
      <Block>
        <Label>Benefits</Label>
        <Sublabel>
          Select a reward for customer&apos;s participation.
        </Sublabel>
        <FormControl>
        <ShowBenefitsButton onClick={() => setShowSelectBenfitModal(true)}>
          Select Benefit
        </ShowBenefitsButton>
        <SelectBenfitModal
        showModal={showSelectBenfitModal}
        setShowModal={setShowSelectBenfitModal}
        rewardCards={renderRewardCards(rewards)}
        saveBenefits={handleChange}
      />
        </FormControl>
        <BenefitSublabel>
          Benefit <BenefitName>{qrCodeModel?.benefitName}</BenefitName>
        </BenefitSublabel>
      </Block>
      <Block>
        <Label>Font</Label>
        <FormControl fullWidth>
          <NativeSelect
            id="demo-customized-select-native"
            value={fontFamily}
            style={{ color: "#6096FF", background: "#21212E", border: "none" }}
            onChange={(event) => {
              handleChange(event, "fontFamily");
            }}
            input={
              <FormField sx={{ ...formFieldOverrides, margin: "8px 0 0 0" }} />
            }
          >
            <option value="Inter">Inter</option>
          </NativeSelect>
        </FormControl>
      </Block>
      <Block>
        <Label>Text</Label>
        <Sublabel>Maximum 100 characters.</Sublabel>
        <Content>
          {text.slice(0, 2).map((textItem, index) => {
            return (
              <FormField
                inputProps={{
                  "aria-label": `text${index}`,
                  maxLength: "100",
                }}
                fullWidth
                value={textItem}
                onChange={(event) => {
                  handleChange(event, "text", event.target.value, index);
                }}
              />
            );
          })}
        </Content>
      </Block>
    </Fields>
  );
}


function renderRewardCards(rewards) {
  const rewardCards = rewards.filter(({ category }) => category === "Badge")
  .map(
    (
      {
        name,
        startDate,
        endDate,
        startTime,
        endTime,
        timezone,
        description,
        image,
        redemptionCount,
        isDraft,
        category,
        _id,
        membershipTier,
        membershipId,
      },
      index
    ) => {

      return (
        <RewardCard
          {...{
            name,
            startDate,
            endDate,
            startTime,
            endTime,
            timezone,
            description,
            image,
            redemptionCount,
            isDraft,
            category,
            id: _id,
            membershipTier,
            membershipId
          }}
        />
      );
    }
  );
  return rewardCards;
}



const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Error = styled.div`
  color: #eb0859;
  font-size: 14px;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

export const Label = styled(BaseText)`
  width: 100%;
  font-size: 18px;
  margin-left: 4px;
  margin-top: 20px;
  font-family: "inter";
  font-weight: 500;
`;

export const Sublabel = styled(Label)`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.75);
  width: 100%;
  margin: 0px 4px;
  color: #7f86ad;
`;

export const BenefitSublabel = styled(Label)`
  font-size: 16px;
  color: #6096FF;
  width: 100%;
  margin: 0px 4px;
  font-weight: 500;
`;

export const BenefitName = styled.span`
  font-size: 16px;
  color: #6096FF;
  width: 100%;
  margin: 0px 4px;
  color: #F4F3EE;
  font-weight: 400;
`;

const ShowBenefitsButton = styledMui(Button)(() => ({
  width: '100%',
  height: 40,
  background: "#21212E",
  border: "1px solid #21212E",
  borderRadius: 8,
  fontWeight: 400,
  fontSize: 16,
  color: "#6096FF",
  justifyContent: "center",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
  padding: "6px 8px",
  marginTop: 10,
  marginBottom: 10,
  marginRight: 5,
  fontFamily: "Poppins",
  fontWeight: 500
}));

export const FormField = styledMui(InputBase)({
  display: "flex",
  borderRadius: 10,
  backgroundColor: "rgba(217, 217, 217, 0.2)",
  color: "#7F86AD",
  margin: "8px 0",
  height: 38,
  fontSize: 16,
  padding: "10px 15px",
  fontFamily: "Poppins",
  background: "#21212E",
  border: "1px solid #21212E",
  lineHeight: "28px",
  borderRadius: "8px",
  "&::placeholder": {
    color: "#7F86AD",
  },
});

const formFieldOverrides = {
  border: "1px solid #D9D9D9",
};

QrCodeModalDesignForms.propTypes = {
  formValues: PropTypes.object,
  handleChange: PropTypes.func,
};

export default memo(QrCodeModalDesignForms);
