import React, { memo, useEffect, useState } from "react";
import { styled as styledMui } from "@mui/material/styles";
import styled from "styled-components";
import { IconPin } from "images/icons/svg-components";
import Percentage from "./Percentage";
import { formatSreams } from "../utils";

const SongCard = ({ data }) => {
  const { id, name, totalStream, weekAgoTotalStream, streamGrowthRate } = data;

  return (
    <Wrapper>
      <UpperPart>
        <SongTitle>
          <span className="title">{name}</span>
        </SongTitle>
        <StreamCount>
          <span>{formatSreams(totalStream)}</span>{" "}
          <span style={{ color: "#7F86AD" }}>streams</span>
        </StreamCount>
      </UpperPart>
      <LowerPart>
        <Percentage value={streamGrowthRate}></Percentage>
        <Time>from last week</Time>
      </LowerPart>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 32px;
  gap: 20px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
`;

const UpperPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LowerPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SongTitle = styled.div`
  width: 192px;
  height: 72px;
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.lightBlue};
  ${(props) => props.theme.typography.header2};
  gap: 4px;

  .title {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit the text to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StreamCount = styled.div`
  color: ${(props) => props.theme.palette.lightBlue};
  ${(props) => props.theme.typography.header3};
`;

const Time = styled.div`
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.header3};
`;

export default SongCard;
