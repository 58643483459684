/*
 *
 * DiscordStoreOrders actions
 *
 */

import { DEFAULT_ACTION, FETCH_SERVERS } from "./constants";

import API from "utils/brandAPI";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export const fetchDiscordServers= async (brandId) => {
  return async (dispatch) => {
    API.get(`/discord/servers/${brandId}`)
      .then((result) => {
        dispatch({ type: FETCH_SERVERS, payload: result.data });
      })
      .catch((err) => console.log(err));
  };

};

export const fetchMembers = () => {
  return async (dispatch) => {
    API.get("brands/members")
      .then((result) => {
        dispatch({ type: FETCH_MEMBERS, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};


