import React, { useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconCopy,
  IconMail,
  IconSpotify,
  IconWallet,
} from "images/icons/svg-components";
import { mainTheme } from "theme/mainTheme";
import { ImageUserPortalDefault, ImageUserProfileMock } from "images";
import { Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { formattedDate } from "utils/date";
import { isEmpty } from "utils/object";
import queryString from "query-string";
import qs from "qs";
import { MembershipData } from "containers/ManageMembership/mock";
import DiscordVerified from "components/UserModal/components/DiscordVerify";
import YoutubeVerified from "components/UserModal/components/YoutubeVerify";
import TwitterVerified from "components/UserModal/components/TwitterVerify";
import InstagramVerified from "components/UserModal/components/InstagramVerify";
import FacebookVerified from "components/UserModal/components/facebookVerify";
// import TwitterShareButton from "components/TwitterShare";

const AccountDetail = ({
  onClose,
  data,
  totalRewardForUser,
  userId,
  totalReward,
  membershipData,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const queryParams = queryString.parse(location.search);
  const brandId = queryParams?.brandId || localStorage.getItem("brandId");
  const handleCopyClick = () => {
    navigator.clipboard.writeText(data?.user?.walletAddress ?? "");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const renderRecentActivities = () => {
    const recentActivities =
      data?.recentActivity

    return recentActivities.length === 0 ? (
      <NoActivity>No activity</NoActivity>
    ) : (
      recentActivities.map((item, idx) => (
        <div className="recentActivity" key={`${idx}key`}>
          {item.rewardName.length > 0 ? (
            <div className="title">Joined {item.rewardName[0].name}</div>
          ) : item.membershipName.length > 0 ? (
            <div className="title">Joined {item.membershipName[0].name}</div>
          ) : (
            ""
          )}
          <div className="date">{formattedDate(item.date, 2)}</div>
        </div>
      ))
    );
  };

  return (
    <Wrapper className={onClose ? "gap" : ""}>
      {/* {data?.membership && (
        <> */}
      <Box>
        <HeaderWrapper>
          <Header>Account</Header>
          {onClose && <CloseIcon className="icon" onClick={onClose} />}
        </HeaderWrapper>
        <div className="account">
          <div className="content">
            <IconMail
              style={{
                height: "24px",
                width: "24px",
                color: mainTheme.palette.lightBlue,
                flexShrink: 0,
              }}
            />
            <span className="bodyM" style={{ wordBreak: "break-all" }}>
              {data?.user?.email}
            </span>
          </div>
          <div className="content">
            <IconSpotify
              style={{
                height: "24px",
                width: "24px",
                color: "#1ED760",
              }}
            />
            <span className="bodyM" style={{ wordBreak: "break-all" }}>
              {data?.user?.spotifyId === "" ||
                data?.user?.spotifyId === undefined
                ? "Not Authenticated"
                : data?.user?.spotifyId}
            </span>
          </div>
          {/* <YoutubeVerified formValues={data?.user} brandId={brandId} />
          <TwitterVerified
            userData={data?.user}
            brandId={brandId}
            userId={userId}
          />
          <DiscordVerified formValues={data?.user} brandId={brandId} />

          <InstagramVerified formValues={data?.user} brandId={brandId} /> */}

          {/* <FacebookVerified formValues={data?.user} brandId={brandId} /> */}

          {/* <div className="content">
            <IconWallet
              style={{
                height: "24px",
                width: "24px",
                stroke: mainTheme.palette.lightBlue,
                flexShrink: 0,
              }}
            />

            <span className="walletAddress">{data?.user?.walletAddress}</span>
            <IconCopy
              style={{
                cursor: "pointer",
                flexShrink: 0,
                color: isCopied
                  ? mainTheme.palette.white
                  : mainTheme.palette.lightBlue,
              }}
              onClick={handleCopyClick}
            />
          </div> */}
        </div>
      </Box>
      <Box>
        <HeaderWrapper>
          <Header>Wallet Address</Header>
        </HeaderWrapper>
        <div className="account">
          <div
            className="content"
            style={{ background: "#21212E", padding: "10px" }}
          >
            {/* <IconWallet
              style={{
                height: "24px",
                width: "24px",
                stroke: mainTheme.palette.lightBlue,
                flexShrink: 0,
              }}
            /> */}
            <span className="bodyM" style={{ wordBreak: "break-all" }}>
              {data?.user?.walletAddress}
            </span>
            <IconCopy
              style={{
                cursor: "pointer",
                flexShrink: 0,
                color: isCopied
                  ? mainTheme.palette.white
                  : mainTheme.palette.lightBlue,
              }}
              onClick={handleCopyClick}
            />
          </div>
        </div>
      </Box>
      <Box>
        <Header>Recent Activity</Header>
        <Divider
          sx={{
            width: "100%",
            borderLeft: "1px solid #352B42",
            borderColor: mainTheme.palette.darkGrey,
          }}
        />
        {renderRecentActivities()}
      </Box>
      {/* </> */}
      {/* )} */}
    </Wrapper>
  );
};
const NoActivity = styled.div`
  color: #eee;
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};

  .icon {
    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.lightGrey};

  .icon {
    color: ${(props) => props.theme.palette.lightGrey};
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
  gap: 40px;

  &.gap {
    gap: 48px;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 768px) {
    :nth-of-type(1) {
      order: 2;
    }
    :nth-of-type(2) {
      order: 1;
    }
    :nth-of-type(3) {
      order: 3;
    }
    :nth-of-type(4) {
      order: 4;
    }
    :nth-of-type(5) {
      order: 5;
    }
    .membership-header {
      display: none;
    }
    .account {
      .membership {
        border: none;
        flex-direction: column;
        img {
          width: 209px;
          height: 209px;
          border-radius: 6px;
          object-fit: cover;
        }
        .text-wrapper {
          gap: 8px;
          .header {
            display: block;
            margin: 0;
            text-align: center;
          }
          .bodyS {
            text-align: center;
          }
          .header2 {
            display: none;
          }
        }
      }
    }
  }

  .recentActivity {
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-bottom: 1px solid #352b42;
    padding-bottom: 20px;
    .title {
      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.white};
    }
    .date {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .walletAddress {
    ${(props) => props.theme.typography.bodyM};
    color: ${(props) => props.theme.palette.white};
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .stats {
    display: flex;
    justify-content: space-around;

    .stat-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .header {
        ${(props) => props.theme.typography.header}
        color: ${(props) => props.theme.palette.white};
        margin-bottom: 0px;
      }

      .bodyS {
        ${(props) => props.theme.typography.bodyS}
        color: ${(props) => props.theme.palette.lightGrey};
      }
    }
  }

  .account {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .content {
      display: flex;
      align-items: center;
      padding: 5px 0;
      border-radius: 6px;
      gap: 10px;

      .bodyM {
        ${(props) => props.theme.typography.bodyM}
        color: ${(props) => props.theme.palette.white};
      }
    }
  }

  .membership {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 24px;

    border: 1px solid #6096ff;
    border-radius: 6px;

    img {
      height: 80px;
      width: 80px;
      border-radius: 8px;
      opacity: 80%;
      object-fit: cover;
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .header {
        display: none;
      }
      .header,
      .header2 {
        ${(props) => props.theme.typography.header2}
        color: ${(props) => props.theme.palette.white};
      }
      .bodyS {
        ${(props) => props.theme.typography.bodyS}
        color: ${(props) => props.theme.palette.white};
      }
    }
  }
`;
export default AccountDetail;
