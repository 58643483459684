import React from "react";
import { styled as styledMui } from "@mui/material/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Button } from "@mui/material";

export const FilterButton = ({
  label,
  onClick,
  ariaControls,
  ariaExpanded,
  ariaHaspopup,
  id,
  eleRef,
}) => {
  return (
    <EditButton
      id={id}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      aria-haspopup={ariaHaspopup}
      onClick={onClick}
      ref={eleRef}
      endIcon={<FilterListIcon />}
    >
      {label}
    </EditButton>
  );
};

const BaseButton = styledMui(Button)(() => ({
  width: 157,
  height: 38,
  borderRadius: 10,
  textTransform: "none",
  fontSize: 16,
  fontFamily: "Poppins",
  "&:hover": {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
}));

const EditButton = styledMui(BaseButton)(() => ({
  background: "transparent",
  border: "2px solid #E5E5E5",
  borderRadius: 12,
  width: "auto",
  color: "#ffffff",
  marginRight: 16,
  padding: "20px 8px",
  "&:last-of-type": {
    marginRight: 0,
  },
}));
