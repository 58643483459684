import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, Divider, ButtonBase } from "@mui/material";
import { ImageCampaignLogo } from "images";
import { IconMail, IconVerified } from "images/icons/svg-components";
import { IconSpotify } from "images/icons/svg-components";
import UserAPI from "utils/userAPI";
import qs from "qs";
import { InstagramIcon } from "lucide-react";
import { AiFillInstagram } from "react-icons/ai";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const InstagramVerified = ({
  formValues,
  error,
  brandId,
  isBrand,
  onClose = () => {},
  onClaimMembership = () => {},
}) => {
  const { email } = formValues;

  const redirectUri = `${process.env.REACT_APP_API_URL}/api/v1/instagram/callback`; // Replace with your redirect URI

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [instagramMediaData, setInstagramMediaData] = useState(null);

  useEffect(() => {
    UserAPI.get("instagram/user").then((res) => {
      console.log(res, "responseinstagram");
      if (res.data?.user) {
        setIsAuthenticated(true);
        setUserData(res.data.user);
        setInstagramMediaData(res?.data?.instagram_media);
      } else {
        setIsAuthenticated(false);
      }
    });

    return () => {};
  }, []);

  const handleLogin = () => {
    const scope = "user_profile,user_media";
    // "user_profile,user_media,instagram_graph_user_media,instagram_graph_user_profile,instagram_basic,pages_read_engagement,pages_show_list";

    const state = JSON.stringify({
      brandId,
      email,
      // isBrand: isBrand ? true : false,
    });

    const stringify = qs.stringify({
      response_type: "code",
      client_id: process.env.REACT_APP_INSTAGRAM_CLIENT_ID,
      scope: scope,
      redirect_uri: process.env.REACT_APP_INSTAGRAM_REDIRECT_URI,
      state: state,
    });
    const authUrl = "https://api.instagram.com/oauth/authorize?" + stringify;

    window.location.assign(authUrl);
  };

  return (
    <>
      {isAuthenticated ? (
        // <Wrapper>
        //   <InstagramIcon color="blue" /> <div>{userData?.username}</div>
        // </Wrapper>
        <IntegrationItemWrapper>
          <IntegrationIcon>
            <AiFillInstagram />
          </IntegrationIcon>
          <IntegrationText>Instagram</IntegrationText>
          {userData?.username}
          <IntegrationButton onClick={handleLogin} isConnected={true}>
            {"Connected"}
            <CheckCircleIcon
              style={{ height: "16px", width: "16px", margin: 0 }}
            ></CheckCircleIcon>
          </IntegrationButton>
        </IntegrationItemWrapper>
      ) : (
        <IntegrationItemWrapper>
          <IntegrationIcon>
            <AiFillInstagram />
          </IntegrationIcon>
          <IntegrationText>Instagram</IntegrationText>
          <IntegrationButton onClick={handleLogin}>
            {"Connect"}
          </IntegrationButton>
        </IntegrationItemWrapper>
      )}
    </>
  );
};

const InstagramButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.white,
  textTransform: "none",
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "18px 20px",
  gap: "20px",
  height: "40px",
  width: "250px",
  fontSize: "15px",
  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));

const IntegrationItemWrapper = styled.div`
  display: flex;
  padding: 20px 20px 20px 28px;
  align-items: center;
  width: 100%;
  align-self: stretch;
  border-radius: 8px;
  background: var(--darker-grey, #21212e);
  justify-content: space-between;
  .left-content {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  @media (max-width: 768px) {
    background: none;
    padding: 10px 20px;
    border-bottom: 1px solid #21212e;
    max-width: none;
  }
`;

const IntegrationIcon = styled.div`
  > * {
    width: 32px;
    height: 32px;
    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
  }
  color: var(--light-grey, #7f86ad);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IntegrationText = styled.div`
  color: var(--light-grey, #7f86ad);
  ${"" /* width: 260px; */}
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const IntegrationButton = styled.button`
  display: flex;
  width: 100%;
  max-width: 155px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--darkest-grey, #1a1a22);
  @media (max-width: 768px) {
    max-width: 105px;
  }
  color: ${(props) =>
    props.isConnected ? (props.isHovered ? "#6096FF" : "#0BD680") : "#7f86ad"};

  border: ${(props) =>
    props.isConnected
      ? props.isHovered
        ? "1px solid #6096FF"
        : "1px solid transparent"
      : "1px solid transparent"};
`;

const CustomModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--darkest-grey, #1a1a22);
  border-radius: 16px;
  width: 450px;
  height: ${(props) => (props.isLarge ? "370px" : "280px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding: 60px 60px 60px 60px;
  align-self: stretch;
  > * {
    zoom: 0.8;
  }
`;

const Modalheader = styled.div`
  color: var(--white, #f4f3ee);
  text-align: center;
  margin-bottom: 32px;
  /* Header I */
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Modaltitles = styled.div`
  color: var(--light-blue, #6096ff);
  /* Header II */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Modalinput = styled.input`
  margin-top: 8px;
  width: 100%;
  height: 60px;
  background: var(--darkest-grey, #1a1a22);
  border-radius: 8px;
  border: 1px solid var(--dark-grey, #352b42);
  color: var(--white, #f4f3ee);
  padding: 0 16px;
  margin-bottom: 28px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export default InstagramVerified;
