/**
 *
 * Dashboard
 *
 */

import React, { memo, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import LeftMenu from "components/LeftMenu/Loadable";
import AppHeader from "components/AppHeader";
import MembershipContractModal from "components/MembershipContractModal";
import RewardContractModal from "components/RewardContractModal";
import logo from "images/icons/girlscrewicon.svg";
const brandInfo = {
  name: "Girls Crew",
  logo,
};
export default function Layout({
  children,
  title,
  metaDescription,
  activeTab,
}) {
  // const [activeTab, setActiveTab] = useState("analytics");
  const [selectedMembership, setSelectedMembership] = useState({});
  const [selectedReward, setSelectedReward] = useState({});
  const [page, setPage] = useState("rewardCards");
  return (
    <>
      <AppHeader brandInfo={brandInfo} />
      <DashboardWrapper>
        <Helmet>
          <title>Dashboard</title>
          <meta name="description" content="Description of Dashboard" />
        </Helmet>
        <AppContent className="container-fluid">
          <LeftMenu
            activeTab={activeTab}
            // setActiveTab={setActiveTab}
            setManageRewardPage={setPage}
            setSelectedMembership={setSelectedMembership}
          />
          <Content>{children}</Content>
        </AppContent>
      </DashboardWrapper>
      <MembershipContractModal />
      <RewardContractModal />
    </>
  );
}
const DashboardWrapper = styled.div`
  height: 100%;
`;

const AppContent = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
`;

const Content = styled.div`
  flex: 1;
  padding: 20px 40px;
  width: 80%;
  display: flex;
  height: calc(100% - 92px); // 92 px is header height
  overflow: auto;
`;
