import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import AppHeaderUser from "components/AppHeaderUser";
import IntegrationItem from "./components/IntegrationItem";
import { AiFillInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { SiApplemusic } from "react-icons/si";
import { BsSpotify } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsDiscord } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { FaShopify } from "react-icons/fa";
import axios from "axios";
import { Link } from "react-router-dom";
import API from "utils/brandAPI";
import { useParams } from "react-router-dom";
import InstagramVerified from "components/UserModal/components/InstagramVerify";
import queryString from "query-string";
import Verified from "../UserOnboarding/components/Verified";
import USERAPI from "utils/userAPI";
import YoutubeVerified from "components/UserModal/components/YoutubeVerify";

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;

const TabButton = styled.button`
  padding: 5px;
  margin: 0 10px;
  font-size: 25px;
  max-width: 160px;
  border: none;
  background-color: transparent;
  color: ${(props) => (props.active ? "#F4F3EE" : "#6096FF")};
  cursor: pointer;
`;

const TabContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
`;

const IntegrationsDisp = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 60px;
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const UserBrandMembershipPage = () => {
  const [activeTab, setActiveTab] = useState("Memberships");
  const [brands, setBrands] = useState([]);
  const [email, setEmail] = useState("");
  const [searchText, setSearchText] = useState("");
  const { brandId } = useParams(); // Access the brandId from the URL parameter
  const [formValues, setFormValues] = useState("");

  const queryParams = queryString.parse(location.search);

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    fetchBrands();
    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (queryParams?.connect) {
      handleTabChange("Connect");
    }
  }, []); // Empty dependency array to run the effect only once

  const fetchUserProfile = async () => {
    const userProfile = await USERAPI.get(`users/profile`);
    console.log(userProfile, "userprofile");
    if (userProfile?.data?.data?.user?.email) {
      setEmail(userProfile?.data?.data?.user?.email);
      setFormValues(userProfile?.data?.data?.user);
    }
  };

  // console.log(brands);
  const fetchBrands = async () => {
    try {
      const response = await API.get(`membership/?brand=${brandId}`);

      console.log(response, "response");
      if (response.data.status === "success") {
        setBrands(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const filteredBrands = brands.filter((brand) =>
    brand.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div>
      <AppHeaderUser />
      <TabsContainer>
        <TabButton
          active={activeTab === "Memberships"}
          onClick={() => handleTabChange("Memberships")}
        >
          Memberships
        </TabButton>
        <TabButton
          active={activeTab === "Connect"}
          onClick={() => handleTabChange("Connect")}
        >
          Connect
        </TabButton>
      </TabsContainer>
      {activeTab === "Memberships" && (
        <TabContent>
          <MembershipsDisp>
            <ItemDisp>
              <ItemText>Memberships</ItemText>
              <GridContainer>
                {filteredBrands.map((reward) => (
                  <GridItemLink
                    key={reward._id}
                    to={`/userhome/${brandId}/${reward._id}`}
                  >
                    <ItemImage src={reward.image} />
                  </GridItemLink>
                ))}
              </GridContainer>
            </ItemDisp>
          </MembershipsDisp>
        </TabContent>
      )}
      {activeTab === "Connect" && (
        <TabContent>
          <IntegrationsDisp>
            <ButtonColumn>
              {/* <IntegrationItem
                icon={<BsSpotify />}
                itemText="Spotify"
                buttonText="Connect"
              /> */}
              {/* <Verified
                formValues={{ email }}
                brandId={brandId}
                isBrand={true}
              /> */}
              <IntegrationItem
                icon={<AiOutlineTwitter />}
                itemText="Twitter"
                buttonText="Connect"
              />
              {/* <InstagramVerified
                formValues={{ email }}
                brandId={brandId}
                isBrand={true}
              /> */}
              {/* <IntegrationItem
                icon={<AiFillInstagram />}
                itemText="Instagram"
                buttonText="Connect"
              /> */}
              <IntegrationItem
                icon={<SiApplemusic />}
                itemText="Apple Music"
                buttonText="Connect"
              />
            </ButtonColumn>
            <ButtonColumn>
              <YoutubeVerified
                formValues={{ email }}
                brandId={brandId}
                isBrand={false}
                data={formValues}
                fetchUserProfile={fetchUserProfile}
              />
              <IntegrationItem
                icon={<BsDiscord />}
                itemText="Discord"
                buttonText="Connect"
              />
              <IntegrationItem
                icon={<FaTiktok />}
                itemText="Tiktok"
                buttonText="Connect"
              />
              <IntegrationItem
                icon={<FaShopify />}
                itemText="Shopify"
                buttonText="Enter Details"
              />
            </ButtonColumn>
          </IntegrationsDisp>
        </TabContent>
      )}
    </div>
  );
};

const MembershipsDisp = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemDisp = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  margin-bottom: 50px;
`;

const ItemText = styled.div`
  font-size: 25px;
  color: #fff;
  margin-bottom: 24px;
`;

const ItemImagesDisp = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
`;

const ItemImage = styled.img`
  width: 280px;
  height: 280px;
  border-radius: 6px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  max-width: 1200px;
  width: 100%;
`;

const GridItemLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: inherit;
`;
export default UserBrandMembershipPage;
