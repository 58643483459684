import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import makeSelectMembers from "./selectors";
import PropTypes from "prop-types";
import brandAPI from "utils/brandAPI";
import { fetchMembers } from "./actions";
import API from "utils/brandAPI";
import styled from "styled-components";
import Layout from "components/Layout";
import { styled as styledMui } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress } from "@mui/material";
import { useQuery } from "react-query";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import queryString from "query-string";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { IconFullScreen, IconUpload } from "images/icons/svg-components";
import { Table, TableHead, TableBody, Checkbox } from "@mui/material";
import { formatSreams } from "containers/Analytics/ArtistDashboard/utils";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import AccountDetail from "components/AccountDetails";
import { mainTheme } from "theme/mainTheme";
import makeSelectManageBrand from "containers/ManageBrand/selector";
import { AiFillInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { SiApplemusic } from "react-icons/si";
import { BsSpotify } from "react-icons/bs";
import { BsTwitch } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsDiscord } from "react-icons/bs";
import { FaShopify } from "react-icons/fa";

const Members = ({ fetchMembers, membersState, brandState }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = React.useState(false);

  const [showModal, setshowModal] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState({});

  const sortOptions = ["Total Redeemed"];
  const filterOptions = ["Most Popular", "Upload Date", "Price"];
  const queryParams = queryString.parse(location.search);
  const [selectedServersAndRoles, setSelectedServersAndRoles] = useState([]);

  const [sortType, setsortType] = useState(sortOptions[0]);
  const [filterType, setFilterType] = useState(filterOptions[0]);
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const handleFilter = () => {};

  const init = async () => {
    if (brandState.brand._id) {
      const discordSelectedServers = await brandAPI.get(
        `/discord/selected/${brandState.brand._id}`
      );
      setSelectedServersAndRoles(discordSelectedServers.data.data.servers);
    }
  };

  useEffect(() => {
    // Fetch members on screen load only
    init();
    // can't console log here becasue of the await async dealy
  }, []);

  useEffect(() => {
    if (brandState.brand._id && queryParams.valid) {
      uploadMembersToSalesForce();
    }
  }, [brandState]);

  const {
    data,
    isLoading: isMembersLoading,
    error: membersEror,
  } = useQuery(["members_data"], () => API.get(`brands/members`), {
    keepPreviousData: true,
    onSuccess: (res) => {
      // if (!brandArtistId && res?.data?.data?.length > 0) {
      //   setArtistId(res.data.data[0].id);
      // }
    },
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleRowSelect = (event, emailAddress) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [
        ...prevSelectedRows,
        emailAddress,
      ]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row !== emailAddress)
      );
      setSelectAll(false);
      if (selectedRows.length == 1) {
        setSelectAll(false);
      }
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked && data?.data?.data?.members) {
      const allEmailAddresses = data?.data?.data?.members.map((row) => row.email);
      setSelectedRows(allEmailAddresses);
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      setSelectAll(false);
    }
  };

  function satisfiesDiscordConditions(discordDetails, selectedServersAndRoles) {
    // For each server in selectedServersAndRoles
    for (let selectedServer of selectedServersAndRoles) {
      // Find the corresponding server in discordDetails
      const correspondingServer = discordDetails.find(
        (server) => server.id === selectedServer.id
      );

      // If there's no corresponding server, return false
      if (!correspondingServer) return false;

      // If the selectedServer has roles
      if (selectedServer.roles && selectedServer.roles.length > 0) {
        // If the corresponding server doesn't have memberData or roles, return false
        if (
          !correspondingServer.memberData ||
          !correspondingServer.memberData.roles
        )
          return false;

        // For each role in selectedServer.roles, check if its id exists in correspondingServer.memberData.roles
        for (let role of selectedServer.roles) {
          if (!correspondingServer.memberData.roles.includes(role.id))
            return false;
        }
      }
    }
    // If all checks passed, return true
    return true;
  }

  async function uploadMembersToSalesForce() {
    if (brandState?.brand?._id) {
      setIsLoading(true);
      console.log("brand state: ", brandState, brandState?.brand?._id);
      await API.post(
        `/salesforce/uploadMembersToSalesForce/${brandState?.brand?._id}`
      );
      setIsLoading(false);
      setNotification(true);
      setTimeout(() => {
        setNotification(false);
        if (queryParams.valid) {
          window.location.replace("/members");
        }
      }, 4000);
    }
  }

  async function salesForceLogin() {
    if (brandState?.brand?.isSalesForceConnected) {
      uploadMembersToSalesForce();
    } else {
      const redirectUrl = process.env.REACT_APP_SALESFORCE_REDIRECT_URL;
      const clientId = process.env.REACT_APP_SALESFORCE_CLIENTID;
      const authorizationUrl = `https://agility-platform-305.my.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&state=${JSON.stringify(
        { brandId: localStorage.getItem("brandId") }
      )}`;
      window.location.assign(authorizationUrl);
    }
  }
  function CustomCheckbox(props) {
    return (
      <StyledCheckbox
        disableRipple
        checkedIcon={
          <CheckedBox>
            <CheckIcon fontSize="small" sx={{ color: "white" }} />
          </CheckedBox>
        }
        indeterminateIcon={
          <IndeterminateBox>
            <RemoveIcon fontSize="small" />
          </IndeterminateBox>
        }
        icon={<CheckBoxIcon />}
        {...props}
      />
    );
  }

  return (
    <Layout activeTab={"members"}>
      <PageWrapper>
        <Header>
          <HeaderWrapper>
            Members
            {isLoading ? (
              <ExportButton>
                <CircularProgress size={28} />
              </ExportButton>
            ) : (
              <ExportButton onClick={salesForceLogin}>
                <IconUpload />
                Export Data
              </ExportButton>
            )}
          </HeaderWrapper>
          <RewardTypesGroup>
            <FormControl>
              <NativeSelect
                onChange={(event) => {
                  handleFilter(event);
                }}
                label="Filter"
                input={<FormField />}
                IconComponent={() => (
                  <KeyboardArrowDownOutlinedIcon fontSize="small" />
                )}
              >
                {filterOptions.map((option) => (
                  <option key={option}>{option}</option>
                ))}
              </NativeSelect>
            </FormControl>
            <CustomFormControl variant="standard">
              <InputLabel htmlFor="input-with-icon-adornment" />
              <InputField
                id="input-with-icon-adornment"
                disableUnderline
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
              />
            </CustomFormControl>
          </RewardTypesGroup>
        </Header>
        <MainContentWrapper>
          <ScrollableDiv>
            <Table
              style={{
                width: "100%",
                borderCollapse: "unset",
              }}
            >
              <TableHead
                style={{
                  position: "sticky",
                  top: "0",
                  zIndex: 1,
                  backgroundColor: "#1a1a22",
                }}
              >
                <StyledTableRow>
                  <StyledTableHeadCell />
                  <StyledTableHeadCell />
                  <StyledTableHeadCell />
                  <StyledTableHeadCell />
                  <StyledTableHeadCell />
                  {/* // streams : spotify , youtube , applemusic */}
                  <StyledTableHeadCellWithUnderLine
                    colSpan={3}
                    style={{
                      color: "#7F86AD",
                    }}
                  >
                    Streams
                  </StyledTableHeadCellWithUnderLine>
                  {/* // saved : spotify  */}
                  <StyledTableHeadCellWithUnderLine
                    colSpan={1}
                    style={{
                      color: "#7F86AD",
                    }}
                  >
                    Saved
                  </StyledTableHeadCellWithUnderLine>
                  {/* // spend : shopify */}
                  <StyledTableHeadCellWithUnderLine
                    colSpan={1}
                    style={{
                      color: "#7F86AD",
                    }}
                  >
                    Spend
                  </StyledTableHeadCellWithUnderLine>
                  {/* // subscribe and follow : spotify , youtube , instagram , discord , twitch */}
                  <StyledTableHeadCellWithUnderLine
                    colSpan={5}
                    style={{
                      color: "#7F86AD",
                    }}
                  >
                    Subscribe & Follow
                  </StyledTableHeadCellWithUnderLine>
                  {/* // likes : youtube , instagram , twitter , spotify */}

                  <StyledTableHeadCellWithUnderLine
                    colSpan={4}
                    style={{
                      color: "#7F86AD",
                    }}
                  >
                    Likes
                  </StyledTableHeadCellWithUnderLine>
                  <StyledTableHeadCell />
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableHeadCell
                    style={{ width: "20px", paddingRight: "28px" }}
                  >
                    <CustomCheckbox
                      checked={selectAll}
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length <= data.length
                      }
                      onChange={handleSelectAll}
                    />
                  </StyledTableHeadCell>
                  <StyledTableHeadCell style={{ paddingLeft: 0 }}>
                    Email Address
                  </StyledTableHeadCell>
                  <StyledTableHeadCell>Membership</StyledTableHeadCell>
                  <StyledTableHeadCell>Rewards</StyledTableHeadCell>
                  <StyledTableHeadCell />

                  {/* // streams : spotify , youtube , applemusic */}
                  <StyledTableHeadCell className="integration-icons">
                    {<BsSpotify />}
                  </StyledTableHeadCell>
                  <StyledTableHeadCell className="integration-icons">
                    {<SiApplemusic />}
                  </StyledTableHeadCell>
                  <StyledTableHeadCell className="integration-icons">
                    {<BsYoutube />}
                  </StyledTableHeadCell>
                  {/* // saved : spotify  */}
                  <StyledTableHeadCell className="integration-icons">
                    {<BsSpotify />}
                  </StyledTableHeadCell>
                  {/* // spend : shopify */}
                  <StyledTableHeadCell className="integration-icons">
                    {<FaShopify />}
                  </StyledTableHeadCell>
                  {/* // subscribe and follow : spotify , youtube , instagram , discord , twitch */}
                  <StyledTableHeadCell className="integration-icons">
                    {<BsSpotify />}
                  </StyledTableHeadCell>
                  <StyledTableHeadCell className="integration-icons">
                    {<BsYoutube />}
                  </StyledTableHeadCell>
                  <StyledTableHeadCell className="integration-icons">
                    {<AiFillInstagram />}
                  </StyledTableHeadCell>
                  <StyledTableHeadCell className="integration-icons">
                    {<BsDiscord />}
                  </StyledTableHeadCell>
                  {/* <StyledTableHeadCell className="integration-icons">
                  {<BsTwitch />}
                </StyledTableHeadCell> */}
                  {/* // likes : youtube , instagram , twitter , spotify */}
                  <StyledTableHeadCell className="integration-icons">
                    {<BsYoutube />}
                  </StyledTableHeadCell>
                  <StyledTableHeadCell className="integration-icons">
                    {<AiFillInstagram />}
                  </StyledTableHeadCell>
                  <StyledTableHeadCell className="integration-icons">
                    <AiOutlineTwitter />
                  </StyledTableHeadCell>
                  <StyledTableHeadCell className="integration-icons">
                    <BsSpotify />
                  </StyledTableHeadCell>
                  <StyledTableHeadCell />
                  {/* <StyledTableHeadCell /> */}

                  {/* <StyledTableHeadCell className="integration-icons">Campaigns</StyledTableHeadCell>
                <StyledTableHeadCell>Streams</StyledTableHeadCell>

                <StyledTableHeadCell>Shopify USD Spent</StyledTableHeadCell>
                <StyledTableHeadCell>Instagram Hashtag</StyledTableHeadCell>
                <StyledTableHeadCell>Total Likes</StyledTableHeadCell>
                <StyledTableHeadCell>Subscribed</StyledTableHeadCell>
                <StyledTableHeadCell></StyledTableHeadCell> */}
                </StyledTableRow>
              </TableHead>
              <TableBody style={{ overflow: "scroll" }}>
                {isMembersLoading ? (
                  <tr>
                    <td
                      colSpan={20}
                      style={{ textAlign: "center", padding: 18 }}
                    >
                      Loading...
                    </td>
                  </tr>
                ) : membersEror ? (
                  <tr>
                    <td
                      colSpan={20}
                      style={{ textAlign: "center", padding: 18 }}
                    >
                      Error
                    </td>
                  </tr>
                ) : (
                  data.data.data.members
                    .filter((row) => row.email.includes(searchTerm))
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        {console.log(row)}
                        <StyledTableBodyCell style={{ paddingRight: 0 }}>
                          <CustomCheckbox
                            checked={selectedRows.includes(row.email)}
                            onChange={(event) =>
                              handleRowSelect(event, row.email)
                            }
                          />
                        </StyledTableBodyCell>
                        <StyledTableBodyCell style={{ paddingLeft: 0 }}>
                          {row.email}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row.membershipName ? row.membershipName : "None"}
                        </StyledTableBodyCell>

                        <StyledTableBodyCell>
                          {row?.userbenefits}{" "}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell />

                        {/* // streams : spotify , youtube , applemusic */}
                        <StyledTableBodyCell
                          style={{ paddingRight: 0 }}
                          className="integration-icons"
                        >
                          {formatSreams(row.streams)}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell
                          style={{ paddingRight: 0 }}
                          className="integration-icons"
                        >
                          {row?.youtubeDetails?.[0]?.totalLikeVideos
                            ? row?.youtubeDetails?.[0]?.totalLikeVideos
                            : 0}
                        </StyledTableBodyCell>
                        {/* // apple music */}
                        <StyledTableBodyCell className="integration-icons">
                          0
                        </StyledTableBodyCell>

                        {/* // saved : spotify  */}
                        <StyledTableBodyCell
                          style={{ paddingRight: 0 }}
                          className="integration-icons"
                        >
                          {row?.AlbumOrSongSaved ? (
                            <CheckIcon />
                          ) : (
                            <ClearIcon />
                          )}
                        </StyledTableBodyCell>

                        {/* // spend : shopify */}
                        <StyledTableBodyCell
                          style={{ paddingRight: 0 }}
                          className="integration-icons"
                        >
                          {row?.totalUsdSpentOnShopifyByUser
                            ? parseFloat(
                                row?.totalUsdSpentOnShopifyByUser
                              ).toFixed(2)
                            : 0}
                        </StyledTableBodyCell>

                        {/* <StyledTableBodyCell style={{ paddingRight: 0 }}>
                      {row?.spotifyPlayistLiked ? <CheckIcon /> : <ClearIcon />}
                    </StyledTableBodyCell> */}

                        {/* // subscribe and follow : spotify , youtube , instagram , discord , twitch */}

                        <StyledTableBodyCell
                          style={{ paddingRight: 0 }}
                          className="integration-icons"
                        >
                          {row?.spotifyArtistFollow ? (
                            <CheckIcon />
                          ) : (
                            <ClearIcon />
                          )}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell
                          style={{ paddingRight: 0 }}
                          className="integration-icons"
                        >
                          {row?.youtubeDetails?.[0]?.isSubscribed ? (
                            <CheckIcon />
                          ) : (
                            <ClearIcon />
                          )}
                        </StyledTableBodyCell>
                        {/* // instagram omit currently */}
                        <StyledTableBodyCell className="integration-icons">
                          <ClearIcon />
                        </StyledTableBodyCell>
                        {/* // discord follow */}
                        <StyledTableBodyCell
                          style={{ paddingRight: 0 }}
                          className="integration-icons"
                        >
                          {satisfiesDiscordConditions(
                            row.discordDetails,
                            selectedServersAndRoles
                          ) ? (
                            <ClearIcon />
                          ) : (
                            <CheckIcon />
                          )}
                        </StyledTableBodyCell>
                        {/* // twitch omit currently */}
                        <StyledTableBodyCell className="integration-icons">
                          <ClearIcon />
                        </StyledTableBodyCell>

                        {/* // likes : youtube , instagram , twitter , spotify */}
                        {/* <StyledTableBodyCell
                        style={{ paddingRight: 0 }}
                        className="integration-icons"
                      >
                        {row?.youtubeDetails?.[0]?.totalLikeVideos
                          ? row?.youtubeDetails?.[0]?.totalLikeVideos
                          : 0}
                      </StyledTableBodyCell> */}

                        <StyledTableBodyCell
                          style={{ paddingRight: 0 }}
                          className="integration-icons"
                        >
                          {row?.instagramHashtagMatch
                            ? parseFloat(row?.instagramHashtagMatch)
                            : 0}{" "}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell className="integration-icons">
                          {row?.twitterLikes || 0}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell className="integration-icons">
                          {row?.spotifyTrackLiked ? 1 : 0}
                        </StyledTableBodyCell>

                        <StyledTableBodyCell
                          style={{ paddingLeft: 0, paddingRight: "20px" }}
                        >
                          <IconFullScreen
                            className="icon"
                            onClick={() => {
                              setSelectedModalData(row);
                              setshowModal(true);
                            }}
                          />
                        </StyledTableBodyCell>
                      </StyledTableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </ScrollableDiv>

          <Drawer
            anchor={"right"}
            open={showModal}
            sx={{
              "& .MuiDrawer-paper": {
                width: "29%",
              },
            }}
            onClose={() => {
              setshowModal((prev) => !prev);
            }}
          >
            <ContentWrapper>
              {selectedModalData?.user && (
                <AccountDetail
                  data={selectedModalData}
                  totalReward={selectedModalData.userbenefits}
                  totalRewardForUser={selectedModalData.userbenefits}
                  userId={selectedModalData.user._id}
                  // brandName={brandData?.name}
                  onClose={() => {
                    setshowModal((prev) => !prev);
                  }}
                />
              )}
            </ContentWrapper>
          </Drawer>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={notification}
            message="Export Sucessfully"
          />
        </MainContentWrapper>
      </PageWrapper>
    </Layout>
  );
};
const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 60px 40px 0 40px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
  overflow-y: scroll;
`;

const StyledTableRow = styled.tr`
  border-bottom: 1px solid #352b42;
`;

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0;
  }
  &.MuiCheckbox-indeterminate {
    color: ${(props) => props.theme.palette.white} !important;
  }
`;

const CheckBoxIcon = styledMui("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  width: 24,
  height: 24,
  background: theme.palette.lightGrey,
}));

const CheckedBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));

const IndeterminateBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));

const StyledTableHeadCellWithUnderLine = styled.th`
  padding: 16px 0px;
  text-align: start;
  width: 50px;
  border-bottom: 1px solid;
  border-color: #372b44;
  white-space: nowrap;
  && {
    ${(props) => props.theme.typography.bodyMBold};
    color: ${(props) => props.theme.palette.lightBlue};
  }

  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    border-bottom: 2px solid rgb(127, 134, 173);
    width: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 9px;
    border-bottom: 2px solid #1a1a22;
  }
`;

const StyledTableHeadCell = styled.th`
  padding: 16px;
  text-align: start;
  width: 270px;
  border-bottom: 1px solid;
  border-color: #372b44;
  white-space: nowrap;
  .integration-icons {
    padding: 3px 55px 0px 0px;
  }
  && {
    ${(props) => props.theme.typography.bodyMBold};
    color: ${(props) => props.theme.palette.lightBlue};
  }
`;

const StyledTableBodyCell = styled.th`
  padding: 16px;
  text-align: start;
  width: 150px !important;
  border-bottom: 1px solid;
  border-color: #372b44;
  .integration-icons {
    padding: 3px 55px 0px 0px;
  }
  .icon {
    color: ${(props) => props.theme.palette.lightGrey};
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }

  && {
    ${(props) => props.theme.typography.bodyMBold};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;

const MainContentWrapper = styled.div`
  border-radius: 12px;
  background-color: ${(props) => props.theme.palette.darkestGrey};

  display: flex;
  flex-direction: column;
  gap: 20px;
  width: auto;
  overflow: scroll;
  scroll-behavior: auto;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: transparent;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    /* border: 1px solid ${(props) => props.theme.palette.itemsHover}; */
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    cursor: pointer;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const ExportButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyS,
  display: "flex",
  width: "fit-content",
  borderRadius: 6,
  padding: "8px 20px",
  textTransform: "none",
  gap: "10px",
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
}));

const RewardTypesGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  height: 44px;
`;

const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.bodyM,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "16px 20px",
  color: theme.palette.lightGrey,
  maxHeight: "44px",

  "& .MuiNativeSelect-select": {
    padding: 0,

    "&.MuiInputBase-input": {
      paddingRight: "12px",
    },
  },
}));

const CustomFormControl = styledMui(FormControl)({
  "& .MuiInputBase-root": {
    marginTop: "0",
  },
});

export const ScrollableDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: transparent;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    /* border: 1px solid ${(props) => props.theme.palette.itemsHover}; */
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    cursor: pointer;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const InputField = styledMui(Input)(({ theme }) => ({
  ...theme.typography.bodyM,
  width: "402px",
  display: "flex",
  justifyContent: "center",
  paddingLeft: "10px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "16px 20px",
  color: theme.palette.lightGrey,
  maxHeight: "44px",

  "& .MuiInput-input": {
    padding: 0,
    color: theme.palette.lightGrey,
    "&::placeholder": {
      color: theme.palette.lightGrey,
      opacity: 1,
    },
  },
}));

const PageWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 28px;
  height: 100%;
  width: 100%;
  overflow: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: transparent;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    /* border: 1px solid ${(props) => props.theme.palette.itemsHover}; */
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    cursor: pointer;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.header1};
`;

Members.propTypes = {
  fetchMembers: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  membersState: makeSelectMembers(),
  brandState: makeSelectManageBrand(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchMembers: () => dispatch(fetchMembers()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Members);
