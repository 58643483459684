import React, { memo, useEffect, useState, useRef, useMemo } from "react";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import styled, { css } from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import {
  Button,
  CircularProgress,
  Input,
  InputBase,
  Modal,
  ButtonBase,
} from "@mui/material";
import { formatSreams } from "containers/Analytics/ArtistDashboard/utils";
import { Close, CloseSharp, Details } from "@mui/icons-material";
import Filters from "./components/Filters";
import UserRewardCards from "./components/UserRewardCards";
import Search from "components/Search";
import MembershipDisplay from "./components/MembershipDisplay";
import { useInjectSaga } from "utils/injectSaga";
import { useInjectReducer } from "utils/injectReducer";
import queryString from "query-string";
import reducer from "./reducer";
import { isEmpty } from "utils/object";
import saga from "./saga";
import UserInfo from "./components/UserInfo";
import RewardsTabsBar from "containers/ManageReward/components/RewardsTabsBar";
import makeSelectUserPortal from "./selector";
import PaymentModal from "components/PaymentModal";
import PaymentSuccessModal from "components/PaymentSuccessModal";
import {
  fetchMe,
  fetchUserMembershipHoldings,
  fetchUserProfile,
  fetchUserRewardHoldings,
  redeemReward,
  checkDeviceStreamedSong,
  inputUserIdStreamedSong,
  logoutUser,
} from "./actions";
import InfoHeader from "./components/InfoHeader";
import { ImageBanner, ImageCampaignLogo, ImageUserProfileMock } from "images";
import MembershipSection from "./components/MembershipSection";
import RewardCard from "./components/RewardCard";
import AccountDetail from "components/AccountDetails";
import {
  IconCampaigns,
  IconCampaignsUserPortal,
  IconDocument,
  IconGift,
  IconProfile,
  IconSpotify,
  IconCrown,
} from "images/icons/svg-components";
import { BsPersonVcard } from "react-icons/bs";
import { AiOutlineImport } from "react-icons/ai";
import { GoTrophy } from "react-icons/go";
import { ReactComponent as IntegrationIcon } from "images/icons/integration-icon.svg";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import { Circle } from "@mui/icons-material";
import CampaignCard from "./components/CampaignCard";
import LeaderboardCard from "./components/LeaderboardCard";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CheckIcon from "@mui/icons-material/Check";
import { IconCheckCircle } from "images/icons/svg-components";
import { formattedDate } from "utils/date";
import { DEFAULT_ACTION } from "./constants";
import Main from "components/UserModal/components/Main";
import UserModal from "components/UserModal";
import ClaimMembershipSnackbar from "./components/ClaimMembershipSnackbar";
import { mainTheme } from "theme/mainTheme";
import API from "utils/userAPI";
import { useSearchParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import qs from "qs";
import { useParams } from "react-router";
import WebsiteIcon from "@mui/icons-material/Language";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YoutubeIcon from "@mui/icons-material/YouTube";
// import { FaDiscord } from 'react-icons/fa';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ImageDefaultUser } from "images";
import { useSocket } from "../../utils/socketIO";
import IntegrationItem from "containers/IntegrationsPage/components/IntegrationItem";
import IntegrationsPage from "containers/IntegrationsPage";
import { AiFillInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { SiApplemusic } from "react-icons/si";
import { BsSpotify } from "react-icons/bs";
import { BsTwitch } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsDiscord } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import { FaShopify } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Campaignicon from "images/Campaignicon.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const UserPortalMembership = ({ dispatch, userPortalState }) => {
  const params = useParams();
  const socket = useSocket();

  const { brandId, membershipId } = useParams(); // Access the brandId from the URL parameter
  const [brandIdMain, setbrandIdMain] = useState(brandId);
  const [memberShipIdMain, setmemberShipIdMain] = useState(membershipId);

  useInjectReducer({ key: "userPortal", reducer });
  useInjectSaga({ key: "userPortal", saga });
  const [password, setPassword] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const queryParams = queryString.parse(location.search);
  const [saveSongInfo, setSaveSongInfo] = useState();
  const [isSavedSong, setIsSavedSong] = useState(false);
  const [totalRewardForUser, setTotalRewardForUser] = useState(0);
  const [tracksStreamed, setTracksStreamed] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    membershipHoldings, // this is only one membership
    rewardData, // this is id of all the rewards
    rewardHoldings, // this is all the rewards users have claimed for all memberships
    membershipData, // this is all  membership with success and
    brand,
    profile,
  } = userPortalState;
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showMembershipModal, setShowMembershipModal] = useState(
    localStorage.getItem("userTokens") !== null &&
      JSON.parse(localStorage.getItem("userTokens"))?.user?.membershipId
        ?.length === 0
  );
  const [showUserModal, setShowUserModal] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const goProfileSettings = () => {
    navigate("/profile-settings-User");
  };
  const [userId, setUserId] = useState(localStorage.getItem("userId") ?? "");
  const userIdRef = useRef(userId);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [selectedTab, setSelectedTab] = useState("rewards");
  const [selectedReward, setSelectedReward] = useState(null);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [leaderboardData, setLeaderBoradData] = useState(false);
  const [currentUserRank, setCurrentUserRank] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [displayedRewardData, setDisplayedRewardData] = useState(rewardData);
  const [autoRedeemedBadge, setAutoRedeemedBadge] = useState(null);
  const displayedRewardDataRef = useRef(displayedRewardData);
  const membershipDataSummary = membershipData.map((obj, index) => ({
    id: obj._id ?? index + 1,
    name: obj.name,
    image: obj.image,
  }));

  const [brandData, setBrandData] = useState(brand);

  // const [brandId, setBrandId] = useState(
  //   brandIdMain === "" ? brand : brandIdMain
  // );

  const [brandRewards, setBrandRewards] = useState(rewardData);
  const [signupmodal, setSignupmodal] = useState({
    headerImage: "",
    text: ["", "", ""],
  });

  const [brandMembership, setBrandMembership] = useState(
    membershipData.length === 0
      ? {}
      : membershipData?.find(
          (membership) => membership?._id === memberShipIdMain
        )
  );

  const [activeTab, setActiveTab] = useState(
    membershipData.length === 0
      ? { id: "0", name: "All" }
      : { id: membershipData[0]._id, name: membershipData[0].name }
  );

  //to make content responsive
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowMembershipModal(true);
  //   }, 5000);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  const breakpoint = 768;

  //for dropdown menu in mobile view
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  let deviceId = localStorage.getItem("deviceId");

  // If not, generate a new identifier and store it in local storage
  function generateUniqueId() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  }

  if (!deviceId) {
    deviceId = generateUniqueId();
    localStorage.setItem("deviceId", deviceId);
  }

  // console.log('This is the device id:', deviceId);

  useEffect(() => {
    const brandId = brandIdMain === "" ? brand : brandIdMain;

    const init = async () => {
      let result;
      if (params.brandSlug) {
        result = await API.get(`/brands/slug/${params.brandSlug}`);
        setBrandId(result.data.data.brand._id);
        setBrandData(result.data.data.brand);
      }
      const brandId = brandIdMain === "" ? brand : brandIdMain;

      let res1 = await API.get(`/signupmodal/${brandId}`); //.then((res1) => setSignupmodal(res1.data.data.signUpModal));
      if (!isEmpty(res1.data.data)) {
        setSignupmodal(res1.data.data.signUpModal);
      }
      let res = !params.brandSlug
        ? await API.get(`/landingPage/${brandId}`)
        : await API.get(`/brands/slug/${params.brandSlug}`); // need to get non default memberships
      if (!isEmpty(res.data.data)) {
        if (!isEmpty(res.data.data.brand)) {
          setBrandData(res.data.data.brand);
        }
        if (res.data.data.rewards.length > 0) {
          setBrandRewards(res.data.data.rewards);
        }
        const nonDefaultMemberships = res.data.data.memberships.filter(
          // this filters out the default membership
          (a) => a.transactionStatus === "SUCCESS"
        );

        if (nonDefaultMemberships.length > 0) {
          setBrandMembership(
            nonDefaultMemberships[nonDefaultMemberships.length - 1]
          );
          // if (
          //   !isEmpty(res.data.data.landingPage) &&
          //   nonDefaultMemberships.length > 0
          // ) {
          //   setBrandMembership(
          //     nonDefaultMemberships.filter(
          //       (m) => m._id === nonDefaultMemberships.memberships[0]
          //     )[0]
          //   );
          // } else {
          //   setBrandMembership(nonDefaultMemberships[0]);
          // }
        }
        const leaderboard = await API.get(`/spotify/leaderBoard/${brandId}`);
        if (!isEmpty(leaderboard.data)) {
          setLeaderBoradData(leaderboard.data);
        }
      }
      // res = await API.get(
      //   `/spotify/get_brand_spotify_save_song?brandId=${brandId}`
      // );
      // // console.log("get_brand_spotify_save_song", res.data.data);
      // setSaveSongInfo(res.data.data);
      // if (userId && res.data.data) {
      //   res = await API.get(
      //     `/spotify/check_user_saved_song?userId=${userId}&trackId=${res.data.data.trackId}`
      //   );
      //   // console.log("check_user_saved_song", res.data.data);
      //   setIsSavedSong(res.data.data);
      // }
    };
    init();
  }, []);

  const saveButtonType = useMemo(() => {
    if (signupmodal?.spotifyLink) {
      return signupmodal.spotifyLink.startsWith(
        "https://open.spotify.com/track"
      )
        ? "Song"
        : "Album";
    }
    return "Song";
  }, [signupmodal]);

  useEffect(() => {
    if (userId != "") {
      dispatch(fetchUserRewardHoldings(brandIdMain));
      dispatch(fetchUserMembershipHoldings(brandIdMain));
      dispatch(fetchUserProfile(memberShipIdMain));
      // setTimeout(() => {
      //   setShowMembershipModal(Object.keys(membershipHoldings).length === 0);
      // }, 3000);
    }
  }, [brandIdMain, memberShipIdMain]);
  useEffect(() => {
    const init = async () => {
      if (userId !== "" && brandId) {
        const userRank = await API.get(`/spotify/currentUserRank/${brandId}`);
        if (!isEmpty(userRank.data)) {
          setCurrentUserRank(userRank.data);
        }
      } else {
        setCurrentUserRank(false);
      }
    };
    init();
  }, [userId]);

  useEffect(() => {
    if (userId !== "") {
      const init = async () => {
        const res = await API.get(
          `/spotify/get_brand_spotify_save_song?brandId=${brandIdMain}`
        );
        // console.log("get_brand_spotify_save_song", res);
        setSaveSongInfo(res.data?.data);
        if (res.data?.data?.trackId) {
          const resp = await API.get(
            `/spotify/check_user_saved_song?userId=${userId}&trackId=${res.data?.data?.trackId}`
          );
          // console.log("check_user_saved_song", res.data.data);
          setIsSavedSong(resp.data?.data);
        }
      };
      init();
      dispatch(fetchUserRewardHoldings(brandIdMain));
      dispatch(fetchUserMembershipHoldings(brandIdMain));
      dispatch(fetchUserProfile(memberShipIdMain));
      userIdRef.current = userId;

      const thisUserId = localStorage.getItem("userId");
      const trackId = "3JsH3qnwhYGs20PBMsCgNx";
      dispatch(checkDeviceStreamedSong(deviceId, trackId, undefined)).then(
        (result) => {
          // device has listened to song
          if (result.data.data.length !== 0) {
            // user has streamed song
            setTracksStreamed((oldTracks) => [...oldTracks, trackId]);
          }
          // dispatch(inputUserIdStreamedSong(thisUserId, trackId)); uncomment if we want to tie to userId
        }
      );
    }
  }, [userId, isRedeeming]);

  // const formattedData = () => {
  //   return { ...profile, rewardHoldings: rewardHoldings };
  // };

  useEffect(() => {
    let rewardsToBeDisplayed = brandRewards;
    console.log(brandRewards, "brandrewards");
    // if (activeTab.id === "0") {
    //   rewardsToBeDisplayed = brandRewards;
    // } else {
    //   rewardsToBeDisplayed = brandRewards.filter((r) => r.membershipId === activeTab.id);
    // }
    rewardsToBeDisplayed = brandRewards.filter(
      (reward) => reward.membershipId === memberShipIdMain
    );
    // const filteredData = rewardsToBeDisplayed.filter(
    //   (r) =>
    //     r.name.toLowerCase().includes(searchTerm) ||
    //     r.category.toLowerCase().includes(searchTerm)
    // );
    console.log(rewardsToBeDisplayed, "filteredData");
    // TODO reward here filtered my membership id
    setDisplayedRewardData(rewardsToBeDisplayed);
    return () => {};
  }, [activeTab, searchTerm, brandRewards]);

  useEffect(() => {
    let totalRewardForUserVar = 0;
    displayedRewardDataRef.current = displayedRewardData;
    // TODO basically here rewards are set redeemable according
    // to membership that is picked for redeeming
    // we have to remove this i guess or need to match this as we will
    // show rewards of only one membership

    // membership condition will be there with each mapping or find
    displayedRewardData.map(
      ({ _id, membershipId, membershipNeeded }, index) => {
        const currentReward = rewardHoldings.find(
          (reward) => reward.typeId === _id
        );

        let isRedeemedYet = false;
        if (currentReward) {
          isRedeemedYet = currentReward.isRedeemed;
        }
        let isRedeemableYet =
          (membershipId === membershipHoldings.typeId ||
            membershipNeeded == false) &&
          tracksStreamed.length !== 0;
        if (userId === "") {
          isRedeemableYet = false;
        }

        if (isRedeemableYet === true || isRedeemableYet === true) {
          totalRewardForUserVar++;
        }
      }
    );
    setTotalRewardForUser(totalRewardForUserVar);
  }, [displayedRewardData, userPortalState]);

  useEffect(() => {
    const handleApprovalStatus = (status) => {
      autoRedeemBadge();
    };

    // Listen for 'approvalStatus' event
    socket.on("approvalStatus", handleApprovalStatus);

    // Cleanup listener when component unmounts
    return () => {
      socket.off("approvalStatus", handleApprovalStatus);
    };
  }, [socket]);

  function renderRewardCards(userId) {
    // TODO : can be filtered by member id
    const rewardCards = displayedRewardData.map(
      (
        {
          name,
          startDate,
          endDate,
          startTime,
          endTime,
          timezone,
          description,
          image,
          redemptionCount,
          isDraft,
          category,
          _id,
          membershipTier,
          membershipId,
          membershipNeeded,
        },
        index
      ) => {
        // TODO membership condition
        const currentReward = rewardHoldings.find(
          (reward) => reward.typeId === _id
        );

        let isRedeemedYet = false;
        if (currentReward) {
          isRedeemedYet = currentReward.isRedeemed;
        }
        const today = new Date();
        const start = new Date(startDate);
        const end = new Date(endDate);
        today >= start && today <= end;
        let isRedeemableYet = today >= start && today <= end;
        //   (membershipId === membershipHoldings.typeId ||
        //     membershipNeeded == false) &&
        //   tracksStreamed.length !== 0;
        // if (userId === "") {
        //   isRedeemableYet = false;
        // }
        // console.log(isRedeemableYet, isRedeemedYet);
        return (
          <RewardCard
            {...{
              name,
              startDate,
              endDate,
              startTime,
              endTime,
              timezone,
              description,
              image,
              redemptionCount,
              isDraft,
              category,
              id: _id,
              membershipTier,
              membershipId,
              isRedeemable: isRedeemableYet, // This is for if the user hasn't claimed the membership yet.
              isRedeemed: isRedeemedYet,
              membershipNeeded,
              userId,
            }}
            key={_id}
            defaultMemberships={[brandMembership]}
            isUser={true}
            setSelectedReward={setSelectedReward}
          />
        );
      }
    );
    return rewardCards;
  }

  // Rerendering Cards to check for redeemed whenever screen goes back to this screen
  useEffect(() => {
    // console.log("Updating reward holdings...");
    if (userId !== "") {
      dispatch(fetchUserRewardHoldings(brandIdMain));
    }
  }, [selectedReward]);

  const handleSearchChange = (search) => {
    setSearchTerm(search);
  };
  // commented tabs out for Gorgon Launch
  const tabs = [
    { name: "rewards", icon: IconGift, tab: "rewards" },
    // { name: "campaigns", icon: IconCampaignsUserPortal, tab: "campaigns" },
    { name: "leaderboard", icon: EmojiEventsOutlinedIcon, tab: "leaderboard" },
    { name: "integrations", icon: IconDocument, tab: "integrations" },
  ];

  const renderTabs = () => {
    return tabs.map((tab, idx) => {
      const Icon = tab.icon;

      return (
        <div
          key={idx}
          className={`tab ${selectedTab === tab.name ? "active" : ""}`}
          onClick={() => {
            setSelectedReward(null);
            setSelectedTab(tab.name);
          }}
        >
          {tab.name === "rewards" ? (
            <Icon style={{ width: "28px", height: "28px", size: "32" }} />
          ) : (
            <Icon fontSize="large" />
          )}
        </div>
      );
    });
  };

  function autoRedeemBadge() {
    const urlParams = new URLSearchParams(window.location.search);
    const rewardId = urlParams.get("rewardId");
    const reward = displayedRewardDataRef.current.find(
      (reward) => reward._id === rewardId
    );
    if (!reward) {
      return;
    }
    // TODO basically here rewards are set redeemable according
    // to membership that is picked for redeeming
    // we have to remove this i guess or need to match this as we will
    // show rewards of only one membership
    const requiredMembershipHeld =
      !reward.membershipNeeded ||
      reward.membershipId === membershipHoldings.typeId;
    // TODO : membership condition here
    const rewardNotRedeemed =
      !rewardHoldings ||
      !rewardHoldings.find(
        (holding) => holding.typeId === reward._id && holding.isRedeemed
      );

    if (
      rewardId &&
      reward &&
      reward.category === "Badge" &&
      requiredMembershipHeld &&
      rewardNotRedeemed
    ) {
      dispatch(redeemReward(rewardId, userIdRef.current));
      setAutoRedeemedBadge(reward);
      setShowRedeemModal(true);
    }
  }

  function renderDates() {
    const today = new Date();
    const _endDate = new Date(selectedReward.endDate);
    const timeDiff = _endDate.getTime() - today.getTime();
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    return (
      <DateContent>
        {selectedReward.endDate && (
          <span style={{ color: "#7F86AD" }}>
            Ends on{" "}
            <span style={{ color: "white" }}>
              {formattedDate(selectedReward.endDate)}
            </span>
          </span>
        )}
        {_endDate > today && (
          <span style={{ color: "#7F86AD" }}>
            Expires in <span style={{ color: "white" }}>{daysLeft} days</span>
          </span>
        )}
      </DateContent>
    );
  }

  const onRedeem = (rewardId, userId) => {
    // rewardId: "", userId: "" if i want to hardcode
    API.post("users/redeem", { rewardId, userId })
      .then((result) => {
        setShowRedeemModal(true);
        setIsRedeeming(false);
      })
      .catch((err) => {
        console.log(err);
        setIsRedeeming(false);
      });
  };

  const handleLogout = async () => {
    dispatch(logoutUser());
    localStorage.removeItem("userTokens");
    localStorage.removeItem("userId");
    setUserId("");
    await API.post("/auth/logout");
  };

  const handleUserLogin = async (
    email,
    membershipId,
    membership,
    brandId,
    userId
  ) => {
    setUserId(localStorage.getItem("userId"));
    // searchParams.set("userId", userId);
    // setSearchParams(searchParams, { replace: true });
  };

  const StyledTableCell = styledMui(TableCell)(({ theme }) => ({
    fontSize: "1rem",
    padding: "1rem",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#21212e",
      color: "#5c8ef0",
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      color: "#7d84ab",
      borderBottom: "1px solid #352b42",
    },
  }));
  const brandName =
    brandMembership?.name || (signupmodal.text && signupmodal.text[0]);
  return (
    <Wrapper>
      <ClaimMembershipSnackbar
        dispatch={dispatch}
        userPortalState={userPortalState}
        brandName={brandData?.name}
      />
      {/* <UserModal
        showModal={showUserModal}
        setShowModal={setShowUserModal}
        onLogin={handleUserLogin}
        selectedMembership={brandMembership}
      /> */}

      <Modal
        open={
          Object.keys(membershipHoldings).length === 0 &&
          userId &&
          userId !== "" &&
          showMembershipModal
        }
        closeAfterTransition
        disableAutoFocus={true}
      >
        <ContentWrapper1>
          {state && state.albumSaved ? (
            <>
              <div className="check-mark">
                <CheckIcon fontSize="large" />
              </div>
              {brandName && (
                <div className="header">
                  <div className="title">
                    {"Welcome to "}
                    {brandName + "'s Membership Program"}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <Box1>
                <img
                  src={signupmodal ? signupmodal.headerImage : ImageDefaultUser}
                />
                <div className="text-wrapper">
                  <div className="text-top">
                    <div className="header2">
                      {signupmodal.text && signupmodal.text[0]}
                    </div>
                    <div className="bodyS">
                      {signupmodal.text && signupmodal.text[1]}
                    </div>
                  </div>
                  <div className="bodyG">{saveButtonType} has been saved!</div>
                </div>
              </Box1>
              <MainTextWrapper>
                <span className="title">Saved on Spotify!</span>
                <span className="subtitle">
                  You are now eligible to receive exclusive benefits and access
                  to {signupmodal.text && signupmodal.text[0]}.
                </span>
              </MainTextWrapper>
              <SubTextWrapper>
                <input type="checkbox" />
                <span className="subtitle">
                  Join the artist mailing list and receive future marketing
                  communications in accordance with Campaign Layer{"’"}s{" "}
                  <span>
                    <Link to={"/privacy-policy"}>Privacy Policy</Link>
                  </span>
                  .
                </span>
              </SubTextWrapper>
            </>
          )}
          <ClaimMembershipButton
            brandMembership={brandMembership}
            dispatch={dispatch}
          />
          <div
            className="back-button"
            onClick={() => setShowMembershipModal(false)}
          >
            Back to My Benefits
          </div>
        </ContentWrapper1>
      </Modal>
      <Modal
        open={showRedeemModal}
        onClose={() => setShowRedeemModal(false)}
        closeAfterTransition
        disableAutoFocus={true}
      >
        <ModalContentWrapper style={{ maxWidth: "620px" }}>
          <CloseIcon
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              width: "20px",
              height: "20px",
              color: "#7F86AD",
              cursor: "pointer",
            }}
            onClick={() => setShowRedeemModal(false)}
          />
          <div className="modal-header">
            <span className="title">
              Congrats! Your benefit was successfully redeemed
            </span>
            <span className="subtitle">
              We sent you the details to your email{" "}
              <span
                className="subtitle"
                style={{ color: mainTheme.palette.lightBlue }}
              >
                {profile?.user?.email}
              </span>
            </span>
          </div>
          <div className="benefit">
            <div className="benefit-item">
              <span className="benefit-title">Benefit:</span>
              <span style={{ marginLeft: "10px" }}>
                {selectedReward?.name || autoRedeemedBadge?.name}
              </span>
            </div>
            <div
              className="wrapper-header"
              onClick={() => {
                setShowRedeemModal(false);
                setSelectedReward(null);
              }}
            >
              Back to My Benefits
            </div>
          </div>
        </ModalContentWrapper>
      </Modal>

      {windowWidth <= breakpoint ? (
        <TopMenuWrapper>
          <TopMenu>
            {showProfile || selectedReward ? (
              <div
                className="wrapper-header"
                onClick={() => {
                  setShowProfile(false);
                  setSelectedReward(null);
                }}
              >
                <ArrowBackIosIcon style={{ height: "20px", width: "20px" }} />
                My Benefits
              </div>
            ) : (
              <TopMenuButton onClick={toggleDropdown}>
                <AiOutlineMenu
                  style={{ width: "24px", height: "24px", color: "#6096FF" }}
                />
              </TopMenuButton>
            )}
            {selectedTab === "rewards" && (
              <ProfileMenuButton onClick={() => setShowProfile(true)}>
                <img src={ImageDefaultUser} width="24px" height="24px" />
              </ProfileMenuButton>
            )}
          </TopMenu>
          {/* {
          isDropdownVisible && ( */}
          <DropdownWrapper isVisible={isDropdownVisible}>
            <DetailsHeaderWrapper>
              <div
                style={{
                  width: "11px",
                  height: "11px",
                  background: "#D9D9D9",
                  borderRadius: "11px",
                  marginLeft: "-5.5px",
                }}
              />
              <DetailsHeader>
                {userId !== "" ? (
                  <>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <DetailsImg src={brandMembership.image} />
                      <DetailsTitle>{brandMembership.name}</DetailsTitle>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      onClick={() =>
                        navigate("/login", {
                          state: {
                            brandId: brandIdMain,
                          },
                        })
                      }
                      style={{
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <TextLogIn>Log in or Signup</TextLogIn>
                      <TextMermbership>
                        To claim membership and benefits
                      </TextMermbership>
                    </div>
                  </>
                )}

                <CloseSharp
                  style={{
                    width: "24px",
                    height: "24px",
                    color: "#7F86AD",
                    cursor: "pointer",
                  }}
                  onClick={toggleDropdown}
                />
              </DetailsHeader>
            </DetailsHeaderWrapper>
            <DropdownContent>
              <DetailsItem
                onClick={() => {
                  setSelectedReward(null);
                  setSelectedTab("rewards");
                  setDropdownVisible(false);
                }}
              >
                <DetailsItemIconWrapper>
                  <IconGift
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "#7F86AD",
                    }}
                  />
                </DetailsItemIconWrapper>
                <DetailsItemText>Benefits</DetailsItemText>
              </DetailsItem>
              <DetailsItem>
                <DetailsItemIconWrapper>
                  <BsPersonVcard
                    style={{
                      width: "20px",
                      height: "18px",
                      color: "#7F86AD",
                    }}
                  />
                </DetailsItemIconWrapper>
                <DetailsItemText>Campaigns</DetailsItemText>
              </DetailsItem>
              <DetailsItem
                onClick={() => {
                  setSelectedTab("leaderboard");
                  setDropdownVisible(false);
                }}
              >
                <DetailsItemIconWrapper>
                  <GoTrophy
                    style={{
                      width: "17px",
                      height: "17px",
                      color: "#7F86AD",
                    }}
                  />
                </DetailsItemIconWrapper>
                <DetailsItemText>Leaderboard</DetailsItemText>
              </DetailsItem>

              <DetailsItem
                onClick={() => {
                  setSelectedTab("integrations");
                  setDropdownVisible(false);
                }}
              >
                <DetailsItemIconWrapper>
                  <IntegrationIcon
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "#7F86AD",
                    }}
                  />
                </DetailsItemIconWrapper>
                <DetailsItemText>Integrations</DetailsItemText>
              </DetailsItem>
              {userId !== "" && (
                <DetailsItem
                  onClick={() => {
                    handleLogout();
                    setDropdownVisible(false);
                  }}
                >
                  <DetailsItemIconWrapper>
                    <AiOutlineImport
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#7F86AD",
                      }}
                    />
                  </DetailsItemIconWrapper>
                  <DetailsItemText>Logout</DetailsItemText>
                </DetailsItem>
              )}
              {/* <div
                className="dropdown-item"
                onClick={() => {
                  setSelectedReward(null);
                  setSelectedTab("rewards");
                  setDropdownVisible(false);
                }}
              >
                <span className="dropdown-item-text">Rewards</span>
              </div> */}

              {/* <div
                className="dropdown-item"
                onClick={() => {
                  setSelectedTab("leaderboard");
                  setDropdownVisible(false);
                }}
              >
                <span className="dropdown-item-text">Leaderboard</span>
              </div> */}
              {/* <div
                className="dropdown-item"
                onClick={() => {
                  handleLogout();
                  setDropdownVisible(false);
                }}
              >
                <span className="dropdown-item-text">Logout</span>
              </div> */}
            </DropdownContent>
          </DropdownWrapper>

          {/* )
        } */}
        </TopMenuWrapper>
      ) : (
        <LeftMenuWrapper>
          <div className="wrapper">
            <img src={ImageCampaignLogo} style={{ width: "10em" }}></img>
            <div className="tabs-wrapper">{renderTabs()}</div>
          </div>

          {userId !== "" ? (
            <div className="logout-wrapper">
              <span className="" onClick={() => handleLogout()}>
                Log Out
              </span>
            </div>
          ) : (
            ""
          )}
        </LeftMenuWrapper>
      )}
      {selectedReward && ( // if have selected reward
        <BenefitScreenWrapper>
          <div
            className="wrapper-header"
            onClick={() => setSelectedReward(null)}
          >
            <ArrowBackIosIcon style={{ height: "18px", width: "18px" }} />
            My Benefits
          </div>
          <RedeemMenuWrapper>
            {/* <ContentWrapper> */}

            {windowWidth > breakpoint && (
              <img
                src={selectedReward.image}
                style={{
                  borderRadius: "20px",
                  maxWidth: "536px",
                  width: "100%",
                  objectFit: "cover",
                }}
              ></img>
            )}
            <RedeemMenuContentWrapper>
              <div className="content-header">
                <span className="subtitle">{selectedReward.category}</span>
                <span className="title">{selectedReward.name}</span>
              </div>
              {windowWidth <= breakpoint && (
                <>
                  {renderDates()}
                  <img
                    src={selectedReward.image}
                    style={{
                      borderRadius: "8px",
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  ></img>
                </>
              )}
              <div className="desc-wrapper">
                <span className="desc-header">Description of the benefit</span>
                <span className="desc-item">{selectedReward.description}</span>
              </div>
              <div className="need-wrapper">
                <span className="need-header">
                  What do you need to redeem this benefit?
                </span>

                {(() => {
                  const selectedRewardData = displayedRewardData.find(
                    (o) => selectedReward && o.name === selectedReward.name
                  );
                  const membershipNeeded =
                    selectedRewardData?.membershipNeeded === true ||
                    selectedRewardData?.membershipNeeded === undefined;
                  const membershipHeld =
                    selectedRewardData?.membershipId ===
                    membershipHoldings.typeId;
                  const membershipForReward =
                    userPortalState.membershipData.find(
                      (o) => o._id === selectedRewardData.membershipId
                    );

                  const songStreamed = tracksStreamed.length !== 0;

                  if (songStreamed) {
                    // if user streamed song
                    if (membershipNeeded && !membershipHeld) {
                      return (
                        <div>
                          <div
                            className="need-item"
                            style={{ color: "#7F86AD", marginBottom: "20px" }}
                          >
                            <CloseIcon fontSize="small" />
                            Holding{" "}
                            {membershipForReward?.name?.match(/^[aeiou]/i)
                              ? "an"
                              : "a"}{" "}
                            {membershipForReward?.name} membership
                          </div>
                          {/* <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CheckIcon fontSize="small" />
                              Stream {`Gorgon City's Voodoo`}
                            </div> */}
                        </div>
                      );
                    } else if (membershipNeeded && membershipHeld) {
                      return (
                        <div>
                          <div
                            className="need-item"
                            style={{ marginBottom: "20px" }}
                          >
                            <CheckIcon fontSize="small" />
                            Holding{" "}
                            {membershipForReward?.name?.match(/^[aeiou]/i)
                              ? "an"
                              : "a"}{" "}
                            {membershipForReward?.name} membership
                          </div>
                          {/* <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CheckIcon fontSize="small" />
                              Stream {`Gorgon City's Voodoo`}
                            </div> */}
                        </div>
                      );
                    } else {
                      return (
                        <div>
                          <div
                            className="need-item"
                            style={{ marginBottom: "20px" }}
                          >
                            <CheckIcon fontSize="small" />
                            No Membership Needed
                          </div>
                          {/* <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CheckIcon fontSize="small" />
                              Stream {`Gorgon City's Voodoo`}
                            </div> */}
                        </div>
                      );
                    }
                  } else {
                    // if user did not stream song
                    if (membershipNeeded && !membershipHeld) {
                      return (
                        <div>
                          <div
                            className="need-item"
                            style={{ color: "#7F86AD", marginBottom: "20px" }}
                          >
                            <CloseIcon fontSize="small" />
                            Holding{" "}
                            {membershipForReward?.name?.match(/^[aeiou]/i)
                              ? "an"
                              : "a"}{" "}
                            {membershipForReward?.name} membership
                          </div>
                          {/* <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CloseIcon fontSize="small" />
                              Stream {`Gorgon City's Voodoo`}
                            </div> */}
                        </div>
                      );
                    } else if (membershipNeeded && membershipHeld) {
                      return (
                        <div>
                          <div
                            className="need-item"
                            style={{ marginBottom: "20px" }}
                          >
                            <CheckIcon fontSize="small" />
                            Holding{" "}
                            {membershipForReward?.name?.match(/^[aeiou]/i)
                              ? "an"
                              : "a"}{" "}
                            {membershipForReward?.name} membership
                          </div>
                          {/* <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CloseIcon fontSize="small" />
                              Stream {`Gorgon City's Voodoo`}
                            </div> */}
                        </div>
                      );
                    } else {
                      return (
                        <div>
                          <div className="need-item">
                            <CheckIcon fontSize="small" />
                            No Membership Needed
                          </div>
                          {/* <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CloseIcon fontSize="small" />
                              Stream {`Gorgon City's Voodoo`}
                            </div> */}
                        </div>
                      );
                    }
                  }
                })()}
                {/* commented out for Gorgon Launch */}
                {/* {saveSongInfo &&
                    (isSavedSong ? (
                      <div className="need-item">
                        <CheckIcon fontSize="small" />
                        Save {saveSongInfo.trackName} on Spotify
                      </div>
                    ) : (
                      <div className="need-item disabled">
                        <CloseIcon fontSize="small" color="red" />
                        Save {saveSongInfo.trackName} on Spotify
                      </div>
                    ))} */}
              </div>
              {windowWidth > breakpoint && (
                <div className="dates-col" style={{ display: "flex" }}>
                  {renderDates()}
                </div>
              )}
              {/* {TODO: membership condition with reward holdings} */}
              {windowWidth > breakpoint && (
                <div className="redeem-button">
                  {(() => {
                    const rewardRedeemed = userPortalState.rewardHoldings.find(
                      (o) => o.isRedeemed && o.typeId === selectedReward.id
                    );
                    const selectedRewardData = displayedRewardData.find(
                      (o) => selectedReward && o.name === selectedReward.name
                    );
                    const membershipNeeded =
                      selectedRewardData?.membershipNeeded === true ||
                      selectedRewardData?.membershipNeeded === undefined;
                    const membershipHeld =
                      selectedRewardData?.membershipId ===
                      membershipHoldings.typeId;

                    if (JSON.stringify(userPortalState.profile) === "{}") {
                      return (
                        <SignInButton
                          onClick={() => {
                            setSelectedReward(null);
                            navigate("/login", {
                              state: {
                                brandId: brandIdMain,
                              },
                            });
                          }}
                        >
                          Sign in with Email
                        </SignInButton>
                      );
                    } else if (rewardRedeemed) {
                      // if reward has been redeemed renders disabled redeemed button
                      return (
                        <RedeemBenefitButton
                          disabled={true}
                          style={{
                            "pointer-events": "none",
                            "user-select": "none",
                          }}
                        >
                          {"Benefit Redeemed"}
                        </RedeemBenefitButton>
                      );
                    } else if (
                      membershipNeeded &&
                      !membershipHeld
                      // (saveSongInfo && !isSavedSong)
                      // commented out for Gorgon Launch
                    ) {
                      // if user does not meet the requirements renders disabled requirements not met button
                      return (
                        <RedeemBenefitButton
                          disabled={true}
                          style={{
                            "pointer-events": "none",
                            "user-select": "none",
                          }}
                        >
                          {"Requirements not met"}
                        </RedeemBenefitButton>
                      );
                    } else {
                      // if user meets requirement, returns redeem benefit button
                      return (
                        <RedeemBenefitButton
                          onClick={() => {
                            setIsRedeeming(true);
                            onRedeem(selectedReward.id, userId);
                          }}
                        >
                          {isRedeeming ? (
                            <CircularProgress size={28} />
                          ) : (
                            "Redeem Benefit"
                          )}
                        </RedeemBenefitButton>
                      );
                    }
                  })()}
                </div>
              )}
            </RedeemMenuContentWrapper>
            {windowWidth <= breakpoint && (
              <div className="redeem-button">
                {(() => {
                  const rewardRedeemed = userPortalState.rewardHoldings.find(
                    (o) => o.isRedeemed && o.typeId === selectedReward.id
                  );
                  const selectedRewardData = displayedRewardData.find(
                    (o) => selectedReward && o.name === selectedReward.name
                  );
                  const membershipNeeded =
                    selectedRewardData?.membershipNeeded === true ||
                    selectedRewardData?.membershipNeeded === undefined;
                  const membershipHeld =
                    selectedRewardData?.membershipId ===
                    membershipHoldings.typeId;

                  if (JSON.stringify(userPortalState.profile) === "{}") {
                    return (
                      <SignInButton
                        onClick={() => {
                          setSelectedReward(null);
                          navigate("/login", {
                            state: {
                              brandId: brandIdMain,
                            },
                          });
                        }}
                      >
                        Sign in with Email
                      </SignInButton>
                    );
                  } else if (rewardRedeemed) {
                    // if reward has been redeemed renders disabled redeemed button
                    return (
                      <RedeemBenefitButton
                        disabled={true}
                        style={{
                          "pointer-events": "none",
                          "user-select": "none",
                        }}
                      >
                        {"Benefit Redeemed"}
                      </RedeemBenefitButton>
                    );
                  } else if (
                    membershipNeeded &&
                    !membershipHeld
                    // (saveSongInfo && !isSavedSong)
                    // commented out for Gorgon Launch
                  ) {
                    // if user does not meet the requirements renders disabled requirements not met button
                    return (
                      <RedeemBenefitButton
                        disabled={true}
                        style={{
                          "pointer-events": "none",
                          "user-select": "none",
                        }}
                      >
                        {"Requirements not met"}
                      </RedeemBenefitButton>
                    );
                  } else {
                    // if user meets requirement, returns redeem benefit button
                    return (
                      <RedeemBenefitButton
                        onClick={() => {
                          setIsRedeeming(true);
                          onRedeem(selectedReward.id, userId);
                        }}
                      >
                        {isRedeeming ? (
                          <CircularProgress size={28} />
                        ) : (
                          "Redeem Benefit"
                        )}
                      </RedeemBenefitButton>
                    );
                  }
                })()}
              </div>
            )}
            {/* </ContentWrapper> */}
          </RedeemMenuWrapper>
        </BenefitScreenWrapper>
      )}
      {selectedReward === null && (
        <MainContentWrapper>
          {selectedTab === "rewards" && (
            <div
              className="background-image"
              style={{
                background: `linear-gradient(180deg, rgba(241, 241, 241, 0.00) 0%, rgba(98, 98, 100, 0.65) 50%, #131316 100%), url("${brandMembership?.image}") center`,
                backgroundPosition: "center",
                backgroundSize: "100%",
              }}
            ></div>
          )}
          {windowWidth <= breakpoint ? (
            <>
              {selectedTab === "rewards" && (
                <MobileRightContent>
                  {userId !== "" ? (
                    <>
                      {showProfile ? (
                        <AccountDetail
                          data={userPortalState?.profile}
                          totalReward={displayedRewardData?.length}
                          totalRewardForUser={totalRewardForUser}
                          userId={userId}
                          brandName={brandData?.name}
                        />
                      ) : (
                        <MembershipWrapper>
                          <MembershipBox
                            data={
                              Object.keys(membershipHoldings).length === 0
                                ? {
                                    membershipImage: brandMembership.image,
                                    membershipName: brandMembership.name,
                                  }
                                : userPortalState?.profile?.membership
                            }
                            brandData={brandData}
                            brandMembership={brandMembership}
                          />
                          <div className="buttons-wrapper">
                            {Object.keys(membershipHoldings).length === 0 && (
                              <ClaimMembershipButton
                                brandMembership={brandMembership}
                                dispatch={dispatch}
                              />
                            )}
                            <ViewProfileButton
                              onClick={() => setShowProfile(true)}
                            >
                              {" "}
                              View Details{" "}
                            </ViewProfileButton>
                          </div>
                        </MembershipWrapper>
                      )}
                    </>
                  ) : (
                    <SignInWrapper>
                      {/* <img
                        src={ImageCampaignLogo}
                        height="32px"
                        width="192px"
                      ></img> */}
                      <div className="content-wrapper">
                        <div className="upper-content">
                          <img
                            src={brandMembership?.image}
                            style={{
                              borderRadius: "6px",
                              height: "209px",
                              width: "209px",
                              objectFit: "cover",
                            }}
                          />
                          <div className="subtitle-wrapper">
                            <div className="subtitle1">
                              {brandMembership?.name ||
                                `${brandData?.name}'s Membership`}
                            </div>
                            <div className="styledsubtitle">
                              {brandMembership?.purchaseType?.toUpperCase()}
                            </div>
                          </div>
                        </div>
                        <div className="lower-content">
                          <div className="subtitle2">
                            To join {brandData?.name}
                            {"'"}s{" "}
                            {brandMembership?.name || "membership program"}, you
                            will need to:
                          </div>
                          <div className="subtext-wrapper">
                            <div className="subtext-item">
                              <img src={Campaignicon} />
                              <div className="subtitle2">
                                Create an account with Campaign
                              </div>
                            </div>
                            <div className="subtext-item">
                              <IconSpotify
                                width="20px"
                                height="20px"
                                color="#0BD680"
                              />

                              <div className="subtitle2">
                                Save their{" "}
                                {signupmodal.text && signupmodal.text[1]}{" "}
                                {saveButtonType.toLowerCase()}
                                {` on Spotify`}
                              </div>
                            </div>
                          </div>
                          <SignInButton
                            onClick={() =>
                              navigate("/login", {
                                state: {
                                  brandId: brandIdMain,
                                },
                              })
                            }
                          >
                            Get Started
                          </SignInButton>
                        </div>
                      </div>
                    </SignInWrapper>
                  )}
                </MobileRightContent>
              )}
              {selectedTab === "rewards" && !showProfile && (
                <>
                  <BrandContent>
                    <UserProfile>{brandData?.name}</UserProfile>
                    {/* {brandData?.name === "Gorgon City" && ( */}
                    <div>
                      <SocialLink
                        href="https://gorgoncity.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <WebsiteIcon color="white" />
                      </SocialLink>
                      <SocialLink
                        href="https://twitter.com/GorgonCity?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TwitterIcon />
                      </SocialLink>
                      <SocialLink
                        href="https://www.instagram.com/gorgoncity/?hl=en"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <InstagramIcon />
                      </SocialLink>
                      <SocialLink
                        href="https://www.youtube.com/channel/UCxD2gCy3e0EUWN5EylcBGMA"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <YoutubeIcon />
                      </SocialLink>
                      <SocialLink
                        href="https://www.discord.com/channel/UCxD2gCy3e0EUWN5EylcBGMA"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {/* <FaDiscord /> */}
                      </SocialLink>
                    </div>
                    {/* )} */}
                  </BrandContent>
                  <RewardPageWrapper>
                    <HeaderWrapper>My Benefits</HeaderWrapper>
                    <RewardCardsWrapper>
                      {renderRewardCards(userId)}
                    </RewardCardsWrapper>
                  </RewardPageWrapper>
                </>
              )}
              {selectedTab === "leaderboard" && (
                <MobileLeaderboardWrapper>
                  <UserRankWrapper>
                    <MobileLeaderboardHeader>
                      <UserProfile>{brandData?.name}</UserProfile>
                      <LeaderboardSubtitle>My Rank</LeaderboardSubtitle>
                    </MobileLeaderboardHeader>
                    <LeaderboardCardItem>
                      <div className="user-info-row">
                        <img
                          src={
                            currentUserRank?.leaderBoardData
                              ? currentUserRank?.leaderBoardData[0]?.userImage
                                ? currentUserRank?.leaderBoardData[0]?.userImage
                                : ImageUserProfileMock
                              : ImageUserProfileMock
                          }
                          className="user-image"
                        />
                        <div className="user-name">
                          {currentUserRank?.leaderBoardData &&
                            currentUserRank?.leaderBoardData[0]
                              ?.spotifyUserName}
                        </div>
                        <div className="user-rank">
                          #
                          {currentUserRank?.leaderBoardData &&
                            currentUserRank?.leaderBoardData[0]?.index}
                        </div>
                      </div>
                      <div className="data-row">
                        <div className="data-item">
                          <div className="data-heading">
                            Saved {saveButtonType}
                          </div>
                          <div className="data-text">
                            <CheckIcon
                              style={{
                                color: "white",
                                background: "#294bfc",
                                borderRadius: "50%",
                                fontSize: "20px",
                                padding: "4px",
                              }}
                            />
                          </div>
                        </div>
                        <div className="data-item">
                          <div className="data-heading">Streams</div>
                          <div className="data-text">
                            {currentUserRank?.leaderBoardData
                              ? currentUserRank?.leaderBoardData[0]
                                  ?.totalSecondsListened
                                ? formatSreams(
                                    Math.floor(
                                      currentUserRank.leaderBoardData[0]
                                        .totalSecondsListened / 60
                                    )
                                  )
                                : "0"
                              : "0"}
                          </div>
                        </div>
                        <div className="data-item">
                          <div className="data-heading">Shopify Spend</div>
                          <div className="data-text">
                            {" "}
                            {currentUserRank?.subtotalPrice
                              ? currentUserRank?.subtotalPrice
                              : 0}
                          </div>
                        </div>
                      </div>
                    </LeaderboardCardItem>
                  </UserRankWrapper>
                  <LeaderboardCardList>
                    <LeaderboardSubtitle>
                      Top 50 Leaderboard
                    </LeaderboardSubtitle>
                    {leaderboardData &&
                      leaderboardData.map((e, rank) => {
                        return (
                          <LeaderboardCardItem>
                            <div className="user-info-row">
                              <img
                                src={
                                  e.leaderBoardData?.userImage
                                    ? e.leaderBoardData?.userImage
                                    : ImageUserProfileMock
                                }
                                className="user-image"
                              />
                              <div className="user-name">
                                {e?.leaderBoardData?.spotifyUserName
                                  ? e?.leaderBoardData?.spotifyUserName
                                  : "-"}
                              </div>
                              {[1, 2, 3].includes(rank + 1) ? (
                                <IconCrown
                                  style={{ background: crownColors[rank + 1] }}
                                />
                              ) : (
                                <div className="user-rank">{`#${
                                  rank + 1
                                }`}</div>
                              )}
                            </div>
                            <div className="data-row">
                              <div className="data-item">
                                <div className="data-heading">
                                  Saved {saveButtonType}
                                </div>
                                <div className="data-text">
                                  {Math.random() > 0.5 ? (
                                    <CheckIcon
                                      style={{
                                        color: "white",
                                        background: "#294bfc",
                                        borderRadius: "50%",
                                        fontSize: "20px",
                                        padding: "4px",
                                      }}
                                    />
                                  ) : (
                                    <CloseIcon
                                      style={{
                                        color: "black",
                                        background: "#FF8379",
                                        borderRadius: "50%",
                                        fontSize: "20px",
                                        padding: "4px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="data-item">
                                <div className="data-heading">Streams</div>
                                <div className="data-text">
                                  {" "}
                                  {e?.leaderBoardData?.totalSecondsListened
                                    ? formatSreams(
                                        e?.leaderBoardData?.totalSecondsListened
                                      )
                                    : "-"}
                                </div>
                              </div>
                              <div className="data-item">
                                <div className="data-heading">
                                  Shopify Spend
                                </div>
                                <div className="data-text">
                                  {e?.subtotalPrice ? e?.subtotalPrice : 0}
                                </div>
                              </div>
                            </div>
                          </LeaderboardCardItem>
                        );
                      })}
                  </LeaderboardCardList>
                </MobileLeaderboardWrapper>
              )}
            </>
          ) : (
            <>
              <img
                src={brandMembership?.image}
                style={{
                  marginBottom: "28px",
                  height: "120px",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "12px",
                }}
              ></img>
              <ContentWrapper>
                <LeftContent>
                  {/* <BrandContent>
                      <UserProfile>{brandData?.name}</UserProfile>
                      <div>
                        <SocialLink
                          href="https://gorgoncity.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <WebsiteIcon color="white" />
                        </SocialLink>
                        <SocialLink
                          href="https://twitter.com/GorgonCity?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <TwitterIcon />
                        </SocialLink>
                        <SocialLink
                          href="https://www.instagram.com/gorgoncity/?hl=en"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <InstagramIcon />
                        </SocialLink>
                        <SocialLink
                          href="https://www.youtube.com/channel/UCxD2gCy3e0EUWN5EylcBGMA"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <YoutubeIcon />
                        </SocialLink>
                        <SocialLink
                          href="https://www.discord.com/channel/UCxD2gCy3e0EUWN5EylcBGMA"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaDiscord /> 
                        </SocialLink>
                      </div>
                    </BrandContent> */}
                  {/* )} */}
                  {selectedTab === "rewards" && (
                    <RewardPageWrapper>
                      <HeaderWrapper>My Benefits</HeaderWrapper>
                      <RewardCardsWrapper>
                        {renderRewardCards(userId)}
                      </RewardCardsWrapper>
                    </RewardPageWrapper>
                  )}
                  {selectedTab === "integrations" && (
                    <RewardPageWrapper>
                      <HeaderWrapper>My Accounts</HeaderWrapper>
                      <IntegrationsPage
                        userPortalState={userPortalState}
                        dispatch={dispatch}
                      ></IntegrationsPage>
                    </RewardPageWrapper>
                  )}
                  {selectedTab === "campaigns" && (
                    <RewardPageWrapper>
                      <HeaderWrapper>Campaigns</HeaderWrapper>
                      <CampaignCard />
                      <CampaignCard />
                      <CampaignCard />
                    </RewardPageWrapper>
                  )}
                  {selectedTab === "leaderboard" && (
                    <RewardPageWrapper>
                      <TitleWrapper>Sonny Fodera</TitleWrapper>
                      {/* <MyRankWrapper> */}
                      <HeaderWrapper>My Rank</HeaderWrapper>

                      <TableContainer
                        component={Paper}
                        sx={{
                          borderRadius: "20px",
                          background: "unset",
                        }}
                      >
                        <Table
                          sx={{ minWidth: 700 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell sx={{ width: "5%" }}>
                                Rank
                              </StyledTableCell>
                              <StyledTableCell sx={{ width: "55%" }}>
                                Username
                              </StyledTableCell>
                              <StyledTableCell sx={{ width: "15%" }}>
                                Saved {saveButtonType}
                              </StyledTableCell>
                              <StyledTableCell sx={{ width: "15%" }}>
                                Shopify Spend
                              </StyledTableCell>
                              <StyledTableCell sx={{ width: "10%" }}>
                                Streams
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <LeaderboardCard
                              leader={currentUserRank}
                              index={currentUserRank.index}
                              isMyRank={true}
                              setShowUserModal={setShowUserModal}
                            />
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {/* </MyRankWrapper> */}
                      <HeaderWrapper style={{ marginTop: "16px" }}>
                        Top 50 Leaderboard
                      </HeaderWrapper>

                      <TableContainer
                        component={Paper}
                        sx={{
                          borderRadius: "20px",
                          background: "unset",
                        }}
                      >
                        <Table
                          sx={{ minWidth: 700 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell sx={{ width: "5%" }}>
                                Rank
                              </StyledTableCell>
                              <StyledTableCell sx={{ width: "55%" }}>
                                Username
                              </StyledTableCell>
                              <StyledTableCell sx={{ width: "15%" }}>
                                Saved {saveButtonType}
                              </StyledTableCell>
                              <StyledTableCell sx={{ width: "15%" }}>
                                Shopify Spend
                              </StyledTableCell>
                              <StyledTableCell sx={{ width: "10%" }}>
                                Streams
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {leaderboardData &&
                              leaderboardData.map((leader, index) => {
                                return (
                                  <LeaderboardCard
                                    leader={leader}
                                    index={index + 1}
                                    setShowUserModal={setShowUserModal}
                                  />
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </RewardPageWrapper>
                  )}
                </LeftContent>
                {selectedTab === "rewards" && (
                  <RightContent>
                    {/* userPortalState?.profile?.user?.spotifyId === undefined ||
                    userPortalState?.profile?.user?.spotifyId ===
                      "undefined" ? (
                      <MembershipWrapper>
                        <MembershipBox brandMembership={brandMembership} />
                        {userPortalState?.profile?.user?.email !== undefined ||
                        userPortalState?.profile?.user?.email !== "" ? (
                          <AuthenticateSpotify
                            email={userPortalState?.profile?.user?.email}
                          />
                        ) : (
                          ""
                        )}
                      </MembershipWrapper>
                    ) :  */}
                    {userId !== "" ? (
                      Object.keys(membershipHoldings).length === 0 ? (
                        <MembershipWrapper>
                          <MembershipBox
                            data={{
                              membershipImage: brandMembership.image,
                              membershipName: brandMembership.name,
                            }}
                            brandData={brandData}
                          />
                          <ClaimMembershipButton
                            brandMembership={brandMembership}
                            dispatch={dispatch}
                          />
                        </MembershipWrapper>
                      ) : (
                        <>
                          <div className="header">My Profile</div>
                          {/* {console.log(userPortalState?.profile)} */}
                          <AccountDetail
                            data={userPortalState?.profile}
                            totalReward={displayedRewardData?.length}
                            totalRewardForUser={totalRewardForUser}
                            userId={userId}
                          />
                        </>
                      )
                    ) : (
                      <SignInWrapper>
                        <img
                          src={ImageCampaignLogo}
                          height="32px"
                          width="192px"
                        ></img>
                        <div className="content-wrapper">
                          <div className="upper-content">
                            <div className="title">Sign In To Your Account</div>
                            <img
                              src={brandMembership?.image}
                              style={{
                                borderRadius: "12px",
                                height: "307px",
                                width: "307px",
                                objectFit: "cover",
                              }}
                            />
                            <div className="subtitle-wrapper">
                              <div className="subtitle1">
                                {brandMembership?.name ||
                                  `${brandData?.name}'s Membership`}
                              </div>
                              <div className="styledsubtitle">
                                {brandMembership?.purchaseType?.toUpperCase()}
                              </div>
                              <div className="subtitle2">
                                To join{" "}
                                {brandMembership?.name ||
                                  `${brandData?.name}'s membership program}`}
                                , you will need to:
                              </div>
                              <div className="subtext-wrapper">
                                <div className="subtext-item">
                                  <img src={Campaignicon} />
                                  <div className="subtitle2">
                                    Create an account with Campaign
                                  </div>
                                </div>
                                <div className="subtext-item">
                                  <IconSpotify
                                    width="20px"
                                    height="20px"
                                    color="#0BD680"
                                  />
                                  <div className="subtitle2">
                                    Save their{" "}
                                    {signupmodal.text && signupmodal.text[1]}{" "}
                                    {saveButtonType.toLowerCase()} on Spotify
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="lower-content">
                            <SignInButton
                              onClick={() =>
                                navigate("/login", {
                                  state: {
                                    brandId: brandIdMain,
                                  },
                                })
                              }
                            >
                              Get Started
                            </SignInButton>
                          </div>
                        </div>
                      </SignInWrapper>
                    )}
                  </RightContent>
                )}
              </ContentWrapper>
            </>
          )}
        </MainContentWrapper>
      )}
    </Wrapper>
  );
};

const AuthenticateSpotify = ({ email }) => {
  const queryParams = queryString.parse(location.search);
  const brandId = brandIdMain;
  console.log("AuthenticateSpotify", email);
  return (
    <Box>
      <SubheaderText>
        Finish authenticating your Spotify account to claim the membership
      </SubheaderText>
      <a
        href={
          "https://accounts.spotify.com/authorize?" +
          qs.stringify({
            response_type: "code",
            client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
            scope:
              "user-read-private user-read-email user-top-read playlist-read-private user-library-read user-read-recently-played",
            redirect_uri: `${process.env.REACT_APP_API_URL}/api/v1/spotify/callback`,
            state: JSON.stringify({ brandId, email }),
          })
        }
        target="_self"
        rel="noreferrer"
      >
        <SpotifyButton variant="contained" disableRipple>
          <IconSpotify />
          Authenticate Spotify
        </SpotifyButton>
      </a>
    </Box>
  );
};

const ClaimMembershipButton = ({ brandMembership, dispatch }) => {
  const [loading, setLoading] = useState(false);
  const queryParams = queryString.parse(location.search);

  const handleMembershipRedeem = async () => {
    // redeeming membership
    try {
      setLoading(true);
      const res = await API.post("/users/mint", {
        membershipId: brandMembership._id,
      });
      await API.post("/users/membership", {
        membershipId: brandMembership._id,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      localStorage.removeItem("isClaimMemberShipLogin");
      if (error.status === 401) {
        // setError("User not authorized. Please login to continue");
      } else {
        // setError(error?.response?.data?.message);
      }
      setLoading(false);
      return;
    }
    dispatch(fetchUserRewardHoldings(brandIdMain));
    dispatch(fetchUserMembershipHoldings(brandIdMain, true));
    dispatch(fetchUserProfile(memberShipIdMain));

    // autoRedeemBadge(); // auto redeem badge associated with this membership
  };
  return (
    <Box>
      <ClaimButton onClick={() => handleMembershipRedeem()}>
        {loading ? <CircularProgress /> : "Claim Membership"}
      </ClaimButton>
    </Box>
  );
};

const MembershipBox = ({ data, brandData, brandMembership }) => {
  return (
    <Box>
      <HeaderWrapper className="membership-header">
        <Header>Membership</Header>
      </HeaderWrapper>
      <div className="account">
        <div className="membership">
          <img src={data?.membershipImage} />
          <div className="text-wrapper">
            <div className="header2">{data?.membershipName}</div>
            {brandData?.name && brandData?.name !== "" ? (
              <div className="header">
                {brandMembership?.name || `${brandData?.name}'s Membership`}
              </div>
            ) : null}
            {data?.membershipJoined && (
              <div className="bodyS">
                Joined {formattedDate(data?.membershipJoined, 1)}
              </div>
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};
const crownColors = ["", "#FFDD55", "#D9D9D9", "#BE6B1E"];
const MobileLeaderboardWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  margin-top: -20px;
`;

const DetailsItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    background-color: #1a1a22;
  }
`;
const UserRankWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`;

const MobileLeaderboardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

const LeaderboardSubtitle = styled.div`
  ${(props) => props.theme.typography.subHeader};
  color: ${(props) => props.theme.palette.lightGrey};
  line-height: normal;
`;

const LeaderboardCardItem = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  border-radius: 12px;
  width: 100%;
  background: ${(props) => props.theme.palette.darkestGrey};

  .user-info-row {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 20px;
    .user-image {
      width: 70px;
      height: 70px;
      flex-shrink: 0;
      border-radius: 70px;
      object-fit: cover;
    }
    svg {
      font-size: 27px;
      padding: 4px;
      color: black;
      border-radius: 4px;
    }
    .user-name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      align-self: stretch;
      ${(props) => props.theme.typography.subHeader};
      line-height: normal;
      color: ${(props) => props.theme.palette.lightGrey};
    }
    .user-rank {
      display: flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: ${(props) => props.theme.palette.campaignBlue};
      ${(props) => props.theme.typography.bodySMedium};
      line-height: normal;
      color: ${(props) => props.theme.palette.white};
    }
  }

  .data-row {
    display: flex;
    align-items: center;
    align-self: stretch;
    .data-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      justify-content: center;
      .data-heading {
        ${(props) => props.theme.typography.bodyXSMedium};
        line-height: normal;
        color: ${(props) => props.theme.palette.lightGrey};
      }
      .data-text {
        ${(props) => props.theme.typography.header3};
        line-height: normal;
        color: ${(props) => props.theme.palette.white};
        word-break: break-word;
      }
    }
  }
`;

const DetailsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 90px;
  width: 100%;
  background-color: #1a1a22;
`;

const DetailsImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 6px;
`;

const DetailsTitle = styled.div`
  color: var(--light-grey, #7f86ad);

  /* Header II */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const LeaderboardCardList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`;

const BrandContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  div {
    display: flex;
    justify-content: flex-start;
    svg {
      color: #6096ff;
    }
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;

const TextLogIn = styled.div`
  font-family: Inter;
  font-size: 10px;
  margin-bottom: 10px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => props.theme.palette.lightBlue};
  ${(props) => props.theme.typography.subHeader};
`;
const TextMermbership = styled.div`
  font-family: Inter;
  font-size: 15px !important;
  font-weight: 200;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.subHeader};
`;
const ContentWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 40px 32px 40px 32px;
  gap: 20px;
  border-radius: 1em;
  max-width: 630px;
  background: #131316;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 95%;
  @media (max-width: 600px) {
    width: 95%;
  }

  .check-mark {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    padding: 26px;
    border-radius: 50px;
    background: #0bd680;
    color: ${(props) => props.theme.palette.black};
    svg {
      font-size: xxx-large;
    }
  }

  .back-button {
    display: flex;
    gap: 12px;
    align-items: center;
    width: fit-content;
    cursor: pointer;

    ${(props) => props.theme.typography.bodyS};
    color: ${(props) => props.theme.palette.lightBlue};

    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }

  .header1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    gap: 5px;
    .subtitle {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: ${(props) => props.theme.palette.lightGrey};
    }
  }
  .header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      text-align: center;
      color: ${(props) => props.theme.palette.white};
      ${(props) => props.theme.typography.header2};
    }
    .subtitle {
      ${(props) => props.theme.typography.bodyS};
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      color: ${(props) => props.theme.palette.white};
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }
`;

const DetailsItemIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
`;

const DetailsItemText = styled.div`
  color: var(--light-grey, #7f86ad);

  /* Header III */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  .title {
    text-align: center;
    ${(props) => props.theme.typography.header1};
    color: ${(props) => props.theme.palette.white};
    line-height: normal;
    @media (max-width: 768px) {
      ${(props) => props.theme.typography.header2};
    }
  }
  .subtitle {
    text-align: center;
    ${(props) => props.theme.typography.header3};
    color: ${(props) => props.theme.palette.white};
    line-height: normal;
    @media (max-width: 768px) {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};
    }
  }
`;

const SubTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  gap: 12px;
  input {
    width: 24px;
    height: 24px;
  }
  .subtitle {
    ${(props) => props.theme.typography.bodyS};
    line-height: normal;
    color: ${(props) => props.theme.palette.lightGrey};
    span {
      a {
        color: ${(props) => props.theme.palette.white};
      }
    }
  }
`;

const Box1 = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border: 1px solid #352b42;
  border-radius: 6px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    border: none;
    padding: 0;
    .text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }
  img {
    display: flex;
    width: 120px;
    height: 120px;
    border-radius: 6px;
    @media (max-width: 768px) {
      width: 200px;
      height: 200px;
    }
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1 0 0;
    .top-text {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-self: stretch;
    }
    .header2 {
      ${(props) => props.theme.typography.subHeader}
      color: ${(props) => props.theme.palette.white};
      line-height: normal;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    .bodyS {
      ${(props) => props.theme.typography.bodyM}
      color: ${(props) => props.theme.palette.white};
      line-height: normal;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    .bodyG {
      ${(props) => props.theme.typography.bodyS};
      color: #0bd680;
      line-height: normal;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
`;
const FormField = styledMui(InputBase)({});

const IntegrationsDisp = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SubheaderText = styled.span`
  text-align: center;
  ${(props) => props.theme.typography.header4};
  color: ${(props) => props.theme.palette.lightGrey};
  font-weight: 4000;
  font-size: 16px;
  line-height: 28px;
`;

// const ProfileMenuButton = styledMui(ButtonBase)(({ theme }) => ({
//   ...theme.typography.header3,
//   color: theme.palette.lightGrey,
//   ":hover": {
//     color: theme.palette.lightBlue,
//   },
// }));

const ProfileMenuButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  color: ${(props) => props.theme.palette.lightBlue};
  cursor: pointer;
  transition: 0.3s;
`;

const SpotifyButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.black,
  textTransform: "none",
  backgroundColor: "#0BD680",
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "18px 20px",
  gap: "20px",
  height: "60px",
  fontWeight: 500,
  fontSize: "18px",

  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));

const ViewProfileButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  width: "auto",
  height: "44px",
  gap: "8px",
  color: theme.palette.lightGrey,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  padding: "12px 0px 12px 0px",
  borderRadius: "6px",
  backgroundColor: theme.palette.darkestGrey,
  lineHeight: "normal",
}));

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    .membership-header {
      display: none;
    }
    .account {
      .membership {
        border: none;
        flex-direction: column;
        gap: 23px;
        padding: 0px;
        img {
          width: 120px;
          height: 120px;
          border-radius: 6px;
          object-fit: cover;
        }
        .text-wrapper {
          gap: 8px;
          .header {
            display: block;
            margin: 0;
            text-align: center;
          }
          .bodyS {
            text-align: center;
          }
          .header2 {
            display: none;
          }
        }
      }
    }
  }

  .membership {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 24px;

    border: 1px solid #6096ff;
    border-radius: 6px;

    img {
      height: 80px;
      width: 80px;
      opacity: 80%;
      object-fit: cover;
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .header {
        display: none;
      }
      .header,
      .header2 {
        ${(props) => props.theme.typography.header2};
        color: ${(props) => props.theme.palette.white};
        line-height: normal;
      }
      .bodyS {
        ${(props) => props.theme.typography.bodyXS};
        color: ${(props) => props.theme.palette.white};
        line-height: normal;
      }
    }
  }

  .recentActivity {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.white};
    }
    .date {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};
    }
  }

  .wallet-wrapper {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    border-radius: 6px;
    background-color: ${(props) => props.theme.palette.darkerGrey};

    .walletAddress {
      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.white};
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 296px;
    }
  }

  .stats {
    display: flex;
    justify-content: space-around;

    .stat-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .header {
        ${(props) => props.theme.typography.header}
        color: ${(props) => props.theme.palette.white};
      }

      .bodyS {
        ${(props) => props.theme.typography.bodyS}
        color: ${(props) => props.theme.palette.lightGrey};
      }
    }
  }

  .account {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .content {
      display: flex;
      align-items: center;
      gap: 20px;

      .bodyM {
        ${(props) => props.theme.typography.bodyM}
        color: ${(props) => props.theme.palette.white};
      }
    }
  }
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};

  .icon {
    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

const formFieldOverrides = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#F4F3EE",
  width: "100%",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

const SignInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px 0px;

    .upper-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 23px;
    }
    .title {
      text-align: center;
      color: ${(props) => props.theme.palette.white};
      ${(props) => props.theme.typography.header2};
    }

    .subtitle-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      @media (min-width: 768px) {
        gap: 12px;
      }
    }
    .subtitle1 {
      text-align: center;
      color: ${(props) => props.theme.palette.white};
      ${(props) => props.theme.typography.header2};
    }
    .styledsubtitle {
      display: flex;
      padding: 2px 6px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 6px;
      background-color: ${(props) => props.theme.palette.lightBlue};
      color: ${(props) => props.theme.palette.black};
      ${(props) => props.theme.typography.styledSubtitle};
    }
    .subtitle2 {
      text-align: left;
      color: ${(props) => props.theme.palette.lightGrey};
      ${(props) => props.theme.typography.bodyXS};
    }
    .subtext-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      gap: 4px;
      .subtext-item {
        display: flex;
        align-items: center;
        gap: 12px;
        align-self: stretch;
      }
    }
    .lower-content {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      border-radius: 8px;
    }
  }
`;

const SignInButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyMBold,
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
  textTransform: "none",
  padding: "14px 0",
  width: "100%",

  "&:hover": {
    backgroundColor: theme.palette.white,
    color: theme.palette.campaignBlue,
  },
}));

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 20px 40px 20px;
  gap: 28px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  border-radius: 16px;
  background: ${(props) => props.theme.palette.darkestGrey};

  .wrapper-header {
    ${(props) => props.theme.typography.bodyMBold};
    background: ${(props) => props.theme.palette.campaignBlue};
    color: ${(props) => props.theme.palette.white};
    width: 100%;
    text-align: center;
    padding: 12px 20px;
    border-radius: 6px;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      text-align: center;
      color: ${(props) => props.theme.palette.white};
      ${(props) => props.theme.typography.header1};
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.header2};
      }
    }
    .subtitle {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
      text-align: center;
      color: ${(props) => props.theme.palette.lightGrey};
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodyS};
      }
    }
  }

  .benefit {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .benefit-title {
      text-align: center;
      color: ${(props) => props.theme.palette.white};
      ${(props) => props.theme.typography.header2};
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodyMBold};
      }
    }
    .benefit-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 8px;
      align-items: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;

      text-align: center;
      color: ${(props) => props.theme.palette.lightBlue};
      padding: 20px 12px;
      background: ${(props) => props.theme.palette.darkerGrey};
      border: 1px solid;
      border-color: ${(props) => props.theme.palette.darkGrey};
      border-radius: 6px;
      width: 100%;

      span {
        display: flex;
      }
    }
  }
`;

const RedeemBenefitButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyMBold,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 20px",
  lineHeight: "normal",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const DateContent = styled.div`
  ${(props) => props.theme.typography.subHeader};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 28px;
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.bodyS};
    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`;

const RedeemMenuContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${"" /* justify-content: space-between; */}
  padding: 20px;
  width: 100%;
  height: 100%;
  gap: 40px;

  .wrapper-header {
    display: flex;
    gap: 12px;
    align-items: center;
    width: fit-content;
    cursor: pointer;

    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.lightBlue};

    :hover {
      color: ${(props) => props.theme.palette.white};
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    gap: 20px;
  }

  .content-header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      ${(props) => props.theme.typography.header};
      color: ${(props) => props.theme.palette.white};

      @media (max-width: 768px) {
        line-height: normal;
        ${(props) => props.theme.typography.header2};
      }
    }

    .subtitle {
      ${(props) => props.theme.typography.header2};
      color: ${(props) => props.theme.palette.lightBlue};

      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodySMedium};
        line-height: normal;
      }
    }
  }
  .desc-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .desc-header {
      ${(props) => props.theme.typography.subHeader};
      color: ${(props) => props.theme.palette.white};
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodySMedium};
        line-height: normal;
      }
    }
    .desc-item {
      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.lightGrey};
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodyS};
        line-height: normal;
      }
    }
  }
  .need-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .need-header {
      ${(props) => props.theme.typography.subHeader};
      color: ${(props) => props.theme.palette.white};
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodySMedium};
        line-height: normal;
      }
    }
    .need-item {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      gap: 12px;
      background: ${(props) => props.theme.palette.darkerGrey};
      border-radius: 6px;

      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.lightBlue};
      &.disabled {
        color: ${(props) => props.theme.palette.lightGrey};
      }
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodyS};
        line-height: normal;
      }
    }
  }
`;

const BenefitScreenWrapper = styled.div`
display: flex;
flex-direction: column;
${"" /* justify-content: space-between; */}
height: 100%;
width: 100%;
overflow-y: scroll;

.wrapper-header {
    display: flex;
    gap: 12px;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    padding: 10px;
    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.lightBlue};

    :hover {
      color: ${(props) => props.theme.palette.white};
    }

    @media (max-width: 768px){
      display: none;
    }
`;

const RedeemMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${"" /* gap: 65px; */}
  width: 100%;
  @media (max-width: 768px) {
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    .redeem-button {
      display: flex;
      padding: 12px 20px;
      background-color: ${(props) => props.theme.palette.darkestGrey};
    }
  }
`;

const LeaderboardCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MainLearderBoardWrapper = styled.div``;
const LeaderboardHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 28px;

  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
`;

const MembershipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0px;
  .buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

const MyRankWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const UserProfile = styled.div`
  display: flex;
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
  padding-bottom: 1em;
  @media (max-width: 768px) {
    align-items: flex-start;
    gap: 40px;
    padding: 40px 20px;
    z-index: 0;
    position: relative;
    overflow: auto;
    .background-image {
      display: flex;
      top: -20px;
      left: 0px;
      position: absolute;
      height: 111px;
      width: 100vw;
      z-index: -1;
      background-position: center;
      background-repeat: repeat;
    }
  }

  .wrapper-header {
    display: flex;
    gap: 12px;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    padding: 10px;
    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.lightBlue};

    :hover {
      color: ${(props) => props.theme.palette.white};
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const LeftMenuWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 207px;

  padding: 40px 20px;
  justify-content: space-between;
  border-right: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};

  .logout-wrapper {
    :hover {
      cursor: pointer;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    span {
      color: ${(props) => props.theme.palette.lightGrey};
      ${(props) => props.theme.typography.header3};
      cursor: pointer;
      :hover {
        color: ${(props) => props.theme.palette.white};
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;

    .tabs-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      .tab {
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 6px;
        height: 80px;
        width: 80px;
        color: ${(props) => props.theme.palette.lightBlue};
        cursor: pointer;

        :hover {
          color: ${(props) => props.theme.palette.white};
        }

        &.active {
          background-color: ${(props) => props.theme.palette.darkGrey};
          color: ${(props) => props.theme.palette.white};
        }
      }
    }
  }
  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 0px 20px;
    border-bottom: 1px solid;
    border-color: ${(props) => props.theme.palette.darkGrey};
    .wrapper {
      flex-direction: row;
      justify-content: flex-start;
      gap: 10px;
      .tabs-wrapper {
        flex-direction: row;
        justify-content: flex-start;
        gap: 10px;
      }
    }
  }
`;

const TopMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 100;
`;

const DropdownWrapper = styled.div`
  position: fixed;
  top: 0;

  left: ${(props) => (props.isVisible ? "0" : "-100%")};
  width: ${(props) => (props.isDetails ? "100%" : "84.61%")};
  height: 100vh;
  background-color: #131316;
  transition: left 0.3s ease-in-out;
  z-index: 999;
`;

const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em 0.5em;
  width: 100%;
  .dropdown-item {
    cursor: pointer;
    :hover {
      color: ${(props) => props.theme.palette.white};
    }
    ${(props) => props.theme.typography.header3};
    color: ${(props) => props.theme.palette.lightBlue};
  }
`;

const TopMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  height: 64px;
  background-color: ${(props) => props.theme.palette.darkestGrey};

  .wrapper-header {
    display: flex;
    gap: 4px;
    align-items: center;
    width: fit-content;
    cursor: pointer;

    ${(props) => props.theme.typography.bodySMedium};
    color: ${(props) => props.theme.palette.lightBlue};

    :hover {
      color: ${(props) => props.theme.palette.white};
    }
`;

const TopMenuButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 1em 1em;
  // background-color: ${(props) => props.theme.palette.lightGrey};
  color: ${(props) => props.theme.palette.white};
  cursor: pointer;
  transition: 0.3s;
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 32px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 371px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
  border-radius: 12px;
  padding: 24px 32px;

  .header {
    color: ${(props) => props.theme.palette.lightGrey};
    ${(props) => props.theme.typography.header2};
    margin-bottom: 20px;
  }
`;

const MobileRightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => props.theme.palette.darkestGrey};
  border-radius: 12px;
  padding: 8px 24px 12px 24px;

  .header {
    color: ${(props) => props.theme.palette.lightGrey};
    ${(props) => props.theme.typography.header2};
    margin-bottom: 20px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: ${(props) => props.theme.palette.black};
  > * {
    // zoom: 0.7;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    overflow: auto;
    margin-top: 64px;
    // >* {
    //   zoom: 1
    // }; 
`;

const HeaderWrapper = styled.div`
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.subHeader};
`;

const TitleWrapper = styled.div`
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36.31px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  color: white;
`;

const RewardPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
`;

const RewardCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  @media (max-width: 768px) {
    align-items: center;
  }
`;
const ClaimButton = styledMui(Button)(({ theme }) => ({
  borderRadius: "10px",
  fontSize: "15px",
  lineHeight: "36px",
  textTransform: "none",
  backgroundColor: "#294BFC",
  color: "#fff",
  paddingLeft: "40px",
  paddingRight: "40px",
  transition: "all .3s ease",
  "@media (max-width: 768px)": {
    ...theme.typography.bodySMedium,
    width: "auto",
    height: "44px",
    gap: "8px",
    color: theme.palette.white,
    padding: "12px 0px 12px 0px",
    borderRadius: "6px",
    lineHeight: "normal",
  },
  "&:hover": {
    backgroundColor: "#7f86ad",
  },
}));

const SocialLink = styled.a`
  margin-right: 12px;
  color: white;
`;

const mapStateToProps = createStructuredSelector({
  userPortalState: makeSelectUserPortal(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(UserPortalMembership);
