/**
 *
 * RewardContractModal
 *
 */

import React, { memo } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import PropTypes from "prop-types";
import { isFuture } from "date-fns";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Snackbar, Slide } from "@mui/material";
import {
  clearPublishedRewardContract,
  toggleSnackbar,
} from "containers/NewReward/actions";
import makeSelectNewReward from "containers/NewReward/selectors";
import { Close } from "@mui/icons-material";

function RewardContractModal({ newRewardState, dispatch }) {
  const { publishedReward, showSnackbar } = newRewardState;

  const handleClose = () => {
    if (showSnackbar) {
      dispatch(toggleSnackbar());
      dispatch(clearPublishedRewardContract());
    }
  };

  const goToTx = () => {
    const { publishedContract, publishedReward } = newRewardState;
    const { contractAddress } = publishedContract;
    const { contractAddress: contractAddressFromReward } = publishedReward;
    if (
      contractAddress !== undefined ||
      contractAddressFromReward !== undefined
    ) {
      window.open(
        `https://polygonscan.com/address/${
          contractAddress !== undefined
            ? contractAddress
            : contractAddressFromReward
        }`,
        "_blank"
      );
    }
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  return (
    <Snackbar
      open={showSnackbar}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleClose}
      TransitionComponent={TransitionLeft}
      autoHideDuration={5000}
      key={"membershipSnackbar"}
    >
      <ContentWrapper>
        <LabelWrapper>
          <Label>New reward has been created!</Label>
          <Sublabel>
            {isFuture(new Date(publishedReward.startDate))
              ? `${publishedReward.name} will be available for customers to redeem at a later date.`
              : `${publishedReward.name} is now available for customers to redeem.`}
            &nbsp;
            <span onClick={goToTx}>View transaction</span>
          </Sublabel>
        </LabelWrapper>
        <Close onClick={handleClose} style={{ cursor: "pointer" }} />
      </ContentWrapper>
    </Snackbar>
  );
}

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: centers;
`;

const Label = styled.div`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.white};
`;

const Sublabel = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};

  span {
    color: ${(props) => props.theme.palette.lightBlue};
    cursor: pointer;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: 28px 32px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
`;

RewardContractModal.propTypes = {
  setShowModal: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  newRewardState: makeSelectNewReward(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(RewardContractModal);
