import React from "react";
import styled from "styled-components";
import { ButtonBase } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import { formattedDate } from "utils/date";
import { useSelector } from "react-redux";

const AirdropBenefits = ({ formValues, setFormValues }) => {
  const { rewardData } = useSelector((state) => state.manageReward);
  const handleAdd = (item) => {
    let _formValues = { ...formValues };
    let benefitsArray = _formValues.benefits;

    benefitsArray.push(item);
    const newFormValues = { ..._formValues, benefits: benefitsArray };
    setFormValues(newFormValues);
  };

  const handleRemove = (item) => {
    let _formValues = { ...formValues };
    let benefitsArray = _formValues.benefits;

    benefitsArray = benefitsArray.filter((benefit) => benefit._id !== item._id);
    const newFormValues = { ..._formValues, benefits: benefitsArray };
    setFormValues(newFormValues);
  };

  const isAdded = (item) =>
    formValues.benefits.filter((_item) => _item._id === item._id).length > 0;

  function renderDates(item) {
    const today = new Date();
    const _endDate = new Date(item.endDate);
    const timeDiff = _endDate.getTime() - today.getTime();
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    return (
      <DateContent style={{ display: "flex", gap: "20px" }}>
        {item.endDate && (
          <span style={{ color: "#7F86AD" }}>
            Ends on{" "}
            <span style={{ color: "white" }}>
              {formattedDate(item.endDate)}
            </span>
          </span>
        )}
        {_endDate > today && (
          <span style={{ color: "#7F86AD" }}>
            Expires in <span style={{ color: "white" }}>{daysLeft} days</span>
          </span>
        )}
      </DateContent>
    );
  }

  const RenderCards = () => {
    return rewardData.map((item, idx) => (
      <RewardCard key={idx} className={`${isAdded(item) ? "added" : ""}`}>
        <ContentWrapper>
          <img
            style={{ borderRadius: "8px" }}
            src={item.image}
            width="203px"
            height="127px"
          ></img>
          <Content>
            <MainContent>
              <CategoryText>
                {item.category.charAt(0).toUpperCase() + item.category.slice(1)}
              </CategoryText>
              <RewardName>
                {item.name === "" ? "Reward Title" : item.name}
              </RewardName>
            </MainContent>
            <DateWrapper>{renderDates(item)}</DateWrapper>
          </Content>
        </ContentWrapper>

        {isAdded(item) ? (
          <AddButton onClick={() => handleRemove(item)}>Remove</AddButton>
        ) : (
          <AddButton onClick={() => handleAdd(item)}>Add</AddButton>
        )}
      </RewardCard>
    ));
  };

  return (
    <PageWrapper>
      <RewardCards>{RenderCards()}</RewardCards>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

const RewardCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RewardCard = styled.div`
  display: flex;
  gap: 40px;
  padding: 20px;
  border-radius: 12px;
  justify-content: space-between;

  background-color: ${(props) => props.theme.palette.DB};

  &.added {
    box-sizing: border-box;
    background-color: transparent;

    outline: 1px solid;
    outline-color: ${(props) => props.theme.palette.lightBlue};
  }
`;

const AddButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.lightGrey,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  padding: "12px 20px",
  width: "fit-content",
  height: "fit-content",
  borderRadius: "6px",
  background: theme.palette.darkestGrey,

  "&:hover": {
    backgroundColor: theme.palette.white,
  },
}));

const RewardName = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.header2}
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 12px;
  justify-content: space-between;
  padding: 20px;
  background: ${(props) => props.theme.palette.darkestGrey};
`;

const CategoryText = styled.div`
  ${(props) => props.theme.typography.bodySMedium};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;
const DateWrapper = styled.div`
  display: flex;
`;
const DateContent = styled.div`
  ${(props) => props.theme.typography.bodySMedium};
`;
const ContentWrapper = styled.div`
  display: flex;
  gap: 28px;
`;

export default AirdropBenefits;
