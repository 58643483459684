import { videoType } from "./constants";

export const checkMediaType=(image)=>{
    let uploadType = "image";
    if (image instanceof File) {
        const imageType = image.type.split("/")[1];
        uploadType = videoType.includes(imageType) ? "video" : "image";
      } else if (image) {
        let imageUrl = image.split(/[#?]/)[0].split(".").pop().trim();
        uploadType = videoType.includes(imageUrl) ? "video" : "image";
      }
    return uploadType
}