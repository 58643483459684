import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import { func } from "prop-types";
import { useDebounce } from "utils/hooks";
import API from "utils/brandAPI";
import { last } from "lodash";

function VerifiedSection({ onUpdateName, setSignUpForm, signUpForm }) {
  const [artistId, setArtistId] = useState("");
  const [innerError, setInnerError] = useState("");
  const debouncedArtistId = useDebounce(artistId, 500);
  const [error, setError] = useState([]);
  const [artistInfo, setArtistInfo] = useState({
    status: "",
    name: "",
    avatar: "",
  });

  const addError = (keyToAdd, valueToAdd, currentErrors) => {
    if (!currentErrors.some((err) => err.key === keyToAdd)) {
      return [...currentErrors, { key: keyToAdd, value: valueToAdd }];
    }
    return currentErrors;
  };

  const removeError = (keyToRemove, currentErrors) => {
    return currentErrors.filter((err) => err.key !== keyToRemove);
  };

  const validateDetails = () => {
    let newErrors = [...error]; // create a copy of the error state
    if (!signUpForm.userFullName) {
      newErrors = addError("name", "Name is required", newErrors);
    } else {
      newErrors = removeError("name", newErrors);
    }

    if (!signUpForm.name) {
      newErrors = addError("company", "Company name is required", newErrors);
    } else {
      newErrors = removeError("company", newErrors);
    }

    // If newErrors is empty then validation passed otherwise failed
    setError(newErrors);
    return newErrors.length === 0;
  };

  const getArtistInfo = async () => {
    if (debouncedArtistId) {
      try {
        const res = await API.get(`spotify/artist/${debouncedArtistId}`);
        const avatar =
          res.data?.images && res.data?.images.length > 0
            ? last(res.data?.images)?.url
            : "";
        setArtistInfo({
          status: "success",
          name: res.data?.name,
          avatar,
        });
        setSignUpForm((state) => ({
          ...state,
          artistId,
          artistAvatar: avatar,
          artistName: res.data?.name,
        }));
      } catch (error) {
        setInnerError(error?.response?.data?.message);
        setArtistInfo({
          status: "error",
          name: "",
          avatar: "",
        });
        setSignUpForm((state) => ({
          ...state,
          artistId: null,
          artistAvatar: "",
          artistName: "",
        }));
      }
    } else {
      setArtistInfo({
        status: "",
        name: "",
        avatar: "",
      });
      setSignUpForm((state) => ({
        ...state,
        artistId: "",
        artistAvatar: "",
        artistName: "",
      }));
    }
  };

  useEffect(() => {
    getArtistInfo();
  }, [debouncedArtistId]);

  const handleClickStart = () => {
    if (validateDetails()) {
      onUpdateName();
    }
    else {
      return;
    }
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>Your account is verified!</Title>
        <Subtitle>
          Start creating memberships and rewards by telling us a bit more about
          you!
        </Subtitle>
      </TitleWrapper>
      <FieldWrapper>
        <LabelWrapper>
          <Label>Full Name</Label>
        </LabelWrapper>
        <FormField
          sx={formFieldOverrides}
          placeholder="eg. John Doe"
          inputProps={{
            "aria-label": "fullName",
          }}
          onChange={(e) => {
            setSignUpForm((state) => ({
              ...state,
              userFullName: e.target.value,
            }));
          }}
          value={signUpForm.userFullName}
        />
        <LabelWrapper>
          <Label>Company Name</Label>
        </LabelWrapper>
        <FormField
          sx={formFieldOverrides}
          placeholder="Eg. Campaign"
          inputProps={{
            "aria-label": "companyName",
          }}
          onChange={(e) =>
            setSignUpForm((state) => ({ ...state, name: e.target.value }))
          }
          value={signUpForm.name}
        />
        <LabelWrapper>
          <Label>Spotify Artist ID (Optional)</Label>
        </LabelWrapper>
        <FormField
          sx={formFieldOverrides}
          placeholder="Eg. 12345678"
          inputProps={{
            "aria-label": "artistId",
          }}
          onChange={(e) => setArtistId(e.target.value)}
          value={artistId}
        />
        {artistInfo.status === "success" ? (
          <div>Artist Name: {artistInfo.name}</div>
        ) : innerError ? (
          <ArtistError>{innerError}</ArtistError>
        ) : null}
      </FieldWrapper>
      {error.length > 0 && (
        <ErrorSection>
          {error.length > 0 &&
            error.map((e, index) => (
              <ErrorRow
                key={`${index}key`}
              >{`${e.key}: ${e.value}`}</ErrorRow>
            ))}
        </ErrorSection>
      )}
      <ButtonsField>
        <EnterButton variant="contained" onClick={() => handleClickStart()}>
          Start
        </EnterButton>
      </ButtonsField>
    </Wrapper>
  );
}

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 12px;
`;

const Label = styled.div`
  ${(props) => props.theme.typography.subHeader};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: ${(props) => props.theme.palette.white};
`;

const ErrorSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  margin-top: 16px;
`;
const ErrorRow = styled.div`
  font-size: 12px;
  color: red;
`;

const ArtistError = styled.div`
  font-family: "Inter";
  color: ${(props) => props.theme.palette.fuschia100};
`;
const Subtitle = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const Error = styled.div`
  color: red;
  font-size: 20px;
  margin-bottom: 20px;
`;

const FormField = styledMui(InputBase)({
  width: "476px",
});

const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#F4F3EE",
  padding: "13px 20px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

const ButtonsField = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const ResendButton = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightBlue};
  cursor: pointer;
  text-decoration: underline;
`;

const EnterButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 0",
  width: "179px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

VerifiedSection.propTypes = {
  onUpdateName: func,
};

export default memo(VerifiedSection);
