/** @format */

import React, { useEffect } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, CircularProgress, Divider } from "@mui/material";
import InputBase from "@mui/material/InputBase";

const Main = ({
  formValues,
  onFormValueChanges,
  error,
  handleLogin,
  loading
}) => {
  const { email } = formValues;
  useEffect(() => {
    const inputElement = document.getElementById('emailField');
    if (inputElement) {
      inputElement.focus();
    }
  }, []);
  return (
    <Wrapper>
      <MainText>Sign In / Sign Up</MainText>
      <FieldWrapper isError={error !== ''}>
        <div className="input-wrapper">
          <Label>Email</Label>
          <FormField
            id={"emailField"}
            sx={formFieldOverrides}
            placeholder="Email Address"
            inputProps={{
              "aria-label": "email",
            }}
            fullWidth
            value={email}
            onChange={(event) => onFormValueChanges(event, "email")}
          />
        </div>
        {error && <Error>{error}</Error>}
        <LoginButton
          type="submit"
          onClick={() => handleLogin()}
          variant="contained"
        >
          {loading ? <CircularProgress /> : "Continue"}
        </LoginButton>
      </FieldWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
  height: 249px;
  @media (max-width: 768px){
    gap: 40px;
    height: 100%;
  }
`;

const MainText = styled.div`
  display: flex;
  ${(props) => props.theme.typography.header1};
  line-height: normal;
  color: ${(props) => props.theme.palette.white};
  justify-content: center;
  align-self: stretch;
  @media (max-width: 768px){
    ${(props) => props.theme.typography.header2};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const DividerCustom = styledMui(Divider)(({ theme }) => ({
  ...theme.typography.bodyM,
  color: theme.palette.lightGrey,
  width: "100%",
  display: "flex",
  alignItems: "center",
  span: {
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  "&::before, &::after": {
    height: "1px",
    backgroundColor: theme.palette.darkGrey,
  },
}));

const LoginButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 20px",
  height: "60px",
  lineHeight: "normal",
  "@media (max-width: 768px)" : {
    ...theme.typography.bodySMedium,
    height: "48px",
  },
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "16px 20px",
  color: "#7F86AD",
  height: "60px",
  gap: "12px",
  alignSelf: "stretch",
  alignItems: "center",
  fontFamily: "Inter",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
`;

const Label = styled(BaseText)`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
  line-height: normal;
  @media (max-width: 768px){
    ${(props) => props.theme.typography.subHeader};
  line-height: normal;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  @media (max-width: 768px){
    gap: ${(props) => props.isError?  '20px' : '40px'};
  }
  .input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }
`;

const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
  ${'' /* margin-top: 10px; */}
  color: red;
`;

const SecondaryButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.lightGrey,
  textTransform: "none",
  backgroundColor: "transparent",
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "18px 20px",
  gap: "20px",
  height: "60px",

  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));

const SpotifyButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.white,
  textTransform: "none",
  backgroundColor: "#21BA52",
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "18px 20px",
  gap: "20px",
  height: "60px",

  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));

const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.white,
  lineHeight: "normal",
}));

export default Main;
