import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, Divider, ButtonBase } from "@mui/material";
import { ImageCampaignLogo, ImageUserPortalDefault } from "images";
import { IconMail, IconVerified } from "images/icons/svg-components";
import { IconSpotify } from "images/icons/svg-components";
import qs from "qs";
import API from "utils/userAPI";

const Verified = ({ formValues, brandId }) => {
  const scopes =
    "user-read-email user-read-private user-read-recently-played user-library-read user-top-read user-library-modify playlist-read-private playlist-modify-private playlist-modify-public user-follow-modify user-follow-read";
  const { email } = formValues;
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [text, setText] = useState(["", "", ""]);
  const [spotifyLink, setSpotifyLink] = useState("");
  useEffect(() => {
    const init = async () => {
      const res = await API.get(`signupmodal/${brandId}`);
      if (res.data.status === "success") {
        const headerImage = res.data.data?.signUpModal?.headerImage;
        const headerImageUrl =
          headerImage instanceof File
            ? URL.createObjectURL(headerImage)
            : headerImage;
        setHeaderImageUrl(headerImageUrl);
        setText(res.data.data?.signUpModal?.text);
        setSpotifyLink(res.data.data?.signUpModal?.spotifyLink || "");
      }
    };
    init();
  }, []);
  return (
    <Wrapper>
      {/* <MailIcon>
        <IconMail />
        <IconVerified
          style={{ position: "absolute", bottom: "0", right: "0" }}
        />
      </MailIcon> */}
      <GroupedText>
        <HeaderText>Verification Successful!</HeaderText>
        <SubheaderText>
          Save the{" "}
          {spotifyLink === '' ? '' : spotifyLink.startsWith("https://open.spotify.com/track")
            ? "song"
            : "album"}{" "}
          to continue
        </SubheaderText>
      </GroupedText>
      <Image>
        <img src={headerImageUrl || ImageUserPortalDefault}></img>
      </Image>

      <Content>
        <div className="text-wrapper">
          <div className="header2">{text?.[0]}</div>
          <div className="subheader">{text?.[1]}</div>
        </div>
      </Content>

      <SpotifyButton
        variant="contained"
        disableRipple
        onClick={() =>
        (window.location.href =
          "https://accounts.spotify.com/authorize?" +
          qs.stringify({
            response_type: "code",
            client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
            scope: scopes,
            redirect_uri: `${process.env.REACT_APP_API_URL}/api/v1/spotify/callback`,
            state: JSON.stringify({ brandId, email, spotifyLink }),
          }))
        }
      >
        <IconSpotify className="icon-spotify" />
        Save on Spotify
      </SpotifyButton>
    </Wrapper>
  );
};

const GroupedText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  @media (max-width: 768px){
    gap: 4px;
  }
`;

const Content = styled.div`
  .text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: center;
    .header2 {
      line-height: normal;
      ${(props) => props.theme.typography.header2}
      color: ${(props) => props.theme.palette.white};
    }
    .subheader {
      line-height: normal;
      ${(props) => props.theme.typography.header3}
      color: ${(props) => props.theme.palette.white};
    }
  }
  button .icon-spotify{
    font-size: 40px;
    @media (max-width: 768px){
      font-size: 24px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 627px;
  background: ${(props) => props.theme.palette.darkestGrey};
  border-radius: 16px;
  padding: 40px;
  @media (max-width: 768px) {
    ${'' /* padding: 40px 20px 20px 20px; */}
    margin-top: -25px;
    width: 100%;
  }
`;

const Image = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  ${"" /* padding:40px; */}
  img {
    height: 200px;
    width: 200px;
    @media (max-width: 768px){
      height: 100%;
      width: 100%;
      max-height: 320px;
      max-width: 320px;
    }
  }
`;
const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 18px;
  margin-top: 10px;
  margin-left: 4px;
  color: red;
  text-align: center;
`;

const ClaimButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 20px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 12px;
`;

const MailIcon = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: fit-content;
  align-self: center;
  background-color: ${(props) => props.theme.palette.campaignBlue};
  border-radius: 100%;
  padding: 32.76px 27.3px;
`;

const SecondaryText = styled.span`
  text-align: center;
  white-space: normal;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const Footer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 28px;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const HeaderText = styled.div`
  text-align: center;
  line-height: normal;
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  @media (max-width: 768px){
    ${(props) => props.theme.typography.header2};
  }
`;

const SubheaderText = styled.span`
  text-align: center;
  line-height: normal;
  ${(props) => props.theme.typography.header3};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const SpotifyButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.black,
  textTransform: "none",
  backgroundColor: "#21BA52",
  borderRadius: "6px",
  padding: "12px 0px 12px 20px;",
  gap: "16px",
  width: "100%",
  lineHeight: "normal",
  ".icon-spotify": {
    width: "40px",
    height: "40px",
    "@media (max-width: 768px)": {
      width: "24px",
      height: "24px",
    }
  },
  "@media (max-width: 768px)": {
    ...theme.typography.bodySMedium,
    lineHeight: "normal",
  },
  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));
export default Verified;
