import React from "react";
import styled from "styled-components";

export const Top = ({ data = [] }) => {
  return (
    <Container>
      <div className="heading">Top Rewards Redeemed</div>

      {data.slice(0, 5).map((item, index) => {
        return (
          <div className="item" key={index}>
            <div className="item-index">{index + 1}</div>
            <div className="item-value">{item}</div>
          </div>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  padding: 24px 16px;
  background: #36353d;
  border-radius: 12px;
  .heading {
    font-size: 18px;
    line-height: 27px;
  }
  .item {
    margin: 20px 0;
    display: flex;
    &-index {
      color: #b1b1b1;
      font-size: 14px;
      margin-right: 12px;
      line-height: 16px;
    }
    &-value {
      color: #fff;
      font-size: 16px;
      line-height: 16px;
    }
  }
`;
