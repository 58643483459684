/**
 *
 * RewardsTabsBar
 *
 */

import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, ButtonBase } from "@mui/material";
import { func, array } from "prop-types";

function RewardsTabsBar({
  activeTab,
  setActiveTab,
  membershipDataSummary,
  isAllRemoved,
}) {
  const RewardTypes = isAllRemoved
    ? membershipDataSummary
    : [{ id: "0", name: "All" }, ...membershipDataSummary];

  const renderRewardTypesTabs = () =>
    RewardTypes.map((type) => {
      if (activeTab.id === type.id) {
        return (
          <ActiveRewardTypeButton
            onClick={() => setActiveTab(type)}
            key={type.id}
          >
            {type.name}
          </ActiveRewardTypeButton>
        );
      }
      return (
        <RewardTypeButton
          onClick={() => setActiveTab(type)}
          disableElevation
          key={type.id}
        >
          {type.name}
        </RewardTypeButton>
      );
    });

  return (
    <RewardsFilter>
      <RewardTypesGroup>{renderRewardTypesTabs()}</RewardTypesGroup>
    </RewardsFilter>
  );
}

const RewardTypeButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header2,
  textTransform: "none",
  color: theme.palette.lightBlue,
  padding: "16px 0 ",
  width: "fit-content",

  "&:hover": {
    color: theme.palette.white,
    background: "none",
  },
}));

const ActiveRewardTypeButton = styledMui(RewardTypeButton)(({ theme }) => ({
  color: theme.palette.white,
  fontWeight: "600",
  borderRadius: "0",

  "::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    borderBottom: "3px solid #FFFFFF",
  },
}));

const RewardsFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RewardTypesGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

RewardsTabsBar.propTypes = {
  activeTab: PropTypes.object,
  setActiveTab: func,
  setPage: func,
  setData: func,
  rewardData: array,
  membershipDataSummary: array,
};

export default memo(RewardsTabsBar);
