import React from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { LANDING_PAGE_DEFAULTS } from "utils/constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CodeIcon from "@mui/icons-material/Code";
import { useNavigate } from "react-router";
import EmbedModal from "../EmbedModal";

const LandingPageMenu = ({
  landingPageFormValues,
  signUpModalFormValues,
  qrCodeModalFormValues,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [clickedEmbed, setClickedEmbed] = React.useState("landingpage");

  const menuItems = [
    {
      title: "Pop Up Modal",
      description: "Embed a pop up modal onto your website using this builder",
      image: signUpModalFormValues.headerImage,
      embedClick: () => {
        setClickedEmbed("signupmodal");
        setOpen(true);
      },
      editClick: () => {
        navigate(
          `/signupmodal-edit?brandId=${localStorage.getItem("brandId")}`
        );
      },
      previewClick: () => {
        navigate(`/signupmodal?brandId=${localStorage.getItem("brandId")}`);
      },
    },
    {
      title: "Website",
      description: "Embed a full page onto your website using this builder",
      image: landingPageFormValues.headerImage,
      embedClick: () => {
        setClickedEmbed("landingpage");
        setOpen(true);
      },
      editClick: () => {
        navigate(
          `/landingpage-edit?brandId=${localStorage.getItem("brandId")}`
        );
      },
      previewClick: () => {
        navigate(`/landingpage?brandId=${localStorage.getItem("brandId")}`);
      },
    },
    {
      title: "QR Code",
      description:
        "Generate QR code for your badges to use at in-person events",
      image: qrCodeModalFormValues.headerImage,
      embedClick: () => {
        setClickedEmbed("landingpage");
        setOpen(true);
      },
      editClick: () => {
        navigate(`/qrcode-edit?brandId=${localStorage.getItem("brandId")}`);
      },
      previewClick: () => {
        navigate(`/qrcode?brandId=${localStorage.getItem("brandId")}`);
      },
    },
  ];

  function renderMenuItems() {
    return menuItems.map((item, idx) => {
      return item.image === "" ? (
        <MenuItemWrapper key={idx} onClick={item.editClick}>
          <span
            className="title"
            style={{ fontSize: "40px", lineHeight: "48px" }}
          >
            +
          </span>
          <span className="title">{item.title}</span>
          <span className="description">{item.description}</span>
        </MenuItemWrapper>
      ) : (
        <FilledMenuItemWrapper key={idx}>
          <img src={item.image} onClick={item.editClick}></img>
          <div className="footer">
            <span>{item.title}</span>
            <div className="icons">
              <CodeIcon onClick={item.embedClick} />
              <VisibilityIcon onClick={item.previewClick} />
            </div>
          </div>
        </FilledMenuItemWrapper>
      );
    });
  }
  return (
    <Wrapper>
      <EmbedModal
        open={open}
        setOpen={(bool) => setOpen(bool)}
        brandId={localStorage.getItem("brandId")}
        type={clickedEmbed}
      />
      <Header>
        <span>Landing Page</span>
      </Header>
      <MenuWrapper>{renderMenuItems()}</MenuWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
`;

const MenuWrapper = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;
`;

const MenuItemWrapper = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 60px 10px;
  border-radius: 12px;
  border: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};
  width: 300px;
  height: 240px;

  .title {
    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.white};
    font-size: 18px;
  }

  .description {
    text-align: center;
    ${(props) => props.theme.typography.bodyS};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;

const FilledMenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    cursor: pointer;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => props.theme.palette.darkestGrey};
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    padding: 20px 32px;
    ${(props) => props.theme.typography.Subheader};
    color: ${(props) => props.theme.palette.white};

    .icons {
      display: flex;
      gap: 24px;
      svg {
        color: ${(props) => props.theme.palette.lightBlue};
        cursor: pointer;
        :hover {
          color: ${(props) => props.theme.palette.white};
        }
      }
    }
  }

  .description {
    text-align: center;
    ${(props) => props.theme.typography.bodyS};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;

export default LandingPageMenu;
