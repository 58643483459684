import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import API from "utils/userAPI";
import Modal from "react-modal";
import { current } from "immer";
import { fetchUserProfile } from "../../UserPortal/actions";
import qs from "qs";
const GlobalStyles = createGlobalStyle`
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;  
}
`;

const IntegrationItem = ({
  icon,
  itemText,
  buttonText,
  user,
  brand,
  dispatch,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shopifyUrl, setShopifyUrl] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [spotifyArtistId, setSpotifyArtistId] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [currentButtonText, setCurrentButtonText] = useState(buttonText);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const breakpoint = 768;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      (itemText === "Twitter" && user?.twitterId) ||
      (itemText === "Spotify" && user?.spotifyId) ||
      (itemText === "Discord" && user?.discordAuth) ||
      (itemText == "YouTube" && buttonText == "Connected")
    ) {
      setCurrentButtonText("Connected");
    } else {
      setCurrentButtonText("Connect");
    }

    return () => {};
  }, [itemText, user]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleShopifyFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here (e.g., save the Shopify URL and access token)
    // console.log("Shopify URL:", shopifyUrl);
    // console.log("Access Token:", accessToken);
    closeModal();
  };

  const handleWebsiteFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here (e.g., save the website URL)
    // console.log("Website URL:", websiteUrl);
    closeModal();
  };

  const handleSpotifyFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here (e.g., save the Spotify artist ID)
    // console.log("Spotify Artist ID:", spotifyArtistId);
    closeModal();
  };

  const handleYoutubeLogin = () => {
    console.log(user);
    // if (user?.email && brand?._id) {
      const clientId = process.env.REACT_APP_YOUTUBE_CLIENT_ID; // Replace this with your YouTube OAuth client ID
      const redirectUri = `${process.env.REACT_APP_YOUTUBE_CALLBACK}`; // Replace with your redirect URI
      const scope = "https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile";
      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?` + qs.stringify({
        client_id: clientId,
        redirect_uri: redirectUri,
        scope: scope,
        response_type: "code",
        access_type: "offline",
        state: JSON.stringify({ brandId: brand?._id, email: 'daymien.georgiy@falkcia.com', isBrand: false })
      });
      // const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&access_type=offline&state=${JSON.stringify(
      //   { brandId: "65432e58dc634e0f4f569503", email: "kalyan+1@campaignlayer.com", isBrand: false }
      // )}`;
      window.location.assign(authUrl);
    // }
  };
  const handleYoutubeDisconnect = async () => {
    const res = await API.post("/youtube/disconnect", { userId: user?._id });
   const tokens =  JSON.parse(localStorage.getItem("userTokens"))
  tokens.user.youtubeAuth=false
  localStorage.setItem("userTokens", JSON.stringify(tokens));
    setCurrentButtonText("Connect");
  };

  const getSpotifyAuthUrl = (brandid, email) => {
    const spotifyAuthUrl =
      "https://accounts.spotify.com/authorize?" +
      qs.stringify({
        response_type: "code",
        client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
        scope:
          "user-read-private user-read-email user-top-read playlist-read-private user-library-read user-read-recently-played",
        redirect_uri: `${process.env.REACT_APP_API_URL}/api/v1/spotify/callback`,
        // @ts-ignore
        state: JSON.stringify({ brandId: brandid, email: email }),
      });

    return spotifyAuthUrl;
  };

  const handleConnect = () => {
    switch (itemText) {
      case "Twitter":
        if (user && brand) {
          window.open(
            `${process.env.REACT_APP_API_URL}/api/v1/auth/twitter?userId=${user?._id}&brandId=${brand?._id}`,
            "_self"
          );
        }
        break;
      case "Spotify":
        if (user && brand) {
          // console.log(brand?._id, user?.email);
          // console.log(getSpotifyAuthUrl(brand?._id, user?.email));
          window.open(`${getSpotifyAuthUrl(brand?._id, user?.email)}`, "_self");
        }
        break;
      case "Discord":
        handleDiscordConnect();
        break;
      case "Website":
        setShowWebsiteConnectModal(true);
        break;
      case "YouTube":
        handleYoutubeLogin();
        break;
      default:
        break;
    }
  };

  const handleDisconnect = () => {
    switch (itemText) {
      case "Discord":
        handleDiscordDisconnect();
        break;
      case "Twitter":
        handleTwitterDisconnect();
        break;
      case "Spotify":
        handleSpotifyDisconnect();
      case "YouTube":
        handleYoutubeDisconnect();
        break;
      default:
        break;
    }
  };

  const handleDiscordConnect = () => {
    if (itemText === "Discord") {
      const { email } = user;
      const brandId = user.brandIds[0];
      const clientId = process.env.REACT_APP_DISCORD_CLIENT_ID;
      const redirectUri = `${process.env.REACT_APP_API_URL}/api/v1/discord/callback`;
      const scope =
        "identify%20email%20guilds.join%20guilds%20guilds.members.read%20connections";
      const state = JSON.stringify({ brandId, email, isBrand: false });
      const encodedState = encodeURIComponent(state);
      const authUrl = `https://discord.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${encodedState}`;
      window.location.assign(authUrl);
    }
  };

  const handleDiscordDisconnect = async () => {
    const userId = user._id;
    try {
      await API.post("/discord/user/disconnect", { id: userId });
    } catch (err) {
      console.log(err);
    }
    setCurrentButtonText("Connect");
  };
  const handleTwitterDisconnect = async () => {
    try {
      await API.post("/twitter/disconnect");
      setCurrentButtonText("Connect");
      dispatch(fetchUserProfile(brand?._id));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSpotifyDisconnect = async () => {
    try {
      await API.post("/spotify/disconnect");
      setCurrentButtonText("Connect");
      dispatch(fetchUserProfile(brand?._id));
    } catch (err) {
      console.log(err);
    }
    setCurrentButtonText("Connect");
  };

  return (
    <>
      <IntegrationItemWrapper isConnected={currentButtonText === "Connected"}>
        <div className="left-content">
          <IntegrationIcon isConnected={currentButtonText === "Connected"}>
            {icon}
          </IntegrationIcon>
          <IntegrationText isConnected={currentButtonText === "Connected"}>
            {itemText}
            {windowWidth <= breakpoint && (
              <CheckCircleIcon
                style={{ height: "18px", width: "18px", margin: 0 }}
              ></CheckCircleIcon>
            )}
          </IntegrationText>
        </div>
        {currentButtonText === "Enter Details" && (
          <IntegrationButton
            onClick={openModal}
            isConnected={false}
            isHovered={false}
          >
            {currentButtonText}
          </IntegrationButton>
        )}
        {currentButtonText === "Connected" && (
          <IntegrationButton
            disabled={false}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            isHovered={isHovered}
            isConnected={true}
            onClick={handleDisconnect}
          >
            {windowWidth <= breakpoint || isHovered ? (
              "Disconnect"
            ) : (
              <>
                Connected
                <CheckCircleIcon
                  style={{ height: "18px", width: "18px", margin: 0 }}
                ></CheckCircleIcon>
              </>
            )}
          </IntegrationButton>
        )}
        {currentButtonText === "Connect" && (
          <IntegrationButton onClick={handleConnect} isConnected={false}>
            {currentButtonText}
          </IntegrationButton>
        )}
      </IntegrationItemWrapper>
      <GlobalStyles />
      {/* {itemText === "Shopify" && (
        <CustomModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          isLarge={true}
          overlayClassName="custom-modal-overlay"
        >
          <Modalheader>Shopify</Modalheader>
          <form onSubmit={handleShopifyFormSubmit}>
            <Modaltitles>Shopify Store URL</Modaltitles>
            <Modalinput
              type="text"
              value={shopifyUrl}
              onChange={(e) => setShopifyUrl(e.target.value)}
              placeholder="Shopify Store URL"
              required
            />
            <Modaltitles>Shopify Access Token</Modaltitles>
            <Modalinput
              type="text"
              value={accessToken}
              onChange={(e) => setAccessToken(e.target.value)}
              placeholder="Shopify Access Token"
              required
            />
            <button type="submit">Save</button>
          </form>
        </CustomModal>
      )} */}
      {itemText === "Website" && (
        <CustomModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          isLarge={false}
          overlayClassName="custom-modal-overlay"
        >
          <Modalheader>Website</Modalheader>
          <form onSubmit={handleWebsiteFormSubmit}>
            <Modaltitles>Website URL</Modaltitles>
            <Modalinput
              type="text"
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
              placeholder="www.campaignlayer.com"
              required
            />
            <button type="submit">Save</button>
          </form>
        </CustomModal>
      )}
      {itemText === "Spotify" && (
        <CustomModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          isLarge={false}
          overlayClassName="custom-modal-overlay"
        >
          <Modalheader>Spotify</Modalheader>
          <form onSubmit={handleSpotifyFormSubmit}>
            <Modaltitles>Spotify Artist ID</Modaltitles>
            <Modalinput
              type="text"
              value={spotifyArtistId}
              onChange={(e) => setSpotifyArtistId(e.target.value)}
              placeholder="ABCDEFG1234"
              required
            />
            <button type="submit">Save</button>
          </form>
        </CustomModal>
      )}
    </>
  );
};

const IntegrationItemWrapper = styled.div`
  display: flex;
  padding: 20px 20px 20px 28px;
  align-items: center;
  width: 100%;
  align-self: stretch;
  border-radius: 8px;
  background: ${(props) => (props.isConnected ? "#1A1A22" : "#21212E")};
  justify-content: space-between;
  .left-content {
    display: flex;
    align-items: center;
    gap: 24px;
    @media (max-width: 768px) {
      gap: 12px;
    }
  }
  @media (max-width: 768px) {
    background: none;
    padding: 14px 20px;
    border-bottom: 1px solid #21212e;
    max-width: none;
  }
`;

const IntegrationIcon = styled.div`
  > * {
    width: 32px;
    height: 32px;
    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
      padding: 4px;
    }
  }
  color: ${(props) => (props.isConnected ? "#6096FF" : "#7F86AD")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IntegrationText = styled.div`
  color: ${(props) => (props.isConnected ? "#F4F3EE" : "#7F86AD")};
  display: flex;
  gap: 12px;
  align-items: center;
  svg {
    display: ${(props) => (props.isConnected ? "inline-block" : "none")};
    color: #0bd680;
  }
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const IntegrationButton = styled.button`
  ${(props) => props.theme.typography.bodySMedium};
  display: flex;
  width: 100%;
  max-width: 155px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--darkest-grey, #1a1a22);
  color: ${(props) =>
    props.isConnected ? (props.isHovered ? "#6096FF" : "#0BD680") : "#7f86ad"};

  @media (max-width: 768px) {
    max-width: 105px;
    color: #7f86ad;
    background: ${(props) => (props.isConnected ? "none" : "#1a1a22")};
    border: none;
  }

  border: ${(props) =>
    props.isConnected
      ? props.isHovered
        ? "1px solid #6096FF"
        : "1px solid var(--dark-grey, #352B42)"
      : "1px solid var(--dark-grey, #352B42)"};
`;

const CustomModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--darkest-grey, #1a1a22);
  border-radius: 16px;
  width: 450px;
  height: ${(props) => (props.isLarge ? "370px" : "280px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding: 60px 60px 60px 60px;
  align-self: stretch;
  > * {
    zoom: 0.8;
  }
`;

const Modalheader = styled.div`
  color: var(--white, #f4f3ee);
  text-align: center;
  margin-bottom: 32px;
  /* Header I */
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Modaltitles = styled.div`
  color: var(--light-blue, #6096ff);
  /* Header II */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Modalinput = styled.input`
  margin-top: 8px;
  width: 100%;
  height: 60px;
  background: var(--darkest-grey, #1a1a22);
  border-radius: 8px;
  border: 1px solid var(--dark-grey, #352b42);
  color: var(--white, #f4f3ee);
  padding: 0 16px;
  margin-bottom: 28px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export default IntegrationItem;
