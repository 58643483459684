/**
 *
 * Dashboard
 *
 */

import React, { memo, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";

import styled from "styled-components";

import { useInjectSaga } from "utils/injectSaga";
import { useInjectReducer } from "utils/injectReducer";
import LeftMenu from "../../components/LeftMenu/Loadable";
import makeSelectDashboard from "./selectors";
import reducer from "./reducer";
import saga from "./saga";

import ManageMembership from "../ManageMembership/Loadable";
import NewMembership from "../NewMembership/Loadable";
import ManageReward from "../ManageReward/Loadable";

import LandingPage from "../LandingPage/Loadable";
import Analytics from "containers/Analytics";
import Reports from "containers/Reports";
import NewCampaign from "containers/NewCampaign";
import { Campaign } from "@mui/icons-material";
import CampaignsPage from "containers/CampaignsPage";

export function Dashboard() {
  useInjectReducer({ key: "dashboard", reducer });
  useInjectSaga({ key: "dashboard", saga });
  const [activeTab, setActiveTab] = useState("analytics");
  const [selectedMembership, setSelectedMembership] = useState({});
  const [selectedReward, setSelectedReward] = useState({});
  const [page, setPage] = useState("rewardCards");
  return (
    <DashboardWrapper>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="Description of Dashboard" />
      </Helmet>
      <AppContent className="container-fluid">
        <LeftMenu
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setManageRewardPage={setPage}
          setSelectedMembership={setSelectedMembership}
        />
        <Content>
          {activeTab === "analytics" && <Analytics />}
          {activeTab === "reports" && <Reports />}
          {activeTab === "memberships" && (
            <ManageMembership
              setActiveTab={setActiveTab}
              selectedMembership={selectedMembership}
              setSelectedMembership={setSelectedMembership}
            />
          )}
          {activeTab === "newMembership" && (
            <NewMembership setActiveTab={setActiveTab} />
          )}
          {activeTab === "editMembership" && (
            <NewMembership
              isEditing
              selectedMembership={selectedMembership}
              setActiveTab={setActiveTab}
            />
          )}
          {activeTab === "rewards" && (
            <ManageReward
              selectedMembership={selectedMembership}
              setSelectedReward={setSelectedReward}
              selectedReward={selectedReward}
              page={page}
              setPage={setPage}
            />
          )}
          {activeTab === "landingPage" && (
            <LandingPage setActiveTab={setActiveTab} />
          )}
          {/* {activeTab === "campaign" && (
            <CampaignsPage setActiveTab={setActiveTab} />
          )}
          {activeTab === "newCampaign" && <NewCampaign />} */}
        </Content>
      </AppContent>
    </DashboardWrapper>
  );
}
const DashboardWrapper = styled.div`
  height: 100%;
`;

const AppContent = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
`;

const Content = styled.div`
  flex: 1;
  padding: 20px 40px;
  display: flex;
  height: calc(100% - 92px); // 92 px is header height
  overflow: auto;
`;
Dashboard.propTypes = {};

const mapStateToProps = createStructuredSelector({
  dashboard: makeSelectDashboard(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Dashboard);
