import React, { memo, useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { SIGN_UP_MODAL_DEFAULTS } from "utils/constants";
import queryString from "query-string";
import API from "utils/userAPI";
import EditSignUpModalDisplay from "containers/LandingPage/components/EditSignUpModalDisplay";
import { isEmpty } from "utils/object";

function BrandSignUpModal() {
  const [isLoading, setIsLoading] = useState(true);
  const queryParams = queryString.parse(location.search);
  const [formValues, setFormValues] = useState({
    ...SIGN_UP_MODAL_DEFAULTS,
    membershipText: SIGN_UP_MODAL_DEFAULTS.membershipText.replace(
      "[]",
      localStorage.getItem("companyName") + "'s"
    ),
  });
  const navigate = useNavigate();
  const brandId = localStorage.getItem("brandId") || queryParams?.brandId;

  const handleBacktoEditorClick = () => {
    navigate(`/signupmodal-edit?brandId=${brandId}`);
  };

  const init = async () => {
    setIsLoading(true);
    const res = await API.get(`/signupmodal/${brandId}`);
    const sigupModel = JSON.parse(localStorage.getItem("signUpModel"));
    res.data.data.signUpModal = sigupModel
      ? { ...sigupModel, headerImage: sigupModel.headerImage }
      : res.data.data.signUpModal;
    if (!isEmpty(res.data.data.signUpModal)) {
      setFormValues((prev) => ({
        ...prev,
        ...res.data.data.signUpModal,
        theme: res.data.data.signUpModal.theme || SIGN_UP_MODAL_DEFAULTS.theme,
        text:
          res.data.data.signUpModal.text.length >= 3
            ? res.data.data.signUpModal.text
            : [...SIGN_UP_MODAL_DEFAULTS.text],
        spotifyText:
          res.data.data.signUpModal.spotifyText ||
          SIGN_UP_MODAL_DEFAULTS.spotifyText,
        spotifyLink:
          res.data.data.signUpModal.spotifyLink ||
          SIGN_UP_MODAL_DEFAULTS.spotifyLink,
        membershipText:
          res.data.data.signUpModal.membershipText ||
          SIGN_UP_MODAL_DEFAULTS.membershipText.replace(
            "[]",
            localStorage.getItem("companyName") + "'s"
          ),
      }));
    }
    console.log(res);
    setIsLoading(false);
  };
  useEffect(() => {
    init();
    return () => { };
  }, []);

  if (queryParams?.modal) {
    return <EditSignUpModalDisplay formValues={formValues} />;
  } else {
    return (
      <Wrapper style={{
        backgroundImage: `url(${formValues.headerImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'noRepeat',
      }}>
        <HeaderButtons>
          <BackButton onClick={handleBacktoEditorClick}>
            <ChevronLeftIcon />
            <span>Back to Editor</span>
          </BackButton>
        </HeaderButtons>
        <Content>
          {isLoading ? (
            <CircularProgress size={100} />
          ) : (
            <EditSignUpModalDisplay formValues={formValues} />
          )}
        </Content>
      </Wrapper>
    );
  }
}
const Wrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: #1a1a22;
  overflow: auto;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.70);
  width: 100%;
  overflow: auto;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  padding:40px;
  align-items: center;
  transition: all 0.3s ease;
  color: #6096ff;
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  &:hover {
    opacity: 0.7;
  }
`;

const HeaderButtons = styled.div`
  display: flex;
  background: #1a1a22;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #352b42;
  height: 92px;
  padding: 0 40px;
  align-items: center;
  img {
    height: 40px;
    margin: auto 0;
  }
`;

BrandSignUpModal.propTypes = {};

export default memo(BrandSignUpModal);
