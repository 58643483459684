import React, { memo, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { ImageUserInfo, ImageUserProfileMock } from "images";
import { numberWithCommas } from "utils/dollar";
import { Button } from "@mui/material";
import { monthNames } from "utils/date";

const UserInfo = ({ membershipHoldings, membershipData = [], brand = {} }) => {
  // sort membershipData by spendMin
  // membershipData = [...membershipData].sort((a, b) => a.spendMin - b.spendMin);

  // get Year and Month of membershipHoldings.membershipEntry.startTime
  const date = membershipHoldings?.createdAt;
  let yearMonth = "May 2023";
  if (date) {
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const monthName = monthNames[Number(month) - 1];
    yearMonth = `${monthName} ${year}`;
  }

  const progress = useMemo(() => {
    let idx = membershipData.findIndex(
      (item) => item._id === membershipHoldings?.membershipEntry?._id
    );
    idx = idx === -1 ? 0 : idx;
    if (membershipData.length === 1) return 100;
    else return (idx / (membershipData.length - 1)) * 100;
  }, [membershipData]);

  function renderBar({ progress }) {
    return (
      <>
        <Bar>
          <BarProgress progress={progress}></BarProgress>
        </Bar>
        <Labels>
          {membershipData.map((tier, idx) => (
            <>
              {membershipData.length === 1 ? (
                <div style={{ flex: 1 }} /> // For putting label to the end of progress bar when there is only one membership
              ) : (
                <></>
              )}
              {/* <LabelWrapper> // 06-16-23: Temporarily removing min/max spend for Fodera
                <BarPoint />
                <LabelName>{tier.name}</LabelName>
                <LabelThreshold>
                  ${numberWithCommas(tier.spendMin)}
                </LabelThreshold>
              </LabelWrapper> */}
            </>
          ))}
        </Labels>
      </>
    );
  }

  const currentTierIdx = useMemo(() => {
    let idx = membershipData.findIndex(
      (i) => i._id === membershipHoldings?.membershipEntry?._id
    );
    idx = idx === -1 ? 0 : idx;
    return idx;
  }, [membershipData, membershipHoldings]);

  const renderAmountToNextTier = () => (
    <>
      {membershipData &&
      membershipData.length > 1 &&
      currentTierIdx !== membershipData.length - 1
        ? `$${
            membershipData[currentTierIdx + 1].spendMin -
            membershipData[currentTierIdx].spendMin
          }
            to
            $${membershipData[currentTierIdx + 1].name}
            Tier
            `
        : ""}
    </>
  );
  return (
    <Wrapper>
      <UserWrapper>
        <TextBody style={{ color: "#6096FF" }}>My Rewards</TextBody>
        <UserName>
          <Circle />
          John
        </UserName>
      </UserWrapper>
      <ContentWrapper
        style={{
          height: !membershipHoldings?.membershipEntry ? "369px" : "417px",
        }}
      >
        <img
          src={ImageUserInfo}
          style={{
            height: !membershipHoldings?.membershipEntry ? "57%" : "62%",
          }}
        ></img>
        <Content
          style={{
            padding: !membershipHoldings?.membershipEntry
              ? "63px 64px 88px 64px"
              : "72px 40px 32px 72px",
          }}
        >
          <ImageWrapper>
            <img
              src={
                membershipHoldings?.membershipEntry
                  ? membershipHoldings?.membershipEntry.image
                  : ImageUserProfileMock
              }
            />
          </ImageWrapper>
          <RightSide
            style={{
              justifyContent: !membershipHoldings?.membershipEntry
                ? "center"
                : "",
              gap: !membershipHoldings?.membershipEntry ? "72px" : "62px",
            }}
          >
            <InfoWrapper>
              {membershipHoldings?.membershipEntry ? (
                <>
                  <TextHeader2>
                    {brand.name ? brand.name : "Girls Crew"}
                  </TextHeader2>
                  <TextHeader>
                    {membershipHoldings.membershipEntry.name}
                  </TextHeader>
                  <TextBody>Member Since {yearMonth}</TextBody>
                  <TextBody>{renderAmountToNextTier()}</TextBody>
                </>
              ) : (
                <TextHeader>No Membership</TextHeader>
              )}
            </InfoWrapper>
            {!membershipHoldings?.membershipEntry && (
              <RedeemButton>Redeem Membership</RedeemButton>
            )}
            {membershipHoldings?.membershipEntry && (
              <BarWrapper>{renderBar({ progress })}</BarWrapper>
            )}
          </RightSide>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

const UserWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

const RedeemButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyS,
  width: "fit-content",
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
  borderRadius: 6,
  padding: "12px 20px",
  textTransform: "none",
  marginTop: 30,
}));

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
`;

const Content = styled.div`
  position: absolute;
  display: flex;
  height: max-content;
  width: 100%;
  gap: 48px;
`;

const ImageWrapper = styled.div`
  height: fit-content;
  border-radius: 8px;
  padding: 8px;
  background-color: ${(props) => props.theme.palette.darkerGrey};
  img {
    width: 250px;
    height: 250px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
`;

const BarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  position: relative;
`;

const Bar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 33px;
  width: 100%;
  padding: 8px;
  border-radius: 100px;
  background-color: ${(props) => props.theme.palette.darkerGrey};
`;

const BarProgress = styled.div`
  display: flex;

  height: 17px;
  border-radius: 50px;
  width: ${(props) => props.progress + "%"};
  background-color: ${(props) => props.theme.palette.lightBlue};
`;

const BarPoint = styled.div`
  border-radius: 100%;
  width: 9px;
  height: 9px;
  background-color: ${(props) => props.theme.palette.campaignBlue};
  margin-bottom: 12px;
`;

const Labels = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
`;

const LabelName = styled.div`
  color: ${(props) => props.theme.palette.lightBlue};
  ${(props) => props.theme.typography.bodyS};
`;
const LabelThreshold = styled.div`
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.bodyS};
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UserName = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  border-radius: 100px;
  padding: 12px 20px;
  width: fit-content;
  align-self: flex-end;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
  background-color: ${(props) => props.theme.palette.darkestGrey};
`;

const Circle = styled.div`
  border-radius: 100%;
  width: 12px;
  height: 12px;
  background-color: ${(props) => props.theme.palette.campaignBlue};
`;

const TextHeader = styled.span`
  ${(props) => props.theme.typography.header};
`;

const TextHeader2 = styled.span`
  ${(props) => props.theme.typography.header2};
`;

const TextBody = styled.span`
  height: 28px;
  ${(props) => props.theme.typography.bodyM};
`;

export default UserInfo;
