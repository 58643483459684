/**
 *
 * VerifyEmailSection
 *
 */

import React, { memo, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import { func } from "prop-types";
import BackButton from "../BackButton/Loadable";
import axios from "axios";

function VerifyEmailSection({
  onBackClick,
  onCreateBrand,
  error,
  onResend = () => {},
}) {
  const [code, setCode] = useState("      ");
  const [index, setIndex] = useState(0);

  const maxLengthCheck = (object) => {
    if (object.target.value && object.target.value.length > 1) {
      // eslint-disable-next-line no-param-reassign
      object.target.value = object.target.value.slice(0, 1);
    }
  };

  const handleKeyPress = async (event) => {
    // Handle the delete/backspace key
    if (event.keyCode === 8 || event.keyCode === 46) {
      setCode(code.substring(0, code.length - 1));
      setIndex(index > 1 ? index - 1 : 0);
      return;
    }
    // code for copy paste but not complete
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "v") {
      const copiedCode = await navigator.clipboard.readText();
      setCode(copiedCode);
      copiedCode
        .split("")
        .map((char, index) => (document.getElementById(index).value = char));
      setIndex(5);
      event.target.blur();
      onCreateBrand(copiedCode);
    }
  };

  const onInputChange = (evt, idx) => {
    if (evt.target.value && evt.target.value.length > 0) {
      let newCode = code;
      let splits = code.split("");
      splits[idx] = evt.target.value;
      newCode = splits.join("");
      setCode(newCode);
      setIndex(idx + 1);
      if (idx === 5) {
        evt.target.blur();
        // enter action
        onCreateBrand(newCode);
      }
    }
  };

  return (
    <Wrapper onKeyDown={handleKeyPress}>
      <TitleWrapper>
        <Title>Verify your account</Title>
        <Subtitle>We have sent the 6-digit code to your email.</Subtitle>
      </TitleWrapper>
      <Fields>
        <FormField
          sx={formFieldOverrides}
          type="number"
          id={0}
          onInput={maxLengthCheck}
          inputProps={{
            "aria-label": "code",
          }}
          inputRef={(input) => input && index === 0 && input.focus()}
          onChange={(e) => onInputChange(e, 0)}
        />
        <FormField
          sx={formFieldOverrides}
          type="number"
          id={1}
          onInput={maxLengthCheck}
          inputProps={{
            "aria-label": "code",
          }}
          inputRef={(input) => input && index === 1 && input.focus()}
          onChange={(e) => onInputChange(e, 1)}
        />
        <FormField
          sx={formFieldOverrides}
          type="number"
          id={2}
          onInput={maxLengthCheck}
          inputProps={{
            "aria-label": "code",
          }}
          inputRef={(input) => input && index === 2 && input.focus()}
          onChange={(e) => onInputChange(e, 2)}
        />
        <FormField
          sx={formFieldOverrides}
          type="number"
          id={3}
          onInput={maxLengthCheck}
          inputProps={{
            "aria-label": "code",
          }}
          inputRef={(input) => input && index === 3 && input.focus()}
          onChange={(e) => onInputChange(e, 3)}
        />
        <FormField
          sx={formFieldOverrides}
          type="number"
          id={4}
          onInput={maxLengthCheck}
          inputProps={{
            "aria-label": "code",
          }}
          inputRef={(input) => input && index === 4 && input.focus()}
          onChange={(e) => onInputChange(e, 4)}
        />
        <FormField
          sx={formFieldOverrides}
          type="number"
          id={5}
          onInput={maxLengthCheck}
          inputProps={{
            "aria-label": "code",
          }}
          inputRef={(input) => input && index === 5 && input.focus()}
          onChange={(e) => onInputChange(e, 5)}
        />
      </Fields>
      {error && <Error>{error}</Error>}
      <ButtonsField>
        <EnterButton variant="contained" onClick={() => onCreateBrand(code)}>
          Verify
        </EnterButton>
        <ResendButton onClick={onResend}>Resend Email</ResendButton>
      </ButtonsField>
    </Wrapper>
  );
}

const ButtonsField = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const Error = styled.div`
  color: red;
  font-size: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: ${(props) => props.theme.palette.white};
`;

const Subtitle = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const Fields = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 12px;
`;

const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.header1,
  color: theme.palette.white,
  display: "flex",
  borderRadius: 6,
  height: 65,
  width: 53,
  padding: "20px",
}));

const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#F4F3EE",
  padding: "13px 20px",

  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

const ResendButton = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightBlue};
  cursor: pointer;
  text-decoration: underline;
`;

const EnterButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 0",
  width: "179px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

VerifyEmailSection.propTypes = {
  onBackClick: func,
  onCreateBrand: func,
};

export default memo(VerifyEmailSection);
