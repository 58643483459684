import React, { memo } from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import makeSelectUserPortal from "containers/UserPortal/selector";
import reducer from "containers/UserPortal/reducer";
import saga from "containers/UserPortal/saga";
import { useInjectSaga } from "utils/injectSaga";
import { useInjectReducer } from "utils/injectReducer";
import { styled as styledMui } from "@mui/material/styles";
import { ButtonBase } from "@mui/material";
import { IconCheckCircle } from "images/icons/svg-components";
import { formattedDate } from "utils/date";
import { redeemReward } from "containers/UserPortal/actions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { checkMediaType } from "utils/checkMediaType";
import Tooltip from '@mui/material/Tooltip';

const RewardCard = ({
  name,
  startDate,
  endDate,
  startTime,
  endTime,
  timezone,
  description,
  image,
  redemptionCount,
  isDraft,
  setPage,
  category,
  transactionStatus,
  setSelectedReward,
  id,
  membershipId,
  membershipTier = [],
  isLocked = false,
  isUser,
  hoverable = true,
  showChangeImage = false,
  handleChange,
  defaultMemberships = [],
  dispatch,
  userPortalState,
  verifyEmail,
  isRedeemable,
  isRedeemed,
}) => {
  const today = new Date();
  const _startDate = new Date(startDate);
  const timeDiffStartDate = _startDate.getTime() - today.getTime();
  const daysLeftToStart = Math.ceil(timeDiffStartDate / (1000 * 60 * 60 * 24));
  function renderDates() {
    // console.log("within rewardcard", isRedeemed);
    if (isUser)
      if (isRedeemable && isRedeemed) return <></>;
      else {
        const today = new Date();
        const _endDate = new Date(endDate);
        const timeDiff = _endDate.getTime() - today.getTime();
        const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

        return (
          <DateContent style={{ display: "flex", gap: "20px" }}>
            {endDate && (
              <span style={{ color: "#7F86AD" }}>
                Ends on{" "}
                <span style={{ color: "white" }}>{formattedDate(endDate)}</span>
              </span>
            )}
            {_endDate > today && (
              <span style={{ color: "#7F86AD" }}>
                Expires in{" "}
                <span style={{ color: "white" }}>{daysLeft} days</span>
              </span>
            )}
          </DateContent>
        );
      }
    else if (startDate && endDate)
      return (
        <>
          {formattedDate(startDate)} - {formattedDate(endDate)}
        </>
      );
    else return <></>;
  }
  let uploadType = checkMediaType(image);
  return (
    <Wrapper>
      <ContentWrapper>
        {uploadType == "image" ? (
          <img
            style={{ 
              borderRadius: "8px",
              objectFit: "contain", 
              objectPosition: "center"
            }}
            src={image}
            width="140px"
            height="93px"
          ></img>
        ) : (
          <video
            src={image}
            autoPlay
            style={{ borderRadius: "8px" }}
            width="140px"
            height="93px"
          />
        )}
        <Content>
          <MainContent>
            <CategoryText>
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </CategoryText>
            <RewardName>{name === "" ? "Reward Title" : name}</RewardName>
          </MainContent>
          <DateWrapper>{renderDates()}</DateWrapper>
        </Content>
      </ContentWrapper>

      {
        JSON.stringify(userPortalState.profile) !== "{}" ? ( 
          // user is logged in
          isRedeemable ? (
            // user is part of required membership (true if no membership)
            !isRedeemed ? (
              //user has not redeemed reward
              <RedeemButton
                onClick={() => {
                 {daysLeftToStart<1 && isUser
                    ? setSelectedReward({
                        name,
                        startDate,
                        endDate,
                        image,
                        category,
                        id,
                        description,
                      })
                    : null;}
                }}
              >
                Redeem
              </RedeemButton>
            ) : (
              // user has redeemed reward
              <RedeemButton
                onClick={() => {
                  isUser
                    ? setSelectedReward({
                        name,
                        startDate,
                        endDate,
                        image,
                        category,
                        id,
                        description,
                      })
                    : null;
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#0BD680",
                    gap: "6px",
                  }}
                >
                  <CheckCircleIcon
                    style={{ height: "16px", width: "16px" }}
                  ></CheckCircleIcon>
                  Redeemed
                </span>
              </RedeemButton>
            )
          ) : (
            // user is not part of required membership
            <Tooltip 
            title={daysLeftToStart>0?`Will be available to redeem on ` + formattedDate(startDate):""} 
            placement="top-start">
            
            

            <RedeemButton
              onClick={() => {
                daysLeftToStart<1 && isUser
                  ? setSelectedReward({
                      name,
                      startDate,
                      endDate,
                      image,
                      category,
                      id,
                      description,
                    })
                  : null;
              }}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                <LockOutlinedIcon
                  fontSize="small"
                  style={{ marginRight: "8px" }}
                ></LockOutlinedIcon>
                Locked
              </span>
            </RedeemButton>
            </Tooltip>
          )
        ) : (
          // user is not logged in
          <RedeemButton
            onClick={() => {
              isUser
                ? setSelectedReward({
                    name,
                    startDate,
                    endDate,
                    image,
                    category,
                    id,
                    description,
                  })
                : null;
            }}
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              <LockOutlinedIcon
                fontSize="small"
                style={{ marginRight: "8px" }}
              ></LockOutlinedIcon>
              Locked
            </span>
          </RedeemButton>
        )
      }

    </Wrapper>
  );
};

const RedeemButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.lightBlue,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  padding: "0 20px",
  width: "fit-content",
  height: "fit-content",
  borderRadius: "6px",
  height: "43px",
  lineHeight: "normal",

  "&:hover": {
    backgroundColor: theme.palette.white,
  },
}));

const GreenText = styledMui("span")(({ theme }) => ({
  color: theme.palette.green,
}));

const RewardName = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.bodyMBold}
  line-height: normal;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 12px;
  justify-content: space-between;
  padding: 20px;
  background: ${(props) => props.theme.palette.darkestGrey};

  &:hover {
    background: #252530;
  }
`;

const CategoryText = styled.div`
  ${(props) => props.theme.typography.bodySMedium};
  color: ${(props) => props.theme.palette.lightBlue};
  line-height: normal;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;
const DateWrapper = styled.div`
  display: flex;
`;
const DateContent = styled.div`
  ${(props) => props.theme.typography.bodyS};
`;
const ContentWrapper = styled.div`
  display: flex;
  gap: 28px;
`;

const mapStateToProps = createStructuredSelector({
  userPortalState: makeSelectUserPortal(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(RewardCard);
