/**
 *
 * PreviewLandingPage
 *
 */

import React, {memo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import EditLandingPageDisplay from "../EditLandingPageDisplay";
import CodeIcon from "@mui/icons-material/Code";
import EmbedModal from "../EmbedModal";

function PreviewLandingPage({ membershipData, formValues, isLoading }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleEmbedClick = () => {
    setOpen(true);
  };
  const handleEditClick = () => {
    navigate(`/landingpage-edit?brandId=${localStorage.getItem("brandId")}`);
  };
  const handlePreviewClick = () => {
    navigate(`/landingpage?brandId=${localStorage.getItem("brandId")}`);
  };

  return (
    <Wrapper>
      <EmbedModal
        open={open}
        setOpen={(bool) => setOpen(bool)}
        brandId={localStorage.getItem("brandId")}
      />
      <HeaderButtons>
        <Title>Landing Page Builder</Title>
        <PreviewButton onClick={handlePreviewClick}>
          Preview
          <ButtonIcon>
            <VisibilityIcon />
          </ButtonIcon>
        </PreviewButton>
        <EmbedButton onClick={handleEmbedClick}>
          Embed iFrame
          <ButtonIcon>
            <CodeIcon />
          </ButtonIcon>
        </EmbedButton>
        <EditButton onClick={handleEditClick}>Edit Design</EditButton>
      </HeaderButtons>
      <DisplayWrapper>
        <InnerWrapper>
          <EditLandingPageDisplay
            membershipData={membershipData}
            formValues={formValues}
          />
        </InnerWrapper>
      </DisplayWrapper>
    </Wrapper>
  );
}
const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const Title = styled.div`
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
`;

const DisplayWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  overflow: auto;
  max-height: calc(100vh - 204px);
  height: calc(100vh - 204px);
  flex-direction: column;
  border-radius: 20px 20px 0px 0px;
  background: #1a1a22;
  ::-webkit-scrollbar {
    width: 12px;
    background: transparent;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    /* border: 1px solid ${(props) => props.theme.palette.itemsHover}; */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    cursor: pointer;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const InnerWrapper = styled.div`
  height: calc(100% - 0px);
  padding: 40px 0;
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
`;

const BaseButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyM,
  width: 157,
  height: 44,
  borderRadius: 10,
  textTransform: "none",

  "&:hover": {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
}));

const EditButton = styledMui(BaseButton)(({ theme }) => ({
  background: "#284AFE",
  borderRadius: "6px",
  color: theme.palette.white,
  paddingLeft: "20px",
  paddingRight: "20px",
  width: "fit-content",
  borderRadius: "6px",
  height: "52px",
  fontSize: "18px",
}));

const EmbedButton = styledMui(BaseButton)(({ theme }) => ({
  background: "transparent",
  border: "1px solid #352B42",
  color: theme.palette.lightBlue,
  paddingLeft: "20px",
  paddingRight: "20px",
  width: "fit-content",
  marginLeft: "20px",
  marginRight: "20px",
  borderRadius: "6px",
  height: "52px",
  fontSize: "18px",
}));
const PreviewButton = styledMui(BaseButton)(({ theme }) => ({
  border: "1px solid #352B42",
  color: theme.palette.lightBlue,
  marginLeft: "auto",
  paddingLeft: "12px",
  paddingRight: "12px",
  paddingLeft: "20px",
  paddingRight: "20px",
  width: "fit-content",
  background: "transparent",
  borderRadius: "6px",
  height: "52px",
  fontSize: "18px",
}));
const ButtonIcon = styled.span`
  margin-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
  height: 24px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  flex: 1;
`;

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #352b42;
`;

PreviewLandingPage.propTypes = {
  setPage: PropTypes.func,
  formValues: PropTypes.object,
  membershipData: PropTypes.array,
};

export default memo(PreviewLandingPage);
