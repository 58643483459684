/*
 *
 * UserPortal actions
 *
 */

import {
  DEFAULT_ACTION,
  CREATE_USER,
  VERIFY_EMAIL,
  REDEEM_REWARD,
  MINT_NFT_TO_USER,
  FETCH_USER_MEMBERSHIP_HOLDINGS,
  FETCH_USER_REWARD_HOLDINGS,
  INIT_DATA,
  FETCH_USER_PROFILE,
  TOGGLE_SNACKBAR,
} from "./constants";

import API from "utils/userAPI";
export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function createUser(data) {
  return async (dispatch) => {
    API.post("auth/user/register", data)
      .then((result) => {
        dispatch({ type: CREATE_USER, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
}

export function verifyEmail(token) {
  return async (dispatch) => {
    API.get(`auth/verify/${token}`)
      .then((result) => {
        dispatch({ type: VERIFY_EMAIL, payload: result.data });
        localStorage.setItem("userTokens", JSON.stringify(result.data?.data));
      })
      .catch((err) => console.log(err));
  };
}

export function mintNftToUser(email, membershipId) {
  return async (dispatch) => {
    API.post("users/mint", { email, membershipId })
      .then((result) => {
        dispatch({ type: MINT_NFT_TO_USER, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
}

export function fetchUserRewardHoldings() {
  return async (dispatch) => {
    API.get("users/holdings/rewards")
      .then((result) => {
        dispatch({ type: FETCH_USER_REWARD_HOLDINGS, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
}

export function fetchUserMembershipHoldings(brandId, toggle = false) {
  return async (dispatch) => {
    API.get("users/holdings/memberships")
      .then(async (result) => {
        // dispatch({ type: FETCH_USER_MEMBERSHIP_HOLDINGS, payload: result.data });
        let res = await API.get("users/holdings/rewards");
        let res2 = await API.get(`/landingPage/${brandId}`);
        let res3 = await API.get("users/holdings/rewards");
        console.log(result.data.data, "membershipholdings");
        dispatch({
          type: INIT_DATA,
          payload: {
            membershipHoldings: result.data.data, // gets latest one
            // membershipHoldings: result.data.data[result.data.data.length - 1], // gets latest one
            // membershipHoldingsAll: result.data.data, // gets latest one
            rewardData: res.data.data.map((a) => a.rewardsEntry),
            rewardHoldings: res3.data.data,
            membershipData: res2.data.data.memberships.filter(
              (a) => a.transactionStatus === "SUCCESS" && a.isDefault === false
            ),
            brand: res2.data.data.brand,
          },
        });
        if (toggle) {
          dispatch(toggleSnackbar());
        }
      })
      .catch((err) => {
        API.get(`/landingPage/${brandId}`).then((result) => {
          dispatch({
            type: INIT_DATA,
            payload: {
              membershipData: result.data.data.memberships.filter(
                (a) => a.transactionStatus === "SUCCESS"
              ),
              rewardData: result.data.data.rewards,
            },
          });
        });
      });
  };
}

// Checks if a device has streamed a song
export const checkDeviceStreamedSong = (
  deviceId,
  trackId,
  userId = undefined
) => {
  return async (dispatch) => {
    return API.get("/users/check_signup_song", {
      params: { deviceId, trackId, userId },
    })
      .then((result) => {
        dispatch({ type: DEFAULT_ACTION, payload: result.data });
        return result;
      })
      .catch((e) => {
        // user has not streamed song
        console.log("Device has not streamed song");
      });
  };
};

// inputs userId has listened to track
export const inputUserIdStreamedSong = (userId, trackId) => {
  API.post(`/users/save_signup_song`, { userId, trackId })
    .then((result) => {
      dispatch({ type: DEFAULT_ACTION, payload: result.data });
    })
    .catch((err) => console.log(err));
};

export const redeemReward = (rewardId, userId) => {
  // console.log("to the api call runs");
  return async (dispatch) => {
    API.post("users/redeem", { rewardId, userId })
      .then((result) => {
        dispatch({ type: REDEEM_REWARD, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

export const fetchMe = (userId) => {
  return async (dispatch) => {
    API.get("users/me", { userId })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };
};

export const fetchUserProfile = (membershipId = undefined) => {
  return async (dispatch) => {
    if (membershipId) {
      API.get(`users/profile?membershipId=${membershipId}`)
        .then((result) => {
          dispatch({ type: FETCH_USER_PROFILE, payload: result.data.data });
        })
        .catch((err) => console.log(err));
      return;
    }
    API.get(`users/profile`)
      .then((result) => {
        dispatch({ type: FETCH_USER_PROFILE, payload: result.data.data });
      })
      .catch((err) => console.log(err));
  };
};

export const toggleSnackbar = () => ({
  type: TOGGLE_SNACKBAR,
});
