import { FETCH_BRAND } from "./constants";
import API from "utils/brandAPI";

export function fetchBrand() {
  return async (dispatch) => {
    try {
      const result = await API.get(`brands/me`);
      if (result?.data) {
        dispatch({ type: FETCH_BRAND, payload: result.data });
      }
    } catch (error) {
      console.log("Me api error: ", error);
    }
  };
}
