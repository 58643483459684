/**
 *
 * QrCodeModalEdit
 *
 */

import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, CircularProgress } from "@mui/material";
import Accordion from "components/Accordion";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "utils/object";
import { uploadFile } from "utils/s3";
import QRCode from 'qrcode';
import VisibilityIcon from "@mui/icons-material/Visibility";
import queryString from "query-string";
import { QR_CODE_MODAL_DEFAULTS } from "utils/constants";
import API from "utils/brandAPI";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import SaveLandingPageModal from "components/SaveLandingPageModal";
import QrCodeModalDesignForms from "./components/QrCodeModalDesignForms";
import EditQrCodeModalDisplay from "containers/LandingPage/components/EditQrCodeModalDisplay/Loadable";
import makeSelectManageQrCodeModal from "./selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  fetchDataAction,
  saveDataAction,
  changeDataAction,
  resetChangeDataAction,
} from "./actions";
function QrCodeModalEdit({
  fetchQrCodeModalState,
  saveQrCodeModalState,
  handleQrCodeModalChange,
  resetQrCodeModalChange,
  qrCodeModalState,
}) {
  const queryParams = queryString.parse(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showExitModal, setShowExitModal] = useState(false);
  const [shouldSaveUpdates, setShouldSaveUpdates] = useState(false);
  const brandId = localStorage.getItem("brandId") || queryParams?.brandId;
  const [brandRewards, setBrandRewards] = useState([])
  const { publishedYet } = qrCodeModalState;
  let { formValues } = qrCodeModalState;

  const init = async () => {
    setIsLoading(true);

    const qrCodeModel = JSON.parse(localStorage.getItem("qrCodeModel"));
    if (qrCodeModel && qrCodeModel.brandId && brandId === qrCodeModel.brandId) {
      handleQrCodeModalChange({
        ...qrCodeModel,
      });
    } else {
      fetchQrCodeModalState(brandId);
    }


    setIsLoading(false);
    const res = await API.get(`/landingPage/${brandId}`)
    if (res.data.data.rewards.length > 0) {
      setBrandRewards(res.data.data.rewards);
    }

  };
  useEffect(() => {
    init();
    localStorage.getItem("qrCodeModel")
      ? setShouldSaveUpdates(true)
      : setShouldSaveUpdates(false);
    return () => {};
  }, []);
  
  const handleSave = async () => {
    setShouldSaveUpdates(false);
    const convertedHeaderImage =
      !formValues.headerImage.name &&
      formValues.headerImage.split("/")[0] == "data:image"
        ? await dataURLtoFile(formValues.headerImage)
        : formValues.headerImage;
    let updatedFormValues = { ...formValues };

    // upload header img to s3
    let headerImageUrl = convertedHeaderImage;
    if (headerImageUrl && headerImageUrl instanceof File) {
      headerImageUrl = await uploadFile(convertedHeaderImage);
    }
    

    updatedFormValues = {
      ...updatedFormValues,
      headerImage: headerImageUrl,
    };
    setIsLoading(true);
    await saveQrCodeModalState(publishedYet, updatedFormValues, brandId);
    localStorage.removeItem("qrCodeModel");
    setIsLoading(false);
  };

  const handleGoBack = () => {
    resetQrCodeModalChange();
    navigate(`/landingpage-preview`);
  };

  const handlePreviewClick = () => {
    navigate(`/qrcode?brandId=${brandId}`);
  };

const handlePrintClick = async () => {
  // Access the base64 image string from form values
  let qrCodeModel = JSON.parse(localStorage.getItem("qrCodeModel"));
  let imageString = qrCodeModel?.headerImage;
  let img = new Image();
  if (qrCodeModel.headerImage.split("/")[0] == "data:image") {
    let base64Image = imageString.split(";base64,").pop();
    img.src = "data:image/png;base64," + base64Image;
  } else {
    img.src = imageString;
  }
  
    // Create an image

    // When the image is loaded, print it
    img.onload = function() {
        // Scaling factor for the QR code
        let scaleFactor = 2;

        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');

        // Set the canvas dimensions to the scaled image dimensions
        canvas.width = this.naturalWidth * scaleFactor;
        canvas.height = this.naturalHeight * scaleFactor;

        // Scale and draw the image onto the canvas
        ctx.scale(scaleFactor, scaleFactor);
        ctx.drawImage(this, 0, 0);

        // Create a new iframe and document for the print job
        let printIframe = document.createElement('iframe');
        printIframe.style.display = 'none';
        document.body.appendChild(printIframe);

        // Add the canvas to the body of the iframe's document and center it
        let iframeBody = printIframe.contentWindow.document.body;
        iframeBody.style.display = 'flex';
        iframeBody.style.justifyContent = 'center';
        iframeBody.style.alignItems = 'center';
        iframeBody.style.height = '100vh';
        iframeBody.appendChild(canvas);

        // Call the print function after a timeout 
        // (this is necessary to ensure that the iframe has been fully created before we call print)
        setTimeout(function() {
            printIframe.contentWindow.print();
            // Remove the iframe after printing
            document.body.removeChild(printIframe);
        }, 250);
  };
};

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const handleChange = async (event, name, value, index = 0) => {
    setShouldSaveUpdates(true);
    if (name === "text") {
      const textArray = formValues.text;
      const newTextArray = [...textArray];
      newTextArray[index] = value;

      handleQrCodeModalChange({ ...formValues, [name]: newTextArray });
      localStorage.setItem(
        "qrCodeModel",
        JSON.stringify({
          ...formValues,
          [name]: newTextArray,
        })
      );
    } else if (name === "benefits") {
      // here generate qr code and input as image
      // value is the id of the reward.
      // create QR code that sends user to http://localhost:3000/landingpage-user?brandId={brandId}&rewardId={value}
      
      const qrUrl = `${window.location.origin}/landingpage-user?brandId=${brandId}&rewardId=${value.id}`;
      const qrCodeDataUrl = await QRCode.toDataURL(qrUrl);
      const response = await fetch(qrCodeDataUrl);
      const blob = await response.blob();
      const qrCodeFile = new File([blob], "qrCode.png", { type: "image/png" });
      console.log("QR code Url:", qrUrl)
      const newName = qrCodeFile.name.replace(/\s/g, "");
      const updatedBackgroundImage = new File([qrCodeFile], newName, {
        type: qrCodeFile.type,
      });
      let base64 = await toBase64(updatedBackgroundImage);
      handleQrCodeModalChange({
         ...formValues, 
         [name]: value.id,
         ["headerImage"]: updatedBackgroundImage
        });
      localStorage.setItem(
        "qrCodeModel",
        JSON.stringify({
          ...formValues,
          [name]: value,
          ["benefitName"]: value.name,
          ["headerImage"]: base64
        })
      );
    } else {
      handleQrCodeModalChange({ ...formValues, [name]: value });
      localStorage.setItem(
        "qrCodeModel",
        JSON.stringify({
          ...formValues,
          [name]: value,
        })
      );
    }
  };

  const handleClickBack = () => {
    if (shouldSaveUpdates) {
      setShowExitModal(true);
      localStorage.removeItem("qrCodeModel");
    } else {
      resetQrCodeModalChange();
      localStorage.removeItem("qrCodeModel");
      navigate(`/landingpage-preview`);
    }
  };

  const handleSaveExit = async () => {
    await handleSave();
    resetQrCodeModalChange();
    navigate(`/landingpage-preview`);
  };

  return (
    <Wrapper>
      <HeaderButtons>
        <BackButton onClick={handleClickBack}>
          <ChevronLeftIcon />
          <span>Back to Dashboard</span>
        </BackButton>
        <div>
          <PrintButton onClick={handlePrintClick}>
            Print
            <ButtonIcon>
              <LocalPrintshopOutlinedIcon />
            </ButtonIcon>
          </PrintButton>
          <PreviewButton onClick={handlePreviewClick}>
            Preview
            <ButtonIcon>
              <VisibilityIcon />
            </ButtonIcon>
          </PreviewButton>
          <SaveButton onClick={handleSave}>
            {isLoading ? <CircularProgress size={28} /> : "Save Design"}
          </SaveButton>
        </div>
      </HeaderButtons>

      <Content>
        <LeftContent>
          <LeftInnerWrapper>
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <EditQrCodeModalDisplay formValues={formValues} />
            )}
          </LeftInnerWrapper>
        </LeftContent>
        <RightContent>
          {/* Design */}
          <Accordion defaultExpanded title="Design" id="design-top-scroll-id">
            <QrCodeModalDesignForms
              formValues={formValues}
              handleChange={handleChange}
              rewards={brandRewards}
            />
          </Accordion>
        </RightContent>
      </Content>
      <SaveLandingPageModal
        showModal={showExitModal}
        setShowModal={setShowExitModal}
        onClose={handleGoBack}
        onSave={handleSaveExit}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  color: #6096ff;
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  &:hover {
    opacity: 0.7;
  }
`;

const BaseButton = styledMui(Button)(() => ({
  width: 221,
  height: 47,
  borderRadius: 10,
  textTransform: "none",
  fontSize: 20,
  fontFamily: "Poppins",
  "&:hover": {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
}));

const Content = styled.section`
  display: flex;
  width: 100%;
`;

const LeftContent = styled.section`
  display: flex;
  flex-direction: column;
  flex: 5;
  overflow: auto;
  max-height: calc(100vh - 93px);
  height: calc(100vh - 93px);
  background: #1a1a22;
  ::-webkit-scrollbar {
    width: 12px;
    background: transparent;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    /* border: 1px solid ${(props) => props.theme.palette.itemsHover}; */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    cursor: pointer;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
const LeftInnerWrapper = styled.div`
  height: 100%;
  padding: 40px 0;
  margin-left: auto;
  margin-right: auto;
`;
const RightContent = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow: 0px 4px 50px #0d0d14;
  overflow-y: auto;
  max-height: calc(100vh - 93px);
  height: calc(100vh - 93px);
  overflow-x: hidden;
  width: 510px;
  min-width: 510px;
  background: #1a1a22;
  padding: 20px 40px;
  ::-webkit-scrollbar {
    width: 12px;
    background: transparent;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    /* border: 1px solid ${(props) => props.theme.palette.itemsHover}; */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    cursor: pointer;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #352b42;
  height: 92px;
  padding: 0 40px;
  align-items: center;
  img {
    height: 40px;
    margin: auto 0;
  }
`;

const SaveButton = styledMui(BaseButton)(() => ({
  background: "#284AFE",
  borderRadius: "6px",
  color: "#ffffff",
  paddingLeft: "20px",
  paddingRight: "20px",
  width: "fit-content",
  borderRadius: "6px",
  height: "52px",
  fontSize: "18px",
}));

const PreviewButton = styledMui(BaseButton)(() => ({
  border: "1px solid #352B42",
  color: "#6096FF",
  paddingLeft: "12px",
  paddingRight: "12px",
  paddingLeft: "20px",
  paddingRight: "20px",
  width: "fit-content",
  background: "transparent",
  borderRadius: "6px",
  height: "52px",
  marginLeft: "auto",
  marginRight: "20px",
  fontSize: "18px",
}));

const PrintButton = styledMui(BaseButton)(() => ({
  border: "1px solid #352B42",
  color: "#6096FF",
  paddingLeft: "12px",
  paddingRight: "12px",
  paddingLeft: "20px",
  paddingRight: "20px",
  width: "fit-content",
  background: "transparent",
  borderRadius: "6px",
  height: "52px",
  marginLeft: "auto",
  marginRight: "20px",
  fontSize: "18px",
}));
const ButtonIcon = styled.span`
  margin-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
  height: 24px;
`;
QrCodeModalEdit.propTypes = {
  setPage: PropTypes.func,
  formValues: PropTypes.object,
  handleChange: PropTypes.func,
  addQuestion: PropTypes.func,
  membershipData: PropTypes.array,
  qrCodeModalState: PropTypes.object,
  fetchData: PropTypes.func,
  saveData: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  qrCodeModalState: makeSelectManageQrCodeModal(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchQrCodeModalState: (brandId) => dispatch(fetchDataAction(brandId)),
    saveQrCodeModalState: (publishedYet, formValues, brandId) =>
      dispatch(saveDataAction(publishedYet, formValues, brandId)),
    handleQrCodeModalChange: (formValues) =>
      dispatch(changeDataAction(formValues)),
    resetQrCodeModalChange: () => dispatch(resetChangeDataAction()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(QrCodeModalEdit);
