import { Table } from "@mui/material";
import React, { Component, PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      // <TooltipContainer style={{ border: "none" }}>
      <div
        style={{
          backgroundColor: "#29364b",
          color: "white",
          padding: "10px",
          borderRadius: "15px",
          maxWidth: "195px",
          paddingRight: "25px",
        }}
      >
        <P size={"14px"} margin={"2px"} color="#8e98ac">
          {payload[0].payload?._id}
        </P>
        <P size={"30px"} margin={"2px"} color="#ffffff">
          {payload[0].payload?.count} mints
        </P>
        {/* <P size={"14px"} margin={"2px"} color="#8e98ac">
          {payload[0].payload?._id}
        </P> */}
      </div>
      // </TooltipContainer>
    );
  }

  return null;
};
const TooltipContainer = styled.div`
  border: 1px solid #fff;
  background: #36353d;
  border-radius: 16px;
  padding: 12px 16px;
  .date {
    font-size: 10px;
    display: flex;
  }
  .label {
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .value {
    font-size: 22px;
  }
  .dot {
    background: #8a73e7;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin: auto 8px auto 0;
  }
`;

export default class Chart extends Component {
  render() {
    let { graphChart } = this.props;
    return (
      <div style={{ height: "calc(100vh - 500px)",marginTop:"25px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={"100%"}
            data={graphChart}
            style={{
              backgroundColor: "#1b212f",
              maxWidth: "270px",
              height: "320px",
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="35%" stopColor="#1f294b" stopOpacity={1} />
                <stop offset="95%" stopColor="#1f2635" stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} horizontal={false} />
            <XAxis dataKey="_id" axisLine={false} tickLine={false} />
            <YAxis
              dataKey="count"
              axisLine={false}
              tickLine={false}
              tickMargin={20}
              type="number"
              domain={graphChart.length>0?[]:[0, 5000]}
            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{
                stroke: "#294BFC",
                strokeWidth: 2,
                strokeDasharray: 12,
              }}
            />
            <Area
              type="monotone"
              dataKey="count"
              stroke="#294BFC"
              fill="url(#colorUv)"
              width={"100%"}
              dot={
                <circle
                  r="9px"
                  fill="#294BFC"
                  strokeWidth={"21px"}
                  strokeOpacity={"0.2"}
                />
              }
              activeDot={
                <circle
                  r="9px"
                  fill="#294BFC"
                  stroke="#294BFC"
                  strokeWidth={"21px"}
                  strokeOpacity={"0.2"}
                />
              }
              line
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
const P = styled.p`
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color};
  margin: ${(props) => props.margin};
`;
