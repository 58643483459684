import React, { useState } from "react";
import styled from "styled-components";
import { Button, FormControl, InputBase, NativeSelect } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import { mainTheme } from "theme/mainTheme";
import { format, parse } from "date-fns";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const CampaignActions = ({
  setFormValues,
  formValues,
  actionInputFields,
  setActionInputFields,
}) => {
  const { songs } = useSelector((state) => state.campaignsPage);
  const filters = [
    { label: "Number of Streams", name: "SpotifyStreamAction" },
    { label: "Number of Minutes", name: "SpotifyMinutesAction" },
  ];

  const handleChange = (event, rowId, name) => {
    let _formValues = { ...formValues };

    if (name === "song") {
      const value = JSON.parse(event.target.value);
      let _actions = [...actionInputFields];

      const index = _actions.findIndex((item) => item.rowId === rowId);

      const newItem = {
        rowId: rowId,
        trackId: value.id,
        trackName: value.name,
      };

      if (index !== -1) {
        _actions[index] = { ..._actions[index], ...newItem };
      } else {
        _actions.push(newItem);
      }
      setActionInputFields(_actions);
      const newFormValues = { ..._formValues, actions: _actions };
      setFormValues(newFormValues)

      return;
    } else if (name === "action") {
      let _actions = [...actionInputFields];

      const index = _actions.findIndex((item) => item.rowId === rowId);

      const newItem = {
        rowId: rowId,
        name: event.target.value,
      };

      if (index !== -1) {
        _actions[index] = { ..._actions[index], ...newItem };
      } else {
        _actions.push(newItem);
      }
      // _formValues.actions = _actions;
      setActionInputFields(_actions);
      const newFormValues = { ..._formValues, actions: _actions };
      setFormValues(newFormValues)

      return;
    } else if (name === "metric") {
      const value = event.target.value;
      let _actions = [...actionInputFields];

      const index = _actions.findIndex((item) => item.rowId === rowId);

      const newItem = {
        rowId: rowId,
        minutes:
          _actions[index].name === "SpotifyMinutesAction"
            ? value === ""
              ? ""
              : parseInt(value)
            : "",
        count:
          _actions[index].name === "SpotifyStreamAction"
            ? value === ""
              ? ""
              : parseInt(value)
            : "",
      };

      if (index !== -1) {
        _actions[index] = { ..._actions[index], ...newItem };
      } else {
        _actions.push(newItem);
      }

      setActionInputFields(_actions);
      const newFormValues = { ..._formValues, actions: _actions };
      setFormValues(newFormValues)

      return;
    }
  };

  const handleAddFields = () => {
    setActionInputFields((prev) => [
      ...prev,
      { rowId: prev.length === 0 ? 0 : prev[prev.length - 1].rowId + 1 },
    ]);
    const newFormValues = { ..._formValues, actions: _actions };
    setFormValues(newFormValues)
  };

  const handleRemoveFields = (idToRemove) => {
    let _formValues = { ...formValues };
    setActionInputFields((prev) => prev.filter((item) => item.rowId !== idToRemove));

    let _actions = _formValues.actions;
    _actions = _actions.filter((item) => item.rowId !== idToRemove);
    _formValues.actions = _actions;
    setFormValues(_formValues);
  };

  const renderFields = () => {
    return actionInputFields.map((item, idx) => {
      return (
        <Fields key={idx}>
          <Field>
            <FormControl>
              <NativeSelect
                value={
                  item?.trackId
                    ? JSON.stringify({
                        id: item?.trackId,
                        name: item?.trackName,
                      })
                    : "none"
                }
                onChange={(event) => {
                  handleChange(event, item.rowId, "song");
                }}
                id={idx}
                defaultValue="none"
                IconComponent={() => <KeyboardArrowDownOutlinedIcon />}
                input={
                  <FormField
                    sx={{
                      ...formFieldOverrides,
                      width: "100%",
                    }}
                  />
                }
              >
                <option value="none" disabled>
                  Select a Song
                </option>
                {songs.map((song, idx) => (
                  <option value={JSON.stringify(song)} key={idx}>
                    {song.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Field>
          <Field>
            <FormControl>
              <NativeSelect
                value={item?.name}
                defaultValue="none"
                onChange={(event) => {
                  handleChange(event, item.rowId, "action");
                }}
                IconComponent={() => <KeyboardArrowDownOutlinedIcon />}
                input={
                  <FormField
                    sx={{
                      ...formFieldOverrides,
                      width: "100%",
                    }}
                  />
                }
              >
                <option value="none" disabled>
                  Select an Action
                </option>
                {filters.map((filter, idx) => (
                  <option value={filter.name} key={idx}>
                    {filter.label}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Field>
          <Field>
            <FormField
              sx={formFieldOverrides}
              placeholder="Metric"
              inputProps={{
                "aria-label": "name",
              }}
              disabled={item?.name ? false : true}
              style={{
                marginRight: "53px",
                borderColor: item?.name ? "" : "grey",
              }}
              value={
                item.name === "SpotifyStreamAction" ? item.count : item.minutes
              }
              onChange={(event) => {
                handleChange(event, item.rowId, "metric");
              }}
            />
          </Field>
          <CloseIcon
            onClick={() => handleRemoveFields(item.rowId)}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              color: `${mainTheme.palette.lightBlue}`,
              height: "100%",
              width: "28px",
            }}
          />
        </Fields>
      );
    });
  };

  return (
    <Wrapper>
      <TabWrapper>
        <TabButton>Spotify</TabButton>
      </TabWrapper>
      <MenuWrapper>
        <ContentWrapper>
          <MenuHeader>
            <div className="title">Song</div>
            <div className="title">Action</div>
            <div className="title">Metrics</div>
          </MenuHeader>
          {renderFields()}
        </ContentWrapper>
        <NewRequirementButton onClick={handleAddFields}>
          Add New Requirement
          <AddIcon
            style={{
              height: "24px",
              width: "24px",
            }}
          />
        </NewRequirementButton>
      </MenuWrapper>
    </Wrapper>
  );
};

const FormField = styledMui(InputBase)({
  display: "flex",
});

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Fields = styled.div`
  display: flex;
  position: relative;
  gap: 16px;
  width: 100%;
  align-items: center;
`;

const NewRequirementButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyM,
  padding: "20px 0 ",
  width: "100%",
  borderRadius: "0 0 8px 8px",
  gap: "12px",
  textTransform: "none",
  backgroundColor: theme.palette.darkestGrey,
  color: theme.palette.lightBlue,
  "&:hover": {
    backgroundColor: theme.palette.lightBlue,
    color: theme.palette.darkestGrey,
  },
  "&:disabled": {
    backgroundColor: "rgba(217, 217, 217, 0.5)",
  },
}));

const TabButton = styled.div`
  display: flex;
  padding: 30px 10px;

  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.white};
  border-bottom: 5px solid;
  border-color: ${(props) => props.theme.palette.lightBlue};
`;

const TabWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MenuHeader = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.white};

  .title {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 16px;
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};
  border-radius: 8px 8px 0 0;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const formFieldOverrides = {
  ...mainTheme.typography.bodyM,
  border: "1px solid #352B42",
  borderRadius: "6px",
  color: "#F4F3EE",
  padding: "12px 12px 12px 20px",

  svg: {
    color: "#F4F3EE",
  },

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 0.7,
    },
  },

  "& .MuiNativeSelect-select": {
    padding: 0,
    "&.Mui-disabled": {
      "-webkit-text-fill-color": "#7F86AD",
    },
  },

  "input::-webkit-datetime-edit-fields-wrapper": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#F4F3EE",
  },

  "input[type=date]::-webkit-calendar-picker-indicator": {
    filter:
      "invert(98%) sepia(54%) saturate(245%) hue-rotate(347deg) brightness(110%) contrast(91%)", //#F4F3EE
  },

  "input[type=time]::-webkit-calendar-picker-indicator": {
    filter:
      "invert(98%) sepia(54%) saturate(245%) hue-rotate(347deg) brightness(110%) contrast(91%)", //#F4F3EE
  },

  ".Mui-disabled": {
    "-webkit-text-fill-color": "grey",
    color: "grey",
    svg: {
      color: "grey",
    },
  },
};

export default CampaignActions;
