import React, { memo, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { ImageUserInfo, ImageUserProfileMock } from "images";
import { numberWithCommas } from "utils/dollar";
import { Button } from "@mui/material";
import { monthNames } from "utils/date";

const InfoHeader = ({
  membershipHoldings,
  membershipData = [],
  brand = {},
  rewardData = [],
}) => {
  const date = membershipHoldings?.createdAt;
  let yearMonth = "May 2023";
  if (date) {
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const monthName = monthNames[Number(month) - 1];
    yearMonth = `${monthName} ${year}`;
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <InfoWrapper>
          <TextHeader>{brand.name ? brand.name : "Girls Crew"}</TextHeader>
          {membershipHoldings?.membershipEntry ? (
            <>
              {/* <TextHeader>{membershipHoldings.membershipEntry.name}</TextHeader> */}
              <Chips>
                <InfoChip>Member Since {yearMonth}</InfoChip>
                <InfoChip>{rewardData.length} Rewards</InfoChip>
                <ActiveChip>
                  <Circle />
                  Active
                </ActiveChip>
              </Chips>
            </>
          ) : (
            <TextHeader>No Membership</TextHeader>
          )}
        </InfoWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};
const ActiveChip = styled.div`
  display: flex;
  gap: 16px;
  background-color: ${(props) => props.theme.palette.lightBlue};
  border-radius: 100px;
  ${(props) => props.theme.typography.bodyS};
  align-items: center;
  padding: 4px 20px;
  gap: 10px;
`;
const Chips = styled.div`
  display: flex;
  gap: 16px;
`;

const InfoChip = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 20px;
  background-color: ${(props) => props.theme.palette.darkerGrey};
  border-radius: 100px;
  ${(props) => props.theme.typography.bodyS};
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const Circle = styled.div`
  border-radius: 100%;
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.theme.palette.white};
`;

const TextHeader = styled.span`
  ${(props) => props.theme.typography.header};
`;

export default InfoHeader;
