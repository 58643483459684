import React, { memo, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import PropTypes from "prop-types";
import makeSelectDiscordPage from "./selectors";
import { useInjectReducer } from "utils/injectReducer";
import reducer from "./reducer";
import API from "utils/brandAPI";
import styled from "styled-components";
import Layout from "components/Layout";
import { styled as styledMui } from "@mui/material/styles";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  Input,
  MenuItem,
  Select,
  ListItemText,
  InputAdornment,
  InputBase,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { IconFullScreen, IconUpload } from "images/icons/svg-components";
import { Table, TableHead, TableBody, Checkbox } from "@mui/material";
import { formatSreams } from "containers/Analytics/ArtistDashboard/utils";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import AccountDetail from "components/AccountDetails";
import { fetchDiscordServers } from "./actions";
import { mainTheme } from "theme/mainTheme";
import { CatchingPokemonSharp } from "@mui/icons-material";

const Discord = ({ discordPageState, fetchDiscordServers }) => {
  useInjectReducer({ key: "discordPage", reducer });
  const [showModal, setshowModal] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState({});
  const [memberData, setMemberData] = useState([])
  const brandId = localStorage.getItem("brandId") || queryParams?.brandId;
  const sortOptions = ["Total Redeemed"];
  const filterOptions = ["Most Popular", "Upload Date", "Price"];

  const [sortType, setsortType] = useState(sortOptions[0]);
  const [filterType, setFilterType] = useState(filterOptions[0]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };
  

  const [discordServers, setDiscordServers] = useState([]);
  const [selectedDiscordServers, setSelectedDiscordServers] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState({});
  

  const handleChange = (event) => {
    const selectedServerNames = event.target.value;
    const selectedServers = discordServers.filter(server => selectedServerNames.includes(server.name));
    setSelectedDiscordServers(selectedServers);
  };
  

  const [discordsForBrandID, setdiscordsForBrandID] = useState([]);
  const [ShopifyForDiscord, setShopifyForDiscord] =
    useState(null);

  const handleFilter = () => {};
  
  

  useEffect(() => {
    // Fetch discord on screen load only
    const init = async () => {
      const servers = await API.get(`/discord/servers/${brandId}`);
      const members = await API.get("brands/members");
      setDiscordServers(servers.data.data.servers)
      setMemberData(members.data.data.members)
    }
    init()
    
  }, []);

  useEffect(() => {
    const selectedIds = selectedDiscordServers.map(server => server.id);
  }, [selectedDiscordServers]);

  
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleRowSelect = (event, emailAddress) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [
        ...prevSelectedRows,
        emailAddress,
      ]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row !== emailAddress)
      );
      if (selectedRows.length == 1) {
        setSelectAll(false);
      }
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allEmailAddresses = memberData.map((row) => row.email);
      setSelectedRows(allEmailAddresses);
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      setSelectAll(false);
    }
  };

  function CustomCheckbox(props) {
    return (
      <StyledCheckbox
        disableRipple
        checkedIcon={
          <CheckedBox>
            <CheckIcon fontSize="small" sx={{ color: "white" }} />
          </CheckedBox>
        }
        indeterminateIcon={
          <IndeterminateBox>
            <RemoveIcon fontSize="small" />
          </IndeterminateBox>
        }
        icon={<CheckBoxIcon />}
        {...props}
      />
    );
  }
  const isUserInAllSelectedServersAndRoles = (userDetails) => {
    // Gets user's servers
    const selectedIds = selectedDiscordServers.map(server => server.id);
    if (userDetails.discordDetails.length === 0) {
      return selectedIds.length === 0 ? true : false;
    }
    const userServers = userDetails.discordDetails[0].servers;
  
  
    // For every selected server ID, check if it is included in the user's server IDs
    // and the user has all selected roles in the server
    return selectedIds.every(selectedId => {
      const userServer = userServers.find(server => server.id === selectedId);
      if (!userServer) {
        return false;
      }
      console.log("user Roles", userServer.memberData?.roles)
      console.log("selected Roles", selectedRoles)
  
      const userRoles = userServer.memberData?.roles
      const selectedRolesForServer = selectedRoles[selectedId] || [];
  
      return selectedRolesForServer.every(roleId => userRoles?.includes(roleId));

    });
  };
  
  
  return (
    <Layout activeTab={"discord"}>
      <PageWrapper>
        <Header>
          <HeaderWrapper>
            Discord
            <ExportButton>
              <IconUpload />
              Export Data
            </ExportButton>
          </HeaderWrapper>

          <FormControl>
            <StyledInputLabel id="demo-mutiple-checkbox-label">Select Required Discord Servers</StyledInputLabel>
            <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selectedDiscordServers.map(server => server.name)}
                onChange={handleChange}
                input={<StyledInput/>}
                renderValue={(selected) => selected.join(', ')}
              >
                {discordServers.map((server) => (
                  <MenuItem key={server.id} value={server.name}>
                    <Checkbox checked={selectedDiscordServers.some(selectedServer => selectedServer.id === server.id)} />
                    <ListItemText primary={server.name} />
                  </MenuItem>
                ))}
              </Select>
          </FormControl>
          {
            selectedDiscordServers.map(server => (
              <FormControl key={server.id}>
                <StyledInputLabel id={`select-${server.id}`}>Select Roles for {server.name}</StyledInputLabel>
                <Select
                  labelId={`select-${server.id}`}
                  multiple
                  value={selectedRoles[server.id] || []}
                  onChange={event => setSelectedRoles(prevRoles => ({ ...prevRoles, [server.id]: event.target.value.map(roleId => roleId) }))}
                  input={<StyledInput />}
                  renderValue={selectedRoleIds => selectedRoleIds.map(roleId => {
                    const role = server.roles.find(role => role.id === roleId);
                    return role ? role.name : '';
                  }).join(', ')}
                >
                  {(server.roles || []).map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      <Checkbox checked={(selectedRoles[server.id] || []).includes(role.id)} />
                      <ListItemText primary={role.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))
          }
        </Header>
        <MainContentWrapper>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableHeadCell
                  style={{ width: "20px", paddingRight: "28px" }}
                >
                  <CustomCheckbox
                    checked={selectAll}
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length <= memberData.length
                    }
                    onChange={handleSelectAll}
                  />
                </StyledTableHeadCell>
                <StyledTableHeadCell style={{ paddingLeft: 0 }}>
                  Email Address
                </StyledTableHeadCell>
                <StyledTableHeadCell>First Name</StyledTableHeadCell>
                <StyledTableHeadCell>Last Name</StyledTableHeadCell>
                <StyledTableHeadCell>Phone Number</StyledTableHeadCell>
                <StyledTableHeadCell>Server Status</StyledTableHeadCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {memberData
                // .filter((row) => row.email.includes(searchTerm))
                ?.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableBodyCell style={{ paddingRight: 0 }}>
                      <CustomCheckbox
                        checked={selectedRows?.includes(row.email)}
                        onChange={(event) => handleRowSelect(event, row.email)}
                      />
                    </StyledTableBodyCell>
                    <StyledTableBodyCell style={{ paddingLeft: 0 }}>
                      {row.email}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {row.customerFirstName}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {row?.customerLastName}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {row.customerPhone}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell style={{ paddingRight: 0 }}>
                      {isUserInAllSelectedServersAndRoles(row) ? "True" : "False"}
                    </StyledTableBodyCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <Drawer
            anchor={"right"}
            open={showModal}
            sx={{
              "& .MuiDrawer-paper": {
                width: "29%",
              },
            }}
            onClose={() => {
              setshowModal((prev) => !prev);
            }}
          >
            <ContentWrapper>
              <AccountDetail
                data={selectedModalData}
                onClose={() => {
                  setshowModal((prev) => !prev);
                }}
              />
            </ContentWrapper>
          </Drawer>
        </MainContentWrapper>
      </PageWrapper>
    </Layout>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 60px 40px 0 40px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
  overflow-y: scroll;
`;

const StyledTableRow = styled.tr`
  border-bottom: 1px solid #352b42;
  border-collapse: collapse;
`;

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0;
  }
  &.MuiCheckbox-indeterminate {
    color: ${(props) => props.theme.palette.white} !important;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    color: #6096FF !important;
  }
`;

const StyledInput = styled(Input)`
  &.MuiInput-root {
    color: #7F86AD !important;
  }
`;

const CheckBoxIcon = styledMui("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  width: 24,
  height: 24,
  background: theme.palette.lightGrey,
}));

const CheckedBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));

const IndeterminateBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));

const StyledTableHeadCell = styled.th`
  padding: 16px;
  text-align: start;

  && {
    ${(props) => props.theme.typography.bodyMBold};
    color: ${(props) => props.theme.palette.lightBlue};
  }
`;

const StyledTableBodyCell = styled.th`
  padding: 16px;
  text-align: start;

  .icon {
    color: ${(props) => props.theme.palette.lightGrey};
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }

  && {
    ${(props) => props.theme.typography.bodyMBold};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;

const MainContentWrapper = styled.div`
  display: flex;
  border-radius: 12px;
  padding: 40px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
`;

const ExportButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyS,
  display: "flex",
  width: "fit-content",
  borderRadius: 6,
  padding: "8px 20px",
  textTransform: "none",
  gap: "10px",
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
}));

const RewardTypesGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  height: 44px;
`;

const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.bodyM,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "16px 20px",
  color: theme.palette.lightGrey,
  maxHeight: "44px",

  "& .MuiNativeSelect-select": {
    padding: 0,

    "&.MuiInputBase-input": {
      paddingRight: "12px",
    },
  },
}));

const CustomFormControl = styledMui(FormControl)({
  "& .MuiInputBase-root": {
    marginTop: "0",
  },
});

const InputField = styledMui(Input)(({ theme }) => ({
  ...theme.typography.bodyM,
  width: "402px",
  display: "flex",
  justifyContent: "center",
  paddingLeft: "10px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "16px 20px",
  color: theme.palette.lightGrey,
  maxHeight: "44px",

  "& .MuiInput-input": {
    padding: 0,
    color: theme.palette.lightGrey,
    "&::placeholder": {
      color: theme.palette.lightGrey,
      opacity: 1,
    },
  },
}));

const PageWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 28px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.header1};
`;

Discord.propTypes = {
  fetchDiscordServers: PropTypes.func,
  discordPageState: PropTypes.object,
};


const mapStateToProps = createStructuredSelector({
  discordPageState: makeSelectDiscordPage(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchDiscordServers: (brandId) => dispatch(fetchDiscordServers(brandId)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Discord);
