import React from "react";
import styled from "styled-components";
import { ButtonBase } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import { ImageUserProfileMock } from "images";
import { formattedDate } from "utils/date";
import CampaignCard from "containers/CampaignsPage/components/CampaignCard";
import { mainTheme } from "theme/mainTheme";
import { useDispatch } from "react-redux";
import MemberTable from "./MemberTable";

const AirdropReview = ({ formValues, inputFields }) => {
  const { name, description, startDate, benefits, users } = formValues;

  const renderBenefits = () => {
    return benefits?.map((item, idx) => (
      <div className="benefit-item" key={idx}>
        {item.name}
        <span style={{ color: mainTheme.palette.lightGrey }}>
          {item.progress}
        </span>
      </div>
    ));
  };

  return (
    <Wrapper>
      <ReviewWrapper>
        <Header>
          <div className="titleWrapper">
            <div className="title">{name}</div>
            <div className="subtitle-wrapper">
              <span className="subtitle">{description}</span>
            </div>
          </div>
          <ContentWrapper>
            <div className="benefits">
              <div className="content-title">Benefits</div>
              {renderBenefits()}
            </div>
          </ContentWrapper>
        </Header>
        <ContentWrapper>
          <div className="benefits">
            <div className="content-title">Date of Airdrop</div>
            {formattedDate(startDate, 2)}
          </div>
        </ContentWrapper>
        <TableSection>
          <div className="title">Members</div>
          <MemberTable members={users} isCheckBoxesRemoved={true} />
        </TableSection>
      </ReviewWrapper>
    </Wrapper>
  );
};

const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 20px;

  .title {
    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.white};
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  max-width: 240px;

  .content-title {
    ${(props) => props.theme.typography.bodyMBold};
    color: ${(props) => props.theme.palette.lightBlue};
  }

  .benefits {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .benefit-item {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.white};

      display: flex;
      gap: 16px;
    }
  }
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  .titleWrapper {
    display: flex;
    flex-direction: column;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${(props) => props.theme.typography.header1};
    color: ${(props) => props.theme.palette.white};
  }

  .subtitle-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .subtitle {
    ${(props) => props.theme.typography.bodyS};
    color: ${(props) => props.theme.palette.lightGrey};
  }

  .date {
    ${(props) => props.theme.typography.bodyM};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;
const ReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 40px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export default AirdropReview;
