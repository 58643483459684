/*
 *
 * UserPortal constants
 *
 */

export const DEFAULT_ACTION = "app/UserPortal/DEFAULT_ACTION";
export const CREATE_USER = "app/UserPortal/CREATE_USER";
export const VERIFY_EMAIL = "app/UserPortal/VERIFY_EMAIL";
export const REDEEM_REWARD = "app/UserPortal/REDEEM_REWARD";
export const MINT_NFT_TO_USER = "app/UserPortal/MINT_NFT_TO_USER";
export const FETCH_USER_REWARD_HOLDINGS =
  "app/UserPortal/FETCH_USER_REWARD_HOLDINGS";
export const FETCH_USER_MEMBERSHIP_HOLDINGS =
  "app/UserPortal/FETCH_USER_MEMBERSHIP_HOLDINGS";
export const INIT_DATA = "app/UserPortal/INIT_DATA";
export const FETCH_USER_PROFILE = "app/UserPortal/FETCH_USER_PROFILE";
export const TOGGLE_SNACKBAR = "app/UserPortal/TOGGLE_SNACKBAR";
