/**
 *
 * LandingPageStoryForms
 *
 */

import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { styled as styledMui } from "@mui/material/styles";
import { InputBase, TextField } from "@mui/material";
import { Block, Content, Label, Sublabel } from "../LandingPageDesignForms";

function LandingPageStoryForms({ formValues, handleChange }) {
  const { storyTitle, storyBody, storyCTA,slug } = formValues;
  return (
    <Fields>
      <Block>
        <Label>Title</Label>
        <Sublabel>Maximum 100 characters.</Sublabel>
        <Content>
          <FormField
            inputProps={{
              "aria-label": "description",
              maxLength: "500",
            }}
            fullWidth
            value={storyTitle}
            onChange={(event) => {
              handleChange(event, "storyTitle", event.target.value);
            }}
          />
        </Content>
      </Block>

      <Block>
        <Label>Body</Label>
        <Sublabel>Maximum 300 characters.</Sublabel>
        <Content>
          <LargeFormField
            placeholder="description"
            minRows={3}
            fullWidth
            multiline
            value={storyBody}
            onChange={(event) => {
              handleChange(event, "storyBody", event.target.value);
            }}
          />
        </Content>
      </Block>
      <Block>
        <Label>Slug</Label>
        <Content>
          <FormField
            placeholder="slug"
            inputProps={{
              "aria-label": "description",
              maxLength: "500",
            }}
            fullWidth
            value={slug}
            onChange={(event) => {
              handleChange(event, "slug", event.target.value);
            }}
          />
        </Content>
      </Block>
      <Block>
        <Label>Call To Action</Label>
        <Sublabel>Add your call-to-action button.</Sublabel>
        <Content>
          <FormField
            inputProps={{
              "aria-label": "description",
              maxLength: "500",
            }}
            fullWidth
            value={storyCTA}
            onChange={(event) => {
              handleChange(event, "storyCTA", event.target.value);
            }}
          />
        </Content>
      </Block>
    </Fields>
  );
}

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

export const FormField = styledMui(InputBase)({
  display: "flex",
  borderRadius: 10,
  backgroundColor: "rgba(217, 217, 217, 0.2)",
  color: "#7F86AD",
  margin: "8px 0",
  height: 38,
  fontSize: 16,
  padding: "10px 15px",
  fontFamily: "Poppins",
  background: "#21212E",
  border: "1px solid #21212E",
  lineHeight: "28px",
  borderRadius: "8px",
  "&::placeholder": {
    color: "#7F86AD",
  },
});
const LargeFormField = styledMui(TextField)({
  background: "#21212E",
  outline: "none",
  borderRadius: "8px",
  "& fieldset": {
    border: "none",
  },
  "& textarea": {
    fontFamily: "Poppins",
    color: "#7F86AD",
  },
});

const formFieldOverrides = {
  border: "1px solid #D9D9D9",
  input: {
    "&::placeholder": {
      color: "#ffffff",
      opacity: 1,
    },
  },
};

LandingPageStoryForms.propTypes = {
  formValues: PropTypes.object,
  handleChange: PropTypes.func,
};

export default memo(LandingPageStoryForms);
