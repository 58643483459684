/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import history from "utils/history";
import manageMembershipReducer from "containers/ManageMembership/reducer";
import membersReducer from "containers/Members/reducer";
import manageBrandReducer from "containers/ManageBrand/reducer";
import campaignsPageReducer from "containers/CampaignsPage/reducer";
import manageSignUpModalReducer from "containers/SignUpModalEdit/reducer";
import manageQrCodeModalReducer from "containers/QrCodeModalEdit/reducer";
import shopifystoreordersReducer from "containers/ShopifyStores/reducer";
import discordPageReducer from "containers/DiscordPage/reducer";

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    manageBrand: manageBrandReducer,
    manageMembership: manageMembershipReducer,
    manageMembers: membersReducer,
    shopifyStoreOrders: shopifystoreordersReducer,
    discordPage: discordPageReducer,
    campaignsPage: campaignsPageReducer,
    manageSignUpModal: manageSignUpModalReducer,
    manageQrCodeModal: manageQrCodeModalReducer,

    ...injectedReducers,
  });

  return rootReducer;
}
