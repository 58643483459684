import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import API from "utils/brandAPI";
import { useParams } from "react-router-dom";
import { fetchMemberships } from "containers/ManageMembership/actions";
import { styled as styledMui } from "@mui/material/styles";
import USERAPI from "utils/userAPI";

import {
  Button,
  CircularProgress,
  Input,
  InputBase,
  Modal,
  ButtonBase,
} from "@mui/material";

const PageContainer = styled.div`
  background-color: black;
  color: #294bfc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
  max-width: 1200px;
  width: 100%;
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
`;

const GridItemImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
`;

const BrandName = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const SearchInput = styled.input`
  background-color: white;
  color: #294bfc;
  border: none;
  border-radius: 6px;
  padding: 12px 20px;
  margin-bottom: 20px;
`;

const ClaimButton = styledMui(Button)({
  borderRadius: "10px",
  fontSize: "16px",
  lineHeight: "36px",
  textTransform: "none",
  backgroundColor: "#294BFC",
  color: "#fff",
  paddingLeft: "40px",
  paddingRight: "40px",
  transition: "all .3s ease",

  "&:hover": {
    backgroundColor: "#7f86ad",
  },
});

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .recentActivity {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.white};
    }
    .date {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};
    }
  }

  .wallet-wrapper {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    border-radius: 6px;
    background-color: ${(props) => props.theme.palette.darkerGrey};

    .walletAddress {
      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.white};
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 296px;
    }
  }

  .stats {
    display: flex;
    justify-content: space-around;

    .stat-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .header {
        ${(props) => props.theme.typography.header}
        color: ${(props) => props.theme.palette.white};
      }

      .bodyS {
        ${(props) => props.theme.typography.bodyS}
        color: ${(props) => props.theme.palette.lightGrey};
      }
    }
  }

  .account {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .content {
      display: flex;
      align-items: center;
      gap: 20px;

      .bodyM {
        ${(props) => props.theme.typography.bodyM}
        color: ${(props) => props.theme.palette.white};
      }
    }
  }

  .membership {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 24px;

    border: 1px solid #6096ff;
    border-radius: 6px;

    .text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .header2 {
        ${(props) => props.theme.typography.header2}
        color: ${(props) => props.theme.palette.white};
      }
      .bodyS {
        ${(props) => props.theme.typography.bodyS}
        color: ${(props) => props.theme.palette.white};
      }
    }
  }
`;

const ClaimMembershipButton = ({ brandMembership, dispatch }) => {
  const [loading, setLoading] = useState(false);
  // const queryParams = queryString.parse(location.search);

  const handleMembershipRedeem = async () => {
    // redeeming membership
    try {
      console.log(brandMembership);
      setLoading(true);
      const res = await USERAPI.post("/users/mint", {
        membershipId: brandMembership,
      });
      await USERAPI.post("/users/membership", {
        membershipId: brandMembership,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      localStorage.removeItem("isClaimMemberShipLogin");
      if (error.status === 401) {
        // setError("User not authorized. Please login to continue");
      } else {
        // setError(error?.response?.data?.message);
      }
      setLoading(false);
      return;
    }
    // dispatch(fetchUserRewardHoldings(queryParams?.brandId));
    // dispatch(fetchUserMembershipHoldings(queryParams?.brandId, true));
    // dispatch(fetchUserProfile());

    // autoRedeemBadge(); // auto redeem badge associated with this membership
  };
  return (
    <Box>
      <ClaimButton onClick={() => handleMembershipRedeem()}>
        {loading ? <CircularProgress /> : "Claim Membership"}
      </ClaimButton>
    </Box>
  );
};

const BrandMembersReward = () => {
  const [brands, setBrands] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { brandId, membershipId } = useParams(); // Access the brandId from the URL parameter

  const [userDetail, setUserDetail] = useState({});

  useEffect(() => {
    fetchBrands();
    fetchUserDetail();
  }, []);

  const fetchUserDetail = async () => {
    const response = await USERAPI.get("users/me");
    if (response.data.status === "success") {
      console.log(response.data.data.user);
      setUserDetail(response.data.data.user);
    }
  };

  const fetchBrands = async () => {
    try {
      console.log(brandId, "brandId");
      const response = await API.get(`membership/?brand=${brandId}`);

      console.log(response);
      if (response.data.status === "success") {
        const rewardsData = response.data.data.filter(
          (reward) => reward._id === membershipId
        );
        console.log(rewardsData?.[0]?.rewards);
        setBrands(rewardsData?.[0]?.rewards);
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const filteredBrands = brands.filter((brand) =>
    brand.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <PageContainer>
      <SearchInput
        type="text"
        placeholder="Search by membership..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      {userDetail?.membershipId?.includes(membershipId) ? (
        <Box>
          <ClaimButton>Claimed</ClaimButton>
        </Box>
      ) : (
        <ClaimMembershipButton brandMembership={membershipId} />
      )}

      {/* <button>helo</button> */}

      <GridContainer>
        {filteredBrands.map((reward) => (
          <GridItem key={reward._id}>
            <GridItemImage src={reward.image} alt="Reward Image" />
            <BrandName>{reward.name}</BrandName>
          </GridItem>
        ))}
      </GridContainer>
    </PageContainer>
  );
};

export default BrandMembersReward;
