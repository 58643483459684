import React from "react";
import styled from "styled-components";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Box from "@mui/material/Box";

export const Figure = ({ heading, value, percent, isUp, className }) => {
  return (
    <Container className={className}>
      <div className="heading">{heading}</div>
      <Box sx={{ display: "flex" }}>
        <div className="value">{value}</div>
        <div className="pts">pts</div>
        {isUp ? (
          <ArrowDropUpIcon style={{ color: "#3AD337", marginTop: 6, marginLeft: 8 }} />
        ) : (
          <ArrowDropDownIcon style={{ color: "#F2233C", marginTop: 6, marginLeft: 8 }} />
        )}
        <div className="percent">{percent}%</div>
      </Box>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 24px 32px;
  border-radius: 12px;
  color: #fff;
  background: #36353d;
  /* margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  } */
  .heading {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 8px;
  }
  .value {
    font-size: 36px;
    line-height: 36px;
  }
  .pts {
    font-size: 18px;
    line-height: 27px;
    margin-top: auto;
    margin-bottom: 2px;
    margin-left: 4px;
  }
  .percent {
    font-size: 14px;
    line-height: 21px;
    margin-top: 8px;
  }
`;
