import AppHeader from "components/AppHeader";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import logo from "images/icons/girlscrewicon.svg";
import {
  ButtonBase,
  Divider,
  InputBase,
  FormHelperText,
  Button,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IconUpload } from "images/icons/svg-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrand } from "containers/ManageBrand/action";
import { Box } from "@mui/material";
import API from "utils/brandAPI";
import { uploadFile } from "utils/s3";
import { ImageDefaultBrand, ImageDefaultUser } from "images";
import YoutubeVerified from "components/UserModal/components/YoutubeVerify";
import DiscordVerified from "components/UserModal/components/DiscordVerify";
import DiscordBotAdd from "components/UserModal/components/DiscordBotAdd";
import { FaDiscord, FaInstagram } from "react-icons/fa";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LanguageIcon from "@mui/icons-material/Language";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import InstagramVerified from "components/UserModal/components/InstagramVerify";

const SocialLinks = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { brand } = useSelector((state) => state.manageBrand);
  const [brandUpdated, setBrandUpdated] = useState(false);
  const [twitter, setTwitter] = useState(brand.twitterUsername);
  const goBack = () => {
    navigate("/dashboard");
  };

  const socials = brand.socials;
  // const isSaveActive = Object.values(formValues).some((value) => value !== "");

  useEffect(() => {
    dispatch(fetchBrand());
    setBrandUpdated(false);
  }, [brandUpdated]);

  return (
    <>
      <AppHeader />
      <ContentWrapper className="container-fluid">
        <LeftMenu>
          <ActiveMenuItem
            disableRipple
            disableElevation
            isSelected={false}
            onClick={() => {
              navigate("/profile-settings");
            }}
          >
            Profile Settings
          </ActiveMenuItem>
          <ActiveMenuItem disableRipple disableElevation isSelected={true}>
            Social Links
          </ActiveMenuItem>
        </LeftMenu>

        <MainContent>
          <HeaderWrapper>
            <LeftHeader>
              <BackButton onClick={() => goBack()}>
                <ChevronLeftIcon />
              </BackButton>
              <HeaderTitle>Social Links</HeaderTitle>
            </LeftHeader>
            {/* <SaveButton disabled={!isSaveActive}>Save Changes</SaveButton> */}
          </HeaderWrapper>
          <Wrapper>
            <BrandInfoWrapper>
              <BrandInfo>
                <BrandName>Social Links</BrandName>
                <Divider
                  sx={{
                    height: "1px",
                    borderColor: "#352B42",
                    margin: "20px 0",
                  }}
                />
                <FieldWrapper>
                  <FormField
                    sx={formFieldOverrides}
                    value={""}
                    disabled
                    placeholder=" Instagram"
                    startAdornment={<InstagramIcon />}
                    endAdornment={
                      socials?.Instagram && (
                        <CheckCircleIcon style={{ color: "green" }} />
                      )
                    }
                  />
                  <FormField
                    sx={formFieldOverrides}
                    value={twitter}
                    onChange={(e) => setTwitter(e.target.value)}
                    placeholder=" Twitter"
                    startAdornment={<TwitterIcon />}
                    endAdornment={<TwitterSaveButton value={twitter} />}
                  />
                  <FormField
                    sx={formFieldOverrides}
                    value={socials?.youtube}
                    disabled
                    placeholder=" Youtube"
                    startAdornment={
                      <YouTubeIcon style={{ marginRight: "5px" }} />
                    }
                    endAdornment={
                      socials?.youtube ? (
                        <CheckCircleIcon style={{ color: "green" }} />
                      ) : (
                        <YoutubeVerified
                          formValues={brand}
                          isBrand={true}
                          brandId={brand._id}
                        />
                      )
                    }
                  />
                  <FormField
                    sx={formFieldOverrides}
                    value={socials?.discord}
                    disabled
                    placeholder=" Discord"
                    startAdornment={
                      <FaDiscord style={{ marginRight: "5px" }} />
                    }
                    endAdornment={
                      <Box display="flex" gap={2}>
                        {socials?.discord ? (
                          <CheckCircleIcon style={{ color: "green" }} />
                        ) : (
                          <DiscordVerified
                            formValues={brand}
                            isBrand={true}
                            brandId={brand._id}
                          />
                        )}
                        <DiscordBotAdd />
                      </Box>
                    }
                  />
                  <FormField
                    sx={formFieldOverrides}
                    value={socials?.youtube}
                    disabled
                    placeholder=" Youtube"
                    startAdornment={
                      <InstagramIcon style={{ marginRight: "5px" }} />
                    }
                    endAdornment={
                      socials?.youtube ? (
                        <CheckCircleIcon style={{ color: "green" }} />
                      ) : (
                        <InstagramVerified
                          formValues={brand}
                          isBrand={true}
                          brandId={brand._id}
                        />
                      )
                    }
                  />
                  <FormField
                    sx={formFieldOverrides}
                    value={""}
                    disabled
                    placeholder=" Website"
                    startAdornment={<LanguageIcon />}
                    endAdornment={
                      socials?.Website && (
                        <CheckCircleIcon style={{ color: "green" }} />
                      )
                    }
                  />
                </FieldWrapper>
              </BrandInfo>
            </BrandInfoWrapper>
          </Wrapper>
        </MainContent>
      </ContentWrapper>
    </>
  );
};

const TwitterSaveButton = ({ value }) => {
  const handleTwitterSave = async () => {
    await API.post("/brands/updateTwitterUsername", { userName: value });
  };
  return (
    <SaveButton value={value} onClick={() => handleTwitterSave()}>
      Save
    </SaveButton>
  );
};

const EmptyImage = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.palette.lightGrey};
  height: 136px;
  width: 136px;
  border-radius: 100%;
`;

const TextOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 136px;
  height: 136px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: none;
  background-color: black;
  border-radius: 100%;
  opacity: 0.8;
  cursor: pointer;
  gap: 5px;
`;

const Image = styled.div`
  position: relative;
  height: 136px;
  width: 136px;
  border-radius: 100%;

  img {
    height: 136px;
    width: 136px;
    border-radius: 100%;
  }

  &:hover {
    ${TextOverlay} {
      display: flex;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  overflow-y: scroll;

  /* Hide the scrollbar on WebKit browsers (e.g. Chrome, Safari) */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide the scrollbar on Firefox */
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const Fields = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`;

const UserInfoHeader = styled.div`
  display: flex;
  width: 100%;
  gap: 40px;
  align-items: center;
`;

const PersonalInfoTitle = styled.div`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.white};
`;

const PersonalInfoWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.darkestGrey};
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
`;

const UserPositon = styled.div`
  ${(props) => props.theme.typography.header3};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const UserName = styled.div`
  ${(props) => props.theme.typography.header1};
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

const UserInfoWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.darkestGrey};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 60px;
  border-radius: 20px;
  gap: 40px;
  align-items: center;
`;

const formFieldOverrides = {
  width: "100%",
  backgroundColor: "#21212E",
  borderRadius: "8px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#7F86AD",
  padding: "12px 16px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
    "&.Mui-disabled": {
      "-webkit-text-fill-color": "grey",
    },
  },
};

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const Label = styled.div`
  ${(props) => props.theme.typography.subHeader};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const FormField = styledMui(InputBase)({});

const BrandName = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(props) => props.theme.typography.header1};
`;

const BrandInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LeftHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  color: #6096ff;
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  &:hover {
    opacity: 0.7;
  }
`;

const BrandInfoWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.darkestGrey};
  display: flex;
  width: 100%;
  padding: 60px;
  border-radius: 20px;
  gap: 40px;
  align-items: center;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const HeaderTitle = styled.div`
  ${(props) => props.theme.typography.header1};
`;

const StyledFormHelperText = styledMui(FormHelperText)`
  color: red;
`;

const SaveButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.white,
  textTransform: "none",
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "18px 20px",
  gap: "20px",
  height: "40px",
  width: "250px",
  fontSize: "15px",
  background: "#1976d2",
  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));

const MenuItem = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.lightBlue,
  justifyContent: "flex-start",
  width: "100%",
  textTransform: "none",
  padding: "16px 28px",
  background: "transparent",
  borderRadius: 6,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",

  "&:hover": {
    backgroundColor: "#222239",
    filter: "none",
    color: theme.palette.white,
  },
}));

const ActiveMenuItem = styledMui(MenuItem)(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? theme.palette.darkerGrey : "",
  color: theme.palette.white,
  margin: "5px",
}));

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const LeftMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 27.5px;
  width: 290px;
  border-right: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};
`;

const MainContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 40px 40px 19px 33px;
  gap: 20px;
`;

export default SocialLinks;
