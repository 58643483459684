import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import makeSelectUserPortal from "containers/UserPortal/selector";
import { styled as styledMui } from "@mui/material/styles";
import { ButtonBase } from "@mui/material";

import { formattedDate } from "utils/date";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const RewardCard = ({
  name,
  startDate,
  endDate,
  startTime,
  endTime,
  timezone,
  description,
  image,
  redemptionCount,
  isDraft,
  setPage,
  category,
  transactionStatus,
  setSelectedReward,
  id,
  membershipId,
  membershipTier = [],
  isLocked = false,
  isUser,
  hoverable = true,
  showChangeImage = false,
  handleChange,
  defaultMemberships = [],
  dispatch,
  userPortalState,
  verifyEmail,
  isRedeemable,
  isRedeemed,
  onAddClick,
  addedRewardId
}) => {

  const [buttonText, setButtonText] = useState("Add");

  useEffect(() => {
    setButtonText(addedRewardId === id ? "Remove" : "Add");
  }, [addedRewardId, id]);


  const handleButtonClick = () => {
    if (buttonText === "Add") {
        setButtonText("Remove");
        onAddClick(id, name);
    } else {
        setButtonText("Add");
        onAddClick("", "");
    }
  }

  const buttonDisabled = addedRewardId && addedRewardId !== id;

  function renderDates() {
    
    const today = new Date();
    const _endDate = new Date(endDate);
    const timeDiff = _endDate.getTime() - today.getTime();
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    

    

    return (

      
        <DateContent style={{ display: "flex", gap: "20px" }}>
        {endDate && (
            <span style={{ color: "#7F86AD" }}>
            Ends on{" "}
            <span style={{ color: "white" }}>{formattedDate(endDate)}</span>
            </span>
        )}
        {_endDate > today && (
            <span style={{ color: "#7F86AD" }}>
            Expires in{" "}
            <span style={{ color: "white" }}>{daysLeft} days</span>
            </span>
        )}
        </DateContent>
    );
      }

  

  return (
    <Wrapper active={addedRewardId === id} className={`${addedRewardId === id ? "added" : ""}`}>
      <ContentWrapper>
      <img
        style={{ 
          borderRadius: "8px",
          objectFit: "contain", 
          objectPosition: "center"
        }}
        src={image}
        width="140px"
        height="93px"
      ></img>
        <Content>
          <MainContent>
            <CategoryText>
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </CategoryText>
            <RewardName>{name === "" ? "Reward Title" : name}</RewardName>
          </MainContent>
          <DateWrapper>{renderDates()}</DateWrapper>
        </Content>
      </ContentWrapper>

      <RedeemButton onClick={handleButtonClick} disabled={buttonDisabled}>
        {buttonText}
      </RedeemButton>
    </Wrapper>
  );
};

const RedeemButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.lightGrey,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  padding: "12px 20px",
  width: "fit-content",
  height: "fit-content",
  borderRadius: "6px",
  background: theme.palette.darkestGrey,

  "&:hover": {
    backgroundColor: theme.palette.white,
  },
}));

const GreenText = styledMui("span")(({ theme }) => ({
  color: theme.palette.green,
}));

const RewardName = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.bodyMBold}
  line-height: normal;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 12px;
  justify-content: space-between;
  padding: 20px;
  background: ${(props) => props.theme.palette.darkestGrey};
  border: ${(props) => (props.active ? '1px solid #6096FF' : 'none')};
  &:hover {
    background:#252530;
  }

  &.added {
    box-sizing: border-box;
    background-color: transparent;

    outline: 1px solid;
    outline-color: ${(props) => props.theme.palette.lightBlue};
  }
  
`;

const CategoryText = styled.div`
  ${(props) => props.theme.typography.bodySMedium};
  color: ${(props) => props.theme.palette.lightBlue};
  line-height: normal;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;
const DateWrapper = styled.div`
  display: flex;
`;
const DateContent = styled.div`
  ${(props) => props.theme.typography.bodyS};
`;
const ContentWrapper = styled.div`
  display: flex;
  gap: 28px;
`;




export default RewardCard;
