import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { ImageUserProfileMock } from "images";
import { formatSreams } from "containers/Analytics/ArtistDashboard/utils";
import { IconCrown } from "images/icons/svg-components";
import {
  Button,
} from "@mui/material";
import { isEmpty } from "utils/object";

const GoldCrown = () => (
  <CrownWrapper style={{ background: "#FFDD55" }}>
    <IconCrown color="black" style={{ width: "12.8px", height: "12px" }} />
  </CrownWrapper>
);
const SilverCrown = () => (
  <CrownWrapper style={{ background: "#D9D9D9" }}>
    <IconCrown color="black" style={{ width: "12.8px", height: "12px" }} />
  </CrownWrapper>
);
const BronzeCrown = () => (
  <CrownWrapper style={{ background: "#BE6B1E" }}>
    <IconCrown color="black" style={{ width: "12.8px", height: "12px" }} />
  </CrownWrapper>
);

const LeaderboardCard = ({leader,index,setShowUserModal}) => {
  return (
    <CardWrapper>
      <LefContent>
        <span className="rank">#{index}</span>
        {leader?.userImage?<img src={leader?.userImage}/> : <img src={ImageUserProfileMock} />}
        <span className="username">{leader?.userName?leader?.userName:"-"}</span>
      </LefContent>
      {!leader &&
        <CenterContent>
          <LoginToSeeYourRankButton onClick={()=>setShowUserModal(true)}>
            Log in to See Your Rank 
          </LoginToSeeYourRankButton>
        </CenterContent>
      }
      <RightContent>
        <span className="count">{leader?.totalSecondsListened?formatSreams(leader?.totalSecondsListened):"-"}</span>
        <span className="text">Streams</span>
      </RightContent>
    </CardWrapper>
  );
};

const CrownWrapper = styled.div`
  display: flex;
  width: fit-content;
  padding: 6px;
  border-radius: 4px;
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 28px;

  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
`;

const LefContent = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;

  .username {
    ${(props) => props.theme.typography.subHeader};
    color: ${(props) => props.theme.palette.lightGrey};
  }

  .rank {
    ${(props) => props.theme.typography.bodySMedium};
    color: ${(props) => props.theme.palette.white};
  }

  img {
    border-radius: 100%;
    height: 60px;
    width: 60px;
  }
`;

const CenterContent = styled.div`
  display: flex;
  flex-direction: column;
  .count {
    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.lightBlue};
  }
  .text {
    ${(props) => props.theme.typography.bodyM};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;

const  LoginToSeeYourRankButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "18px 20px",
  height: "60px",
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const RightContent = styled.div`
  display: flex;
  flex-direction: column;

  .count {
    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.lightBlue};
  }
  .text {
    ${(props) => props.theme.typography.bodyM};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;

export default LeaderboardCard;
