/**
 *
 * LandingPageDesignForms
 *
 */

import React, { memo, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import InputBase from "@mui/material/InputBase";
import { Button, FormControl, NativeSelect } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import { MuiColorInput } from "mui-color-input";
import { RemoveButton, handleRemoveFile } from "components/RemoveButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { LANDING_PAGE_HEADER_IMAGE_SIZE_LIMIT } from "utils/constants";

function LandingPageDesignForms({ formValues, handleChange }) {
  const { headerImage, fontFamily, theme, brandColor } = formValues;
  const [headerImageError, setHeaderImageError] = useState("");
  const headerRef = useRef();
  const [headerRemovable, setHeaderRemovable] = useState(
    headerImage === "" ? false : true
  );

  return (
    <Fields>
      <Block>
        <Label>Theme</Label>
        <FormControl fullWidth>
          <NativeSelect
            id="demo-customized-select-native"
            value={theme}
            style={{ color: "#6096FF", background: "#21212E", border: "none" }}
            onChange={(event) => {
              handleChange(event, "theme", event.target.value);
            }}
            input={
              <FormField sx={{ ...formFieldOverrides, margin: "8px 0 0 0" }} />
            }
          >
            <option value="dark">Dark </option>
            <option value="light">Light </option>
          </NativeSelect>
        </FormControl>
      </Block>
      <Block>
        <Label>Brand Color</Label>
        <FormControl fullWidth>
          <ColorPickerWraper>
            <ColorPrimary> Primary </ColorPrimary>
            <ColorInput
              value={brandColor || ""}
              onChange={(event) => handleChange(event, "brandColor", event)}
              format="hex"
            />
          </ColorPickerWraper>
        </FormControl>
      </Block>
      <Block>
        <Label>Font</Label>
        <FormControl fullWidth>
          <NativeSelect
            id="demo-customized-select-native"
            value={fontFamily}
            style={{ color: "#6096FF", background: "#21212E", border: "none" }}
            onChange={(event) => {
              handleChange(event, "fontFamily");
            }}
            input={
              <FormField sx={{ ...formFieldOverrides, margin: "8px 0 0 0" }} />
            }
          >
            <option value="Inter">Inter</option>
          </NativeSelect>
        </FormControl>
      </Block>
      <Block>
        <Label>Banner Image</Label>
        <Sublabel>
          Upload header image. Max file size:{" "}
          {LANDING_PAGE_HEADER_IMAGE_SIZE_LIMIT}
          mb.
        </Sublabel>
        <FormControl>
          <UploadWrapper>
            <FileUploadButton variant="contained" component="label">
              Upload File +
              <input
                id="logo"
                hidden
                accept="image/*"
                type="file"
                ref={headerRef}
                onChange={(event) => {
                  setHeaderRemovable(true);
                  const file = event.target.files[0];
                  if (
                    file.size <
                    LANDING_PAGE_HEADER_IMAGE_SIZE_LIMIT * 1024 * 1024
                  ) {
                    setHeaderImageError("");
                    handleChange(event, "headerImage");
                  } else {
                    setHeaderImageError(
                      `Upload size limit is ${LANDING_PAGE_HEADER_IMAGE_SIZE_LIMIT}MB.`
                    );
                  }
                }}
              />
            </FileUploadButton>
            {/* {headerRef && (
              <RemoveButton
                onClick={() => {
                  handleRemoveFile(
                    headerRemovable,
                    "headerImage",
                    handleChange
                  );
                  setHeaderRemovable(false);
                }}
              />
            )} */}
          </UploadWrapper>
          {headerImageError && <Error>{headerImageError}</Error>}
        </FormControl>
      </Block>
    </Fields>
  );
}
const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ColorPrimary = styled.div`
  color: #7f86ad;
`;

const ColorPickerWraper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 4px;
  margin-top: 20px;
`;
const Error = styled.div`
  color: #eb0859;
  font-size: 14px;
`;
const CustomColorPicker = styledMui(MuiColorInput)({
  display: "flex",
  width: "133px",

  "& .MuiInputBase-input": {
    color: "white",
    boxSizing: "border-box",
    display: "flex",
    width: "94px",
    height: "28px",
    background: "rgba(217, 217, 217, 0.2)",
    border: "1px solid #d9d9d9",
    borderRadius: "10px",
    padding: "0 0 0 7px",
    fontFamily: "Poppins",
  },
  "& .MuiInputBase-root": {
    padding: "0",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  "& .MuiButtonBase-root": {
    borderRadius: "10px",
  },
  "& .MuiInputAdornment-root": {
    margin: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "32px",
    width: "32px",
    background: "rgba(217, 217, 217, 0.2)",
    border: "1px solid #d9d9d9",
    borderRadius: "10px",
  },
  "& .MuiOutlinedInput-notchedOutline": { display: "none" },
});

const ColorInput = styledMui(MuiColorInput)({
  display: "flex",
  "& .MuiInputBase-input": {
    color: "#7F86AD",
    padding: "10px",
  },
});

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
`;

const CustomSelect = styled(Select)`
  background-color: #21212e;
  color: #6096ff;
`;

const FontColorFields = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const DoubleField = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

export const Label = styled(BaseText)`
  width: 100%;
  font-size: 18px;
  margin-left: 4px;
  margin-top: 20px;
  font-family: "inter";
  font-weight: 500;
`;

export const Sublabel = styled(Label)`
  font-size: 13px;
  color: rgba(255, 255, 255, 0.75);
  width: 100%;
  margin: 6px 4px;
  color: #7f86ad;
`;

export const FileUploadButton = styledMui(Button)(() => ({
  width: "100%",
  height: 40,
  background: "#21212E",
  border: "1px solid #21212E",
  borderRadius: 8,
  fontWeight: 400,
  fontSize: 14,
  color: "#6096FF",
  justifyContent: "center",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
  padding: "6px 8px",
  marginTop: 16,
  marginBottom: 16,
  marginRight: 5,
  fontFamily: "Poppins",
}));

const FormField = styledMui(InputBase)({
  display: "flex",
  borderRadius: 10,
  backgroundColor: "rgba(217, 217, 217, 0.2)",
  color: "white",
  margin: "16px 0",
  height: 38,
  fontSize: 16,
  padding: "0 7px",
  fontFamily: "Poppins",
});

const formFieldOverrides = {
  border: "1px solid #D9D9D9",
};

LandingPageDesignForms.propTypes = {
  formValues: PropTypes.object,
  handleChange: PropTypes.func,
};

export default memo(LandingPageDesignForms);
