import React, { useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, Divider } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import GoogleIcon from "images/icons/GoogleIcon.svg";
import { ImageCampaignLogo } from "images";
import { IconMail } from "images/icons/svg-components";
import { useMutation } from "react-query";
import API from "utils/userAPI";
import { Link } from "react-router-dom";

const Verify = ({ formValues, onVerify, brandId }) => {
  const { email } = formValues;
  const [error, setError] = useState("");
  const [code, setCode] = useState("      ");
  const [index, setIndex] = useState(0);
  console.log("yahan");
  const { isLoading: isVerifying, mutate: verify } = useMutation(
    (data) => API.post(`/auth/verify/user`, data),
    {
      onSuccess: (res) => {
        localStorage.setItem("userTokens", JSON.stringify(res.data.data));
        localStorage.setItem("userId", res.data.data.user._id);
        onVerify();
      },
      onError: (err) => {
        setError(err.response.data?.message || err.response.data);
        setIndex(0);
      },
    }
  );
  const { isLoading: isResending, mutate: resend } = useMutation(
    (data) => API.post(`/auth/verify/user/resend`, data),
    {
      onSuccess: () => {},
      onError: (err) => {
        setError(err.response.data?.message || err.response.data);
        setIndex(0);
      },
    }
  );
  const maxLengthCheck = (object) => {
    if (object.target.value && object.target.value.length > 1) {
      // eslint-disable-next-line no-param-reassign
      object.target.value = object.target.value.slice(0, 1);
    }
  };

  const handleKeyPress = async (event) => {
    // Handle the delete/backspace key
    if (event.keyCode === 8 || event.keyCode === 46) {
      setCode(code.substring(0, code.length - 1));
      setIndex(index > 1 ? index - 1 : 0);
      return;
    }
    // code for copy paste but not complete
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "v") {
      for (let index = 0; index < 6; index++) {
        document
          .getElementById(index)
          .setAttribute("style", "border-color:#1BC47D;");
      }
      const copiedCode = await navigator.clipboard.readText();
      setCode(copiedCode);
      copiedCode
        .split("")
        .map((char, index) => (document.getElementById(index).value = char));
      setIndex(5);
      // event.target.blur();
      handleVerify(copiedCode);
    }
  };

  const onInputChange = (evt, idx) => {
    if (evt.target.value && evt.target.value.length > 0) {
      document
        .getElementById(evt.target.id)
        .setAttribute("style", "border-color:#1BC47D;");
      let newCode = code;
      let splits = code.split("");
      splits[idx] = evt.target.value;
      newCode = splits.join("");
      setCode(newCode);
      setIndex(idx + 1);
      if (idx === 5) {
        evt.target.blur();
        // enter action
        handleVerify(newCode);
      }
    } else
      document
        .getElementById(evt.target.id)
        .setAttribute("style", "border-color:#352B42;");
  };

  const handleVerify = async (pincode) => {
    setError("");
    verify({
      email,
      pincode,
      brandId,
    });
  };

  const handleResend = () => {
    setError("");
    resend({
      email,
    });
  };

  return (
    <>
      <MailIcon>
        <IconMail />
      </MailIcon>
      <HeaderWrapper>
        <HeaderText>Confirm Verification Code</HeaderText>
        <SubheaderText>
          We sent a verification code to{" "}
          <span style={{ fontWeight: "700" }}>{email}</span>
        </SubheaderText>
      </HeaderWrapper>
      {isVerifying ? (
        <div>
          <VerifyingText>
            Creating Account, it would take some time...
          </VerifyingText>
        </div>
      ) : (
        <>
          <Fields onKeyDown={handleKeyPress}>
            <FormField
              sx={formFieldOverrides}
              type="number"
              id={0}
              onInput={maxLengthCheck}
              inputProps={{
                "aria-label": "code",
                inputMode: 'numeric'
              }}
              inputRef={(input) => input && index === 0 && input.focus()}
              onChange={(e) => onInputChange(e, 0)}
            />
            <FormField
              sx={formFieldOverrides}
              type="number"
              id={1}
              onInput={maxLengthCheck}
              inputProps={{
                "aria-label": "code",
                inputMode: 'numeric'
              }}
              inputRef={(input) => input && index === 1 && input.focus()}
              onChange={(e) => onInputChange(e, 1)}
            />
            <FormField
              sx={formFieldOverrides}
              type="number"
              id={2}
              onInput={maxLengthCheck}
              inputProps={{
                "aria-label": "code",
                inputMode: 'numeric'
              }}
              inputRef={(input) => input && index === 2 && input.focus()}
              onChange={(e) => onInputChange(e, 2)}
            />
            <FormField
              sx={formFieldOverrides}
              type="number"
              id={3}
              onInput={maxLengthCheck}
              inputProps={{
                "aria-label": "code",
                inputMode: 'numeric'
              }}
              inputRef={(input) => input && index === 3 && input.focus()}
              onChange={(e) => onInputChange(e, 3)}
            />
            <FormField
              sx={formFieldOverrides}
              type="number"
              id={4}
              onInput={maxLengthCheck}
              inputProps={{
                "aria-label": "code",
                inputMode: 'numeric'
              }}
              inputRef={(input) => input && index === 4 && input.focus()}
              onChange={(e) => onInputChange(e, 4)}
            />
            <FormField
              sx={formFieldOverrides}
              type="number"
              id={5}
              onInput={maxLengthCheck}
              inputProps={{
                "aria-label": "code",
                inputMode: 'numeric'
              }}
              inputRef={(input) => input && index === 5 && input.focus()}
              onChange={(e) => onInputChange(e, 5)}
            />
          </Fields>
          {error && <ErrorText>{error}</ErrorText>}
          <SecondaryText>
            Didn’t receive your code?
            <span
              style={{ color: "#6096FF", marginLeft: 16 }}
              onClick={handleResend}
            >
              {isResending ? "Sending code...." : "Resend code"}
            </span>
          </SecondaryText>
        </>
      )}
      <SecondaryText>
        By creating an account, you agree to our
        <br />
       <Link  to="/terms-of-use" style={{color:"#6096FF"}}> <span style={{ color: "#6096FF" }}> Terms of Services </span></Link> &
       <Link  to="/privacy-policy" style={{color:"#6096FF"}}> <span style={{ color: "#6096FF" }}> Privacy Policy </span></Link>
      </SecondaryText>
      <Footer>
        {/* Powered by <img src={ImageCampaignLogo} width="150px" height="24px" /> */}
      </Footer>
    </>
  );
};
const Fields = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;

const VerifyingText = styled.span`
  font-size: 20px;
  text-align: center;
`;

const ErrorText = styled.span`
  color: red;
  text-align: center;
`;

const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.header,
  display: "flex",
  color: "#1BC47D",
  height: 60,
  width: 51,
}));

const formFieldOverrides = {
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },

  input: {
    padding: 0,
    textAlign: "center",
    border: "1px solid",
    borderRadius: "6px",
    borderColor: "#352B42",
  },
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 12px;
`;

const MailIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  align-self: center;
  background-color: ${(props) => props.theme.palette.campaignBlue};
  border-radius: 100%;
  padding: 32.76px 27.3px;
`;

const SecondaryText = styled.span`
  text-align: center;
  white-space: normal;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const Footer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 28px;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const HeaderText = styled.div`
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
`;

const SubheaderText = styled.span`
  ${(props) => props.theme.typography.header3};
  color: ${(props) => props.theme.palette.lightGrey};
  text-align: center;
`;

export default Verify;
