/*
 *
 * NewMembership reducer
 *
 */
import produce from "immer";
import {
  DEFAULT_ACTION,
  EDIT_MEMBERSHIP,
  CREATE_MEMBERSHIP,
  PUBLISH_MEMBERSHIP,
  CLEAR_PUBLISHED_CONTRACT,
  CLEAR_CREATED_MEMBERSHIP,
  CLEAR_ERROR,
  CLEAR_EDITED_MEMBERSHIP,
  SET_ERROR,
  TOGGLE_SNACKBAR,
  ADD_ERROR,
  REMOVE_ERROR,
} from "./constants";
import { isEmpty } from "utils/object";

export const initialState = {
  error: [],
  editedMembership: {},
  createdMembership: {},
  publishedMembership: {},
  publishedContract: {},
  showSnackbar: false,
};

/* eslint-disable default-case, no-param-reassign */
const newMembershipReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CREATE_MEMBERSHIP:
        draft.createdMembership = {
          ...action.payload.data.membership,
          rewards: [],
        };
        return draft;
      case PUBLISH_MEMBERSHIP:
        if (action.payload.status === "Membership already published") {
          draft.publishedMembership = {
            ...action.payload.data.membership,
          };
          draft.publishedContract = {
            contractAddress: action.payload.data.membership.contractAddress,
          };
          break;
        }
        draft.publishedMembership = {
          ...action.payload.data.membership,
          rewards: [],
        };
        draft.publishedContract = {
          ...action.payload.data.contract,
        };
        return draft;
      case EDIT_MEMBERSHIP:
        draft.editedMembership = {
          ...action.payload.data.membership,
          rewards: action.payload.data.rewards,
        };
        return draft;
      case CLEAR_PUBLISHED_CONTRACT:
        draft.publishedMembership = {};
        draft.publishedContract = {};
        return draft;
      case CLEAR_CREATED_MEMBERSHIP:
        draft.createdMembership = {};
        return draft;
      case CLEAR_EDITED_MEMBERSHIP:
        draft.editedMembership = {};
        return draft;
      case CLEAR_ERROR:
        draft.error = [];
        return draft;
      case SET_ERROR:
        if (action.payload?.response?.data?.message) {
          draft.error = [
            { key: "error", value: action.payload.response.data?.message },
          ];
          return draft;
        }
        const payload = action.payload?.response?.data;
        draft.error = payload.error.map((err) => {
          return { key: err.path[1], value: err.message };
        });
        return draft;
      case ADD_ERROR:
        draft.error = draft.error.filter((a) => a.key !== action.payload.key);
        draft.error.push(action.payload);
        return draft;
      case REMOVE_ERROR:
        draft.error = draft.error.filter((a) => a.key !== action.payload.key);
        return draft;
      case TOGGLE_SNACKBAR:
        if (isEmpty(state.publishedMembership)) {
          draft.showSnackbar = false;
          break;
        }
        draft.showSnackbar = !state.showSnackbar;
        break;
      case DEFAULT_ACTION:
        break;
    }
  });

export default newMembershipReducer;
