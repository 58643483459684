import React from "react";
import styled from "styled-components";

export const Summary = ({ data = [] }) => {
  return (
    <Container>
      <div className="heading">Summary of Rewards Redeemed</div>
      <table>
        <thead>
          <tr>
            <th>NFT #</th>
            <th>REWARD TYPE</th>
            <th>STATUS</th>
            <th>DATE OF REDEMPTION</th>
            <th>EMAIL</th>
            <th>WALLET ADDRESS</th>
          </tr>
        </thead>
        <tbody>
          {data.slice(0, 3).map((item) => (
            <tr key={item.id}>
              <td>#{item.id}</td>
              <td>{item.type}</td>
              <td>{item.status}</td>
              <td>{item.date}</td>
              <td>{item.email}</td>
              <td>{item.address}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

const Container = styled.div`
  padding: 24px 16px;
  background: #36353d;
  border-radius: 12px;
  .heading {
    font-size: 18px;
    line-height: 27px;
  }
  table {
    border: 1px solid #fff;
    border-radius: 12px;
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    th {
      text-align: left;
    }
    tr {
      height: 50px;
      border-bottom: 1px solid #fff;
    }
  }
`;
