import React, { memo, useEffect, useState, useRef } from "react";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import {
  Button,
  CircularProgress,
  Input,
  InputBase,
  Modal,
  ButtonBase,
} from "@mui/material";
import Filters from "./components/Filters";
import UserRewardCards from "./components/UserRewardCards";
import Search from "components/Search";
import MembershipDisplay from "./components/MembershipDisplay";
import { useInjectSaga } from "utils/injectSaga";
import { useInjectReducer } from "utils/injectReducer";
import queryString from "query-string";
import reducer from "./reducer";
import { isEmpty } from "utils/object";
import saga from "./saga";
import UserInfo from "./components/UserInfo";
import RewardsTabsBar from "containers/ManageReward/components/RewardsTabsBar";
import makeSelectUserPortal from "./selector";
import PaymentModal from "components/PaymentModal";
import PaymentSuccessModal from "components/PaymentSuccessModal";
import {
  fetchMe,
  fetchUserMembershipHoldings,
  fetchUserProfile,
  fetchUserRewardHoldings,
  redeemReward,
  checkDeviceStreamedSong,
  inputUserIdStreamedSong,
} from "./actions";
import InfoHeader from "./components/InfoHeader";
import { ImageBanner, ImageCampaignLogo } from "images";
import MembershipSection from "./components/MembershipSection";
import RewardCard from "./components/RewardCard";
import AccountDetail from "components/AccountDetails";
import {
  IconCampaigns,
  IconCampaignsUserPortal,
  IconDocument,
  IconGift,
  IconProfile,
  IconSpotify,
} from "images/icons/svg-components";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import { Circle } from "@mui/icons-material";
import CampaignCard from "./components/CampaignCard";
import LeaderboardCard from "./components/LeaderboardCard";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CheckIcon from "@mui/icons-material/Check";
import { IconCheckCircle } from "images/icons/svg-components";
import { formattedDate } from "utils/date";
import { DEFAULT_ACTION } from "./constants";
import Main from "components/UserModal/components/Main";
import UserModal from "components/UserModal";
import ClaimMembershipSnackbar from "./components/ClaimMembershipSnackbar";
import { mainTheme } from "theme/mainTheme";
import API from "utils/userAPI";
import { useSearchParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import qs from "qs";
import { useParams } from "react-router";
import WebsiteIcon from "@mui/icons-material/Language";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YoutubeIcon from "@mui/icons-material/YouTube";
// import { FaDiscord } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { ImageDefaultUser } from "images";
import { useSocket } from "../../utils/socketIO";
import IntegrationItem from "containers/IntegrationsPage/components/IntegrationItem";
import { AiFillInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { SiApplemusic } from "react-icons/si";
import { BsSpotify } from "react-icons/bs";
import { BsTwitch } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsDiscord } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import { FaShopify } from "react-icons/fa";

const UserMembershipPortal = ({ dispatch, userPortalState }) => {
  const params = useParams();
  const { brandId, membershipId } = useParams(); // Access the brandId from the URL parameter
  const [brandIdMain, setbrandIdMain] = useState(brandId);
  const [memberShipIdMain, setmemberShipIdMain] = useState(membershipId);
  console.log(brandIdMain, membershipId, "membershipid");
  const socket = useSocket();
  useInjectReducer({ key: "userPortal", reducer });
  useInjectSaga({ key: "userPortal", saga });
  const [password, setPassword] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const queryParams = queryString.parse(location.search);
  const [saveSongInfo, setSaveSongInfo] = useState();
  const [isSavedSong, setIsSavedSong] = useState(false);
  const [totalRewardForUser, setTotalRewardForUser] = useState(0);
  const [tracksStreamed, setTracksStreamed] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {
    membershipHoldings, // this is only one membership
    rewardData, // this is id of all the rewards
    rewardHoldings, // this is all the rewards users have claimed for all memberships
    membershipData, // this is all  membership with success and
    brand,
    profile,
  } = userPortalState;
  console.log(membershipHoldings, "membershipHoldings");
  const navigate = useNavigate();
  const [showUserModal, setShowUserModal] = useState(false);
  const goProfileSettings = () => {
    navigate("/profile-settings-User");
  };
  const [userId, setUserId] = useState(localStorage.getItem("userId") ?? "");
  const userIdRef = useRef(userId);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [selectedTab, setSelectedTab] = useState("rewards");
  const [selectedReward, setSelectedReward] = useState(null);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [leaderboardData, setLeaderBoradData] = useState(false);
  const [currentUserRank, setCurrentUserRank] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [displayedRewardData, setDisplayedRewardData] = useState(rewardData);
  const [autoRedeemedBadge, setAutoRedeemedBadge] = useState(null);
  const displayedRewardDataRef = useRef(displayedRewardData);
  const membershipDataSummary = membershipData.map((obj, index) => ({
    id: obj._id ?? index + 1,
    name: obj.name,
    image: obj.image,
  }));

  const [brandData, setBrandData] = useState(brand);
  const [brandRewards, setBrandRewards] = useState(rewardData);
  // TODO take membership from router
  console.log(membershipData, "membershipData");
  const [brandMembership, setBrandMembership] = useState(
    membershipData.length === 0
      ? {}
      : membershipData?.find(
          (membership) => membership?._id === memberShipIdMain
        )
  );

  const [membershipHoldingsMain, setmembershipHoldingsMain] = useState(null);

  useEffect(() => {
    if (membershipHoldings) {
      setmembershipHoldingsMain(
        membershipHoldings?.length === 0 || !membershipHoldings?.length
          ? {}
          : membershipHoldings?.find(
              (memberholding) => memberholding?.typeId === memberShipIdMain
            )
      );
    }
  }, [membershipHoldings]);

  const [activeTab, setActiveTab] = useState(
    membershipData.length === 0
      ? { id: "0", name: "All" }
      : { id: memberShipIdMain, name: brandMembership?.name }
  );

  let deviceId = localStorage.getItem("deviceId");

  // If not, generate a new identifier and store it in local storage
  function generateUniqueId() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  }

  if (!deviceId) {
    deviceId = generateUniqueId();
    localStorage.setItem("deviceId", deviceId);
  }

  // console.log('This is the device id:', deviceId);

  useEffect(() => {
    const brandId = brandIdMain === "" ? brand : brandIdMain;

    const init = async () => {
      // if(params.brandSlug)
      // {
      //     const res = await API.get(`/brands/slug/${params.brandSlug}`);

      //     setBrandData(res.data.data.brand)
      // }
      let res = brandId
        ? await API.get(`/landingPage/${brandId}`)
        : await API.get(`/brands/slug/${params.brandSlug}`); // need to get non default memberships
      if (!isEmpty(res.data.data)) {
        if (!isEmpty(res.data.data.brand)) {
          setBrandData(res.data.data.brand);
        }
        if (res.data.data.rewards.length > 0) {
          setBrandRewards(res.data.data.rewards);
        }
        const nonDefaultMemberships = res.data.data.memberships.filter(
          // this filters out the default membership
          (a) => a.transactionStatus === "SUCCESS"
        );

        if (nonDefaultMemberships.length > 0) {
          setBrandMembership(
            nonDefaultMemberships[nonDefaultMemberships.length - 1]
          );
          // if (
          //   !isEmpty(res.data.data.landingPage) &&
          //   nonDefaultMemberships.length > 0
          // ) {
          //   setBrandMembership(
          //     nonDefaultMemberships.filter(
          //       (m) => m._id === nonDefaultMemberships.memberships[0]
          //     )[0]
          //   );
          // } else {
          //   setBrandMembership(nonDefaultMemberships[0]);
          // }
        }
        let leaderboard;
        try {
          leaderboard = await API.get(`/spotify/leaderBoard/${brandId}`);
        } catch (err) {
          console.error(err);
        }
        if (!isEmpty(leaderboard?.data)) {
          setLeaderBoradData(leaderboard?.data);
        }
      }
      // res = await API.get(
      //   `/spotify/get_brand_spotify_save_song?brandId=${brandId}`
      // );
      // // console.log("get_brand_spotify_save_song", res.data.data);
      // setSaveSongInfo(res.data.data);
      // if (userId && res.data.data) {
      //   res = await API.get(
      //     `/spotify/check_user_saved_song?userId=${userId}&trackId=${res.data.data.trackId}`
      //   );
      //   // console.log("check_user_saved_song", res.data.data);
      //   setIsSavedSong(res.data.data);
      // }
    };
    init();
  }, []);

  useEffect(() => {
    if (userId != "") {
      dispatch(fetchUserRewardHoldings(brandIdMain));
      dispatch(fetchUserMembershipHoldings(brandIdMain));
      dispatch(fetchUserProfile(memberShipIdMain));
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setIsLoading(false);
    }
  }, [memberShipIdMain]);
  useEffect(() => {
    const init = async () => {
      try {
        if (userId !== "" && brandId) {
          const userRank = await API.get(`/spotify/currentUserRank/${brandId}`);
          if (!isEmpty(userRank.data)) {
            setCurrentUserRank(userRank.data);
          }
        } else {
          setCurrentUserRank(false);
        }
      } catch (e) {
        console.log(e);
      }
    };
    init();
  }, [userId]);

  useEffect(() => {
    if (userId !== "") {
      const init = async () => {
        const res = await API.get(
          `/spotify/get_brand_spotify_save_song?brandId=${brandIdMain}`
        );
        console.log("get_brand_spotify_save_song", res);
        setSaveSongInfo(res.data?.data);
        if (res.data?.data?.trackId) {
          const resp = await API.get(
            `/spotify/check_user_saved_song?userId=${userId}&trackId=${res.data?.data?.trackId}`
          );
          // console.log("check_user_saved_song", res.data.data);
          setIsSavedSong(resp.data?.data);
        }
      };
      try {
        init();
      } catch (e) {
        console.log(e);
      }
      dispatch(fetchUserRewardHoldings(brandIdMain));
      dispatch(fetchUserMembershipHoldings(brandIdMain));
      dispatch(fetchUserProfile(memberShipIdMain));
      userIdRef.current = userId;

      const thisUserId = localStorage.getItem("userId");
      const trackId = "3JsH3qnwhYGs20PBMsCgNx";
      dispatch(checkDeviceStreamedSong(deviceId, trackId, undefined)).then(
        (result) => {
          // device has listened to song
          if (result.data.data.length !== 0) {
            // user has streamed song
            setTracksStreamed((oldTracks) => [...oldTracks, trackId]);
          }
          // dispatch(inputUserIdStreamedSong(thisUserId, trackId)); uncomment if we want to tie to userId
        }
      );
    }
  }, [userId, isRedeeming, memberShipIdMain]);

  // const formattedData = () => {
  //   return { ...profile, rewardHoldings: rewardHoldings };
  // };

  useEffect(() => {
    let rewardsToBeDisplayed = brandRewards;
    console.log(brandRewards, "brandrewards");
    // if (activeTab.id === "0") {
    //   rewardsToBeDisplayed = brandRewards;
    // } else {
    //   rewardsToBeDisplayed = brandRewards.filter((r) => r.membershipId === activeTab.id);
    // }
    rewardsToBeDisplayed = brandRewards.filter(
      (reward) => reward.membershipId === memberShipIdMain
    );
    // const filteredData = rewardsToBeDisplayed.filter(
    //   (r) =>
    //     r.name.toLowerCase().includes(searchTerm) ||
    //     r.category.toLowerCase().includes(searchTerm)
    // );
    console.log(rewardsToBeDisplayed, "filteredData");
    // TODO reward here filtered my membership id
    setDisplayedRewardData(rewardsToBeDisplayed);
    return () => {};
  }, [activeTab, searchTerm, brandRewards]);

  useEffect(() => {
    let totalRewardForUserVar = 0;
    displayedRewardDataRef.current = displayedRewardData;
    // TODO basically here rewards are set redeemable according
    // to membership that is picked for redeeming
    // we have to remove this i guess or need to match this as we will
    // show rewards of only one membership

    // membership condition will be there with each mapping or find
    displayedRewardData.map(
      ({ _id, membershipId, membershipNeeded }, index) => {
        const currentReward = rewardHoldings.find(
          (reward) => reward?.typeId === _id
        );

        let isRedeemedYet = false;
        if (currentReward) {
          isRedeemedYet = currentReward.isRedeemed;
        }
        let isRedeemableYet =
          (membershipId === membershipHoldingsMain?.typeId ||
            membershipNeeded == false) &&
          tracksStreamed.length !== 0;
        if (userId === "") {
          isRedeemableYet = false;
        }

        if (isRedeemableYet === true || isRedeemableYet === true) {
          totalRewardForUserVar++;
        }
      }
    );
    setTotalRewardForUser(totalRewardForUserVar);
  }, [displayedRewardData, userPortalState]);

  useEffect(() => {
    const handleApprovalStatus = (status) => {
      autoRedeemBadge();
    };

    // Listen for 'approvalStatus' event
    socket.on("approvalStatus", handleApprovalStatus);

    // Cleanup listener when component unmounts
    return () => {
      socket.off("approvalStatus", handleApprovalStatus);
    };
  }, [socket]);

  function renderRewardCards() {
    // TODO : can be filtered by member id
    const rewardCards = displayedRewardData.map(
      (
        {
          name,
          startDate,
          endDate,
          startTime,
          endTime,
          timezone,
          description,
          image,
          redemptionCount,
          isDraft,
          category,
          _id,
          membershipTier,
          membershipId,
          membershipNeeded,
        },
        index
      ) => {
        // TODO membership condition
        const currentReward = rewardHoldings.find(
          (reward) => reward?.typeId === _id
        );

        let isRedeemedYet = false;
        if (currentReward) {
          isRedeemedYet = currentReward.isRedeemed;
        }
        const today = new Date();
        const start = new Date(startDate);
        const end = new Date(endDate);
        today >= start && today <= end;
        let isRedeemableYet = today >= start && today <= end;
        //   (membershipId === membershipHoldings.typeId ||
        //     membershipNeeded == false) &&
        //   tracksStreamed.length !== 0;
        // if (userId === "") {
        //   isRedeemableYet = false;
        // }
        // console.log(isRedeemableYet, isRedeemedYet);
        return (
          <RewardCard
            {...{
              name,
              startDate,
              endDate,
              startTime,
              endTime,
              timezone,
              description,
              image,
              redemptionCount,
              isDraft,
              category,
              id: _id,
              membershipTier,
              membershipId,
              isRedeemable: isRedeemableYet, // This is for if the user hasn't claimed the membership yet.
              isRedeemed: isRedeemedYet,
              membershipNeeded,
            }}
            key={_id}
            defaultMemberships={[brandMembership]}
            isUser={true}
            setSelectedReward={setSelectedReward}
          />
        );
      }
    );
    return rewardCards;
  }

  // Rerendering Cards to check for redeemed whenever screen goes back to this screen
  useEffect(() => {
    // console.log("Updating reward holdings...");
    if (userId !== "") {
      dispatch(fetchUserRewardHoldings(brandIdMain));
    }
  }, [selectedReward]);

  const handleSearchChange = (search) => {
    setSearchTerm(search);
  };
  // commented tabs out for Gorgon Launch
  const tabs = [
    { name: "rewards", icon: IconGift, tab: "rewards" },
    // { name: "campaigns", icon: IconCampaignsUserPortal, tab: "campaigns" },
    // { name: "leaderboard", icon: EmojiEventsOutlinedIcon, tab: "leaderboard" },
    { name: "integrations", icon: IconDocument, tab: "integrations" },
  ];

  const renderTabs = () => {
    return tabs.map((tab, idx) => {
      const Icon = tab.icon;

      return (
        <div
          key={idx}
          className={`tab ${selectedTab === tab.name ? "active" : ""}`}
          onClick={() => {
            setSelectedReward(null);
            setSelectedTab(tab.name);
          }}
        >
          {tab.name === "rewards" ? (
            <Icon style={{ width: "28px", height: "28px" }} />
          ) : (
            <Icon fontSize="large" />
          )}
        </div>
      );
    });
  };

  function autoRedeemBadge() {
    const urlParams = new URLSearchParams(window.location.search);
    const rewardId = urlParams.get("rewardId");
    const reward = displayedRewardDataRef.current.find(
      (reward) => reward._id === rewardId
    );
    if (!reward) {
      return;
    }
    // TODO basically here rewards are set redeemable according
    // to membership that is picked for redeeming
    // we have to remove this i guess or need to match this as we will
    // show rewards of only one membership
    const requiredMembershipHeld =
      !reward.membershipNeeded ||
      reward.membershipId === membershipHoldingsMain?.typeId;
    // TODO : membership condition here
    const rewardNotRedeemed =
      !rewardHoldings ||
      !rewardHoldings.find(
        (holding) => holding?.typeId === reward._id && holding.isRedeemed
      );

    if (
      rewardId &&
      reward &&
      reward.category === "Badge" &&
      requiredMembershipHeld &&
      rewardNotRedeemed
    ) {
      dispatch(redeemReward(rewardId, userIdRef.current));
      setAutoRedeemedBadge(reward);
      setShowRedeemModal(true);
    }
  }

  function renderDates() {
    const today = new Date();
    const _endDate = new Date(selectedReward.endDate);
    const timeDiff = _endDate.getTime() - today.getTime();
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    return (
      <DateContent style={{ display: "flex", gap: "20px" }}>
        {selectedReward.endDate && (
          <span style={{ color: "#7F86AD" }}>
            Ends on{" "}
            <span style={{ color: "white" }}>
              {formattedDate(selectedReward.endDate)}
            </span>
          </span>
        )}
        {_endDate > today && (
          <span style={{ color: "#7F86AD" }}>
            Expires in <span style={{ color: "white" }}>{daysLeft} days</span>
          </span>
        )}
      </DateContent>
    );
  }

  const onRedeem = (rewardId, userId) => {
    // rewardId: "", userId: "" if i want to hardcode
    API.post("users/redeem", { rewardId, userId })
      .then((result) => {
        setShowRedeemModal(true);
        setIsRedeeming(false);
      })
      .catch((err) => {
        console.log(err);
        setIsRedeeming(false);
      });
  };

  const handleLogout = async () => {
    localStorage.removeItem("userTokens");
    localStorage.removeItem("userId");
    setUserId("");
    await API.post("/auth/logout");
  };

  const handleUserLogin = async (
    email,
    membershipId,
    membership,
    brandId,
    userId
  ) => {
    setUserId(localStorage.getItem("userId"));
    // searchParams.set("userId", userId);
    // setSearchParams(searchParams, { replace: true });
  };

  return isLoading ? (
    <CircularProgress />
  ) : (
    <Wrapper>
      <ClaimMembershipSnackbar
        dispatch={dispatch}
        userPortalState={userPortalState}
        brandName={brandData?.name}
      />
      <UserModal
        showModal={showUserModal}
        setShowModal={setShowUserModal}
        onLogin={handleUserLogin}
        selectedMembership={brandMembership}
      />
      <Modal
        open={showRedeemModal}
        onClose={() => setShowRedeemModal(false)}
        closeAfterTransition
        disableAutoFocus={true}
      >
        <ModalContentWrapper style={{ maxWidth: "620px" }}>
          <div className="modal-header">
            <span className="title">
              Congrats! Your benefit was successfully redeemed
            </span>
            <span className="subtitle">
              We sent you the benefit details to your email{" "}
              <span
                className="subtitle"
                style={{ color: mainTheme.palette.lightBlue }}
              >
                {profile?.user?.email}
              </span>
            </span>
          </div>
          <div className="benefit">
            <div className="benefit-item">
              <span className="benefit-title">Benefit:</span>
              <span style={{ marginLeft: "10px" }}>
                {selectedReward?.name || autoRedeemedBadge?.name}
              </span>
            </div>
          </div>
          <RedeemBenefitButton
            onClick={() => {
              setShowRedeemModal(false);
              setSelectedReward(null);
            }}
          >
            Back to My Rewards
          </RedeemBenefitButton>
        </ModalContentWrapper>
      </Modal>

      <LeftMenuWrapper>
        <div className="wrapper">
          <img src={ImageCampaignLogo} style={{ width: "167px" }}></img>
          <div className="tabs-wrapper">{renderTabs()}</div>
        </div>
        <ProfileMenuButton onClick={goProfileSettings}>
          <img src={ImageDefaultUser} />
        </ProfileMenuButton>

        {userId !== "" ? (
          <div className="logout-wrapper">
            <span className="" onClick={() => handleLogout()}>
              Log Out
            </span>
          </div>
        ) : (
          ""
        )}
      </LeftMenuWrapper>

      <MainContentWrapper>
        {selectedReward ? ( // if have selected reward
          <RedeemMenuWrapper>
            <div
              className="wrapper-header"
              onClick={() => setSelectedReward(null)}
            >
              <ArrowBackIosIcon style={{ height: "18px", width: "18px" }} />
              My Benefits
            </div>
            <ContentWrapper>
              <img
                src={selectedReward.image}
                style={{
                  borderRadius: "20px",
                  maxWidth: "536px",
                  width: "100%",
                }}
              ></img>
              <RedeemMenuContentWrapper>
                <div className="content-header">
                  <span className="subtitle">{selectedReward.category}</span>
                  <span className="title">{selectedReward.name}</span>
                </div>
                <div className="desc-wrapper">
                  <span className="desc-header">
                    Description of the benefit
                  </span>
                  <span className="desc-item">
                    {selectedReward.description}
                  </span>
                </div>
                <div className="need-wrapper">
                  <span className="need-header">
                    What you need to redeem this benefit
                  </span>

                  {(() => {
                    const selectedRewardData = displayedRewardData.find(
                      (o) => selectedReward && o.name === selectedReward.name
                    );
                    const membershipNeeded =
                      selectedRewardData?.membershipNeeded === true ||
                      selectedRewardData?.membershipNeeded === undefined;
                    const membershipHeld =
                      selectedRewardData?.membershipId ===
                      membershipHoldingsMain?.typeId;
                    const membershipForReward =
                      userPortalState.membershipData.find(
                        (o) => o._id === selectedRewardData.membershipId
                      );

                    const songStreamed = tracksStreamed.length !== 0;

                    if (songStreamed) {
                      // if user streamed song
                      if (membershipNeeded && !membershipHeld) {
                        return (
                          <div>
                            <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CloseIcon fontSize="small" />
                              Holding{" "}
                              {membershipForReward?.name?.match(/^[aeiou]/i)
                                ? "an"
                                : "a"}{" "}
                              {membershipForReward?.name} membership
                            </div>
                            {/* <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CheckIcon fontSize="small" />
                              Stream {`Gorgon City's Voodoo`}
                            </div> */}
                          </div>
                        );
                      } else if (membershipNeeded && membershipHeld) {
                        return (
                          <div>
                            <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CheckIcon fontSize="small" />
                              Holding{" "}
                              {membershipForReward?.name?.match(/^[aeiou]/i)
                                ? "an"
                                : "a"}{" "}
                              {membershipForReward?.name} membership
                            </div>
                            {/* <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CheckIcon fontSize="small" />
                              Stream {`Gorgon City's Voodoo`}
                            </div> */}
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CheckIcon fontSize="small" />
                              No Membership Needed
                            </div>
                            {/* <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CheckIcon fontSize="small" />
                              Stream {`Gorgon City's Voodoo`}
                            </div> */}
                          </div>
                        );
                      }
                    } else {
                      // if user did not stream song
                      if (membershipNeeded && !membershipHeld) {
                        return (
                          <div>
                            <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CloseIcon fontSize="small" />
                              Holding{" "}
                              {membershipForReward?.name?.match(/^[aeiou]/i)
                                ? "an"
                                : "a"}{" "}
                              {membershipForReward?.name} membership
                            </div>
                            {/* <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CloseIcon fontSize="small" />
                              Stream {`Gorgon City's Voodoo`}
                            </div> */}
                          </div>
                        );
                      } else if (membershipNeeded && membershipHeld) {
                        return (
                          <div>
                            <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CheckIcon fontSize="small" />
                              Holding{" "}
                              {membershipForReward?.name?.match(/^[aeiou]/i)
                                ? "an"
                                : "a"}{" "}
                              {membershipForReward?.name} membership
                            </div>
                            {/* <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CloseIcon fontSize="small" />
                              Stream {`Gorgon City's Voodoo`}
                            </div> */}
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            <div
                              className="need-item"
                              style={{ color: "#7F86AD" }}
                            >
                              <CheckIcon fontSize="small" />
                              No Membership Needed
                            </div>
                            {/* <div
                              className="need-item"
                              style={{ color: "#7F86AD", marginBottom: "20px" }}
                            >
                              <CloseIcon fontSize="small" />
                              Stream {`Gorgon City's Voodoo`}
                            </div> */}
                          </div>
                        );
                      }
                    }
                  })()}
                  {/* commented out for Gorgon Launch */}
                  {/* {saveSongInfo &&
                    (isSavedSong ? (
                      <div className="need-item">
                        <CheckIcon fontSize="small" />
                        Save {saveSongInfo.trackName} on Spotify
                      </div>
                    ) : (
                      <div className="need-item disabled">
                        <CloseIcon fontSize="small" color="red" />
                        Save {saveSongInfo.trackName} on Spotify
                      </div>
                    ))} */}
                </div>
                <div style={{ display: "flex" }}>{renderDates()}</div>

                {/* {TODO: membership condition with reward holdings} */}
                {(() => {
                  const rewardRedeemed = userPortalState.rewardHoldings.find(
                    (o) => o.isRedeemed && o?.typeId === selectedReward.id
                  );
                  const selectedRewardData = displayedRewardData.find(
                    (o) => selectedReward && o?.name === selectedReward.name
                  );
                  const membershipNeeded =
                    selectedRewardData?.membershipNeeded === true ||
                    selectedRewardData?.membershipNeeded === undefined;
                  const membershipHeld =
                    selectedRewardData?.membershipId ===
                    membershipHoldingsMain?.typeId;

                  if (JSON.stringify(userPortalState.profile) === "{}") {
                    return (
                      <SignInButton
                        onClick={() => {
                          setSelectedReward(null);
                          setShowUserModal(true);
                        }}
                      >
                        Sign in with Email
                      </SignInButton>
                    );
                  } else if (rewardRedeemed) {
                    // if reward has been redeemed renders disabled redeemed button
                    return (
                      <RedeemBenefitButton
                        disabled={true}
                        style={{
                          "pointer-events": "none",
                          "user-select": "none",
                        }}
                      >
                        {"Benefit Redeemed"}
                      </RedeemBenefitButton>
                    );
                  } else if (
                    membershipNeeded &&
                    !membershipHeld
                    // (saveSongInfo && !isSavedSong)
                    // commented out for Gorgon Launch
                  ) {
                    // if user does not meet the requirements renders disabled requirements not met button
                    return (
                      <RedeemBenefitButton
                        disabled={true}
                        style={{
                          "pointer-events": "none",
                          "user-select": "none",
                        }}
                      >
                        {"Requirements not met"}
                      </RedeemBenefitButton>
                    );
                  } else {
                    // if user meets requirement, returns redeem benefit button
                    return (
                      <RedeemBenefitButton
                        onClick={() => {
                          setIsRedeeming(true);
                          onRedeem(selectedReward.id, userId);
                        }}
                      >
                        {isRedeeming ? (
                          <CircularProgress size={28} />
                        ) : (
                          "Redeem Benefit"
                        )}
                      </RedeemBenefitButton>
                    );
                  }
                })()}
              </RedeemMenuContentWrapper>
            </ContentWrapper>
          </RedeemMenuWrapper>
        ) : (
          // if have not selected reward
          <>
            <img
              src={ImageBanner}
              style={{
                marginBottom: "28px",
                height: "120px",
                width: "100%",
                objectFit: "cover",
                borderRadius: "12px",
              }}
            ></img>
            <ContentWrapper>
              <LeftContent>
                <UserProfile>{brandData?.name}</UserProfile>
                {brandData?.name === "Gorgon City" && (
                  <div>
                    <SocialLink
                      href="https://gorgoncity.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <WebsiteIcon color="white" />
                    </SocialLink>
                    <SocialLink
                      href="https://twitter.com/GorgonCity?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TwitterIcon />
                    </SocialLink>
                    <SocialLink
                      href="https://www.instagram.com/gorgoncity/?hl=en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <InstagramIcon />
                    </SocialLink>
                    <SocialLink
                      href="https://www.youtube.com/channel/UCxD2gCy3e0EUWN5EylcBGMA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <YoutubeIcon />
                    </SocialLink>
                    <SocialLink
                      href="https://www.discord.com/channel/UCxD2gCy3e0EUWN5EylcBGMA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {/* <FaDiscord /> */}
                    </SocialLink>
                  </div>
                )}
                {selectedTab === "rewards" && (
                  <RewardPageWrapper>
                    <HeaderWrapper>My Benefits</HeaderWrapper>
                    <RewardCardsWrapper>
                      {renderRewardCards()}
                    </RewardCardsWrapper>
                  </RewardPageWrapper>
                )}
                {selectedTab === "integrations" && (
                  <RewardPageWrapper>
                    <HeaderWrapper>Integrations</HeaderWrapper>
                    <IntegrationsDisp>
                      <ButtonColumn>
                        <IntegrationItem
                          icon={<AiFillInstagram />}
                          itemText="Instagram"
                          buttonText="Connect"
                        />
                        <IntegrationItem
                          icon={<AiOutlineTwitter />}
                          itemText="Twitter"
                          buttonText="Connect"
                        />
                        <IntegrationItem
                          icon={<SiApplemusic />}
                          itemText="Apple Music"
                          buttonText="Connect"
                        />
                        <IntegrationItem
                          icon={<BsSpotify />}
                          itemText="Spotify"
                          buttonText="Connect"
                        />
                        {/* <IntegrationItem icon={<BsTwitch/>} itemText="Twitch" buttonText="Connect" /> */}
                      </ButtonColumn>
                      <ButtonColumn>
                        <IntegrationItem
                          icon={<BsYoutube />}
                          itemText="YouTube"
                          buttonText="Connect"
                        />
                        <IntegrationItem
                          icon={<BsDiscord />}
                          itemText="Discord"
                          buttonText={
                            userPortalState.profile.user?.discordAuth
                              ? "Connected"
                              : "Connect"
                          }
                          user={userPortalState.profile.user}
                        />
                        <IntegrationItem
                          icon={<FaTiktok />}
                          itemText="Tiktok"
                          buttonText="Connect"
                        />
                        {/* <IntegrationItem icon={<BsGlobe/>} itemText="Website" buttonText="Enter Details" />
                        <IntegrationItem icon={<FaShopify/>} itemText="Shopify" buttonText="Enter Details" /> */}
                      </ButtonColumn>
                    </IntegrationsDisp>
                  </RewardPageWrapper>
                )}
                {selectedTab === "campaigns" && (
                  <RewardPageWrapper>
                    <HeaderWrapper>Campaigns</HeaderWrapper>
                    <CampaignCard />
                    <CampaignCard />
                    <CampaignCard />
                  </RewardPageWrapper>
                )}
                {selectedTab === "leaderboard" && (
                  <RewardPageWrapper>
                    <MyRankWrapper>
                      <HeaderWrapper>My Rank</HeaderWrapper>
                      <LeaderboardCard
                        leader={currentUserRank}
                        index={currentUserRank.index}
                        setShowUserModal={setShowUserModal}
                      />
                    </MyRankWrapper>
                    <HeaderWrapper style={{ marginTop: "16px" }}>
                      Top 50 Leaderboard
                    </HeaderWrapper>
                    <LeaderboardCardsWrapper>
                      {leaderboardData &&
                        leaderboardData.map((leader, index) => {
                          return (
                            <LeaderboardCard
                              leader={leader}
                              index={index + 1}
                              setShowUserModal={setShowUserModal}
                            />
                          );
                        })}
                    </LeaderboardCardsWrapper>
                  </RewardPageWrapper>
                )}
              </LeftContent>
              {selectedTab === "rewards" && (
                <RightContent>
                  {/* userPortalState?.profile?.user?.spotifyId === undefined ||
                    userPortalState?.profile?.user?.spotifyId ===
                      "undefined" ? (
                      <MembershipWrapper>
                        <MembershipBox brandMembership={brandMembership} />
                        {userPortalState?.profile?.user?.email !== undefined ||
                        userPortalState?.profile?.user?.email !== "" ? (
                          <AuthenticateSpotify
                            email={userPortalState?.profile?.user?.email}
                          />
                        ) : (
                          ""
                        )}
                      </MembershipWrapper>
                    ) :  */}
                  {userId !== "" ? (
                    membershipHoldingsMain &&
                    Object.keys(membershipHoldingsMain).length === 0 ? (
                      <MembershipWrapper>
                        <MembershipBox brandMembership={brandMembership} />
                        <ClaimMembershipButton
                          memberShipIdMain={memberShipIdMain}
                          dispatch={dispatch}
                        />
                      </MembershipWrapper>
                    ) : (
                      <>
                        <div className="header">My Profile</div>
                        {console.log(userPortalState?.profile)}
                        <AccountDetail
                          data={userPortalState?.profile}
                          totalReward={displayedRewardData?.length}
                          totalRewardForUser={totalRewardForUser}
                          userId={userId}
                        />
                      </>
                    )
                  ) : (
                    <SignInWrapper>
                      <img
                        src={ImageCampaignLogo}
                        height="32px"
                        width="192px"
                      ></img>
                      <div className="content-wrapper">
                        <div className="upper-content">
                          <div className="title">Sign In To Your Account</div>
                          <img
                            src={brandMembership?.image}
                            style={{
                              borderRadius: "12px",
                              height: "307px",
                              width: "307px",
                            }}
                          />
                          <div className="subtitle-wrapper">
                            <div className="subtitle1">
                              {brandMembership?.name}
                            </div>
                            <div className="subtitle1">
                              {brandMembership?.purchaseType}
                            </div>
                            <div className="subtitle2">
                              Sign in with email to join {brandData?.name}
                              {"'"}s membership program
                            </div>
                          </div>
                        </div>
                        <div className="lower-content">
                          <SignInButton
                            onClick={() =>
                              navigate("/login", {
                                state: {
                                  brandId: brandIdMain,
                                  memberShipId: memberShipIdMain,
                                },
                              })
                            }
                          >
                            Get Started
                          </SignInButton>
                        </div>
                      </div>
                    </SignInWrapper>
                  )}
                </RightContent>
              )}
            </ContentWrapper>
          </>
        )}
      </MainContentWrapper>
    </Wrapper>
  );
};

const AuthenticateSpotify = ({ email }) => {
  const queryParams = queryString.parse(location.search);
  const brandId = brandIdMain;
  console.log("AuthenticateSpotify", email);
  return (
    <Box>
      <SubheaderText>
        Finish authenticating your Spotify account to claim the membership
      </SubheaderText>
      <a
        href={
          "https://accounts.spotify.com/authorize?" +
          qs.stringify({
            response_type: "code",
            client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
            scope:
              "user-read-private user-read-email user-top-read playlist-read-private user-library-read user-read-recently-played",
            redirect_uri: `${process.env.REACT_APP_API_URL}/api/v1/spotify/callback`,
            state: JSON.stringify({ brandId, email }),
          })
        }
        target="_self"
        rel="noreferrer"
      >
        <SpotifyButton variant="contained" disableRipple>
          <IconSpotify />
          Authenticate Spotify
        </SpotifyButton>
      </a>
    </Box>
  );
};

const ClaimMembershipButton = ({ memberShipIdMain_local, dispatch }) => {
  const [loading, setLoading] = useState(false);
  const queryParams = queryString.parse(location.search);

  const handleMembershipRedeem = async () => {
    // redeeming membership
    try {
      setLoading(true);
      const res = await API.post("/users/mint", {
        membershipId: memberShipIdMain_local,
      });
      await API.post("/users/membership", {
        membershipId: memberShipIdMain_local,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      localStorage.removeItem("isClaimMemberShipLogin");
      if (error.status === 401) {
        // setError("User not authorized. Please login to continue");
      } else {
        // setError(error?.response?.data?.message);
      }
      setLoading(false);
      return;
    }
    dispatch(fetchUserRewardHoldings(brandIdMain));
    dispatch(fetchUserMembershipHoldings(brandIdMain, true));
    dispatch(fetchUserProfile(memberShipIdMain));

    autoRedeemBadge(); // auto redeem badge associated with this membership
  };
  return (
    <Box>
      <ClaimButton onClick={() => handleMembershipRedeem()}>
        {loading ? <CircularProgress /> : "Claim Membership"}
      </ClaimButton>
    </Box>
  );
};

const MembershipBox = ({ brandMembership }) => {
  return (
    <Box>
      <HeaderWrapper>
        <Header>Membership</Header>
      </HeaderWrapper>
      <div className="account">
        <div className="membership">
          <img
            style={{
              height: "80px",
              width: "80px",
              borderRadius: "8px",
              opacity: "80%",
            }}
            src={brandMembership.image}
          ></img>
          <div className="text-wrapper">
            <div className="header2">{brandMembership.name}</div>
          </div>
        </div>
      </div>
    </Box>
  );
};

const FormField = styledMui(InputBase)({});

const IntegrationsDisp = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SubheaderText = styled.span`
  text-align: center;
  ${(props) => props.theme.typography.header4};
  color: ${(props) => props.theme.palette.lightGrey};
  font-weight: 4000;
  font-size: 16px;
  line-height: 28px;
`;

const ProfileMenuButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.lightGrey,
  marginTop: "400px",
  ":hover": {
    color: theme.palette.lightBlue,
  },
}));

const SpotifyButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.black,
  textTransform: "none",
  backgroundColor: "#0BD680",
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "18px 20px",
  gap: "20px",
  height: "60px",
  fontWeight: 500,
  fontSize: "18px",

  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .recentActivity {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.white};
    }
    .date {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};
    }
  }

  .wallet-wrapper {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    border-radius: 6px;
    background-color: ${(props) => props.theme.palette.darkerGrey};

    .walletAddress {
      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.white};
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 296px;
    }
  }

  .stats {
    display: flex;
    justify-content: space-around;

    .stat-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .header {
        ${(props) => props.theme.typography.header}
        color: ${(props) => props.theme.palette.white};
      }

      .bodyS {
        ${(props) => props.theme.typography.bodyS}
        color: ${(props) => props.theme.palette.lightGrey};
      }
    }
  }

  .account {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .content {
      display: flex;
      align-items: center;
      gap: 20px;

      .bodyM {
        ${(props) => props.theme.typography.bodyM}
        color: ${(props) => props.theme.palette.white};
      }
    }
  }

  .membership {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 24px;

    border: 1px solid #6096ff;
    border-radius: 6px;

    .text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .header2 {
        ${(props) => props.theme.typography.header2}
        color: ${(props) => props.theme.palette.white};
      }
      .bodyS {
        ${(props) => props.theme.typography.bodyS}
        color: ${(props) => props.theme.palette.white};
      }
    }
  }
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};

  .icon {
    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

const formFieldOverrides = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#F4F3EE",
  width: "100%",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

const SignInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    .upper-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .title {
        text-align: center;
        color: ${(props) => props.theme.palette.white};
        ${(props) => props.theme.typography.header2};
      }

      .subtitle-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        .subtitle1 {
          text-align: center;
          color: ${(props) => props.theme.palette.white};
          ${(props) => props.theme.typography.header2};
        }
        .subtitle2 {
          text-align: center;
          color: ${(props) => props.theme.palette.lightGrey};
          ${(props) => props.theme.typography.bodyM};
        }
      }
    }

    .lower-content {
      display: flex;
      width: 100%;
    }
  }
`;

const SignInButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyMBold,
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
  textTransform: "none",
  padding: "14px 0",
  width: "100%",

  "&:hover": {
    backgroundColor: theme.palette.white,
    color: theme.palette.campaignBlue,
  },
}));

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 60px;
  gap: 28px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  width: 43%;
  background: ${(props) => props.theme.palette.darkestGrey};

  .modal-header {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      text-align: center;
      color: ${(props) => props.theme.palette.white};
      ${(props) => props.theme.typography.header1};
    }
    .subtitle {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
      text-align: center;
      color: ${(props) => props.theme.palette.lightGrey};
    }
  }

  .benefit {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .benefit-title {
      text-align: center;
      color: ${(props) => props.theme.palette.white};
      ${(props) => props.theme.typography.header2};
    }
    .benefit-item {
      display: flex;
      align-items: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;

      text-align: center;
      color: ${(props) => props.theme.palette.lightBlue};
      padding: 20px;
      background: ${(props) => props.theme.palette.darkerGrey};
      border: 1px solid;
      border-color: ${(props) => props.theme.palette.darkGrey};
      border-radius: 6px;
      width: 100%;
    }
  }
`;

const RedeemBenefitButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "18px 20px",
  height: "60px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const DateContent = styled.div`
  ${(props) => props.theme.typography.subHeader};
`;

const RedeemMenuContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 40px;

  .content-header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      ${(props) => props.theme.typography.header};
      color: ${(props) => props.theme.palette.white};
    }

    .subtitle {
      ${(props) => props.theme.typography.header2};
      color: ${(props) => props.theme.palette.lightBlue};
    }
  }
  .desc-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .desc-header {
      ${(props) => props.theme.typography.subHeader};
      color: ${(props) => props.theme.palette.white};
    }
    .desc-item {
      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.lightGrey};
    }
  }
  .need-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .need-header {
      ${(props) => props.theme.typography.subHeader};
      color: ${(props) => props.theme.palette.white};
    }
    .need-item {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      gap: 12px;
      background: ${(props) => props.theme.palette.darkerGrey};
      border-radius: 6px;

      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.lightBlue};
      &.disabled {
        color: ${(props) => props.theme.palette.lightGrey};
      }
    }
  }
`;

const RedeemMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 65px;

  .wrapper-header {
    display: flex;
    gap: 12px;
    align-items: center;
    width: fit-content;
    cursor: pointer;

    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.lightBlue};

    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

const LeaderboardCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MembershipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MyRankWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const UserProfile = styled.div`
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px;
  overflow-y: scroll;
  padding-bottom: 20px;
`;

const LeftMenuWrapper = styled.div`
  position: sticky;
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 207px;

  padding: 40px 20px;
  justify-content: space-between;
  border-right: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};

  .logout-wrapper {
    :hover {
      cursor: pointer;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    span {
      color: ${(props) => props.theme.palette.lightGrey};
      ${(props) => props.theme.typography.header3};
      cursor: pointer;
      :hover {
        color: ${(props) => props.theme.palette.white};
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;

    .tabs-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      .tab {
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 6px;
        height: 80px;
        width: 80px;
        color: ${(props) => props.theme.palette.lightBlue};
        cursor: pointer;

        :hover {
          color: ${(props) => props.theme.palette.white};
        }

        &.active {
          background-color: ${(props) => props.theme.palette.darkGrey};
          color: ${(props) => props.theme.palette.white};
        }
      }
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 32px;
  width: 100%;
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 371px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
  border-radius: 12px;
  padding: 40px 32px;

  .header {
    color: ${(props) => props.theme.palette.lightGrey};
    ${(props) => props.theme.typography.header2};
    margin-bottom: 48px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.palette.black};
`;

const HeaderWrapper = styled.div`
  display: flex;
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.header2};
`;

const RewardPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

const RewardCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const ClaimButton = styledMui(Button)({
  borderRadius: "10px",
  fontSize: "16px",
  lineHeight: "36px",
  textTransform: "none",
  backgroundColor: "#294BFC",
  color: "#fff",
  paddingLeft: "40px",
  paddingRight: "40px",
  transition: "all .3s ease",

  "&:hover": {
    backgroundColor: "#7f86ad",
  },
});

const SocialLink = styled.a`
  margin-right: 12px;
  color: white;
`;

const mapStateToProps = createStructuredSelector({
  userPortalState: makeSelectUserPortal(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(UserMembershipPortal);
