import React from "react";
import styled from "styled-components";

import { ButtonBase } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import { ImageUserProfileMock } from "images";
import { formattedDate } from "utils/date";
import {IconCrown} from "images/icons/svg-components";
import CampaignCard from "containers/CampaignsPage/components/CampaignCard";
import { mainTheme } from "theme/mainTheme";
import { useDispatch } from "react-redux";

const CampaignReview = ({ formValues, actionInputFields, leaderboardInputFields, brandRewards }) => {
  const { name, description, startDate, endDate, rewards, leaderboard } = formValues;

  const _actions = actionInputFields;
  const _leaderboard = leaderboardInputFields

  const GoldCrown = () => (
    <CrownWrapper style={{ background: "#FFDD55" }}>
      <IconCrown color="black" style={{ width: "18", height: "18" }} />
    </CrownWrapper>
  );
  const SilverCrown = () => (
    <CrownWrapper style={{ background: "#D9D9D9" }}>
      <IconCrown color="black" style={{ width: "18", height: "18" }} />
    </CrownWrapper>
  );
  const BronzeCrown = () => (
    <CrownWrapper style={{ background: "#BE6B1E" }}>
      <IconCrown color="black" style={{ width: "18", height: "18" }} />
    </CrownWrapper>
  );

  const renderActions = () => {
    
  
    if (_actions.length > 0) {
      return _actions?.map((item, idx) => (
        <div className="action-item" key={idx}>
          Listen to &quot;{item?.trackName}&quot;{" "}
          {item?.metric === "" || item?.metric === undefined
            ? item.name === "SpotifyStreamAction"
              ? item?.count
              : item?.minutes
            : item?.metric}{" "}
          {item?.count  ?  item?.count==1?"time":"times" :""}
          {item?.minutes? item?.minutes==1?"minute":"minutes":""}
        </div>
      ));
    } else if (_leaderboard.length > 1) {
      return _leaderboard?.map((item, idx) => {
        let Crown = BronzeCrown; // By default, use the BronzeCrown
        if (idx === 0) {
          Crown = GoldCrown; // For index 0, use the GoldCrown
        } else if (idx === 1) {
          Crown = SilverCrown; // For index 1, use the SilverCrown
        }
        return (
          <div className="action-item" key={idx}>
            <Crown />
            Rank {item?.from} - {item?.to}
          </div>
        );
      });
    } else if (_leaderboard.length === 1) {
      return (
      <div className="action-item">
      <GoldCrown />
      Rank {_leaderboard[0]?.from} - {_leaderboard[0]?.to}
      </div>)
    }
    
  };

  const renderRewards = () => {
    if (_actions.length > 0) {
      return rewards.map((rewardId, idx) => {
        const reward = brandRewards.find(item => item._id === rewardId);

        if (!reward) {
          
          return null;
        }

        return (
          <div className="reward-item" key={idx}>
            {reward.name}
          </div>
        );
      });
  } else if (_leaderboard.length >= 1) {
    return leaderboard.map((item, idx) => {
      const rewardNames = item.rewardId?.map(rewardId => {
          const reward = brandRewards.find(br => br._id === rewardId);
          return reward ? reward.name : null;
        })
        .filter(name => name !== null)
        .join(', ');

      return (
        <div className="reward-item" key={idx}>
          {rewardNames}
        </div>
      );
    });
  }
  };

  return (
    <Wrapper>
      <ReviewWrapper>
        <Header>
          <div className="title">
            {name}
            <span className="date">
              {formattedDate(startDate, 0)} - {formattedDate(endDate, 0)}
            </span>
          </div>

          <div className="subtitle-wrapper">
            <span className="subtitle">{description}</span>
          </div>
        </Header>
        <ContentWrapper>
          <div className="actions">
            <div className="title">Actions Required</div>
            {renderActions()}
          </div>
          <div className="rewards">
            <div className="title">Rewards</div>
            {renderRewards()}
          </div>
        </ContentWrapper>
      </ReviewWrapper>
    </Wrapper>
  );
};
const ContentWrapper = styled.div`
  display: flex;
  gap: 80px;

  .title {
    ${(props) => props.theme.typography.bodyMBold};
    color: ${(props) => props.theme.palette.lightBlue};
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 40%;

    .action-item {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};

      display: flex;
      gap: 16px;
    }
  }

  .rewards {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 40%;

    .reward-item {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.white};

      display: flex;
      justify-content: space-between;
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${(props) => props.theme.typography.header1};
    color: ${(props) => props.theme.palette.white};
  }

  .subtitle-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .subtitle {
    ${(props) => props.theme.typography.bodyS};
    color: ${(props) => props.theme.palette.lightGrey};
  }

  .date {
    ${(props) => props.theme.typography.bodyM};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;
const ReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 32px 40px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

const CrownWrapper = styled.div`
  display: flex;
  width: fit-content;
  padding: 6px;
  border-radius: 4px;
`;

export default CampaignReview;
