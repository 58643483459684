import { Table } from "@mui/material";
import {
  IconInstagram,
  IconSpotify,
  IconTwitter,
} from "images/icons/svg-components";
import React, { Component, PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  Dot,
  Legend,
} from "recharts";
import styled from "styled-components";
import { mainTheme } from "theme/mainTheme";
import { formatSreams } from "../utils";
import { Circle } from "@mui/icons-material";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    // console.log(payload);
    return (
      <ToolTipWrapper>
        <div className="header">
          <h3 className="title">
            {payload[0].payload?.song.name ?? "2023 Summer Tour Splash"}
          </h3>
          <h4 className="subtitle">
            {payload[0].payload?.date ?? "12 Aug 2023 - 25 Aug 2023"}
          </h4>
        </div>
        {/* <div className="requirements">
          {payload[0].payload?.requirements}
          <IconInstagram />
          <IconTwitter />
          <IconSpotify color="#1ED760" />
        </div> */}
        <div className="header">
          {payload[0].payload?.song.name}
          <StreamCount>
            {formatSreams(payload[0].payload?.count)}{" "}
            <span style={{ ...mainTheme.typography.bodyM, color: "#7F86AD" }}>
              streams
            </span>
          </StreamCount>
        </div>
        <div className="total-streams">
          <span>Total Streams</span>
          <span>{formatSreams(payload[0].payload?.song.totalStream)}</span>
        </div>
      </ToolTipWrapper>
    );
  }

  return null;
};

export default class ArtistChart extends Component {
  render() {
    let { chartData, selectedSong } = this.props;

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const month = date.toLocaleString("default", { month: "long" });
      const day = date.getDate();

      return `${day} ${month}`;
    };

    const _chartData = chartData?.map((item) => {
      return { ...item, date: formatDate(item.date), song: selectedSong };
    });

    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={"100%"}
          data={_chartData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
          style={{
            backgroundColor: "#1b212f",
            maxWidth: "270px",
            height: "320px",
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="35%" stopColor="#1f294b" stopOpacity={1} />
              <stop offset="95%" stopColor="#1f2635" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <CartesianGrid vertical={false} horizontal={false} />
          <XAxis dataKey="date" axisLine={false} tickLine={false} />
          <YAxis
            dataKey="count"
            axisLine={false}
            tickLine={false}
            tickMargin={20}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{
              stroke: "#294BFC",
              strokeWidth: 2,
              strokeDasharray: 12,
            }}
          />
          <Area
            type="monotone"
            dataKey="count"
            stroke="#294BFC"
            fill="url(#colorUv)"
            width={"100%"}
            dot={
              <circle
                r="9px"
                fill="#294BFC"
                strokeWidth={"21px"}
                strokeOpacity={"0.2"}
              />
            }
            activeDot={
              <circle
                r="9px"
                fill="#294BFC"
                stroke="#294BFC"
                strokeWidth={"21px"}
                strokeOpacity={"0.2"}
              />
            }
            line
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
const P = styled.p`
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color};
  margin: ${(props) => props.margin};
`;

const ToolTipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #131316;
  padding: 28px;
  border-radius: 12px;
  gap: 20px;

  .total-streams {
    display: flex;
    justify-content: space-between;

    ${(props) => props.theme.typography.bodyS}
    color: ${(props) => props.theme.palette.lightGrey};
  }

  .requirements {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 259px;
    .title {
      ${(props) => props.theme.typography.header3}
      color: ${(props) => props.theme.palette.white};
      margin: 0;
    }
    .subtitle {
      ${(props) => props.theme.typography.bodyS}
      color: ${(props) => props.theme.palette.lightGrey};
      margin: 0;
    }
  }
`;

const StreamCount = styled.div`
  color: ${(props) => props.theme.palette.lightBlue};
  ${(props) => props.theme.typography.header1};
`;
