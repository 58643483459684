import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, FormControlLabel, Checkbox } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { useMutation } from "react-query";
import { validateEmail } from "utils/formatters";
import API from "utils/userAPI";

const SignUp = ({
  formValues,
  onFormValueChanges,
  onSignup,
  onLogin,
  brandId,
  spotifyId
}) => {
  const { password, email } = formValues;
  const [error, setError] = useState("");

  useEffect(() => {
    const inputElement = document.getElementById('passwordField');
    if (inputElement) {
      inputElement.focus();
    }
  }, []);
  const { isLoading: isCreating, mutate: create } = useMutation(
    (data) => API.post(`/auth/user/register`, data),
    {
      onSuccess: () => {
        onSignup();
      },
      onError: async (err) => {
        if (err.response.data.message === "email already exists") {
          const res = await API.post("/auth/user/login", {
            email,
            password,
          });
          localStorage.setItem("userId", res.data.data.userId);
          localStorage.setItem("userTokens", JSON.stringify(res?.data?.data));
          onLogin(email, res?.data?.data?.membershipId);
        } else setError(err.response.data?.message || err.response.data);
      },
    }
  );

  const handleCreate = () => {
    if (!validateEmail(email) || email === "" || email === undefined) {
      setError("Please enter a valid email.");
    } else {
      create({
        email,
        password,
        brandId,
        spotifyId
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log('Enter key pressed!');
      handleCreate(); // Call the same action as the click event
    }
  };

  return (
    <>
      <MainText>Sign In / Sign Up</MainText>
      <FieldWrapper>
        <Label>Password</Label>
        <FormField
          id="passwordField"
          sx={formFieldOverrides}
          placeholder="Password"
          inputProps={{
            "aria-label": "password",
          }}
          fullWidth
          value={password}
          onKeyDown={handleKeyDown}
          onChange={(event) => onFormValueChanges(event, "password")}
          type="password"
          className="mt-4"
        />
      </FieldWrapper>
      {error && <ErrorText>{error}</ErrorText>}
      <LoginButton
        style={{ marginTop: error && 18 }}
        onClick={handleCreate}
        variant="contained"
        disabled={password.length < 8 || isCreating}
      >
        <ButtonText>
          {isCreating ? "Creating Account..." : "Create Account"}
        </ButtonText>
      </LoginButton>
      {error && <Error>{error}</Error>}
    </>
  );
};

const ErrorText = styled.div`
  color: red;
  text-align: center;
`;

const MainText = styled.div`
  display: flex;
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  width: 100%;
  justify-content: center;
`;

const LoginButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "16px 0",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "16px 20px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#F4F3EE",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

const Label = styled(BaseText)`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin-bottom: 8px;
`;

const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
  margin-top: 10px;
  color: red;
`;

const ButtonText = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.white};
`;

const FormField = styledMui(InputBase)({});

export default SignUp;
