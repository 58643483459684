import React, { useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { InputBase, Button, Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import { IconGoogle } from "images/icons/svg-components";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { loginGoogle, loginBrand } from "../../actions";
import { Link, useNavigate } from "react-router-dom";

const MainSection = ({
  formProps,
  onSignupClick,
  onLoginClick,
  onForgotPasswordClick,
  error,
  onVerifyEmailClick,
  handleChange,
  setPage,
  setEmailOnGoogleLogin,
}) => {
  const { email, onEmailChange } = formProps;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onVerifyEmailClick();
    }
  };

  const handleKeyEvent = (e) => {
    if (e.key === "Enter") {
      onVerifyEmailClick();
    }
  };

  const handleGoogleSuccess = async (googleData) => {
    dispatch(
      loginGoogle(googleData.credential, () => {
        console.log("hi");
        navigate("/dashboard");
      })
    );
  };

  const handleGoogleFailure = (response) => {
    console.log(response);
  };

  const GoogleLoginComponent = () => {
    const [showGoogleLogin, setShowGoogleLogin] = React.useState(false);
    const [loginKey, setLoginKey] = React.useState(0); // This is the new state variable

    const handleGoogleLoginClick = () => {
      setShowGoogleLogin(true);
      setLoginKey((prevKey) => prevKey + 1); // Increment the key
    };

    return (
      <>
        <GoogleLoginButton
          variant="outlined"
          startIcon={<IconGoogle />}
          onClick={handleGoogleLoginClick}
        >
          Continue with Google
        </GoogleLoginButton>
        {showGoogleLogin && <GoogleLoginHandler key={loginKey} />}
      </>
    );
  };

  const GoogleLoginHandler = () => {
    const googleLogin = useGoogleOneTapLogin({
      onSuccess: (credentialResponse) => {
        dispatch(
          loginGoogle(credentialResponse.credential, (response) => {
            const e = {
              target: {
                value: response?.data?.data?.email,
              },
            };
            setEmailOnGoogleLogin(e);
            setPage("verified");
            // navigate("/dashboard");
          })
        );
      },
      onError: () => {
        console.log("Login Failed");
      },
    });

    // If there's any other logic or rendering you want to do when the Google login is shown, you can add it here.

    return null; // This component doesn't render anything visually.
  };

  return (
    <ContentWrapper onKeyDown={handleKeyDown}>
      <Title>Let’s get you started!</Title>
      <FieldWrapper>
        <Field>
          <Label>Email</Label>
          <FormField
            sx={formFieldOverrides}
            placeholder="Eg. members@company.com"
            inputProps={{
              "aria-label": "email",
            }}
            fullWidth
            value={email}
            onChange={onEmailChange}
            autoFocus
            onKeyDown={handleKeyEvent}
          />
        </Field>
        <LoginButton variant="contained" onClick={onVerifyEmailClick}>
          Continue
        </LoginButton>
      </FieldWrapper>
      <DividerCustom>or</DividerCustom>
      <GoogleOAuthProvider clientId="658184067902-ot4e5fpaaji4t475vblqqpihhfnjuk7a.apps.googleusercontent.com">
        <GoogleLoginComponent />
      </GoogleOAuthProvider>
      <InfoText>
        By creating an account, you agree to the{" "}
        <Link to="/terms-of-use" style={{ color: "#7F86AD" }}>
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link to="/privacy-policy" style={{ color: "#7F86AD" }}>
          Privacy Policy.
        </Link>
      </InfoText>
    </ContentWrapper>
  );
};

const DividerCustom = styledMui(Divider)(({ theme }) => ({
  "&::before, &::after": {
    height: "1px",
    backgroundColor: theme.palette.darkGrey,
  },
}));

const LoginButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyM,
  fontSize: "20px",
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "16px 0",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const GoogleLoginButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyM,
  fontSize: "20px",
  color: theme.palette.lightGrey,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 0",
  borderColor: theme.palette.darkGrey,

  "& .MuiButton-startIcon": {
    marginRight: "20px",
    marginLeft: 0,
  },

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
    borderColor: theme.palette.darkGrey,
  },
}));

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

const Label = styled(BaseText)`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const FormField = styledMui(InputBase)({});

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 100%;
  gap: 32px;
`;

const InfoText = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
  text-align: center;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: ${(props) => props.theme.palette.white};
`;

const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#F4F3EE",
  padding: "13px 20px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

export default MainSection;
