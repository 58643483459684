import React from "react";
import {
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import queryString from "query-string";
import { Checkbox, FormControlLabel } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { IconExclamation } from "images/icons/svg-components";

function CheckOutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const queryParams = queryString.parse(location.search);
  const brandId = queryParams?.brandId || "";
  const membershipId = queryParams?.membershipId || "";

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payment_id = localStorage.getItem("paymentId");
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${location.protocol}//${location.host}/landingpage?brandId=${brandId}&membershipId=${membershipId}&paymentId=${payment_id}`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" style={{ height: "100%" }} onSubmit={handleSubmit}>
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message">
          <IconExclamation /> Please fill in these fields.
        </div>
      )}
      <LinkAuthenticationElement
        id={
          message
            ? "link-authentication-element"
            : "link-authentication-element-with-margin"
        }
      />

      <PaymentElement id="payment-element" options={paymentElementOptions} />
      {/* commented out this because it auto populates */}
      {/* <CustomFormControlLabel
        control={
          <Checkbox
            disableRipple
            color="default"
            checkedIcon={
              <CheckedBox>
                <CheckIcon fontSize="small" />
              </CheckedBox>
            }
            icon={<CheckBoxIcon />}
          />
        }
        label={
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <Title>Save your information to pay faster with Link</Title>
            <Text>
              Pay faster at localhost:3000 and everywhere Link is accepted.
            </Text>
          </div>
        }
        labelPlacement="end"
      /> */}
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
    </form>
  );
}

const CheckBoxIcon = styledMui("span")(({ theme }) => ({
  display: "flex",
  borderRadius: "4px",
  border: "1px solid",
  alignItems: "center",
  justifyContent: "center",
  borderColor: theme.palette.darkGrey,
  width: 24,
  height: 24,
  background: theme.palette.lightGrey,
  color: "white",
}));

const CheckedBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));

const CustomFormControlLabel = styledMui(FormControlLabel)(({ theme }) => ({
  display: "flex",
  gap: "20px",
  padding: "24px",
  borderRadius: "8px",
  border: "1px solid #352B42",
  alignItems: "flex-start",

  "& .MuiCheckbox-root": {
    padding: 0,
  },
  "&.MuiFormControlLabel-root": {
    margin: 0,
  },
  "& .MuiFormControlLabel-label": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "28px",
    color: theme.palette.lightGrey,
    margin: "0",
  },
}));

const Title = styled.span`
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const Text = styled.span`
  ${(props) => props.theme.typography.bodyS};
  color: ${(props) => props.theme.palette.lightGrey};
`;

export default CheckOutForm;
