import React from "react";
import Layout from "components/Layout";
import styled from "styled-components";
import IntegrationItem from "./components/IntegrationItem";
import { AiFillInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { SiApplemusic } from "react-icons/si";
import { BsSpotify } from "react-icons/bs";
import { BsTwitch } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsDiscord } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import { FaShopify } from "react-icons/fa";
import YoutubeVerified from "components/UserModal/components/YoutubeVerify";
import InstagramVerified from "components/UserModal/components/InstagramVerify";

const IntegrationsPage = ({ userPortalState, dispatch }) => {
  const handleTwitterConnect = () => {
    if (userPortalState?.profile && userPortalState?.brand) {
      window.open(
        `${process.env.REACT_APP_API_URL}/api/v1/auth/twitter?userId=${userPortalState?.profile?.user?._id}&brandId=${userPortalState?.brand?._id}`,
        "_self"
      );
    }
  };
  return (
    <IntegrationsDisp>
      <ButtonColumn>
        {/* <IntegrationItem
          icon={<AiFillInstagram />}
          itemText="Instagram"
          buttonText="Connect"
          user={userPortalState?.profile?.user}
          brand={userPortalState?.brand}
          dispatch={dispatch}
        /> */}
        {/* <IntegrationItem
          icon={<BsSpotify />}
          itemText="Spotify"
          buttonText="Enter Details"
          user={userPortalState?.profile?.user}
          brand={userPortalState?.brand}
          dispatch={dispatch}
        /> */}
        {/* <IntegrationItem
          icon={<AiOutlineTwitter />}
          itemText="Twitter"
          buttonText="Connect"
          onClick={handleTwitterConnect}
          user={userPortalState?.profile?.user}
          brand={userPortalState?.brand}
          dispatch={dispatch}
        /> */}
        {/* <IntegrationItem
          icon={<SiApplemusic />}
          itemText="Apple Music"
          buttonText="Connect"
          user={userPortalState?.profile?.user}
          brand={userPortalState?.brand}
          dispatch={dispatch}
        /> */}

        {/* <InstagramVerified
          formValues={{ email: userPortalState?.profile?.user?.email }}
          brandId={userPortalState?.brand?._id}
          isBrand={false}
        /> */}
        {/* <IntegrationItem
          icon={<BsTwitch />}
          itemText="Twitch"
          buttonText="Connect"
          user={userPortalState?.profile?.user}
          brand={userPortalState?.brand}
          dispatch={dispatch}
        /> */}
        <IntegrationItem
          icon={<BsYoutube />}
          itemText="YouTube"
          buttonText={userPortalState?.profile?.user?.youtubeAuth?"Connected":"Connect"}
          user={userPortalState?.profile?.user}
          brand={userPortalState?.brand}
        />
      </ButtonColumn>
      <ButtonColumn>
        {/* <IntegrationItem
          icon={<BsDiscord />}
          itemText="Discord"
          buttonText={
            userPortalState?.profile?.user?.discordAuth
              ? "Connected"
              : "Connect"
          }
          user={userPortalState?.profile?.user}
          brand={userPortalState?.brand}
          onDisconnect
          dispatch={dispatch}
        /> */}
        {/* <IntegrationItem
          icon={<BsYoutube />}
          itemText="YouTube"
          buttonText={
            userPortalState?.profile?.user?.youtubeAuth
              ? "Connected"
              : "Connect"
          }
          user={userPortalState?.profile?.user}
          brand={userPortalState?.brand}
          onDisconnect
          dispatch={dispatch}
        /> */}
        {/* <YoutubeVerified
          formValues={{ email: userPortalState?.profile?.user?.email }}
          brandId={userPortalState?.brand?._id}
          isBrand={false}
          data={userPortalState?.profile?.user}
          dispatch={dispatch}
        /> */}
        {/* <IntegrationItem
          icon={<FaTiktok />}
          itemText="Tiktok"
          buttonText="Connect"
          user={userPortalState?.profile?.user}
          brand={userPortalState?.brand}
          dispatch={dispatch}
        /> */}
        {/* <IntegrationItem
          icon={<BsGlobe />}
          itemText="Website"
          buttonText="Enter Details"
          user={userPortalState?.profile?.user}
          brand={userPortalState?.brand}
          dispatch={dispatch}
        /> */}
        {/* <IntegrationItem
          icon={<FaShopify />}
          itemText="Shopify"
          buttonText="Enter Details"
          user={userPortalState?.profile?.user}
          brand={userPortalState?.brand}
          dispatch={dispatch}
        /> */}
      </ButtonColumn>
    </IntegrationsDisp>
  );
};

const IntegrationsDisp = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  @media (max-width: 768px) {
    height: 100%;
    gap: 0px;
    max-width: none;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const ButtonColumn = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  @media (max-width: 768px) {
    gap: 0px;
  }
`;

export default IntegrationsPage;
