import React from "react";
import styled from "styled-components";
import RewardCard from "containers/ManageReward/components/RewardCard";

const UserRewardCards = ({ memberships, rewards, filters }) => {
  const firstCapitalized = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const membershipTiers = {};
  memberships.forEach((m) => {
    membershipTiers[m.id] = firstCapitalized(m.name);
  });

  const availbleMembershipIds = [1];
  const lockedMembershipIds = [2, 3];

  function rewardsToRender(id = null) {
    const filterKeys = Object.keys(filters);
    let rewardsToRender = rewards.filter((reward) => {
      return filterKeys.every((key) => {
        if (!filters[key].length) return true;

        // Loops again if reward[key] is an array.
        if (Array.isArray(reward[key])) {
          return reward[key].some((keyEle) => filters[key].includes(keyEle));
        }
        return filters[key].includes(
          typeof reward[key] === "string"
            ? reward[key].toLowerCase()
            : reward[key]
        );
      });
    });
    if (id !== null) {
      rewardsToRender = rewardsToRender.filter((r) => r.membershipId === id);
    }
    return rewardsToRender;
  }

  function renderRewardCards(membershipId = null, isLocked = false) {
    let displayedRewards = rewardsToRender(membershipId);
    return displayedRewards.map(
      ({
        name,
        startDate,
        endDate,
        description,
        image,
        redemptionCount,
        isDraft,
        category,
        _id,
        membershipTier,
      }) => (
        <RewardCard
          {...{
            name,
            startDate,
            endDate,
            description,
            image,
            redemptionCount,
            isDraft,
            category,
            id: _id,
            membershipTier,
          }}
          isLocked={isLocked}
          isUser={true}
          mockMembershipData={memberships}
          key={_id}
        />
      )
    );
  }
  return (
    <Wrapper>
      {availbleMembershipIds.map((id) => {
        return rewardsToRender(id).length === 0 ? (
          <></>
        ) : (
          <div key={id}>
            <Title>Available Rewards</Title>
            <CardsWrapper>{renderRewardCards(id)}</CardsWrapper>
          </div>
        );
      })}
      {lockedMembershipIds.map((id) => {
        const isLocked = true;
        return rewardsToRender(id).length === 0 ? (
          <></>
        ) : (
          <div key={id}>
            <TitleWrapper>
              <Title>{membershipTiers[id]} Rewards</Title>
              <Caption>
                {membershipTiers[id] === "VIP" ? "$3700" : "$1200"} spend to
                unlock rewards
              </Caption>
            </TitleWrapper>
            <CardsWrapper>{renderRewardCards(id, isLocked)}</CardsWrapper>
          </div>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BaseText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  column-gap: 20px;
`;

const Caption = styled(BaseText)`
  font-size: 12px;
  color: #ffffffbf;
  padding-top: 4px;
`;

const Title = styled(BaseText)`
  font-size: 24px;
  letter-spacing: 0.1em;
`;

const CardsWrapper = styled.div`
  margin-bottom: 36px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
`;

export default UserRewardCards;
