import React, { memo, useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { QR_CODE_MODAL_DEFAULTS } from "utils/constants";
import queryString from "query-string";
import API from "utils/userAPI";
import EditQrCodeModalDisplay from "containers/LandingPage/components/EditQrCodeModalDisplay";
import { isEmpty } from "utils/object";

function BrandQrCodeModal() {
  const [isLoading, setIsLoading] = useState(true);
  const queryParams = queryString.parse(location.search);
  const [formValues, setFormValues] = useState({
    ...QR_CODE_MODAL_DEFAULTS
  });
  const navigate = useNavigate();
  const brandId = localStorage.getItem("brandId") || queryParams?.brandId;

  const handleBacktoEditorClick = () => {
    navigate(`/qrcode-edit?brandId=${brandId}`);
  };

  const init = async () => {
    setIsLoading(true);
    const res = await API.get(`/qrcodemodal/${brandId}`);
    const qrcodeModel = JSON.parse(localStorage.getItem("qrCodeModel"));
    res.data.data.qrCodeModal = qrcodeModel
      ? { ...qrcodeModel, headerImage: qrcodeModel.headerImage }
      : res.data.data.qrCodeModal;
    if (!isEmpty(res.data.data.qrCodeModal)) {
      setFormValues((prev) => ({
        ...prev,
        ...res.data.data.qrCodeModal,
        theme: res.data.data.qrCodeModal.theme || QR_CODE_MODAL_DEFAULTS.theme,
        text:
          res.data.data.qrCodeModal.text.length >= 2
            ? res.data.data.qrCodeModal.text
            : [...QR_CODE_MODAL_DEFAULTS.text],
        benefits: QR_CODE_MODAL_DEFAULTS.benefits
      }));
    }
    console.log(res);
    setIsLoading(false);
  };
  useEffect(() => {
    init();
    return () => {};
  }, []);

  if (queryParams?.modal) {
    return <EditQrCodeModalDisplay formValues={formValues} />;
  } else {
    return (
      <Wrapper>
        <HeaderButtons>
          <BackButton onClick={handleBacktoEditorClick}>
            <ChevronLeftIcon />
            <span>Back to Editor</span>
          </BackButton>
        </HeaderButtons>
        <Content>
          {isLoading ? (
            <CircularProgress size={100} />
          ) : (
            <EditQrCodeModalDisplay formValues={formValues} />
          )}
        </Content>
      </Wrapper>
    );
  }
}
const Wrapper = styled.section`
  border-radius: 10px;
  overflow: auto;
  margin: auto;
  padding: auto;
  height: 100vh;
  width: 100%;
  background: #1a1a22;
`;
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  padding:40px;
  align-items: center;
  transition: all 0.3s ease;
  color: #6096ff;
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  &:hover {
    opacity: 0.7;
  }
`;

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #352b42;
  height: 92px;
  padding: 0 40px;
  align-items: center;
  img {
    height: 40px;
    margin: auto 0;
  }
`;

BrandQrCodeModal.propTypes = {};

export default memo(BrandQrCodeModal);
