/**
 *
 * OnboardingPage
 *
 */

import React, { memo, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";

import { useInjectSaga } from "utils/injectSaga";
import { useInjectReducer } from "utils/injectReducer";
import styled from "styled-components";

import LockedSection from "./components/LockedSection";
import LoginSection from "./components/LoginSection";
import SignUpSection from "./components/SignUpSection";
import ForgotPasswordSection from "./components/ForgotPasswordSection";
import VerifyEmailSection from "./components/VerifyEmailSection";

import {
  loginBrand,
  createBrand,
  fetchBrand,
  registerBrand,
  resendVerifyBrand,
  verifyBrand,
  sendForgotPassword,
  getBrandRegistration,
  updateBrandName,
} from "./actions";
import { createDefaultMembership } from "../NewMembership/actions";
import makeSelectOnboardingPage from "./selectors";
import reducer from "./reducer";
import saga from "./saga";
import SideImage from "./components/SideImage";
import {
  ImageCampaignLogo,
  ImageMembershipCards,
  ImageRewardCard,
  ImageVerifyPageGraphs,
} from "images";
import MainSection from "./components/MainSection";
import VerifiedSection from "./components/ VerifiedSection";

export function OnboardingPage({ dispatch, onboardingPage }) {
  useInjectReducer({ key: "onboardingPage", reducer });
  useInjectSaga({ key: "onboardingPage", saga });
  const [page, setPage] = useState("locked"); // change
  const secondaryPages = ["forgot", "newPass", "verified"];

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const onEmailChange = (evt) => setEmail(evt.target.value);
  const onPasswordChange = (evt) => setPassword(evt.target.value);
  const [signUpForm, setSignUpForm] = useState({
    name: "",
    userFullName: "",
    email: "",
    password: "",
    passwordConfirm: "",
    walletAddress: "0x1234",
    artistId: "",
    artistAvatar: "",
    artistName: "",
  });
  const handleSignupChange = (event, name) => {
    setSignUpForm({
      ...signUpForm,
      [name]: event.target.value,
    });
  };

  const setEmailOnGoogleLogin = (evt) => { setSignUpForm({ ...signUpForm, email: evt.target.value }) }
  const navigate = useNavigate();

  const { brand, wallet, createError, loginError, verifyError, forgotError } =
    onboardingPage;

  useEffect(() => {
    if (wallet && Object.keys(wallet).length !== 0) {
      const { address } = wallet;
      setSignUpForm({ ...signUpForm, walletAddress: address });
    }
  }, [wallet]);

  useEffect(() => {
    if (signUpForm.walletAddress !== "0x1234") {
      dispatch(createBrand(signUpForm));
    }
  }, [signUpForm]);
  // delete if not necessary
  // useEffect(() => {
  //   if (accessToken !== undefined && accessToken !== "") {
  //     // route to dashboard page
  //     dispatch(fetchBrand());
  //     return navigate("/dashboard");
  //   }
  // }, [accessToken]);

  // useEffect(() => {
  //   if (brand && Object.keys(brand).length !== 0 && brand?.isVerified) {
  //     // route to dashboard page
  //     return navigate("/dashboard");
  //   }
  // }, [brand, accessToken]);

  const onLoginClick = () => {
    const data = {
      email,
      password,
    };
    dispatch(
      loginBrand(data, () => {
        navigate("/dashboard");
      })
    );
  };

  const handleVerifyBrand = async (code) => {
    dispatch(
      verifyBrand(signUpForm.email, code, () => {
        setPage("verified");
      })
    );
  };

  const handleUpdateBrandNamePublishDefaultMembership = async () => {
    const [firstName, lastName] = signUpForm.userFullName.split(" ");

    dispatch(
      updateBrandName(
        signUpForm.email,
        signUpForm.name,
        firstName || "",
        lastName || "",
        signUpForm.artistId,
        signUpForm.artistAvatar,
        signUpForm.artistName,
        (brand) => navigate("/dashboard")
      )
    ).then(() => {
      dispatch(fetchBrand());
      console.log("create default membership");
      dispatch(createDefaultMembership());
    });
  };
  const onResendVerify = () => {
    dispatch(resendVerifyBrand(signUpForm.email));
  };

  const formProps = {
    email,
    password,
    onEmailChange,
    onPasswordChange,
  };
  const onBackClick = () => setPage("main");
  const onSignupClick = () => setPage("signup");
  const handleCreateBrand = async () => {
    dispatch(
      registerBrand({ ...signUpForm, walletAddress: "0x1234" }, () =>
        setPage("verify")
      )
    );
  };
  const checkEmailExists = async () => {
    dispatch(
      getBrandRegistration(email, (page) => setPage(page), setSignUpForm)
    );
  };
  const onForgotPasswordClick = () => setPage("forgot");
  const onNewPasswordClick = (email) =>
    dispatch(sendForgotPassword(email, () => setPage("newPass")));

  const renderSideImage = () => {
    let graphicContent, titleText, bodyText;

    if (page === "login" || page === "signup") {
      titleText = "Engage and Reward Your Most Loyal Customers";

      graphicContent = (
        <img
          src={ImageRewardCard}
          style={{ maxHeight: "494px", maxWidth: "442px" }}
        />
      );
    } else if (page === "main" || page === "locked") {
      titleText =
        "Build your Customer Engagement Program with Campaign in Minutes!";

      graphicContent = (
        <img
          src={ImageMembershipCards}
          style={{ maxHeight: "336px", maxWidth: "642px" }}
        />
      );
    } else if (page === "verify") {
      titleText = "Easily Track and Manage Your Loyalty Program";

      graphicContent = (
        <img
          src={ImageVerifyPageGraphs}
          style={{ maxHeight: "378px", maxWidth: "501px" }}
        ></img>
      );
    }

    return (
      <SideImage
        graphicContent={graphicContent}
        titleText={titleText}
        bodyText={bodyText}
        secondaryPages={secondaryPages}
        page={page}
      />
    );
  };

  const renderedSideImage = useMemo(() => renderSideImage(), [page]);

  return (
    <OnboardingLayout className="container-fluid">
      <PageWrapper>
        {renderedSideImage}
        <RightMenu secondaryPages={secondaryPages} page={page}>
          <Image>
            <img src={ImageCampaignLogo}></img>
          </Image>
          {page === "locked" && (
            <LockedSection 
              setPage={setPage}
              error={loginError}
            />
          )}
          {page === "main" && (
            <MainSection
              formProps={formProps}
              onSignupClick={onSignupClick}
              onLoginClick={onLoginClick}
              onForgotPasswordClick={onForgotPasswordClick}
              onVerifyEmailClick={checkEmailExists}
              error={loginError}
              handleChange={handleSignupChange}
              setPage={setPage}
              setEmailOnGoogleLogin={setEmailOnGoogleLogin}
            />
          )}
          {page === "login" && (
            <LoginSection
              formProps={formProps}
              onSignupClick={onSignupClick}
              onLoginClick={onLoginClick}
              onForgotPasswordClick={onForgotPasswordClick}
              error={loginError}
            />
          )}
          {page === "signup" && (
            <SignUpSection
              onBackClick={onBackClick}
              onVerifyEmailClick={handleCreateBrand}
              formValues={signUpForm}
              handleChange={handleSignupChange}
              error={createError}
            />
          )}
          {page === "verify" && (
            <VerifyEmailSection
              onBackClick={onSignupClick}
              onCreateBrand={handleVerifyBrand}
              email={signUpForm.email}
              onResend={onResendVerify}
              error={verifyError}
            />
          )}
          {page === "verified" && (
            <VerifiedSection
              onUpdateName={handleUpdateBrandNamePublishDefaultMembership}
              error={verifyError}
              signUpForm={signUpForm}
              setSignUpForm={setSignUpForm}
            />
          )}
          {page === "forgot" && (
            <ForgotPasswordSection
              onNewPasswordClick={onNewPasswordClick}
              onBackClick={onBackClick}
              error={forgotError}
              formProps={formProps}
            />
          )}
          {page === "newPass" && (
            <ForgotPasswordSection
              enterNewPassword
              onBackClick={onForgotPasswordClick}
              formProps={formProps}
            />
          )}
        </RightMenu>
      </PageWrapper>
    </OnboardingLayout>
  );
}
const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 360px;
  width: 500px;
  margin: 0 57px;
`;

const OnboardingLayout = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* padding: 72px 130px; */
`;

const PageWrapper = styled.section`
  display: flex;
  /* height: 100%;
  width: 100%; */
  height: 87%;
  width: 84%;
  justify-content: space-between;
  flex-direction: row;
  background-color: ${(props) => props.theme.palette.darkestGrey};
  border-radius: 16px;
`;

const RightMenu = styled.section`
  display: flex;
  flex-direction: column;

  padding: 60px 60px 100px 100px;
  justify-content: space-between;
  ${(props) =>
    props.secondaryPages.includes(props.page)
      ? { width: "100%", height: "100%" }
      : { width: "50%", height: "100%" }}
`;

const Image = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  img {
    height: 36px;
    width: 225px;
  }
`;

OnboardingPage.propTypes = {};

const mapStateToProps = createStructuredSelector({
  onboardingPage: makeSelectOnboardingPage(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(OnboardingPage);
