/**
 *
 * AddRewardModal
 *
 */

import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, CircularProgress } from "@mui/material";
import makeSelectOnboardingPage from "containers/OnboardingPage/selectors";
import { createUser } from "containers/UserPortal/actions";
import API from "utils/userAPI";
import CheckoutForm from "components/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import queryString from "query-string";
import "./style.css";
import { ImageCampaignLogo } from "images";
import { Close } from "@mui/icons-material";
const stripePromise = loadStripe("pk_test_0x07UHNbcf9TKmcdKj1tXy91");

function PaymentModal({
  showModal,
  setShowModal,
  onRedeem,
  brandName = "",
  isLogin,
  selectedMembership,
}) {
  const [clientSecret, setClientSecret] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [membershipInfo, setMembershipInfo] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = queryString.parse(location.search);
  const brandId = queryParams?.brandId || "6436dda255589abdddd79b2e";
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    setError("");
    setShowModal(false);
  };
  const navigate = useNavigate();

  const init = async () => {
    try {
      setIsLoading(true);
      if (selectedMembership) {
        setMembershipInfo(selectedMembership);
        setSearchParams({
          brandId,
          membershipId: selectedMembership._id,
        });
      } else {
        const res = await API.get("/membership/cheapest", {
          params: { brandId: queryParams?.brandId },
        });
        setMembershipInfo(res.data.data.membership);
        setSearchParams({
          brandId,
          membershipId: res.data.data.membership._id,
        });
      }

      setIsLoading(false);
    } catch (error) {
      console.log("get cheapest membership error:", error);
    }
  };

  useEffect(() => {
    if (showModal && brandId) {
      init();
    }
    return () => {};
  }, [showModal, selectedMembership]);

  const handleClickPay = async () => {
    if (!membershipInfo || membershipInfo.purchasePrice === 0) {
      try {
        setLoading(true);
        const res = await API.post("/users/mint", {
          membershipId: membershipInfo._id,
          brandId,
        });
        setLoading(false);
        await API.post("/users/membership", {
          membershipId: membershipInfo._id,
        });
        onRedeem();
      } catch (error) {
        console.log(error);
        localStorage.removeItem("isClaimMemberShipLogin");
        if (error.status === 401) {
          setError("User not authorized. Please login to continue");
        } else {
          setError(error?.response?.data?.message);
        }
        setLoading(false);
      }
      return;
    }
    try {
      const res = await API.post("/payments/payment-request", {
        gateway: "STRIPE",
        amount: membershipInfo.purchasePrice * 100,
        currency: "USD",
        type: "MEMBERSHIPS",
        typeId: membershipInfo._id,
        brandId,
      });
      if (!res) {
        setError("User not authorized. Please login to continue");
      } else {
        localStorage.setItem("paymentId", res.data.data.uuid);
        setClientSecret(res.data.data.meta.client_secret);
        // route user to success screen
      }
    } catch (error) {
      setError("User not authorized. Please login to continue");
    }
  };

  const elementsOptions = {
    clientSecret,
    fonts: [
      {
        cssSrc: "https://fonts.googleapis.com/css2?family=Inter",
      },
    ],
    appearance: {
      theme: "stripe",
      variables: {
        colorBackground: "#1A1A22",
        colorPrimary: "#352B42",
        colorText: "#7F86AD",
        colorDanger: "#E7CE4A",
        fontFamily: "Inter",
        spacingUnit: "2px",
        borderRadius: "6px",
        borderColor: "#352B42",
        spacingUnit: "10px",
      },
      rules: {
        ".Label": {
          fontWeight: "600",
          fontSize: " 20px",
          lineHeight: "24px",
          color: "#6096FF",
        },
        ".Input": {
          fontWeight: "400",
          fontSize: "18px",
          lineHeight: "28px",
          color: "white",
          padding: "16px 20px",
          borderColor: "#352B42",
        },
        ".Input::placeholder": {
          color: "#7F86AD",
          fontWeight: "400",
          fontSize: "18px",
          lineHeight: "28px",
        },
      },
    },
  };

  return (
    <ModalLayout
      open={showModal}
      onClose={handleClose}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapperBox clientSecret={clientSecret}>
        <CustomModal>
          <Close
            sx={{
              position: "absolute",
              top: "36px",
              right: "36px",
              color: "#7F86AD",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
          {clientSecret && (
            <Header>
              <PayHeader>Pay With Credit / Debit Card</PayHeader>
            </Header>
          )}
          <Content>
            {clientSecret ? (
              <Elements options={elementsOptions} stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            ) : (
              <>
                {isLoading ? (
                  "Loading..."
                ) : membershipInfo?.image ? (
                  <>
                    <MainText>Redeem Membership</MainText>
                    <>
                      <InfoBox>
                        <BrandImage
                          src={membershipInfo.image}
                          alt={membershipInfo.description}
                        />
                        <BrandInfo>
                          <div className="brand-name">{brandName}</div>
                          <div className="membership-name">
                            {membershipInfo.name} Membership
                          </div>
                        </BrandInfo>
                      </InfoBox>
                      <TotalRow>
                        Total Cost
                        <div>
                          {membershipInfo.purchasePrice
                            ? `$${membershipInfo.purchasePrice} USD`
                            : "$0"}
                        </div>
                      </TotalRow>
                      <PayButton onClick={handleClickPay} variant="contained">
                        {loading ? (
                          <CircularProgress />
                        ) : membershipInfo.purchasePrice === 0 ? (
                          "Claim Membership"
                        ) : (
                          "Pay with Credit / Debit Card"
                        )}
                      </PayButton>
                    </>
                  </>
                ) : (
                  <>
                    <MainText>Redeem Membership</MainText>
                    <EmptyMembership>No membership</EmptyMembership>
                  </>
                )}
                {error && (
                  <ErrorWrapper>
                    <Error>{error}</Error>
                  </ErrorWrapper>
                )}
                <SecondaryText style={{ alignSelf: "center" }}>
                  Powered by{" "}
                  <img src={ImageCampaignLogo} width="150px" height="24px" />
                </SecondaryText>
              </>
            )}
          </Content>
        </CustomModal>
      </ModalWrapperBox>
    </ModalLayout>
  );
}
const EmptyMembership = styled.div`
  height: 400px;
`;
const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 40px;
`;

const Price = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.lightGrey};
  gap: 8px;
`;

const MainText = styled.div`
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  align-self: center;
`;

const PayHeader = styled.div`
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  align-self: flex-start;
`;

const SecondaryText = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const ModalLayout = styledMui(Modal)(() => ({}));

const CustomModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 60px 40px 60px;
  width: 100%;
  height: 100%;
`;

const ModalWrapperBox = styledMui(Box)(({ theme, clientSecret }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: clientSecret ? "690px" : "627px",
  background: theme.palette.darkestGrey,
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const ErrorWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  margin: -16px 0;
`;

const Error = styled.div`
  position: absolute;
  font-size: 14px;
  color: red;
`;

const BrandInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 12px;
  ${(props) => props.theme.typography.bodyMBold};

  .body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: ${(props) => props.theme.palette.lightGrey};
    gap: 8px;
  }

  .brand-name {
    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.lightGrey};
  }

  .membership-name {
    ${(props) => props.theme.typography.header1};
    color: ${(props) => props.theme.palette.white};
  }
`;

const BrandImage = styled.img`
  width: 300px;
  border-radius: 12px;
`;

const Total = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};
  border-radius: 6px;
  background: ${(props) => props.theme.palette.darkerGrey};
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const DividerStyles = {
  border: "1px solid #352B42",
  width: "100%",
  margin: "10px 0",
};

const PayButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "18px 0",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const Header = styled.div`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
`;

PaymentModal.propTypes = {
  setShowModal: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  onboardingPage: makeSelectOnboardingPage(),
});

function mapDispatchToProps(dispatch) {
  return {
    createWallet: (data) => dispatch(createWallet(data)),
    createUser: (data) => dispatch(createUser(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(PaymentModal);
