/**
 *
 * LandingPageHowDoesItWork
 *
 */

import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { styled as styledMui } from "@mui/material/styles";
import { InputBase } from "@mui/material";
import { Block, Sublabel } from "../LandingPageDesignForms";

function LandingPageHowDoesItWork({ formValues, handleChange }) {
  const { howWorks } = formValues;
  return (
    <Fields>
      <Block>
        <Content>
          <NumberLabel>1</NumberLabel>
          <FormField
            inputProps={{
              "aria-label": "description",
              maxLength: "500",
            }}
            fullWidth
            value={howWorks[0].title}
            onChange={(event) => {
              handleChange(
                event,
                "howWorks",
                howWorks.map((item, index) => {
                  if (index === 0) {
                    return { ...item, title: event.target.value };
                  }
                  return item;
                })
              );
            }}
          />
        </Content>
        <MidFormField
          inputProps={{
            "aria-label": "description",
            maxLength: "500",
          }}
          fullWidth
          multiline
          minRows={5}
          value={howWorks[0].body.slice(0, 150)}
          onChange={(event) => {
            handleChange(
              event,
              "howWorks",
              howWorks.map((item, index) => {
                if (index === 0) {
                  return { ...item, body: event.target.value.slice(0, 150) };
                }
                return item;
              })
            );
          }}
        />
        <Sublabel>Max. 150 characters.</Sublabel>
      </Block>
      <Block>
        <Content>
          <NumberLabel>2</NumberLabel>
          <FormField
            inputProps={{
              "aria-label": "description",
              maxLength: "500",
            }}
            fullWidth
            value={howWorks[1].title}
            onChange={(event) => {
              handleChange(
                event,
                "howWorks",
                howWorks.map((item, index) => {
                  if (index === 1) {
                    return { ...item, title: event.target.value };
                  }
                  return item;
                })
              );
            }}
          />
        </Content>
        <MidFormField
          inputProps={{
            "aria-label": "description",
            maxLength: "500",
          }}
          fullWidth
          multiline
          minRows={5}
          value={howWorks[1].body.slice(0, 150)}
          onChange={(event) => {
            handleChange(
              event,
              "howWorks",
              howWorks.map((item, index) => {
                if (index === 1) {
                  return { ...item, body: event.target.value.slice(0, 150) };
                }
                return item;
              })
            );
          }}
        />
        <Sublabel>Max. 150 characters.</Sublabel>
      </Block>
      <Block>
        <Content>
          <NumberLabel>3</NumberLabel>
          <FormField
            inputProps={{
              "aria-label": "description",
              maxLength: "500",
            }}
            fullWidth
            value={howWorks[2].title}
            onChange={(event) => {
              handleChange(
                event,
                "howWorks",
                howWorks.map((item, index) => {
                  if (index === 2) {
                    return { ...item, title: event.target.value };
                  }
                  return item;
                })
              );
            }}
          />
        </Content>
        <MidFormField
          inputProps={{
            "aria-label": "description",
            maxLength: "500",
          }}
          fullWidth
          multiline
          minRows={5}
          value={howWorks[2].body.slice(0, 150)}
          onChange={(event) => {
            handleChange(
              event,
              "howWorks",
              howWorks.map((item, index) => {
                if (index === 2) {
                  return { ...item, body: event.target.value.slice(0, 150) };
                }
                return item;
              })
            );
          }}
        />
        <Sublabel>Max. 150 characters.</Sublabel>
      </Block>
    </Fields>
  );
}

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const NumberLabel = styled.div`
  display: flex;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

const FormField = styledMui(InputBase)({
  display: "flex",
  borderRadius: 10,
  backgroundColor: "rgba(217, 217, 217, 0.2)",
  color: "#7F86AD",
  margin: "8px 0",
  height: 38,
  fontSize: 16,
  padding: "10px 15px",
  fontFamily: "Poppins",
  background: "#21212E",
  border: "1px solid #21212E",
  borderRadius: "8px",

  "&::placeholder": {
    color: "#7F86AD",
  },
});

export const MidFormField = styledMui(FormField)({
  height: 150,
  overflow: "hidden",
});
const LargeFormField = styledMui(FormField)({
  height: 184,
  overflow: "hidden",
});

const formFieldOverrides = {
  border: "1px solid #D9D9D9",
  input: {
    "&::placeholder": {
      color: "#ffffff",
      opacity: 1,
    },
  },
};

LandingPageHowDoesItWork.propTypes = {
  formValues: PropTypes.object,
  handleChange: PropTypes.func,
};

export default memo(LandingPageHowDoesItWork);
