import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
// import { ChainId, ThirdwebProvider } from '@thirdweb-dev/react';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles/globals.css";
import configureStore from "./configureStore";
import history from "utils/history";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "react-hot-toast";
import * as Sentry from "@sentry/react";
// This is the chainId your dApp will work on.

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60,
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

const initialState = {};
const store = configureStore(initialState, history);

Sentry.init({
  dsn: "https://3c63e23ae158090855196ee4df785454@o4505789574217728.ingest.sentry.io/4505793895530496",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <ThirdwebProvider desiredChainId={activeChainId}> */}
      <BrowserRouter>
        <CookiesProvider>
          <QueryClientProvider client={queryClient}>
            <App />
            <Toaster position="top-right" reverseOrder={false} />
          </QueryClientProvider>
        </CookiesProvider>
      </BrowserRouter>
      {/* </ThirdwebProvider> */}
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
