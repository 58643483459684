import React, { useState, useEffect, useRef } from "react";
import Lottie from "react-lottie";
import animationData from "./lottie/c";
import { Flex } from "@chakra-ui/react";
import "./styles.min.css";
import companyLogoImg from "../../images/campaignlogo.png";
import { Button } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const useShapeAnimations = () => {
  const shapeRefs = useRef([]);

  useEffect(() => {
    shapeRefs.current.forEach((el) => {
      if (!el) return;

      const shape1 = document.createElement("div");
      const shape2 = document.createElement("div");
      shape1.classList.add("shapes-1");
      shape2.classList.add("shapes-2");
      el.appendChild(shape1);
      el.appendChild(shape2);

      const setShadow = (e) => {
        let x = (e?.clientX || 0) - window.innerWidth / 2;
        let y = (e?.clientY || 0) - window.innerHeight / 2;
        let translateX = (x / 50).toFixed(1);
        let translateY = (y / 50).toFixed(1);

        shape1.style.boxShadow = `inset ${translateX}px ${translateY}px 10px 5px rgba(0, 0, 0, .15)`;
        shape2.style.boxShadow = `${(translateX / 4).toFixed(2)}px ${(
          translateY / 4
        ).toFixed(2)}px 5px 5px rgba(0, 0, 0, .15)`;
      };

      if (!window.matchMedia("(any-hover: none)").matches) {
        setShadow();
        window.addEventListener("mousemove", setShadow);
      } else {
        setShadow({
          clientX: window.innerWidth / 2,
          clientY: window.innerHeight / 2,
        });
      }

      return () => {
        window.removeEventListener("mousemove", setShadow);
      };
    });
  }, []);

  return shapeRefs;
};

const LandingPage = () => {
  const shapeRefs = useShapeAnimations();
  const navigate = useNavigate();

  const [lottieSize, setLottieSize] = useState("20vw"); // Default size

  useEffect(() => {
    const updateSize = () => {
      if (window.innerWidth <= 600) {
        setLottieSize("24vw");
      } else {
        const rootStyle = getComputedStyle(document.documentElement);
        const size = rootStyle.getPropertyValue("--size") || "20vw";
        setLottieSize(size);
      }
    };

    window.addEventListener("resize", updateSize);

    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <>
      <Flex
        direction="column"
        flex="1"
        style={{ overflow: "scroll", backgroundColor: "#131316" }}
      >
        <section className="hero-section" data-animate="true">
          <div className="hero-section__letter">
            <img src={companyLogoImg} style={{ color: "black" }} />
          </div>
          <h1
            className="intro-section__heading content"
            data-animate="true"
            style={{
              fontSize: "24px",
              textAlign: "justify",
              lineHeight: "29px",
              fontWeight: "400",
              fontFamily: "'Inter'",
            }}
          >
            Campaign is an advanced tool for artists and content creators, that
            utilises social data (YouTube, Spotify among others) to identify
            their top fans. We leverage the YouTube data of users to join
            exclusive fandoms created by the artist using our
            portal. These circles help to strengthen the bond between artists
            and their fans through personalised interaction.
          </h1>
          <ClaimButton
            style={{
              backgroundColor: "#294BFC",
              width: "30%",
              alignSelf: "center",
            }}
            onClick={() => {
              navigate("/landing");
            }}
          >
            Click to Start
          </ClaimButton>
        </section>

        <section className="intro-section content-center">
          <div
            className="shapes"
            data-shape="circle"
            data-animate="true"
            ref={(el) => (shapeRefs.current[0] = el)}
          />
          <h2
            className="intro-section__heading content heading3"
            data-animate="true"
            style={{ textAlign: "center" }}
          >
            How Do I Sign Up ?
          </h2>
          <p className="intro-section__paragraph heading2" data-animate="true">
            <ol style={{ fontFamily: "Inter", lineHeight: "35px" }}>
              <li>
                Click on &quot;Sign Up with YouTube&quot; to initiate the
                process.
              </li>
              <li>Ensure to check all checkboxes during the OAuth process.</li>
              <li>
                Once redirected to the homepage, you have successfully signed up
                with the artist.
              </li>
              <li>
                Navigate to the desired fandom. You&quot;ll be directed to a
                detailed page outlining the required actions.
              </li>
              <li>One of the actions will be to subscribe to a channel.</li>
              <li>
                Upon clicking the &quot;Subscribe&quot; button, the channel will
                be automatically subscribed to.
              </li>
              <li>
                Now, you&quot;ll notice the &quot;Join Fandom&quot; button
                becomes enabled.
              </li>
              <li>
                Click on the &quot;Join Fandom&quot; button to join successfully.
              </li>
              <li>
                For more information, please refer to our{" "}
                <a href="terms-of-use">Terms of Service</a> and{" "}
                <a href="privacy-policy">Privacy Policy</a>.
              </li>
            </ol>
          </p>
        </section>
      </Flex>
    </>
  );
};

const ClaimButton = styledMui(Button)({
  borderRadius: "10px",
  fontSize: "16px",
  lineHeight: "36px",
  textTransform: "none",
  marginTop: "40px",
  backgroundColor: "#294BFC",
  color: "#fff",
  paddingLeft: "40px",
  paddingRight: "40px",
  transition: "all .3s ease",

  "&:hover": {
    backgroundColor: "#7f86ad",
  },
});
export default LandingPage;
