import React, { memo } from "react";
import styled from "styled-components";
import { ImageOnboarding, ImageOnboardingSmall } from "images";

const SideImage = ({
  graphicContent,
  titleText,
  bodyText,
  secondaryPages,
  page,
}) => {
  return (
    <Wrapper page={page} secondaryPages={secondaryPages}>
      <BackgroundImage
        src={
          secondaryPages.includes(page) ? ImageOnboardingSmall : ImageOnboarding
        }
      />
      <ContentWrapper>
        <GraphicWrapper>{graphicContent}</GraphicWrapper>
        <TextWrapper>
          <TitleText>{titleText}</TitleText>
          <BodyText>{bodyText}</BodyText>
        </TextWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.secondaryPages.includes(props.page)
      ? ""
      : { width: "50%", height: "100%" }}
  max-width: 100%;

  img {
    height: 100%;
    width: 100%;
  }
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
`;

const GraphicWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  padding: 0 44px;
  gap: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  color: ${(props) => props.theme.palette.white};
`;

const TitleText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
`;

const BodyText = styled.div`
  ${(props) => props.theme.typography.bodyS};
`;

export default memo(SideImage);
