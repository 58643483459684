import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import makeSelectOnboardingPage from "containers/OnboardingPage/selectors";
import queryString from "query-string";
import { createUser } from "containers/UserPortal/actions";
import { ImageCampaignLogo } from "images";
import { Close } from "@mui/icons-material";
import { useSocket } from "utils/socketIO";

function PaymentSuccessModal({
  showModal,
  setShowModal,
  selectedMembership,
  isFreeMembership,
  brandName,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = queryString.parse(location.search);
  const brandId = queryParams?.brandId || "";
  const membershipId = queryParams?.membershipId || "";
  const [membershipInfo, setMembershipInfo] = useState();
  const [error, setError] = useState("");
  const socket = useSocket();

  useEffect(() => {
    setError("");
    socket.on("membershipMintedSuccess", (data) => {
      console.log(
        "membership minted success",
        searchParams.get("paymentId"),
        data
      );
      if (searchParams.get("paymentId") === data.uuid) {
        setIsLoading(false);
      }
    });
    socket.on("membershipMintedError", (data) => {
      console.log(
        "membership minted error",
        searchParams.get("paymentId"),
        data
      );
      if (searchParams.get("paymentId") === data.uuid) {
        setIsLoading(false);
        setError(data.error);
      }
    });

    return () => {};
  }, []);

  const handleClose = () => {
    if (!isLoading && !error) {
      location.href = `/landingpage-user?brandId=${brandId}`;
    }
    setShowModal(false);
  };

  const handleReturn = () => {
    handleClose();
  };

  const fetchMembership = async () => {
    setMembershipInfo(selectedMembership);
  };

  useEffect(() => {
    if (brandId) {
      fetchMembership();
    }
  }, [membershipId, selectedMembership]);

  const successContent = (
    <>
      <Membership>
        {membershipInfo && (
          <InfoBox>
            <MembershipImg
              src={membershipInfo.image}
              alt={membershipInfo.description}
            />
            <MembershipInfo>
              <div className="brand-name">{brandName}</div>
              <div className="membership-name">
                {membershipInfo.name} Membership
              </div>
            </MembershipInfo>
          </InfoBox>
        )}
      </Membership>
      <BaseButton onClick={handleReturn}>Return to site</BaseButton>
    </>
  );
  return (
    <ModalLayout
      open={showModal}
      onClose={handleClose}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapperBox>
        <CustomModal>
          <Close
            sx={{
              position: "absolute",
              top: "36px",
              right: "36px",
              color: "#7F86AD",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />

          <Header>
            {isFreeMembership ? (
              <MainText>Successfully Unlocked Membership</MainText>
            ) : isLoading ? (
              <></>
            ) : error ? (
              <MainText>Error</MainText>
            ) : (
              <MainText>Successfully Unlocked Membership</MainText>
            )}
          </Header>
          <Content>
            {isFreeMembership ? (
              successContent
            ) : isLoading ? (
              <LoadingContent>Minting now...</LoadingContent>
            ) : error ? (
              <LoadingContent>{error}</LoadingContent>
            ) : (
              successContent
            )}
            <SecondaryText style={{ alignSelf: "center" }}>
              Powered by{" "}
              <img src={ImageCampaignLogo} width="150px" height="24px" />
            </SecondaryText>
          </Content>
        </CustomModal>
      </ModalWrapperBox>
    </ModalLayout>
  );
}

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 40px;
`;

const MembershipInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 12px;
  ${(props) => props.theme.typography.bodyMBold};

  .body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: ${(props) => props.theme.palette.lightGrey};
    gap: 8px;
  }

  .brand-name {
    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.lightGrey};
  }

  .membership-name {
    ${(props) => props.theme.typography.header1};
    color: ${(props) => props.theme.palette.white};
  }
`;

const LoadingContent = styled.div`
  width: 100%;
  height: 400px;
  line-height: 400px;
  text-align: center;
  color: #fff;
  align-items: center;
`;

const Membership = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

const MainText = styled.div`
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  align-self: center;
`;

const SecondaryText = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const ModalLayout = styledMui(Modal)(() => ({}));

const CustomModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 60px 0 80px;
  width: 100%;
  height: 100%;
`;

const ModalWrapperBox = styledMui(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35.8%",
  height: "72.69%",
  background: theme.palette.darkestGrey,
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const MembershipImg = styled.img`
  width: 300px;
  max-height: 300px;
  border-radius: 12px;
`;

const Header = styled.div`
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  flex: 1;
`;

const BaseButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "18px 0",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

PaymentSuccessModal.propTypes = {
  setShowModal: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  onboardingPage: makeSelectOnboardingPage(),
});

function mapDispatchToProps(dispatch) {
  return {
    createWallet: (data) => dispatch(createWallet(data)),
    createUser: (data) => dispatch(createUser(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(PaymentSuccessModal);
