/**
 *
 * FaqAccordion
 *
 */

import React, { memo, useState } from "react";

import styled from "styled-components";
import { Button } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";

function Membership({
  name,
  description,
  quantity,
  spendMin,
  purchasePrice,
  brandColor,
  rewards = [],
  themeValues,
  onClaim = () => {},
}) {
  return (
    <Wrapper bgColor={themeValues.membershipsBgColor}>
      <Header>
        <Name>{name}</Name>
        <KPI>
          <KPIName>Quantity</KPIName>
          <KPIValue>{quantity}</KPIValue>
        </KPI>
        {/* <KPI> // 06-16-23: Temporarily removing min/max spend for Fodera
          <KPIName>Minimum Spend</KPIName>
          <KPIValue>${spendMin}</KPIValue>
        </KPI> */}
        <KPI>
          <KPIName>Purchase Price</KPIName>
          <KPIValue>${purchasePrice}</KPIValue>
        </KPI>
      </Header>
      {rewards.length > 0 && (
        <>
          <BenefitsTitle>Benefits & Perks</BenefitsTitle>
          <BenefitsContainer>
            {rewards.map((reward) => {
              return (
                <Benefit bgColor={themeValues.membershipsPerkColor}>
                  {reward.name}
                </Benefit>
              );
            })}
          </BenefitsContainer>
        </>
      )}
      <Description textColor={themeValues.membershipsDetailColor}>
        {description}
      </Description>
      <ClaimButton style={{ backgroundColor: brandColor }} onClick={onClaim}>
        Claim Membership
      </ClaimButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: ${(props) => props.bgColor};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 40px;
  margin: 20px 80px;
  width: calc(100% - 160px);
`;

const Header = styled.div`
  display: flex;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(127, 134, 173, 0.3);
`;

const BenefitsTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  margin-top: 32px;
`;

const BenefitsContainer = styled.div`
  display: flex;
  margin-top: 12px;
`;
const Benefit = styled.div`
  height: 42px;
  line-height: 42px;
  background: ${(props) => props.bgColor};
  border-radius: 42px;
  padding: 0 24px;
  margin: 6px 12px 6px 0;
  font-size: 18px;
`;

const Name = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  margin-right: auto;
  color: ${(props) => props.color};
`;
const KPI = styled.div`
  margin-left: 40px;
`;
const KPIName = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
`;

const KPIValue = styled.div`
  font-size: 18px;
  line-height: 28px;
  opacity: 0.5;
`;

const Description = styled.div`
  opacity: 0.5;
  font-size: 18px;
  margin-top: 16px;
  font-weight: 300;
  color: ${(props) => props.textColor};
`;

const ClaimButton = styledMui(Button)({
  borderRadius: "10px",
  fontSize: "16px",
  lineHeight: "36px",
  textTransform: "none",
  marginTop: "40px",
  backgroundColor: "#294BFC",
  color: "#fff",
  paddingLeft: "40px",
  paddingRight: "40px",
  transition: "all .3s ease",

  "&:hover": {
    backgroundColor: "#7f86ad",
  },
});

export default memo(Membership);
