/**
 *
 * AppHeader
 *
 */

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import companyLogoImg from "../../images/campaignlogo.png";
import { Backdrop, ButtonBase, Divider, Fade } from "@mui/material";
import Modal from "@mui/material/Modal";

import { useNavigate } from "react-router-dom";
import API from "utils/brandAPI";
import { IconProfile } from "images/icons/svg-components";
import { ImageDefaultBrand, ImageDefaultUser } from "images";
// eslint-disable-next-line react/prop-types
function AppHeader() {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal((prev) => !prev);
  const navigate = useNavigate();

  const isOnboarding = window.location.pathname === "/";

  const { brand } = useSelector((state) => state.manageBrand);

  const handleLogout = async () => {
    localStorage.removeItem("brandTokens");
    localStorage.removeItem("brandId");
    localStorage.removeItem("artistId");
    localStorage.removeItem("artistName");
    localStorage.removeItem("artistAvatar");
    await API.post("/auth/logout");
    navigate("/");
  };

  const goProfileSettings = () => {
    navigate("/profile-settings");
  };

  return (
    <BottomBorder>
      <Wrapper className="container-fluid">
        <CompanyLogo onClick={() => navigate("/dashboard")}>
          <img src={companyLogoImg} alt="logo" />
        </CompanyLogo>
        {!isOnboarding && (
          <RightHeader>
            <UserInfoWrapper>
              <UserLogo>
                <img
                  src={brand?.brandLogo ? brand?.brandLogo : ImageDefaultBrand}
                />
              </UserLogo>

              <Username>{brand.name}</Username>
            </UserInfoWrapper>
            <ProfileButton
              src={brand?.userPicture ? brand?.userPicture : ImageDefaultUser}
              onClick={() => {
                toggleModal();
              }}
            />
            <Modal
              open={showModal}
              onClose={toggleModal}
              closeAfterTransition
              disableAutoFocus={true}
            >
              <ProfileMenu>
                <UserName>
                  {brand?.userFirstName} {brand?.userLastName}
                </UserName>
                <UserMail>{brand.email}</UserMail>
                <Divider
                  sx={{ borderColor: "#352B42", margin: "20px 0" }}
                ></Divider>
                <ProfileMenuButton onClick={goProfileSettings}>
                  Profile Settings
                </ProfileMenuButton>
                <ProfileMenuButton onClick={handleLogout}>
                  Log Out
                </ProfileMenuButton>
              </ProfileMenu>
            </Modal>
          </RightHeader>
        )}
      </Wrapper>
    </BottomBorder>
  );
}
const BottomBorder = styled.div`
  width: 100%;
  border-bottom: 1px solid #352b42;
`;

const Wrapper = styled.div`
  display: flex;
  height: 92px;
  padding: 0 40px;
  justify-content: space-between;
`;

const UserInfoWrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  border: 1px solid #352b42;
  border-radius: 8px;
  column-gap: 16px;
  padding: 10px 16px;
`;

const RightHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Username = styled.div`
  display: flex;
  color: ${(props) => props.theme.palette.lightBlue};
`;

const UserLogo = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.palette.lightGrey};

  img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
`;

const CompanyLogo = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  img {
    width: 250px;
    height: 40px;
  }
`;

const ProfileButton = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-left: 28px;
  border-radius: 100%;
`;

const ProfileMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 82px;
  right: 40px;
  padding: 20px;
  background-color: ${(props) => props.theme.palette.darkerGrey};
  border-radius: 8px;
  z-index: 10;
`;

const ProfileMenuButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.lightGrey,
  width: "fit-content",

  padding: "10px",
  ":hover": {
    color: theme.palette.lightBlue,
  },
}));

const UserName = styled.div`
  padding: 10px;
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.white};
`;

const UserMail = styled.div`
  padding: 10px;
  ${(props) => props.theme.typography.header3};
  color: ${(props) => props.theme.palette.lightGrey};
`;

AppHeader.propTypes = {
  name: PropTypes.string,
  logo: PropTypes.string,
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
});

export default connect(mapStateToProps)(AppHeader);
