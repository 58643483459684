import React, { useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, ButtonBase } from "@mui/material";
import { useNavigate } from "react-router";
import AirdropCard from "./AirdropCard";
import { ImageNoData } from "images";

const Airdrops = ({ airdrops = [] }) => {
  const navigate = useNavigate();

  const renderAirdropCards = () => {
    return airdrops.map((item, idx) => <AirdropCard data={item} />);
  };

  return (
    <Wrapper>
      <Header>
        Airdrop
        <CreateAirdropButton onClick={() => navigate("/create-airdrop")}>
          + Create Airdrop
        </CreateAirdropButton>
      </Header>
      {airdrops.length === 0 ? (
        <>
        <EmptyWrapper>
        <img src={ImageNoData} />
        <InfoBoxText>No airdrops have been created.</InfoBoxText>
        </EmptyWrapper>
        </>
      ) : (
        <AirdropsCardsWrapper>{renderAirdropCards()}</AirdropsCardsWrapper>
      )
      }
      
    </Wrapper>
  );
};

const AirdropsCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: hidden;
  scroll-behavior: auto;
`;

const TabButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header3,
  textTransform: "none",
  color: theme.palette.lightBlue,
  padding: "16px 0",
  width: "fit-content",

  "&:hover": {
    color: theme.palette.white,
    background: "none",
  },
}));

const CreateAirdropButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  width: "fit-content",
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
  borderRadius: 6,
  padding: "8px 20px",
  textTransform: "none",
  gap: "10px",
  height: "44px",
}));

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 28px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.header1};
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const InfoBoxText = styled.div`
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.bodyS}
`;

export default Airdrops;
